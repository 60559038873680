.c-progress-bar {
  $this: &;
  box-shadow: none;
  position: relative;
  width: 100%;
  z-index: 101;

  &--max4 {
    #{$this}-list__item {
      width: calc(100% / 4);
    }
  }

  &-list {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    list-style-type: none;
    margin: 15px 0 20px 0;
    padding: 0;
    &__item {
      align-items: center;
      background: transparent;
      border: none;
      display: flex;
      justify-content: center;
      padding: 0;
      width: calc(100% / 3);
      &.is-active {
        #{$this}__label {
          color: $appColorPrimary1;
        }
        #{$this}__number {
          background: $appColorPrimary1;
          border: 4px $appColorPrimary1 solid;
          color: $appColorWhite;
        }
      }
    }
  }

  &__number {
    align-items: center;
    background: $appColorGrayScale6;
    border-radius: 50%;
    color: $appColorWhite;
    content: attr(data-text);
    display: flex;
    font-weight: bold;
    height: 32px;
    justify-content: center;
    margin: 0 15px 0 0;
    transition: background 300ms, color 300ms;
    width: 32px;
  }

  &__label {
    @include ellipsis();
    align-items: center;
    color: $appColorGrayScale6;
    display: block;
    font-size: $appFontSizeM;
    font-weight: $appFontWeightExtraBold;
    transition: color 300ms;
    max-width: calc(100% - 47px - 17.5px);
  }

  .progress {
    height: 4px;
    margin-bottom: 0 !important;
    &-bar {
      background-color: $appColorPrimary3;
    }
  }
}

@media (max-width: $appBreakPoint) {
  .c-progress-bar {
    $this: &;

    &--max4 {
      #{$this}-list__item {
        width: 100%;
      }
    }

    &-list {
      flex-direction: column;
      margin: 0;
      &__item {
        justify-content: flex-start;
        margin-bottom: 10px;
        width: 100%;
        &:last-child {
          margin-bottom: 0;
        }
        &.is-active {
          #{$this}__label {
            color: $appColorPrimary1;
          }
        }
      }
    }
    &__label {
      &::before {
        display: inline;
        content: attr(data-text);
        padding: 0 5px 0 0;
      }
      max-width: 100%;
    }
    &__number {
      display: none;
    }
  }
  .progress {
    display: none;
  }
}
