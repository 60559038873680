.modal.show {
  align-content: center;
  align-items: center;
  display: flex !important;
  justify-content: center;
  .modal-dialog {
    margin: 0 auto;
  }
}

.c-modal {
  $this: &;
  max-width: 500px;
  min-width: calc(360px * 0.92);
  width: 500px;

  &--preview {
    max-width: 740px;
    width: 740px;
    .modal {
      &-content {
        border-radius: $appRadiusS !important;
      }
      &-body {
        padding: 0 !important;
      }
    }
  }

  .btn-group {
    align-items: center;
    display: flex;
    justify-content: center;
  }
  .c-btn {
    &--close {
      background: none !important;
      border: none !important;
      color: #fff;
      font-size: 35px;
      position: absolute;
      right: -40px;
      top: -40px;
      padding: 0;
      width: auto;
    }
  }
  .modal {
    &-content {
      border-radius: $appRadiusXL;
    }
    &-header {
      box-sizing: border-box;
      overflow: visible;
      padding: 20px;
      position: relative;
      visibility: visible;
      &.is-ellipsis {
        padding-bottom: 13px;
      }
    }
    &-title {
      color: $appColorBlack;
      font-size: $appFontSizeL;
      font-weight: bold;
      width: 100%;
      .ellipsis {
        @include ellipsis();
        display: inline-block;
        max-width: 100%;
      }
      .no-ellipsis {
        display: inline-block;
        overflow: hidden;
      }
    }
    &-body {
      font-size: $appFontSizeM;
      padding: 20px;
      .preview-content {
        overflow: hidden;
        width: 100%;
        &--movie {
          aspect-ratio: 16 / 9;
        }
        img {
          width: 100%;
        }
        iframe {
          display: block;
          height: 100%;
          width: 100%;
        }
      }
      .error-message-list-wrapper {
        margin: 0 0 20px 0;
        .btn-close {
          right: -1px;
          top: 7px;
        }
      }
      .result-message-list-wrapper {
        margin: 0 0 20px 0;
        .btn-close {
          right: -1px;
          top: 7px;
        }
      }
    }
  }

  // 確認用モーダル
  &--confirm {
    #{$this}-content {
      margin: 0;
      &__item {
        margin: 0;
        .c-text {
          margin-top: 10px;
          padding: 0;
          p {
            margin: 0 0 0 0;
          }
        }
        &--btns {
          justify-content: center;
          margin: 30px 0 20px 0;
          .c-btn {
            align-items: center;
            display: flex;
            justify-content: center;
            margin-left: 0;
            width: 190px;
          }
          .c-btn + .c-btn {
            margin-left: 7.5px;
          }
        }
      }
    }
  }
}

@media (max-width: 840px) {
  .c-modal {
    &--preview {
      max-width: 660px;
      width: 660px;
    }
  }
}

@media (max-width: $appBreakPoint) {
  .c-modal {
    $this: &;
    margin: 0 auto;
    width: 92%;
    .c-btn {
      &--close {
        right: -15px;
      }
    }
    .modal {
      &-content {
        border-radius: $appRadiusM;
        margin: 60px 0 60px 0;
      }
      &-header {
        box-sizing: border-box;
        overflow: visible;
        padding: 15px;
        position: relative;
        visibility: visible;
        &.is-ellipsis {
          padding-bottom: 8px;
        }
      }
      &-title {
        color: $appColorBlack;
        font-size: $appFontSizeL;
        font-weight: bold;
      }

      &-body {
        height: auto;
        overflow: visible;
        padding: 15px;
      }
    }

    // 確認用モーダル
    &--confirm {
      #{$this}-content {
        &__item {
          .c-text {
            font-size: $appFontSizeS;
          }
          &--btns {
            // flex-direction: column-reverse !important;
            flex-direction: column !important;
            margin: 20px 0 10px 0;
            .c-btn {
              // margin: 15px 0 0 0;
              margin: 0;
              width: 100%;
            }
            .c-btn + .c-btn {
              // margin: 0 0;
              margin: 15px 0 0 0;
            }
          }
        }
      }
    }
  }
}
