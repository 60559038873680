.p-connection-list__item {
  .connection-avatar {
    justify-content: start !important;

    img {
      display: block;
      height: 100%;
      object-fit: cover;
    }
  }

  .connection-detail {
    align-items: flex-start !important;
    flex-grow: 0 !important;
    flex-direction: column !important;
    min-width: 600px !important;

    .location {
      color: gray !important;
    }

    .connection {
      color: gray !important;
    }
  }

  .badge-primary {
    background: #008bcc !important;
  }
}

.card-image-circle {
  border-radius: 50%;
  align-items: center;
  cursor: pointer;
  display: flex;
  justify-content: center;
  position: relative;
  .col {
    align-items: center;
    background: #fff;
    border-radius: 50%;
    display: flex;
    flex-basis: auto;
    flex-grow: 0;
    justify-content: center;
    margin: 0;
    overflow: hidden;
    padding: 0;
  }
  img {
    display: block;
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}
