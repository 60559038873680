.p-deal-subscribe {
  $this: &;

  &-container {
    display: flex;
    flex-direction: column;
    gap: 30px;
    padding: 0;
    .col,
    .row {
      margin: 0;
      padding: 0;
    }
    &-head {
      display: flex;
      flex-direction: row;
      gap: 30px;
      justify-content: space-between;
      &__item {
        &--logo {
          max-width: 200px;
          min-width: 200px;
          width: 200px;
        }
        &--summary {
          box-sizing: border-box;
          display: flex;
          flex-direction: column;
          gap: 16px;
          max-width: calc(100% - 230px);
          min-width: calc(100% - 230px);
          width: calc(100% - 230px);
          &.row {
            padding: 12px 0;
          }
          .c-btn {
            width: 250px;
          }
        }
      }
      // &__btn {
      //   margin-top: 8px;
      // }
    }
    &-body {
      display: flex;
      flex-direction: column;
      gap: 40px;
      justify-content: space-between;
      width: 100%;
      &__caption {
        p {
          margin: 0;
          padding: 0;
        }
      }
    }
    &-foot {
      display: flex;
      justify-content: center;
      .c-btn {
        width: 250px;
      }
    }
  }

  &__logo {
    img {
      width: 100%;
    }
    &--no-image {
      align-items: center;
      background: $appColorGrayScale9;
      border-radius: $appRadiusL;
      display: flex;
      justify-content: center;
      height: 200px;
      i {
        color: $appColorGrayScale8;
        font-size: 104px;
      }
    }
  }

  &__title {
    box-sizing: border-box;
    border-bottom: 1px var(--app-color-gray-scale-6) solid;
    font-size: $appFontSize3XL + 4px;
    font-weight: $appFontWeightExtraBold;
    margin: 0;
    padding-bottom: 12px;
  }

  &__caption {
    box-sizing: border-box;
    font-size: $appFontSizeM;
    margin: 0;
    padding: 4px 0;
  }

  &__tags {
    align-items: flex-start;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: 8px;
    width: 100%;
  }

  &--edit,
  &--edit.card,
  &--preview,
  &--preview.card,
  &--complete,
  &--complete.card {
    background: $appColorWhite;
    border-radius: 8px;
    box-sizing: border-box;
    margin-top: 20px;
    padding: 45px 40px;
    width: 100%;

    .row {
      margin: 0;
      padding: 0;
    }

    #{$this}__block {
      padding: 0;
      min-height: auto;
    }

    #{$this}-form {
      align-items: flex-start;
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      gap: 40px;
      width: 100%;

      &__title {
        box-sizing: border-box;
        border-bottom: 1px var(--app-color-gray-scale-6) solid;
        font-size: $appFontSizeXL + 2px;
        font-weight: $appFontWeightExtraBold;
        margin: 0 0 8px 0;
        padding-bottom: 12px;
        width: 100%;
      }

      &.col,
      &.row {
        margin: 0;
        padding: 0;
      }

      &__block {
        align-items: flex-start;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        gap: 24px;
        width: 100%;
      }

      &__supply {
        &-text {
          color: $appColorFreeRed;
          font-size: $appFontSizeS;
          font-weight: $appFontWeightExtraBold;
          margin: 8px 0 20px 0;
          p {
            margin: 0;
            padding: 0;
          }
        }
      }

      &__item {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        margin: 0;
        position: relative;
        width: 100%;

        .form-group {
          width: 100%;
        }

        .supply {
          color: $appColorGrayScale11;
          display: flex;
          flex-direction: column;
          font-size: $appFontSizeS;
          gap: 4px;
          padding-top: 8px;
          width: 100%;
          p {
            margin: 0;
            padding: 0;
          }
        }

        // ------------------------------------
        // フォームパーツの調整: 吹き出し位置
        // ------------------------------------
        // X軸位置揃え
        &--establishment-on {
          .form-group {
            .invalid-feedback {
              right: auto;
              left: 60px;
            }
          }
        }

        // Y軸位置揃え: 画面幅によって、エラー文言が複数行にな吹き出し対応
        &--email {
          .form-group {
            .invalid-feedback {
              bottom: 48px;
              top: auto;
            }
          }
        }

        &--survey-type {
          .form-group {
            .invalid-feedback {
              right: auto;
              left: 144px;
            }
          }
        }

        // ------------------------------------
        // フォームパーツの調整: 入力グループ系
        // ------------------------------------
        &--establishment-on {
          justify-content: flex-start;
          .form-group {
            align-items: center;
            display: flex;
            justify-content: flex-start;
            width: auto;
          }
          .year {
            max-width: 260px;
            min-width: 260px;
            width: 260px;
          }
          .month {
            max-width: 100px;
            min-width: 100px;
            width: 100px;
            label {
              height: 21px;
              overflow: hidden;
              visibility: hidden;
            }
          }
          .year,
          .month {
            padding: 0;
          }
          .unit {
            align-items: center;
            box-sizing: border-box;
            max-width: 35px;
            padding: 25px 0 0 0;
            text-align: center;
            width: 35px;
          }
        }

        &--survey-type {
          .form-group {
            legend {
              margin-bottom: 0;
              label {
                margin-bottom: 0;
              }
            }
          }
          .c-radio {
            .form-control {
              padding-bottom: 0 !important;
            }
            .custom-control-label {
              font-weight: $appFontWeightNormal;
            }
          }
          .c-radio.form-group + .form-group {
            margin-top: 12px;
          }
        }

        &--survey-text {
          margin-top: 12px;
          .form-group {
            .invalid-feedback {
              top: -40px;
              z-index: 1001;
            }
          }
        }

        // ------------------------------------
        // フォームパーツの調整: テキストエリア
        // ------------------------------------
        &--consultation {
          .c-input {
            height: 240px;
            min-height: 150px;
          }
          .form-group {
            .invalid-feedback {
              top: -40px;
            }
          }
        }
        &--consultation,
        &--overview {
          .c-input {
            height: 210px;
            min-height: 210px;
          }
        }
        .form-group {
          margin-bottom: 0;
          label {
            font-size: $appFontSizeM;
            font-weight: $appFontWeightExtraBold;
            margin-bottom: 5px;
          }
        }
        &--btns {
          flex-direction: row;
          margin: 20px 0 5px 0;
          // margin: 45px 0 5px 0;
          justify-content: center;
          button.c-btn {
            font-size: $appFontSizeL;
          }
          .c-btn {
            margin: 0 7.5px;
            width: 265px;
          }
        }
      }
    }
  }

  &--preview,
  &--preview.card {
    padding-top: 55px;
    flex-wrap: wrap;
    #{$this}-form {
      flex-wrap: wrap;
      &__block {
        gap: 20px;
      }

      &__item {
        border-bottom: 1px solid $appColorGrayScale6;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        padding-bottom: 15px;
        .label {
          flex-basis: auto;
          flex-grow: 0;
          font-size: $appFontSizeM;
          font-weight: $appFontWeightExtraBold;
          padding: 0 0 0 0;
          min-width: 220px;
          width: 220px;
        }
        .value {
          flex-basis: auto;
          flex-grow: 1;
          font-size: $appFontSizeM;
          padding: 0 0 0 0;
          width: calc(100% - 220px);
        }
        &--employee-count-type {
          .label {
            min-width: 340px;
            width: 340px;
          }
          .value {
            width: calc(100% - 340px);
          }
        }

        &--survey {
          flex-wrap: wrap;
          .label {
            min-width: 495px;
            width: 495px;
          }
          .value {
            width: calc(100% - 495px);
          }
          .text {
            padding: 8px 0;
            width: 100%;
          }
        }

        &--consent,
        &--btns {
          border-bottom: 0;
        }
        &--btns {
          justify-content: center;
          margin: 45px 0 5px 0;
          padding-bottom: 0;
          .c-btn {
            margin: 0 7.5px;
          }
        }
        &--consent {
          margin-bottom: 0;
          padding-bottom: 0;
          .nav {
            align-items: center;
            box-sizing: border-box;
            display: flex;
            justify-content: flex-start;
            padding-left: 33px;
            width: 100%;
            .separator {
              display: block;
              flex-basis: auto;
              flex-grow: 0;
              text-align: center;
              width: 30px;
            }
            a {
              color: $appColorPrimary4;
              font-size: $appFontSizeM;
              // font-weight: $appFontWeightExtraBold;
              position: relative;
              text-align: center;
              &:hover,
              &:active,
              &:visited {
                color: $appColorPrimary4;
              }
              &:hover {
                text-decoration: underline;
              }
            }
          }
          .form-group {
            background: $appColorGrayScale9;
            border-radius: $appRadiusM;
            box-sizing: border-box;
            padding: 25px;
            width: 100%;
            .invalid-feedback {
              left: 25px;
              right: auto;
              top: -10px;
              &::before {
                left: auto;
                right: auto;
              }
            }
          }
          .c-checkbox {
            .form-control {
              padding: 15px 0 !important;
            }
            &__item {
              .custom-control-label {
                display: flex;
                flex-direction: column;
                font-weight: $appFontWeightNormal;
                padding-left: 32px;
                &::before,
                &::after {
                  margin-top: 1px;
                  top: 2px;
                }
                p {
                  margin-bottom: 0;
                  width: 100%;
                }
              }
            }
          }
        }
      }
    }
  }

  &--complete,
  &--complete.card {
    #{$this}-form {
      flex-direction: column;
      &__item {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        margin: 0;
        padding: 0;
        width: 100%;
        &--image {
          color: $appColorGrayScale3;
          padding: 20px 0 30px 0;
          i {
            font-size: 110px;
            line-height: 110px;
            margin: 0 auto;
          }
        }
        &--text {
          font-size: $appFontSize2XL;
          justify-content: center;
        }
        &--supplement {
          flex-direction: column;
          font-size: $appFontSizeBase;
          margin-top: 36px;
          justify-content: center;
          p {
            margin-bottom: 4px;
            text-align: center;
            width: 100%;
            &.warning {
              color: $appColorFreeRed;
              font-weight: $appFontWeightExtraBold;
            }
          }
        }
        &--btns {
          justify-content: center;
          margin: 45px 0;
          .c-btn {
            width: 265px;
          }
        }
      }
    }
  }
}

@media (max-width: $appBreakPoint) {
  .p-deal-subscribe {
    $this: &;

    &-container {
      &-head {
        flex-direction: column;
        &__item {
          max-width: 100%;
          min-width: 100%;
          width: 100%;
          &--logo {
            display: flex;
            justify-content: center;
          }
        }
      }
      &-body {
        &__caption {
          font-size: $appFontSizeS;
          p {
            display: inline;
          }
        }
      }
    }

    &__logo {
      max-width: 200px;
      min-width: 200px;
      width: 200px;
    }

    &__title {
      font-size: $appFontSize2XL;
    }

    &__caption {
      font-size: $appFontSizeS;
    }

    &--edit,
    &--edit.card,
    &--edit-option,
    &--edit-option.card,
    &--preview,
    &--preview.card,
    &--complete,
    &--complete.card {
      border-radius: $appRadiusM;
      padding: 32px 24px;

      #{$this}-form {
        flex-direction: column;

        &__supply {
          &-text {
            color: $appColorFreeRed;
            font-size: $appFontSizeS - 1;
            font-weight: $appFontWeightExtraBold;
            margin: 8px 0 5px 0;
          }
        }

        &__block {
          &--image {
            margin-bottom: 10px;
            max-width: 100%;
            min-width: 100%;
            width: 100%;
            #{$this}-form {
              &__item {
                .form-group {
                  display: flex;
                  justify-content: center;
                }
                .c-upload-supply {
                  display: none;
                }
              }
            }
          }
          &--base-data {
            max-width: 100%;
            min-width: 100%;
            padding-left: 0;
            width: 100%;
          }
          &--detail-data {
            margin-top: 0;
          }
        }

        &__item {
          margin: 0 0 15px 0;
          .form-group {
            label {
              font-size: $appFontSizeS;
              margin-bottom: 5px;
            }
            .c-input {
              font-size: $appFontSizeS;
            }
          }

          &--logo {
            .form-group {
              .invalid-feedback {
                width: auto;
              }
            }
          }

          &--establishment-on {
            align-items: center;
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            justify-content: space-between;
            width: 100%;
            .form-group {
              width: 100%;
            }
            .unit {
              max-width: 30px;
              width: 30px;
            }
          }

          &--establishment-on {
            .year {
              max-width: 180px;
              min-width: 180px;
              width: 180px;
            }
          }

          &--btns {
            display: flex;
            // flex-direction: column-reverse;
            flex-direction: column;
            margin: 17px 0 0 0;
            .c-btn {
              margin: 0;
              width: 100%;
            }
            .c-btn + .c-btn {
              // margin: 0 0 15px 0;
              margin: 15px 0 0 0;
            }
          }
        }
      }
    }

    &--preview,
    &--preview.card {
      #{$this}-form {
        &__block {
          &--image {
            display: flex;
            justify-content: center;
            margin-bottom: 35px;
            width: 100%;
          }
        }

        &__item {
          flex-direction: column;
          margin-bottom: 15px;
          padding-bottom: 10px;

          .label {
            font-size: $appFontSizeS;
            min-width: 100%;
            width: 100%;
            // min-width: 160px;
            // width: 160px;
          }
          .value {
            box-sizing: border-box;
            font-size: $appFontSizeS;
            // padding-left: 15px;
            padding-left: 0;
            width: 100%;
          }

          &--btns {
            display: flex;
            // flex-direction: column-reverse;
            flex-direction: column;
            margin: 32px 0 0 0;
            .c-btn {
              margin: 0;
              width: 100%;
            }
            .c-btn + .c-btn {
              // margin: 0 0 15px 0;
              margin: 15px 0 0 0;
            }
          }

          &--consent {
            margin-bottom: 0;
            padding-bottom: 0;
            .nav {
              padding-left: 33px;
              width: 100%;
              a {
                font-size: $appFontSizeS;
              }
            }
            .form-group {
              padding: 5px 20px;
              .invalid-feedback {
                top: -25px;
              }
            }
            .c-checkbox {
              &.form-group {
                .form-control {
                  padding: 15px 0 !important;
                }
              }
              &__item {
                margin-bottom: 0;
                .custom-control-label {
                  font-size: $appFontSizeS;
                }
              }
            }
          }
        }
      }
    }

    &--complete,
    &--complete.card {
      #{$this}-form {
        &__item {
          &--image {
            i {
              font-size: 80px;
              line-height: 80px;
              margin: 0 auto;
            }
          }
          &--text {
            font-size: $appFontSizeL;
            justify-content: center;
          }
          &--supplement {
            font-size: $appFontSizeS;
            margin-top: 18px;
          }
          &--btns {
            // flex-direction: column-reverse;
            flex-direction: column;
            justify-content: center;
            margin: 45px 0 20px 0;
            .c-btn {
              margin: 0;
              width: 100%;
            }
            .c-btn + .c-btn {
              // margin: 0 0 15px 0;
              margin: 15px 0 0 0;
            }
          }
        }
      }
    }
  }
}
