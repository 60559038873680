.p-notification {
  $this: &;
  margin: 0 auto 15px auto;

  .row {
    margin: 0;
  }

  &-link {
    color: #000000 !important;
    .value--message:hover {
      text-decoration: underline;
    }
  }

  &--company-invitation {
    #{$this}-company-invitation-list {
      &__item {
        align-items: center;
        background: $appColorWhite;
        border: none;
        border-radius: $appRadiusM;
        display: flex;
        flex-wrap: wrap;
        gap: 0 12px;
        list-style: none;
        margin: 0;
        padding: 15px 15px;
        width: 100%;
        &:last-child {
          border-bottom: none;
        }
        &:hover {
          background: $appColorGrayScale9;
        }
        .value {
          align-items: center;
          display: flex;
          flex-grow: 0;
          &.col {
            padding: 0;
          }
          &-group {
            align-items: stretch;
            display: flex;
            &--title {
              align-items: center;
              flex-grow: 1;
              width: calc(100% - 240px);
            }
            &--btns {
              box-sizing: border-box;
              max-width: 228px;
              min-width: 228px;
              padding: 0;
              width: auto;
              .c-btn {
                height: 40px;
                margin: 0;
                width: 110px;
                &__text {
                  font-size: $appFontSizeM;
                }
              }
              .c-btn + .c-btn {
                margin-left: 8px;
              }
            }
          }
          &--invitation-message {
            display: block;
            flex-grow: 1;
            min-width: 200px;
            width: auto;
            p {
              display: inline-block;
              margin: 0;
              &.date {
                color: $appColorGrayScale11;
                display: block;
                font-size: $appFontSizeXS;
                width: 100%;
              }
            }
          }
        }
      }

      #{$this}-company-invitation-list__item
        + #{$this}-company-invitation-list__item {
        margin-top: 15px;
      }
    }
  }

  &-head {
    align-items: center;
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-end;
    width: 100%;
    &__item {
      &.row {
        margin-bottom: 15px;
      }
      &--keyword {
        position: relative;
        max-width: 300px;
        min-width: 300px;
        transition: max-width 0.3s, min-width 0.3s, width 0.3s;
        width: 300px;
        .c-icon {
          color: $appColorGrayScale6;
          font-size: 36px;
          left: 1px;
          position: absolute;
          top: 8px;
          transform: rotate(-45deg);
          z-index: 1001;
        }
        .c-input {
          border-radius: 50px;
          padding-left: 36px;
          padding-right: 18px;
          transition: width 0.3s;
          width: 300px;
          z-index: 1000;
        }
        .form-group {
          margin: 0;
          .invalid-feedback {
            top: -40px;
          }
        }
      }
    }
  }

  &-list {
    background: none;
    border-radius: $appRadiusM;
    list-style: none;
    margin: 0;
    padding: 0;
    &__item {
      align-items: center;
      background: $appColorWhite;
      border: none !important;
      border-bottom: 1px $appColorGrayScale6 solid !important;
      border-radius: 0 !important;
      display: flex;
      flex-wrap: wrap;
      gap: 10px 12px;
      list-style: none;
      margin: 0;
      padding: 15px 15px;
      width: 100%;

      &:last-child {
        border-bottom: none;
      }

      &:hover {
        background: $appColorGrayScale9;
      }

      &--head {
        background: $appColorGrayScale9;
        padding: 20px 0 12.5px 0;
        .label,
        .value {
          align-items: center;
          display: flex;
          justify-content: flex-start;
          font-weight: $appFontWeightExtraBold;
        }
        &:hover {
          background: $appColorGrayScale9;
        }
      }

      &--empty {
        &:hover {
          background: $appColorWhite;
        }
        .col.value {
          max-width: 100%;
          min-width: 100%;
          padding: 15px 15px !important;
          text-align: center;
          width: 100%;
        }
      }

      .col.label,
      .col.value {
        padding: 0;
      }

      .label,
      .value {
        align-items: center;
        display: flex;
        flex-grow: 0;
        &.is-sortable {
          cursor: pointer;
        }
        &-group {
          align-items: stretch;
          display: flex;
          &--title {
            align-items: center;
            flex-grow: 1;
            width: calc(100% - 324px);
          }
        }
        &--message {
          flex-grow: 1;
          min-width: 200px;
          width: auto;
        }
        &--title {
          max-width: 150px;
          min-width: 150px;
          width: auto;
        }
        &--type {
          justify-content: center;
          max-width: 120px;
          min-width: 120px;
          width: auto;
        }
        &--created-at {
          justify-content: center;
          max-width: 180px;
          min-width: 180px;
          width: auto;
        }
      }

      .c-badge {
        border-radius: $appRadiusM;
        font-size: $appFontSizeS;
        height: 30px;
        min-width: 70px;
        padding: 0 10px;
        width: auto;
      }

      .value {
        &--image {
          padding-right: 0 !important;
        }
        &--control {
          align-items: center;
          cursor: pointer;
          flex-direction: row;
          flex-wrap: wrap;
          display: flex;
          justify-content: center;
          text-align: center;
          .c-menu-icon + .c-menu-icon {
            margin-left: 6px;
          }
        }
      }
    }
  }
}

@media (max-width: 920px) {
  .p-notification {
    $this: &;

    &-list {
      &__item {
        .label,
        .value {
          &-group {
            &--title {
              width: 100%;
            }
          }
          &--type {
            justify-content: flex-start;
          }
          &--created-at {
            justify-content: flex-start;
          }
        }
      }
    }
  }
}

@media (max-width: $appBreakPoint) {
  .p-notification {
    $this: &;
    margin: 0 auto 16px auto;

    #{$this}-company-invitation-list {
      &__item {
        align-items: center;
        background: $appColorWhite;
        border: none;
        border-radius: $appRadiusM;
        display: flex;
        flex-wrap: wrap;
        gap: 10px 0;
        list-style: none;
        margin: 0;
        padding: 15px !important;
        width: 100%;
        &:last-child {
          border-bottom: none;
        }
        &:hover {
          background: $appColorGrayScale9;
        }
        .value {
          font-size: $appFontSizeS;
          &-group {
            &--title {
              width: 100%;
            }
            &--btns {
              box-sizing: border-box;
              display: flex;
              justify-content: flex-end;
              max-width: 100%;
              min-width: 100%;
              padding: 0;
              width: 100%;
              .c-btn {
                height: 32px;
                margin: 0;
                width: 110px;
                &__text {
                  font-size: $appFontSizeS;
                }
              }
              .c-btn + .c-btn {
                margin-left: 8px;
              }
            }
          }
        }
      }
      #{$this}-company-invitation-list__item
        + #{$this}-company-invitation-list__item {
        margin-top: 15px;
      }
    }

    &-head {
      flex-direction: column;
      flex-wrap: wrap;
      width: 100%;
      &__item {
        &--keyword {
          max-width: 100%;
          min-width: 100%;
          width: 100%;
          .c-input {
            width: 100%;
          }
          .form-group {
            width: 100%;
          }
        }
      }
    }

    &-list {
      &__item {
        gap: 0 12px;
        padding: 15px !important;
        &--head {
          .label {
            &--control {
              display: none !important;
            }
          }
        }
        .col {
          font-size: $appFontSizeS;
          padding: 2.5px !important;
        }
        .col.value--image + .col.value--title {
          padding-left: 5px !important;
        }
        .label,
        .value {
          &-group {
            justify-content: space-between;
            width: 100%;
            &--title {
              width: 100%;
            }
            .value {
              &--control {
                align-items: flex-end;
                justify-content: flex-end;
                margin-top: 0;
                max-width: 50%;
                min-width: 50%;
                width: 50%;
              }
            }
          }

          &--type {
            max-width: 40%;
            min-width: 40%;
            width: 40%;
          }
          &--created-at {
            justify-content: flex-end;
            max-width: 60%;
            min-width: 60%;
            width: 60%;
          }
        }
      }
    }
  }
}
