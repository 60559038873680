.p-deal {
  $this: &;
  background: $appColorWhite;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.075);
  margin: 0;
  padding: 0;
  border-radius: $appRadiusXL;
  box-sizing: border-box;
  color: $appColorBlack;
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding: 45px 40px;
  position: relative;
  width: 100%;
  z-index: 100;

  .c-message + &--preview {
    margin-top: 20px;
  }

  &-header {
    display: flex;
    flex-direction: row;
    gap: 30px;
    justify-content: space-between;
    &__block {
      &--logo {
        max-width: 200px;
        min-width: 200px;
        width: 200px;
      }
      &--summary {
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        gap: 16px;
        max-width: calc(100% - 230px);
        min-width: calc(100% - 230px);
        padding: 12px 0;
        width: calc(100% - 230px);
      }
    }
  }

  &-body {
    display: flex;
    flex-direction: column;
    gap: 30px;
    justify-content: space-between;
    width: 100%;
    &::before {
      border-top: 1px $appColorGrayScale6 solid;
      content: '';
      display: block;
      height: 0;
    }
    &-list {
      display: flex;
      flex-direction: column;
      gap: 36px;
      width: 100%;
      &-item {
        display: flex;
        flex-direction: column;
        gap: 12px;
        width: 100%;
        &.is-double-margin {
          gap: 24px;
        }
        &__label {
          align-items: center;
          display: flex;
          font-size: $appFontSizeL;
          font-weight: $appFontWeightExtraBold;
          justify-content: flex-start;
          margin: 0;
          padding: 0;
          &.is-border {
            border-bottom: 1px $appColorGrayScale6 solid;
            padding-bottom: 12px;
          }
        }

        &__value {
          background: $appColorGrayScale9;
          border-radius: $appRadiusXL;
          box-sizing: border-box;
          font-size: $appFontSizeM;
          margin: 0;
          padding: 24px;
          width: 100%;
          a {
            color: $appColorPrimary4;
            font-size: $appFontSizeM;
            font-weight: $appFontWeightNormal;
            position: relative;
            text-align: center;
            text-decoration: none;
            &:hover,
            &:active,
            &:visited {
              color: $appColorPrimary4;
            }
            &:hover {
              text-decoration: underline;
            }
          }
        }
      }
    }
  }

  &__logo {
    img {
      width: 100%;
    }
    &--none {
      align-items: center;
      background: $appColorGrayScale9;
      border-radius: $appRadiusL;
      display: flex;
      justify-content: center;
      height: 200px;
      i {
        color: $appColorGrayScale8;
        font-size: 104px;
      }
    }
  }

  &__name {
    box-sizing: border-box;
    border-bottom: 1px $appColorGrayScale6 solid;
    font-size: calc($appFontSize3XL + 4px);
    font-weight: $appFontWeightExtraBold;
    margin: 0;
    padding-bottom: 12px;
  }

  &__caption {
    box-sizing: border-box;
    font-size: $appFontSizeM;
    margin: 0;
    padding: 4px 0;
  }

  &-subscribe {
    &__btn {
      height: 45px;
      width: 250px;
    }
  }

  &-category {
    &-list {
      align-items: flex-start;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      line-height: 0;
      justify-content: flex-start;
      gap: 8px;
      width: 100%;
      &__item {
        line-height: 0;
        max-width: 100%;
        width: auto;
      }
    }
  }

  &-supervisor {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 12px;
    justify-content: center;

    &-list {
      align-items: stretch;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 24px;
      justify-content: flex-start;
      width: 100%;

      &.is-loading {
        .c-loader {
          &-container {
            height: auto;
            padding: 82px 0;
            width: 100%;
          }
        }
      }

      &__item {
        width: 130px;
      }
    }
    &__image {
      align-items: center;
      background: $appColorWhite;
      border: 8px solid $appColorGrayScale8;
      border-radius: 50%;
      box-sizing: border-box;
      display: flex;
      max-height: 130px;
      min-height: 130px;
      height: 130px;
      justify-content: center;
      overflow: hidden;
      max-width: 130px;
      min-width: 130px;
      width: 130px;
      &:hover {
        opacity: 0.9;
      }
      img {
        width: 100%;
      }
      &--none {
        background: $appColorGrayScale9;
      }
      &--user {
        i {
          color: $appColorGrayScale8;
          font-size: 78px;
        }
      }
      &--company {
        i {
          color: $appColorGrayScale8;
          font-size: calc(78px * 0.85);
        }
      }
    }
    &__name {
      color: $appColorPrimary4;
      overflow: hidden;
      text-align: center;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 100%;
      &:hover {
        text-decoration: underline;
      }
    }
  }

  &-introduction {
    &-list {
      align-items: stretch;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 12px;
      justify-content: flex-start;
      &__item {
        cursor: pointer;
        display: block;
        height: 150px;
        position: relative;
        width: calc((100% / 3) - (24px / 3));
        &:hover {
          opacity: 0.8;
        }
      }
    }
    &__image {
      align-items: center;
      background-color: $appColorGrayScale9;
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100%;
      width: 100%;
    }
  }
}

@media (max-width: 860px) {
  .p-deal {
    &-introduction {
      &-list {
        &__item {
          width: calc((100% / 2) - (12px / 2));
        }
      }
    }
  }
}

@media (max-width: $appBreakPoint) {
  .p-deal {
    border-radius: var(--app-radius-m);
    padding: 48px 24px 32px 24px;

    &-header {
      flex-direction: column;
      &__block {
        max-width: 100%;
        min-width: 100%;
        width: 100%;
        &--logo {
          display: flex;
          justify-content: center;
        }
      }
    }

    &-body {
      &-list {
        &-item {
          &__value {
            font-size: var(--app-font-size-s);
            padding: 14px;
            a {
              font-size: var(--app-font-size-s);
            }
          }
        }
      }
    }

    &__logo {
      max-width: 200px;
      min-width: 200px;
      width: 200px;
      img {
        width: 100%;
      }
    }

    &__name {
      font-size: var(--app-font-size-2xl);
    }

    &__caption {
      font-size: var(--app-font-size-s);
    }
    &-subscribe {
      &__btn {
        width: 100%;
      }
    }
  }
}

@media (max-width: 600px) {
  .p-deal {
    &-introduction {
      &-list {
        &__item {
          height: 180px;
          width: 100%;
        }
      }
    }
    &-supervisor {
      &-list {
        align-items: stretch;
        flex-wrap: wrap;
        gap: 16px 12px;
        justify-content: space-between;
        &__item {
          width: calc(50% - 12px / 2);
        }
      }
      &__image {
        max-width: 140px;
        min-width: 140px;
        width: 140px;
      }
      &__name {
        font-size: var(--app-font-size-s);
      }
    }
  }
}
