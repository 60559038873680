/**
 * ヘッダー
 */
.l-header {
  $this: &;
  align-items: center;
  background: $appColorGrayScale8;
  border-bottom: 1px $appColorGrayScale12 solid;
  box-sizing: border-box;
  display: flex;
  height: 60px;
  justify-content: space-between;
  min-width: 360px;
  position: fixed;
  top: 0;
  transition: background 0.3s, height 0.3s;
  width: 100%;
  z-index: 8100;

  &--auth {
    background: transparent;
    border: none;
    display: block;
    height: auto;
    position: absolute;
    width: auto;
    #{$this}__logo {
      margin: 25px 0 0 25px;
      width: 120px;
    }
  }

  &--no-login {
    #{$this}__item {
      &--account {
        flex-grow: 99;
      }
    }
  }

  &__logo {
    margin: -3px 0 0 15px;
    width: 75px;
    img {
      display: block;
      margin-top: 2px;
      width: 100%;
      &.white {
        display: none;
      }
    }
  }

  &__block {
    transition: opacity 300ms, width 300ms;
    &--logo {
      width: 200px;
    }
    &--search {
      flex-grow: 99;
    }
    &--other {
      align-items: center;
      display: flex;
      flex-grow: 1;
      justify-content: flex-end;
    }
  }

  &__item {
    align-items: center;
    background: $appColorGrayScale8;
    // border-bottom: 1px $appColorGrayScale12 solid;
    box-sizing: border-box;
    display: flex;
    height: 59px;
    max-height: 59px;
    transition: background 0.3s, height 0.3s, opacity 0.3s, width 0.3s;
    .col {
      transition: background 0.3s;
    }
    &--account {
      // background: transparent;
      justify-content: flex-end;
      padding: 0 0;
      z-index: 8002;
    }

    &--signup {
      // background: transparent;
      justify-content: flex-end;
      padding: 0 12px 0 0;
      z-index: 8002;
      .c-btn {
        &--conversion {
          height: 38px;
          width: 140px;
          .c-btn {
            &__text {
              font-size: $appFontSizeM;
            }
          }
        }
      }
    }

    &--signout {
      box-sizing: border-box;
      padding: 0 14px;
      a {
        color: $appColorBlack;
        font-size: $appFontSizeM;
        // font-weight: $appFontWeightExtraBold;
        &:hover,
        &:active,
        &:visited {
          color: $appColorBlack;
        }
        &:hover {
          text-decoration: underline;
        }
      }
    }

    &--notification {
      width: 108px;
      z-index: 8000;
    }

    &--invitation {
      width: 54px;
      z-index: 8100;
    }

    &--toggle {
      width: 155px;
      z-index: 8000;
      &-user-work-status-workable-am {
        width: 210px;
      }
    }
    &--menu {
      width: 0;
      overflow: hidden;
      z-index: 8000;
    }
  }

  &-invitation,
  &-notification {
    &-list {
      border-radius: 0;
      display: flex;
      flex-direction: row-reverse;
      height: 100%;
      justify-content: flex-start;
      list-style: none;
      &__item {
        background: transparent;
        border: none;
        box-sizing: border-box;
        padding: 0;
        .col {
          align-items: center;
          box-sizing: border-box;
          cursor: pointer;
          display: flex;
          height: 100%;
          padding: 0 15px;
          position: relative;
          &:hover {
            background: $appColorGrayScale2Alpha;
            i {
              color: $appColorGrayScale2;
            }
          }
          &:active,
          &:visited {
            background: none;
          }
          &.is-badge {
            &::before {
              background: $appColorFreeRed;
              border-radius: 50%;
              content: '';
              height: 10px;
              position: absolute;
              width: 10px;
            }
          }
          i {
            color: $appColorGrayScale2;
            font-size: 24px;
          }
        }
        &--account {
          i {
            margin-top: 2px;
          }
        }
        &--system {
          i {
            margin-top: 2px;
          }
          .col {
            &.is-badge {
              &::before {
                right: 15px;
                top: 17px;
              }
            }
          }
        }
        &--message {
          i {
            margin-top: 4px;
          }
          .col {
            &.is-badge {
              &::before {
                right: 12px;
                top: 17px;
              }
            }
          }
        }
      }
    }
  }
  &-toggle {
    &-list {
      border-radius: 0;
      display: flex;
      flex-direction: row-reverse;
      height: 100%;
      justify-content: flex-start;
      list-style: none;

      // 稼働状況日付指定の場合
      &--user-work-status-workable-am {
        .l-header-toggle-list {
          &__item {
            width: 195px;
            .c-switch {
              .custom-control-label {
                width: 195px;
              }
              .custom-control-label {
                &::before {
                  width: 195px;
                }
              }
              .custom-control-input:checked ~ .custom-control-label {
                &::after {
                  transform: translateX(165px);
                }
              }
            }
          }
        }
      }

      &__item {
        background: transparent;
        border: none;
        box-sizing: border-box;
        height: 100%;
        margin-right: 15px;
        padding: 0;
        position: relative;
        width: 140px;
        &.is-loading {
          .c-text {
            opacity: 0;
          }
          .loader {
            display: block;
            left: calc(50% - 5px);
            top: calc(50% - 7px);
          }
        }
        .loader {
          display: none;
          position: absolute;
          z-index: 1000;
        }
        .c-text {
          color: $appColorWhite;
          cursor: pointer;
          display: block;
          font-size: $appFontSizeS - 1;
          font-weight: $appFontWeightExtraBold;
          left: 40px;
          letter-spacing: -0.05em;
          line-height: $appFontSizeS - 1;
          position: absolute;
          top: 24px;
          z-index: 1001;
          &--user-work-status {
            &-disworkable {
              left: 52px;
            }
            &-workable-now {
              left: 40px;
            }
            &-workable-am {
              left: 40px;
            }
            &-other {
              left: 40px;
            }
          }
          &.is-active {
            color: $appColorWhite;
            left: 25px;
          }
          &::selection {
            background: transparent;
          }
        }
        .c-switch {
          margin-top: 6px;
          padding-left: 0;
          z-index: 1000;
          .custom-control-input:focus:not(:checked)
            ~ .custom-control-label::before {
            border-color: $appColorGrayScale5;
          }
          .custom-control-input:checked ~ .custom-control-label {
            &::after {
              transform: translateX(110px);
            }
            &::before {
              background: $appColorSecondary1;
              border-color: $appColorSecondary1;
            }
          }
          .custom-control-label {
            &::before {
              background: $appColorGrayScale5;
              border-color: $appColorGrayScale5;
              height: 26px;
              left: 0;
              width: 140px;
            }
            &::after {
              background: $appColorWhite;
              border-color: $appColorWhite;
              height: 18px;
              left: 6px;
              top: 6.5px;
              width: 18px;
            }
          }
        }
      }
    }
  }

  &-search {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: flex-start;
    position: relative;
    &__item {
      margin: 0;
      position: relative;
      .form-group {
        margin-bottom: 0;
      }
      &--keyword {
        .form-group {
          .invalid-feedback {
            top: 40px;
            &::before {
              border-top: transparent;
              border-bottom: 6px solid #e95464;
              bottom: auto;
              top: -5px;
            }
          }
        }
      }
    }
    .c-input {
      border-color: $appColorGrayScale2Alpha !important;
      border-radius: 50px;
      box-sizing: border-box;
      font-size: $appFontSizeM;
      height: 34px;
      padding-left: 36px;
      padding-right: 42px;
      transition: width 300ms;
      width: 274px;
      z-index: 1000;
    }
    .c-btn {
      &--enter {
        $this: &;
        background: $appColorSecondary1;
        border-color: $appColorSecondary1;
        height: 26px;
        margin: 0 0 0 12px;
        width: 120px;
        &:hover {
          background: $appColorSecondary6 !important;
          border-color: $appColorSecondary6 !important;
        }
        .c-btn__text {
          font-size: $appFontSizeS;
        }
      }
      &--open {
        background: $appColorGrayScale6;
        border: none;
        border-radius: 50%;
        height: 26px;
        top: 4.5px;
        position: absolute;
        right: 6.5px;
        width: 26px;
        z-index: 1001;
        &:hover {
          background: $appColorGrayScale5;
        }
        &:active {
          background: $appColorGrayScale5 !important;
        }
        &.is-active {
          background: $appColorGrayScale5;
          i {
            transform: rotate(180deg);
          }
        }
        i {
          color: $appColorWhite;
          font-size: 10px;
          transform: rotate(90deg);
          transition: transform 0.1s ease-out;
        }
      }
    }
    .c-icon {
      color: $appColorGrayScale6;
      position: absolute;
      z-index: 1001;
      &--lens {
        font-size: 36px;
        left: 1px;
        top: 5px;
        transform: rotate(-45deg);
      }
    }
  }

  // モバイル用
  &-menu {
    &-list {
      border-radius: 0;
      display: flex;
      flex-direction: row;
      height: 100%;
      justify-content: flex-start;
      list-style: none;
      &__item {
        background: transparent;
        border: none;
        box-sizing: border-box;
        padding: 0;
        width: 56px;
        .col {
          align-items: center;
          border-left: 1px solid $appColorGrayScale2Alpha;
          box-sizing: border-box;
          cursor: pointer;
          display: flex;
          height: 100%;
          padding: 0 13px;
          position: relative;
          &:hover,
          &:active,
          &:visited {
            background: $appColorGrayScale2;
            i {
              color: $appColorWhite;
            }
          }
          i {
            color: $appColorGrayScale2;
            font-size: 28px;
            &.mdi-close {
              font-size: 24px;
              margin: 0 0 0 2px;
              transform: rotate(0deg);
            }
          }
        }
        &--search {
          i {
            margin: -2px 0 0 -1px;
            transform: rotate(-45deg);
            &.mdi-close {
              font-size: 24px;
              margin: 0 0 0 2px;
              transform: rotate(0deg);
            }
          }
        }
        &--menu {
          i {
            margin: 0 0 0 1px;
          }
        }
      }
    }
  }
}

@media (max-height: 900px) {
  .l-header {
    $this: &;
    &--auth {
      position: relative;
      #{$this}__logo {
        margin-bottom: 30px;
      }
    }
  }
}

@media (max-width: 1180px) {
  .l-header {
    $this: &;
    &__block {
      &--logo {
        width: 120px;
      }
    }
    &__item {
      &--toggle {
        overflow: hidden;
        width: 0;
      }
      // &--notification {
      //   overflow: hidden;
      //   width: 0;
      // }
    }
  }
}

@media (max-width: 900px) {
  .l-header {
    $this: &;
    &-search {
      .c-input {
        width: 254px;
      }
    }
  }
}

@media (max-width: $appBreakPoint) {
  .l-header {
    $this: &;
    height: 50px;
    &__block {
      &--logo {
        width: 90px;
      }
      &--other {
        max-width: calc(100% - 90px);
      }
    }

    &__logo {
      margin-left: 12px;
      width: 61px;
    }

    &__item {
      height: 49px;
      max-height: 49px;
      &--search {
        display: none;
      }
      &--account {
        width: 56px;
      }

      &--signup {
        padding-right: 10px;
        .c-btn {
          &--conversion {
            height: 32px;
            width: 116px;
            .c-btn {
              &__text {
                font-size: $appFontSizeS;
              }
            }
          }
        }
      }

      &--signout {
        padding: 0 12px;
        a {
          font-size: $appFontSizeS;
        }
      }

      &--notification {
        width: 80px;
      }
      &--invitation {
        width: 40px;
      }
      &--menu {
        width: 110px;
      }
    }

    &-invitation,
    &-notification {
      &-list {
        &__item {
          .col {
            padding: 0 10px;
            &.is-badge {
              &::before {
                height: 8px;
                width: 8px;
              }
            }
            i {
              font-size: 20px;
            }
          }
          &--account {
            i {
              margin-top: 2px;
            }
          }
          &--system {
            i {
              margin-top: 2px;
            }
            .col {
              &.is-badge {
                &::before {
                  right: 11px;
                  top: 15px;
                }
              }
            }
          }
          &--message {
            i {
              margin-top: 4px;
            }
            .col {
              &.is-badge {
                &::before {
                  right: 8px;
                  top: 15px;
                }
              }
            }
          }
        }
      }
    }

    &--auth {
      padding: 25px 0 0 4% !important;
      position: relative;
      #{$this}__logo {
        margin: 0;
        width: 120px;
        img {
          width: 100%;
        }
      }
    }
  }
}

@media (max-width: 374px) {
  .l-header {
    $this: &;
    &__block {
      &--logo {
        width: 80px;
      }
      &--other {
        justify-content: flex-end;
        max-width: calc(100% - 80px);
      }
    }
    &__item {
      height: 50px;
      &--account {
        width: 50px;
        .c-profile-dropdown {
          &__image {
            width: 50px;
          }
        }
      }
      &--menu {
        width: 88px;
      }
    }
    &-menu {
      &-list {
        &__item {
          &--search {
            width: 44px;
            .col {
              justify-content: center;
              padding: 0;
            }
          }
          &--menu {
            width: 44px;
            .col {
              justify-content: center;
              padding: 0;
            }
          }
        }
      }
    }
  }
}
