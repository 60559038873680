.c-pagination {
  $this: &;
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  // justify-content: center;
  position: relative;
  width: 100%;
  z-index: 2000;

  .c-select {
    height: 40px;
    &__control {
      border-radius: $appRadiusS !important;
    }
  }

  &.is-nodata {
    #{$this}-nav {
      &__item,
      &__link {
        background: $appColorGrayScale6 !important;
        border: 1px solid $appColorGrayScale6 !important;
      }
    }
  }

  &__block {
    margin: 0 0 15px 0;
    &--nav {
      margin-right: 15px;
      &.is-pc {
        display: flex;
      }
      &.is-sp {
        display: none;
      }
    }
    &--config {
      align-items: center;
      display: flex;
      justify-content: flex-start;
      margin-right: 15px;
      width: 455px;
      &-project {
        width: 642px;
      }
      #{$this}__label {
        padding: 0;
        width: 70px;
      }

      .c-checkbox {
        &--is-casual-interview {
          margin-bottom: 0;
        }
        &--is-wanted {
          margin-bottom: 0;
        }
      }

      .c-select {
        &--sort-by {
          margin-right: 15px;
          width: 210px;
        }
        &--sort-order {
          margin-right: 15px;
          width: 100px;
        }
        &--per {
          margin-right: 15px;
          width: 100px;
          &.is-no-sort {
            margin-left: 0px;
          }
        }
      }
    }
  }

  &__label {
    flex-basis: auto;
    flex-grow: inherit;
    font-size: $appFontSizeM;
    font-weight: $appFontWeightExtraBold;
  }

  &-nav {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
    &__link {
      align-items: center;
      background: $appColorWhite;
      border: 1px solid $appColorGrayScale6;
      border-left: none;
      border-radius: 0;
      box-sizing: border-box;
      color: $appColorBlack;
      display: flex;
      font-size: 14px;
      font-weight: $appFontWeightExtraBold;
      height: 40px;
      justify-content: center;
      min-width: 40px;
      padding: 0.5rem 0rem;
      transition: background 300ms, border 300ms, color 300ms;
      &:active,
      &:hover {
        background: $appColorPrimary1;
        border-color: $appColorPrimary1;
        color: $appColorWhite;
      }
      &:visited {
        background: $appColorWhite;
        border-color: $appColorGrayScale6;
        color: $appColorBlack;
      }
    }
    &__item {
      display: block;
      &:first-child {
        #{$this}-nav__link {
          border-left: 1px solid $appColorGrayScale6;
          border-bottom-left-radius: $appRadiusS;
          border-top-left-radius: $appRadiusS;
        }
      }
      &:last-child {
        #{$this}-nav__link {
          border-bottom-right-radius: $appRadiusS;
          border-top-right-radius: $appRadiusS;
        }
      }
      &.is-active {
        #{$this}-nav__link {
          background: $appColorPrimary1;
          border: $appColorPrimary1;
          color: $appColorWhite;
          cursor: default;
          &:hover {
            background: $appColorPrimary1;
          }
        }
      }
      &.is-disabled {
        #{$this}-nav__link {
          color: $appColorGrayScale6;
          border-color: $appColorGrayScale6;
        }
      }
    }
  }

  &-summary {
    align-items: center;

    // background: $appColorGrayScale2;
    // border-radius: $appRadiusS;
    // box-sizing: border-box;
    // color: $appColorWhite;
    // height: 40px;
    // padding: 0 12px;

    display: flex;
    font-size: $appFontSizeM;
    font-weight: $appFontWeightExtraBold;
    justify-content: flex-start;

    .total,
    .separator,
    .range {
      display: inline;
      flex-basis: auto;
      flex-grow: inherit;
      padding: 0;
      width: auto;
    }
    .separator {
      padding: 5px;
    }
  }
}

@media (max-width: $appBreakPoint) {
  .c-pagination {
    $this: &;
    margin-bottom: 4px;
    &__block {
      margin: 0 0 8px 0;
      &--nav {
        &.is-pc {
          display: none;
        }
        &.is-sp {
          display: flex;
          width: 100%;
        }
      }
      &--config {
        flex-wrap: wrap;
        justify-content: space-between;
        width: 100%;
        #{$this}__label {
          padding: 0;
          width: 70px;
        }

        .c-checkbox {
          &--is-casual-interview {
            margin: 12px 0 12px 0;
            // order: 1;
            width: 100%;
          }
          &--is-wanted {
            margin: 12px 0 12px 0;
            // order: 1;
            width: 100%;
          }
        }

        .c-select {
          &--sort-by {
            margin: 0 0 8px 0;
            // order: 2;
            width: 100%;
          }
          &--sort-order {
            margin: 0 8px 0 0;
            // order: 3;
            width: calc(50% - 4px);
          }
          &--per {
            margin: 0;
            // order: 4;
            width: calc(50% - 4px);
            &.is-no-sort {
              margin: 8px 0 0 0;
              width: 100%;
            }
          }
        }
      }
      &--summary {
        width: 100%;
      }
    }
    &-nav {
      align-items: center;
      flex-direction: row;
      justify-content: space-between;
      list-style: none;
      width: 100%;
      &__item {
        background: transparent;
        flex-grow: 0;
        padding: 0;
        &.is-active {
          #{$this}-nav__link {
            border: none;
            box-sizing: border-box;
            height: 39px;
            min-width: 80px;
            width: auto;
            .total {
              &::before {
                content: '/';
                padding: 0 2.5px;
              }
            }
          }
        }
        &--first,
        &--prev,
        &--next,
        &--last {
          flex-grow: 1;
          width: 100%;
          #{$this}-nav__link {
            border: 1px solid $appColorGrayScale6;
          }
        }
        &--first,
        &--prev {
          margin-right: 6px;
        }
        &--next,
        &--last {
          margin-left: 6px;
        }

        &.is-disabled {
          #{$this}-nav__link {
            background: $appColorGrayScale6;
            color: $appColorWhite;
          }
        }
      }
      &__link {
        border-radius: $appRadiusS;
        box-sizing: border-box;
        font-size: $appFontSizeS;
        padding: 0 10px;
        width: 100%;
      }
    }
    &-summary {
      justify-content: center;
      width: 100%;
    }
  }
}

// ------------------------------------------------------------
// 旧ページネーションのスタイル
// ------------------------------------------------------------
.pagination {
  margin-bottom: 0;
  &-wrapper {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    margin: 15px 0;
    justify-content: center;
  }
  &-select-wrapper {
    align-items: center;
    display: flex;
    height: 40px;
    margin: 0 1em 0.75em 0;
    flex-wrap: wrap;
  }

  // ページャーリンク
  .page {
    &-item {
      border-color: #ccc;
    }
    &-link {
      align-items: center;
      border-color: #ccc;
      box-sizing: border-box;
      color: #333;
      display: flex;
      font-size: 14px;
      font-weight: $appFontWeightExtraBold;
      height: 36px;
      justify-content: center;
      min-width: 36px;
      padding: 0.5rem 0rem;
      transition: background 300ms;
      &:hover {
        background: #eef2f7;
      }
    }
  }
  .page-item.disabled {
    .page-link {
      color: #ccc;
      border-color: #ccc;
    }
  }
  .page-item.active {
    .page-link {
      background: #008bcc;
      border: #008bcc;
      &:hover {
        background: #008bcc;
      }
    }
  }

  // ページャー表示数設定セレクター
  &-select-per,
  &-select-sort {
    align-items: center;
    display: flex;
    justify-content: flex-start;

    &-label {
      color: #333;
      flex-basis: content;
      flex-grow: initial;
      font-size: 14px;
      font-weight: 700;
      padding: 1px 10px 0 0;
      margin: 0;
    }
    select {
      color: #333;
      font-size: 14px;
      font-weight: $appFontWeightExtraBold;
      height: 36px !important;
      width: 85px;
    }
  }

  // ページャーサマリー情報
  &-summary {
    align-items: center;
    display: flex;
    color: #333;
    font-size: 14px;
    font-weight: 700;
    .row {
      margin: 0 10px 0 0;
    }
  }
}
