.p-dashboard {
  $this: &;
  display: flex;
  flex-direction: column;

  .c-loader {
    &-container {
      display: flex;
      min-height: 200px;
      padding: 0;
      width: 100%;
      .preloader {
        background: transparent;
        margin-top: 10px;
      }
    }
  }

  .c-title {
    margin: 0 0 20px 0;
    padding: 0 0 15px 5px;
    &__text {
      font-size: $appFontSize2XL;
      // font-weight: $appFontWeightNormal;
      margin: 0;
    }
  }

  .p-search {
    &-list {
      &__item {
        transition: width 0.3s;
      }
    }
  }

  &__block {
    &--left {
      max-width: calc(260px * 3 + 24px);
      margin-right: 20px;
      width: calc(60% - 20px);
      transition: margin 0.3s, width 0.3s;
    }
    &--right {
      margin-left: 20px;
      max-width: 532px;
      width: calc(40% - 20px);
      transition: margin 0.3s, width 0.3s;
    }
  }

  &__link {
    color: $appColorBlack;
    text-decoration: none;
    &:hover {
      color: $appColorBlack;
      text-decoration: underline;
    }
    &:active,
    &:visited {
      color: $appColorBlack;
      text-decoration: none;
    }
    &--more {
      color: $appColorBlack;
      display: flex;
      justify-content: flex-start;
      text-decoration: none;
      width: 100%;
    }
  }

  &-link-list {
    display: flex;
    gap: 12px;
    justify-content: flex-start;
    padding-bottom: 42px;
    width: 100%;
    &__item {
      // width: calc((100% - 24px) / 3);
      width: 100%;
    }
  }

  &-news {
    &-list {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      padding-bottom: 20px;
      width: 100%;
      &__item {
        margin-top: 4px;
        width: 100%;
      }
      &__item + &__item {
        margin-top: 12px;
      }
    }
    &__title {
      align-items: center;
      display: flex;
      flex-wrap: nowrap;
      justify-content: flex-start;
      gap: 10px;
      margin: auto;
      padding: 0;
      &::before {
        content: '\30FB';
        display: inline;
        padding-right: 5px;
      }
      &.is-new {
        &::before {
          display: none;
        }
      }
    }
    &__link {
      // @include ellipsisMultiple(2);
      @include ellipsis();
      color: $appColorPrimary1;
      font-size: $appFontSizeM;
      font-weight: $appFontWeightExtraBold;
      width: calc(100% - 20px);
      &:hover,
      &:active,
      &:visited {
        color: $appColorPrimary1 !important;
        text-decoration: underline !important;
      }
    }
  }

  &-post {
    align-items: flex-start;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    width: 100%;
  }

  &-public-post {
    align-items: flex-start;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    max-width: cacl(804px + 532px + 40px);
    width: 100%;
  }

  &-project {
    align-items: flex-start;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;

    &-category-list,
    &-skill-list {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      &__item {
        margin: 0 8px 8px 0;
      }
    }

    &-select {
      &-category,
      &-skill {
        &__block {
          box-sizing: border-box;
          max-height: 420px;
          overflow: auto;
          padding-right: 16px;
          transition: height 0.3s, max-height 0.3s;
          .simplebar-vertical {
            background: transparent;
            border-radius: 20px;
            margin: 2px 4px 2px 0;
            width: 6px;
            .simplebar-scrollbar {
              // background: $appColorGrayScale3;
              border-radius: 20px;
              width: 6px;
            }
          }
          .simplebar-horizontal {
            background: transparent;
            border-radius: 20px;
            height: 6px;
            margin: 0;
            width: 100%;
            .simplebar-scrollbar {
              // background: $appColorGrayScale3;
              border-radius: 20px;
              height: 6px !important;
              top: 0;
            }
          }
        }
      }
      &-category {
        margin-top: 30px;
      }
      &-skill {
        margin-top: 45px;
      }
    }

    &-list {
      color: $appColorBlack;
      position: relative;
      width: 100%;
      z-index: 100;

      & + & {
        margin-top: 30px;
      }

      &__error {
        align-items: center;
        display: flex;
        justify-content: center;
        min-height: 200px;
      }
    }

    #{$this}__link {
      &--more {
        margin: 6px 0 12px 0;
      }
    }

    .c-btn {
      &--more-matching-project,
      &--more-new-project {
        font-size: $appFontSizeM;
        margin: 24px auto 48px auto;
        width: 320px;
        .c-btn__text {
          font-size: $appFontSizeM;
        }
      }
    }
  }

  &-platinum-scout {
    &-list {
      align-items: flex-start;
      display: flex;
      flex-direction: column;
      padding-bottom: 20px;
      position: relative;
      width: 100%;
      z-index: 100;
      &__error {
        align-items: center;
        display: flex;
        justify-content: center;
        min-height: 200px;
        width: 100%;
      }
      &__item {
        align-items: center;
        display: flex;
        margin: 0 0 0 0;
        width: 100%;
        &.is-sticker {
          #{$this}-platinum-scout-item__title {
            margin-top: 30px;
          }
        }
      }
      &__item + &__item {
        margin-top: 12px;
      }
      #{$this}__link {
        &--more {
          margin: 12px 0 0 0;
        }
      }
      .c-btn {
        &--more-platinum-scout {
          font-size: $appFontSizeM;
          margin: 24px auto 24px auto;
          width: 220px;
          .c-btn__text {
            font-size: $appFontSizeM;
          }
        }
      }
    }
    &-item {
      &__image {
        display: flex;
        flex-direction: column;
        gap: 8px;
        margin: 0 0 0 0;
        width: 64px;
      }
      &__title {
        @include ellipsisMultiple(2);
        font-size: $appFontSizeBase;
        margin: 0 0 0 12px;
        width: calc(100% - 76px);
      }
      &__sticker-official {
        display: flex;
        flex-direction: column;
        height: 22px;
        width: 100%;
      }
    }
    &__link {
      color: $appColorBlack;
      text-decoration: none;
      &:hover {
        color: $appColorBlack;
        text-decoration: underline;
      }
      &:active,
      &:visited {
        color: $appColorBlack;
        text-decoration: none;
      }
    }
    &__image {
      align-items: center;
      background: $appColorWhite;
      box-sizing: border-box;
      border: 4px solid $appColorGrayScale7;
      border-radius: 50%;
      display: flex;
      flex-grow: initial;
      justify-content: center;
      height: 64px;
      margin: 0;
      overflow: hidden;
      padding: 0;
      width: 64px;
      img {
        width: 100%;
      }
      i {
        color: $appColorGrayScale8;
        font-size: 48px;
      }
    }
  }
}

@media (max-width: 1656px) {
  .p-dashboard {
    $this: &;
    &__block {
      &--left {
        max-width: calc(260px * 3 + 24px);
        min-width: calc(260px * 3 + 24px);
        width: auto;
      }
      &--right {
        min-width: auto;
        width: calc(100% - 846px);
      }
    }
  }
}

@media (max-width: 1500px) {
  .p-dashboard {
    $this: &;
    &-project {
      &-select {
        &-category,
        &-skill {
          &__block {
            max-height: 540px;
          }
        }
      }
    }
  }
}

@media (max-width: 1456px) {
  .p-dashboard {
    $this: &;
    &__block {
      &--left {
        max-width: calc(300px * 2 + 12px);
        min-width: calc(300px * 2 + 12px);
        width: auto;
      }
      &--right {
        max-width: none;
        min-width: auto;
        width: calc(100% - 654px);
      }
    }
    .p-search {
      &-list {
        &__item {
          max-width: 300px;
          width: 300px;
        }
      }
    }
  }
}

@media (max-width: 1080px) {
  .p-dashboard {
    $this: &;
    &__block {
      &--left {
        max-width: calc(260px * 2 + 12px);
        min-width: calc(260px * 2 + 12px);
        width: auto;
      }
      &--right {
        max-width: none;
        min-width: auto;
        width: calc(100% - 574px);
      }
    }
    .p-search {
      &-list {
        &__item {
          max-width: 260px;
          width: 260px;
        }
      }
    }
  }
}

@media (max-width: 980px) {
  .p-dashboard {
    $this: &;
    &__block {
      flex-direction: column;
      &--left {
        margin: 0 0 0 0;
        max-width: none;
        min-width: auto;
        width: 100%;
      }
      &--right {
        margin-left: 0;
        max-width: none;
        min-width: auto;
        width: 100%;
      }
    }
    &-project {
      &-list {
        .p-search {
          &-list {
            &__item {
              margin: 0 1.5% 1.5% 0;
              max-width: none;
              width: 32%;
              &:nth-child(3n) {
                margin-right: 0;
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 860px) {
  .p-dashboard {
    $this: &;
    &-project {
      .c-title {
        padding: 0 0 15px 10px;
      }
      &-list {
        .p-search {
          &-list {
            &__item {
              margin: 0 2% 2% 0;
              max-width: none;
              width: calc(50% - 2%);
              &:nth-child(2n) {
                margin-right: 0;
              }
              &:nth-child(3n) {
                margin-right: 2%;
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: $appBreakPoint) {
  .p-dashboard {
    $this: &;
    .c-title {
      margin: 0 0 20px 0;
      padding: 0 0 15px 10px;
      &__text {
        font-size: $appFontSizeXL;
      }
    }
    &-news {
      &-list {
        &__item + &__item {
          margin-top: 10px;
        }
      }
      &__title {
        &::before {
          font-size: $appFontSizeS;
        }
      }
      &__link {
        font-size: $appFontSizeS;
        width: calc(100% - 20px);
      }
    }
    &-project {
      gap: 50px;
      &-select {
        &-category {
          margin-top: 24px;
        }
        &-skill {
          margin-top: 36px;
        }
      }
      &-list {
        .p-search {
          &-list {
            &__item {
              margin: 0 12px 12px 0;
              max-width: none;
              width: calc(50% - 6px);
              &:nth-child(even) {
                margin-right: 0;
              }
              &:nth-child(odd) {
                margin-right: 12px;
              }
            }
          }
        }
      }
      .c-btn {
        &--more-matching-project,
        &--more-new-project {
          // margin: 12px auto 36px auto;
          width: 100%;
          .c-btn__text {
            font-size: $appFontSizeM;
          }
        }
      }
    }
    &-platinum-scout {
      &-list {
        &__item {
          &.is-sticker {
            #{$this}-platinum-scout-item__title {
              margin-top: 28px;
            }
          }
        }
        .c-btn {
          &--more-platinum-scout {
            font-size: $appFontSizeM;
            // margin: 24px auto 12px auto;
            width: 100%;
            .c-btn__text {
              font-size: $appFontSizeM;
            }
          }
        }
      }
      &-item {
        &__title {
          font-size: $appFontSizeM;
        }
        &__sticker-official {
          height: 20px;
        }
      }
    }
  }
}

@media (max-width: 572px) {
  .p-dashboard {
    $this: &;

    &-project {
      &-list {
        .p-search {
          &-list {
            &__item {
              margin-right: 0 !important;
              max-width: none;
              width: 100%;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 572px) {
  .p-dashboard {
    $this: &;

    &-link-list {
      align-items: flex-start;
      display: flex;
      flex-direction: column;
      gap: 12px;
      justify-content: flex-start;
      padding-bottom: 42px;
      width: 100%;
      &__item {
        width: 100%;
      }
    }
  }
}
