.p-chat-group-participant {
  $this: &;

  .row {
    margin: 0;
  }

  &__unread-count {
    @include ellipsis;
    align-items: center;
    background: $appColorFreeRed;
    border-radius: 50px;
    box-sizing: border-box;
    color: $appColorWhite;
    display: flex;
    font-size: $appFontSizeS;
    font-weight: $appFontWeightExtraBold;
    height: 20px;
    justify-content: center;
    max-width: 32px;
    min-width: 20px;
    padding: 0 5px;
    position: absolute;
    right: -3px;
    top: -3px;
    width: auto;
    z-index: 1001;
  }

  &-list {
    background: none;
    border: 0px transparent solid;
    list-style: none;
    margin: 0;
    height: 0;
    overflow: hidden;
    padding: 0;
    transition: border .3s, height .3s;
    &:last-child {
      &.is-expand {
        border-bottom: none;
      }
    }

    &.is-expand {
      border-bottom: 2px $appColorGrayScale6 solid;
      border-top: 1px $appColorGrayScale6 solid;
      overflow: hidden;
      visibility: visible;
    }

    &__item {
      align-items: center;
      background: $appColorWhite;
      border: none;
      border-bottom: 1px $appColorGrayScale6 solid;
      border-radius: 0;
      cursor: pointer;
      display: flex;
      flex-wrap: wrap;
      list-style: none;
      margin: 0;
      // padding: 12px 0 12px 70px;
      padding: 12px 0 12px 85px;
      // padding: 12px 0 12px 100px;
      width: 100%;
      transition: padding .3s;
      &:last-child {
        border-bottom: none;
      }

      &:hover {
        background: $appColorGrayScale9;
      }

      &--dm {
        padding: 12px 0 12px 20px;
      }

      &--empty {
        &:hover {
          background: $appColorWhite;
        }
        .col.value {
          max-width: 100%;
          min-width: 100%;
          padding: 15px 15px !important;
          text-align: center;
          width: 100%;
        }
      }

      &-image {
        align-items: center;
        background: $appColorWhite;
        box-sizing: border-box;
        border: 4px solid $appColorGrayScale8;
        border-radius: 50%;
        display: flex;
        flex-grow: initial;
        justify-content: center;
        height: 48px;
        margin: 0;
        overflow: hidden;
        padding: 0;
        width: 48px;
        img {
          width: 100%;
        }
        i {
          color: $appColorGrayScale8;
          font-size: 48px;
        }
      }

      .col.label,
      .col.value {
        padding: 0 12.5px 0 12.5px;
      }

      .label,
      .value {
        align-items: center;
        display: flex;
        flex-grow: 0;
        transition: margin .3s, padding .3s, max-width .3s, min-width .3s, width .3s;
        &.is-sortable {
          cursor: pointer;
        }
        &-group {
          align-items: stretch;
          display: flex;
          transition: width .3s;
          &--title {
            width: 200px;
          }
          &--message {
            width: calc(100% - 510px);
            align-items: center;
            flex-grow: 1;
          }
        }

        &--title {
          align-items: center;
          max-width: 150px;
          min-width: 150px;
          width: auto;
          a {
            @include ellipsis;
            color: $appColorBlack;
            display: block;
            text-decoration: none;
            max-width: 130px;
            &:visited,
            &:active {
              color: $appColorBlack;
            }
            &:hover {
              color: $appColorBlack;
              text-decoration: underline;
            }
          }
          i {
            margin-left: 5px;
          }
        }

        &--message {
          align-items: center;
          max-width: 100%;
          min-width: 100%;
          width: auto;
          a {
            @include ellipsis;
            color: $appColorBlack;
            display: block;
            text-decoration: none;
            max-width: calc(100% - 20px);
            &:visited,
            &:active {
              color: $appColorBlack;
            }
            &:hover {
              color: $appColorBlack;
              text-decoration: underline;
            }
          }
          i {
            margin-left: 5px;
          }
        }

        &--latest-sent-at {
          max-width: 210px; 
          min-width: 210px;
          width: auto;
        }
        &--reference-type {
          max-width: 100px; 
          min-width: 100px;
          width: auto;
        }
        &--image {
          max-width: 48px;
          min-width: 48px;
          width: auto;
        }


      }

      .c-badge {
        border-radius: $appRadiusM;
        font-size: $appFontSizeS;
        height: 30px;
        min-width: 70px;
        padding: 0 10px;
        width: auto;
      }

      .value {
        &--image {
          padding: 0 !important;
        }        
        &--message {
          @include ellipsis;
        }

        &--control {
          align-items: center;
          cursor: default;
          flex-direction: row;
          flex-wrap: wrap;
          display: flex;
          justify-content: flex-start;
          text-align: center;
          .c-menu-icon + .c-menu-icon {
            margin-left: 6px;
          }

          .c-menu-icon--detail {
            // background: red;
            // background: transparent;
            i {
              // color: $appColorSecondary1;
              font-size: 36px;
              margin-top: 1px;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1180px) {
  .p-chat-group-participant {
    $this: &;
    &-list {
      &__item {
        padding-left: 70px;
        .label,
        .value {
          &-group {
            align-items: stretch;
            display: flex;
            &--title {
              width: 170px;
            }
            &--message {
              width: calc(100% - 455px);
              align-items: center;
              flex-grow: 1;
            }
          }
 
          &--title {
            max-width: 120px;
            min-width: 120px;
            width: auto;
            a { max-width: 100px; }
            i { margin-left: 5px; }
          }
          &--latest-sent-at {
            justify-content: flex-end;
            max-width: 205px;
            min-width: 205px;
          }
          &--reference-type {
            max-width: 80px; 
            min-width: 80px;
          }
        }
      }
    }
  }
}

@media (max-width: 1000px) {
  .p-chat-group-participant {
    $this: &;
    &-list {
      &__item {
        padding-left: 15px;
      }
    }
  }
}


@media (max-width: $appBreakPoint) {
  .p-chat-group-participant {
    $this: &;
    &-list {
      &.is-expand {
        height: auto !important;
      }

      &__item {
        padding: 10px !important;

        .col {
          font-size: $appFontSizeS;
        }

        .label,
        .value {
          &-group {
            &--message,
            &--status {
              width: 100%;
            }
          }
          &--latest-sent-at {
            justify-content: flex-end;
            max-width: calc(100% - 75px);
            min-width: calc(100% - 75px);
            padding-right: 0 !important;
          }
          &--message {
            padding: 8px 0 !important;
          }
          &--reference-type {
            padding: 0 !important;
            max-width: 75px; 
            min-width: 75px;
          }
        }
      }
    }
  }
}
