.p-user-publish-profile {
  $this: &;
  box-sizing: border-box;
  display: flex;
  margin: 0;
  width: 100%;

  &-head {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin: 0;
    position: relative;
    width: 100%;
    &__error {
      position: absolute;
      width: 100%;
      .form-group {
        .invalid-feedback {
          right: 0;
          top: -63px;
        }
      }
    }
    .c-title {
      border: none;
      margin: 0;
      padding: 0;
      width: auto;
      &.is-required {
        align-items: center;
        display: flex;
        justify-content: flex-start;
        &::after {
          background: $appColorFreeRed;
          box-sizing: border-box;
          border-radius: 4px;
          color: #fff;
          // content: '必須';
          content: '\5FC5\9808';
          font-size: 11px;
          font-weight: $appFontWeightExtraBold;
          line-height: 1em;
          margin: -5px 0 0 10px;
          padding: 7px 10px 5px 10px;
        }
      }
      &__text {
        font-size: $appFontSizeM;
        font-weight: $appFontWeightExtraBold;
      }
    }
    .c-btn {
      height: $appBtnHeightS;
      margin: -12px 0 0 0;
      width: 140px;
      &--add-price {
        width: 180px;
      }
      &__text {
        font-size: $appFontSizeS;
      }
    }
  }

  &__block {
    align-items: center;
    border-radius: 4px 4px 0 0;
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 10px 0 0 0;
    width: 100%;
  }

  // -------------------------------------------
  // 設定済みの経歴リスト
  // -------------------------------------------
  &-list {
    border-radius: 4px;
    list-style: none;
    margin: 0;
    overflow: hidden;
    padding: 0;
    width: 100%;

    &__item {
      align-items: flex-start;
      border-radius: 0;
      border: none;
      border-bottom: 1px $appColorGrayScale6 solid;
      display: flex;
      flex-wrap: wrap;
      font-size: $appFontSizeM;
      padding: 12.5px 0 5px 0;
      width: 100%;

      &--label {
        background: $appColorGrayScale8;
        .col {
          // font-size: $appFontSizeM;
          font-weight: $appFontWeightExtraBold;
        }
      }

      &:hover {
        .col.control {
          opacity: 1;
          visibility: visible;
        }
      }

      .col {
        box-sizing: border-box;
        flex-basis: auto;
        flex-grow: 0;
        font-size: $appFontSizeS;
        margin: 0;
        padding: 0 12.5px 5px 12.5px;
        text-align: left;

        // カラム設定: 検索制限の対象ユーザー,会社
        &.code {
          flex-grow: 1;
          min-width: 100px;
          width: calc(100% - 160px);
        }

        // カラム設定: 共通
        &.empty {
          padding: 15px 0;
          text-align: center;
          width: 100%;
        }

        &.control {
          align-items: center;
          background: rgba(0, 0, 0, 0.5);
          box-sizing: border-box;
          display: flex;
          height: 100%;
          justify-content: flex-end;
          padding: 0 7.5px;
          opacity: 0;
          position: absolute;
          top: 0;
          transition: opacity 300ms;
          visibility: hidden;
          width: 100%;
          &.is-pc {
            .c-btn {
              background: #fff;
              border-color: #fff;
              height: 36px;
              margin: 0;
              width: 36px;
              &:hover {
                background: #fff;
                opacity: 0.9;
              }
              i {
                color: #333;
                font-size: 18px;
              }
            }
          }
          .c-btn + .c-btn {
            margin-left: 6px;
          }
          &.is-sp {
            display: none;
          }
        }
      }
    }
  }

  // -------------------------------------------
  // 希望プロジェクト条件の新規登録・編集フォーム
  // -------------------------------------------
  &-form {
    &__item {
      // display: none;
      display: flex;
      flex-direction: column;
      margin: 0 0 15px 0;
      position: relative;
      width: 100%;

      .form-group {
        margin-bottom: 0;
        label {
          font-size: $appFontSizeM;
          font-weight: $appFontWeightExtraBold;
          margin-bottom: 5px;
        }
        .c-checkbox {
          &__item {
            label {
              font-weight: $appFontWeightNormal;
            }
          }
        }
        legend {
          font-size: $appFontSizeM;
          font-weight: $appFontWeightExtraBold;
          margin-bottom: 0;
        }
        &.c-radio {
          label {
            font-weight: $appFontWeightNormal;
          }
        }
      }
      &--btns {
        margin: 30px 0 20px 0;
        button.c-btn {
          font-size: $appFontSizeL;
        }
        .c-btn {
          margin: 0 auto;
          width: 265px;
        }
      }
    }
    &--is-public {
      .c-radio {
        margin-top: -10px;
      }
    }
  }
  // -------------------------------------------
  // 利用されている説明部分のスタイル
  // -------------------------------------------
  .descriptions {
    width: 100%;
    background-color: #b8eae170;
    color: $appColorSecondary5;
    padding: 10px 20px;
    margin-top: 15px;
    border-radius: 4px;
    .description-item {
      display: flex;
      flex-wrap: wrap;
    }
    .select-name {
      font-weight: $appFontWeightExtraBold;
    }
    .select-description {
      margin-left: 3px;
    }
  }

  // -------------------------------------------
  // 利用されているModalのスタイル
  // -------------------------------------------
  &__modal {
    &--is-public {
      .__title {
        font-size: $appFontSize2XL;
        font-weight: $appFontWeightExtraBold;
        margin-bottom: 20px;
        text-align: center;
      }
      .__description {
        font-size: $appFontSizeM;
        margin-bottom: 20px;
      }
      .__btn-group {
        display: flex;
        justify-content: right;
        margin-top: 20px;
        .c-btn {
          &__text {
            font-size: $appFontSizeM;
          }
          margin: 0 10px;
          width: 120px;
          height: $appBtnHeightM;
        }
      }
    }
  }
}

@media (max-width: $appBreakPoint) {
  .p-user-publish-profile {
    $this: &;
    box-sizing: border-box;
    display: flex;
    margin: 0;

    &-head {
      .c-title {
        &__text {
          font-size: $appFontSizeS;
        }
      }
      .c-btn {
        &__text {
          font-size: $appFontSizeS;
          line-height: 0;
        }
      }
    }

    &-list {
      &__item {
        padding: 12.5px 10px 10px 10px !important;
        .col {
          font-size: $appFontSizeS;
          padding: 2.5px !important;
          width: 33.3% !important;
          &.empty {
            width: 100% !important;
          }

          &.code {
            min-width: 100%;
            width: 100%;
          }

          &.control {
            align-items: center;
            background: transparent;
            box-sizing: border-box;
            display: flex;
            height: auto;
            justify-content: center;
            padding: 0 7.5px;
            opacity: 1;
            position: relative;
            transition: opacity 0;
            visibility: visible;
            width: 100% !important;
            .c-text {
              color: $appColorPrimary4;
              cursor: pointer;
              font-size: $appFontSizeM;
              font-weight: $appFontWeightExtraBold;
              width: 80px;
            }
            &.is-sp {
              display: flex;
              justify-content: flex-end;
              margin-top: 5px;
              padding: 15px 0px !important;
              .c-btn {
                height: 32px;
                margin: 0;
                width: 140px;
                &__text {
                  font-size: $appFontSizeS;
                  line-height: 0;
                }
                &:hover {
                  background: #fff;
                  opacity: 0.9;
                }
                i {
                  color: #333;
                  font-size: 18px;
                }
              }
              .c-btn + .c-btn {
                margin-left: 10px;
              }
            }
            &.is-pc {
              display: none;
            }
          }
        }
      }
    }
    &-form {
      &__item {
        margin: 0 0 15px 0;
        .form-group {
          label {
            font-size: $appFontSizeS;
            margin-bottom: 5px;
          }
          .c-input {
            font-size: $appFontSizeS;
          }
          .c-select {
            &__menu {
              font-size: $appFontSizeS;
            }
            &__single-value {
              color: $appColorBlack !important;
              font-size: $appFontSizeS !important;
            }
          }
        }
        &--btns {
          margin: 20px 0 10px 0;
          .c-btn {
            margin-left: 0;
            margin-right: 0;
            width: 100%;
          }
          .c-btn + .c-btn {
            margin-top: 15px;
          }
        }
      }
    }
  }
}
