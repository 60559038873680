.c-dropdown {
  position: relative;
  &__btn {
    align-items: center;
    cursor: pointer;
    display: flex;
    height: 40px;
    justify-content: center;
    margin: 0;
    padding: 0;
    width: 40px;
    i {
      font-size: 28px;
    }
  }
  &-nav {
    @include fadeOut;
    position: absolute;
    right: 2px;
    top: 35px;
    transition: opacity 0ms;
    width: 140px;
    z-index: 1100;
    &.is-active {
      @include fadeIn;
    }

    &-list {
      background: $appColorWhite;
      border: 1px solid rgba(0, 0, 0, 0.15);
      border-radius: $appRadiusM;
      box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.05);
      list-style: none;
      overflow: hidden;
      padding: 0;
      width: 100%;
      &__item {
        border: none;
        border-bottom: 1px dotted $appColorGrayScale6;
        box-sizing: border-box;
        padding: 0;
        width: 100%;

        &:last-child {
          border-bottom: none;
        }

        a {
          @include ellipsis;
          align-items: center;
          color: $appColorBlack;
          display: block;
          font-size: $appFontSizeM;
          justify-content: flex-start;
          line-height: $appFontSizeS;
          padding: 12px 10px;
          text-decoration: none;
          width: 100%;
          &:hover {
            background: $appColorHoverGray;
          }
          &:hover,
          &:active,
          &:visited {
            text-decoration: none;
          }
        }
      }
    }

    &.is-active {
      height: auto;
      visibility: visible;
      opacity: 1;
      overflow: inherit;
    }
  }
}
@media (max-width: $appBreakPoint) {
  .c-dropdown {
    &__btn {
      height: 36px;
      width: 36px;
      i {
        font-size: 24px;
      }
    }
    &-nav {
      right: 2px;
      top: 35px;
      width: 140px;
      &-list {
        &__item {
          a {
            font-size: $appFontSizeS;
            padding: 12px 10px;
          }
        }
      }
    }
  }
}
