.c-switch {
  align-items: center;
  display: flex;
  justify-content: flex-start;
  padding-left: 40px;

  .custom-control-label {
    align-items: center;
    color: $appColorBlack;
    display: flex;
    font-weight: $appFontWeightNormal;
    margin: 0;
    line-height: 22px;
    &::before {
      border-radius: 18px;
      height: 18px;
      left: -40px;
      width: 32px;
    }
    &::after {
      border-radius: 50%;
      top: 4.5px;
      left: -37px;
      width: 14px;
      height: 14px;
    }
  }

  .custom-control-input {
    max-height: 0;
    max-width: 0;
    overflow: hidden;
  }

  .custom-control-input:checked ~ .custom-control-label {
    &::after {
      transform: translateX(13px);
    }
  }

  .custom-control-input:checked ~ .custom-control-label::before {
    color: $appColorPrimary1;
    border-color: $appColorPrimary1;
    background-color: $appColorPrimary1;
  }

  .custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
    border: $appColorGrayScale6 solid 1px;
  }
  .custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: none;
  }

  .custom-control-input:disabled:checked ~ .custom-control-label::before {
    color: $appColorGrayScale8;
    border-color: $appColorGrayScale8;
    background-color: $appColorGrayScale8;
  }
}
