.l-search-filter {
  $this: &;
  align-items: center;
  // dark mode
  // background: rgba(49, 58, 70, .9);
  box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.2);
  background: rgba(
    red($appColorGrayScale15),
    green($appColorGrayScale15),
    blue($appColorGrayScale15),
    0.9
  );
  // background: rgba(
  //   red($appColorLightModeSideBarScale1),
  //   green($appColorLightModeSideBarScale1),
  //   blue($appColorLightModeSideBarScale1),
  //   0.85
  // );

  backdrop-filter: blur(10px);
  display: flex;
  flex-direction: column;
  max-height: calc(100% - 60px);
  min-width: 360px;
  height: auto;
  justify-content: flex-start;
  left: 200px;
  overflow: visible;
  padding: 30px;
  position: fixed;
  top: -1000px;
  transition: background 0.3s, left 0.3s, opacity 0.15s, padding 0.3s, top 0.3s,
    width 0.3s;
  visibility: hidden;
  width: calc(100% - 200px);
  z-index: 2999;

  // アカウント設定モード
  // &--account-setting {
  //   background: rgba(0, 139, 204, .8);
  // }

  &--no-sidebar {
    left: 0;
    width: 100%;
  }

  &.is-pc {
    display: flex;
  }

  &.is-sp {
    display: none;
  }

  &.is-active {
    top: 60px;
    visibility: visible;
  }

  &-form {
    width: 100%;
  }

  &__container {
    height: 100%;
    overflow: visible;
    // padding-bottom: 45px;
    width: 100%;
    .simplebar {
      &-wrapper {
        overflow: visible !important;
      }
      &-mask {
        overflow: inherit !important;
      }
      &-content-wrapper {
        overflow: visible !important;
      }
    }
    .c-select-checkbox {
      .simplebar {
        &-wrapper {
          overflow: hidden !important;
        }
        &--mask {
          overflow: hidden !important;
        }
        &-content-wrapper {
          overflow: hidden scroll !important;
        }
      }
    }
  }

  &--project {
    #{$this}__reset {
      margin: 10px 0 0 0;
    }
  }

  &__reset {
    align-items: center;
    cursor: pointer;
    display: flex;
    margin: 10px 0 0 0;
    padding: 0;
    .c-btn {
      background: $appColorGrayScale7;
      border: 2px solid $appColorGrayScale7;
      border-radius: $appRadiusM;
      height: 35px;
      margin: 0;
      width: 160px;

      &:hover,
      &:disabled {
        background: $appColorGrayScale8 !important;
        border: 2px solid $appColorGrayScale8 !important;
      }
      &:focus,
      &:active,
      &:visited {
        background: $appColorGrayScale7 !important;
        border: 2px solid $appColorGrayScale7 !important;
      }

      i {
        // dark mode
        // color: $appColorWhite;
        color: $appColorBlack;
        font-size: 18px;
        // margin: 2px 5px 0 0;
        margin: 2px 8px 0 -7px;
      }
      &__text {
        // color: $appColorWhite;
        color: $appColorBlack;
        font-size: $appFontSizeS;
      }
    }
  }

  &__close {
    align-items: center;
    cursor: pointer;
    display: flex;
    height: 46px;
    justify-content: center;
    padding: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 46px;
    z-index: 2990;
    i {
      // dark mode
      // color: $appColorWhite;
      color: $appColorBlack;
      font-size: 24px;
    }
  }

  &__enter {
    display: none;
  }

  &__block {
    align-items: stretch;
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    position: relative;
    width: 100%;
  }

  &__item-group {
    align-items: stretch;
    display: flex;
    margin: 0 20px 0 0;
    #{$this}__item {
      &:last-child {
        margin-right: 0;
      }
    }
  }

  &__item {
    // dark mode
    // color: $appColorWhite;
    color: $appColorBlack;
    margin: 0 20px 20px 0;

    // width
    &--search-type {
      width: 100px;
    }
    &--project-owner {
      width: 150px;
    }
    &--project-type {
      width: 150px;
    }
    &--interview-type {
      width: 150px;
    }
    &--participation-type {
      width: 250px;
    }
    &--work-type {
      width: 130px;
    }
    &--price-type {
      width: 150px;
    }
    &--price-amount {
      width: 120px;
    }
    &--price-evaluate {
      width: 80px;
    }
    &--from {
      width: 95px;
    }
    &--to {
      width: 95px;
    }
    &--category {
      width: 400px;
    }
    &--location {
      width: 200px;
    }
    &--company {
      width: 200px;
    }
    &--company-employee-count-type {
      width: 210px;
    }
    &--is-offerable {
      width: 155px;
    }
    &--skill-category {
      width: 400px;
    }
    &--affiliation {
      width: 150px;
    }
    &--resident-status {
      width: 150px;
    }

    &--no-label {
      padding-top: 22px;
    }

    &--category,
    &--location,
    &--skill-category {
      .c-select-checkbox {
        &-option {
          max-height: 250px !important;
          min-height: 50px !important;
          width: 180%;
        }
      }
    }

    &--wanted {
      margin-bottom: 0;
    }

    &--from,
    &--to {
      position: relative;
      .overlay {
        height: 100%;
        opacity: 0;
        position: absolute;
        width: 100%;
        z-index: 1001;
        .form-group {
          height: 100%;
          .c-input {
            &--date {
              height: 100%;
            }
          }
          .invalid-feedback {
            display: none;
          }
        }
      }
      .c-input {
        position: relative;
        z-index: 1000;
      }
      .form-group {
        &.text-danger {
          .invalid-feedback {
            top: -77px;
            width: 155px !important;
            z-index: 1002;
          }
        }
      }
    }

    &.is-pc {
      display: block;
    }
    &.is-sp {
      display: none;
    }

    .field-label {
      // dark mode
      // color: $appColorWhite;
      color: $appColorBlack;
      font-size: $appFontSizeXS;
      font-weight: $appFontWeightExtraBold;
      margin-bottom: 0;
    }

    .form-group {
      margin-bottom: 0;
      width: 100%;
      &.text-danger {
        label {
          // dark mode
          // color: $appColorWhite;
          color: $appColorBlack;
        }
        .invalid-feedback {
          padding: 6px;
          width: 150%;
        }
      }
    }

    .c-input {
      background: transparent !important;
      border-radius: 0;
      border: none;
      // dark mode
      // border-bottom: 1px $appColorWhite solid !important;
      // color: $appColorWhite;
      border-bottom: 1px $appColorBlack solid !important;
      box-sizing: border-box;
      color: $appColorBlack;
      font-size: $appFontSizeS;
      height: 40px;
      padding: 0 0 1px 0 !important;
      width: 100%;
      &:focus {
        border: none !important;
        // dark mode
        // border-bottom: 1px $appColorWhite solid !important;
        border-bottom: 1px $appColorBlack solid !important;
      }
      &::placeholder {
        // dark mode
        // color: $appColorWhite;
        color: $appColorBlack;
        opacity: 0.4;
      }
    }

    .c-checkbox {
      margin-top: 10px;
      .form-control {
        padding: 0 !important;
      }
      &__item {
        label {
          color: $appColorWhite;
          font-size: $appFontSizeS;
        }
        .custom-control-input ~ .custom-control-label::before {
          box-shadow: none !important;
        }
      }
    }

    input[type='date']::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }

    input[type='date']::-webkit-clear-button {
      -webkit-appearance: none;
    }

    input[type='date']::-webkit-calendar-picker-indicator {
      height: 100%;
      opacity: 0;
      position: absolute;
      width: 100%;
    }

    .c-select-checkbox {
      border-bottom-color: $appColorBlack;
      color: $appColorBlack;
      height: 40px;
      // dark mode
      // border-bottom-color: $appColorWhite;
      // color: $appColorWhite;
      &__dropdown {
        // dark mode
        // color: $appColorWhite;
        color: $appColorBlack;
      }
      &-value {
        &-item {
          .placeholder {
            // dark mode
            // color: $appColorWhite;
            color: $appColorBlack;
          }
        }
      }
    }

    // select: 単数選択専用
    .c-select {
      background: transparent;
      // dark mode
      // color: $appColorWhite;
      color: $appColorBlack;
      cursor: pointer;
      font-size: $appFontSizeS;
      height: 40px;
      width: 100%;
      z-index: 1100;
      // &__value-container {}
      &__control {
        background: transparent;
        border: none !important;
        // dark mode
        // border-bottom: 1px $appColorWhite solid !important;
        border-bottom: 1px $appColorBlack solid !important;
        border-radius: 0;
        box-shadow: none;
        box-sizing: border-box !important;
        cursor: pointer;
        height: 40px;
        min-height: 40px !important;
        &:active {
          border: none;
        }
        div {
          padding-left: 0;
          padding-right: 0;
        }
        &--menu-is-open {
          .c-select__indicators {
            svg {
              transform: rotate(180deg);
            }
          }
        }
      }
      &__menu {
        color: $appColorBlack !important;
        cursor: pointer;
      }
      &__single-value {
        // dark mode
        // color: $appColorWhite !important;
        color: $appColorBlack !important;
        padding-top: 1.5px;
      }
      &__placeholder {
        // dark mode
        // color: $appColorWhite;
        color: $appColorBlack;
        opacity: 0.4;
      }
      &__indicators {
        // dark mode
        // color: $appColorWhite;
        color: $appColorBlack;
        padding: 0 0 0 8px !important;
        svg {
          // dark mode
          // fill: $appColorWhite;
          fill: $appColorBlack;
          height: 12px;
          width: 12px;
          transition: transform 0.15s;
        }
      }
      &__indicator-separator {
        display: none;
      }
    }
  }
}

@media (max-width: 1180px) {
  .l-search-filter {
    $this: &;
    left: 60px;
    padding: 25px;
    width: calc(100% - 60px);

    &--no-sidebar {
      left: 0;
      width: 100%;
    }

    &--project {
      #{$this}__reset {
        margin: 10px 0 5px 0;
      }
    }
    &__reset {
      margin: 10px 0 5px 0;
    }
  }
}

@media (max-width: $appBreakPoint) {
  .l-search-filter {
    $this: &;
    // dark mode
    // background: rgba(49, 58, 70, .9);
    box-shadow: none;
    background: rgba(
      red($appColorGrayScale15),
      green($appColorGrayScale15),
      blue($appColorGrayScale15),
      0.9
    );
    // background: rgba(
    //   red($appColorLightModeSideBarScale1),
    //   green($appColorLightModeSideBarScale1),
    //   blue($appColorLightModeSideBarScale1),
    //   0.85
    // );
    height: calc(100% - 50px);
    left: -100%;
    max-height: calc(100% - 50px);
    opacity: 1;
    overflow: initial;
    padding: 0 0 70px 0;
    top: 50px;
    transition: opacity 0.15s, left 0.3s, top 0s, width 0s;
    visibility: visible;
    width: 100%;
    z-index: 8090;

    &--company {
      #{$this}__reset {
        margin: 15px 0 50px 0;
      }
      #{$this}__item {
        &--btns {
          margin: 10px 0 190px 0;
          .c-btn {
            margin: 0;
          }
        }
      }
    }

    &--project {
      #{$this}__reset {
        margin: 15px 0 50px 0;
      }
      #{$this}__item {
        &--btns {
          margin: 10px 0 140px 0;
          .c-btn {
            margin: 0;
          }
        }
      }
    }

    &--user {
      #{$this}__reset {
        margin: 15px 0 50px 0;
      }
      #{$this}__item {
        &--btns {
          margin: 10px 0 190px 0;
          .c-btn {
            margin: 0;
          }
        }
      }
    }

    &.is-pc {
      // display: none;
      max-height: 0;
      overflow: hidden;
      visibility: hidden;
    }

    &.is-sp {
      display: flex;
    }

    &.is-active {
      left: 0;
      top: 50px;
      visibility: visible;
    }

    &__reset {
      width: 100%;
      .c-btn {
        height: 42px;
        width: 100%;
      }
    }

    &__close {
      align-items: center;
      background: $appColorGrayScale2;
      box-sizing: border-box;
      bottom: 0;
      display: flex;
      height: 50px;
      right: 0;
      top: auto;
      width: 50px;
      i {
        color: $appColorWhite;
        font-size: 28px;
        margin: 0;
      }
    }

    &__enter {
      display: flex;
      width: 100%;
      .c-btn {
        height: 40px;
        &__text {
          font-size: $appFontSizeS;
        }
      }
    }

    &__control {
      align-items: center;
      box-sizing: border-box;
      bottom: 0;
      display: flex;
      flex-wrap: wrap;
      height: 70px;
      justify-content: center;
      overflow: hidden;
      padding: 0 75px 0 4%;
      position: absolute;
      width: 100%;
    }

    &__container {
      box-sizing: border-box;
      height: 100%;
      padding: 4% 4% 0 4%;
      z-index: 2999;
      .simplebar {
        &-wrapper {
          overflow: hidden !important;
        }
        &--mask {
          overflow: hidden !important;
        }
        &-content-wrapper {
          overflow: hidden scroll !important;
        }
      }
    }

    &__block {
      margin: 0 auto;
    }
    &__item {
      margin: 0 0 25px 0;
      width: 100%;
      .field-label {
        margin-bottom: -8px;
      }
      &.is-pc {
        display: none;
      }
      &.is-sp {
        display: block;
      }
      &--no-label {
        padding-top: 0;
      }

      &--from,
      &--to {
        .overlay {
          height: 100%;
          width: 100%;
        }
        .form-group {
          &.text-danger {
            .invalid-feedback {
              width: auto !important;
              z-index: 1002;
            }
          }
        }
      }

      .form-group {
        &.text-danger {
          .invalid-feedback {
            padding: 6.5px 15px;
            width: auto;
          }
        }
      }

      .c-select {
        &__indicators {
          margin-right: 6px;
        }
      }
    }
    &__item-group {
      margin: 0 0 0 0;
      width: 100%;
    }
  }
}

@media (max-width: 359px) {
  .l-search-filter {
    $this: &;
    left: -1080px;
  }
}
