.p-error {
  $this: &;
  &--empty,
  &--failed {
    display: flex;
    flex-direction: column;
    padding: 80px 0 0 0;
    width: 100%;
    #{$this}__title {
      display: flex;
      font-size: $appFontSize3XL;
      font-weight: $appFontWeightBold;
      justify-content: center;
      margin: 0 0 0 0;
    }
    #{$this}__desc {
      display: flex;
      font-size: $appFontSizeL;
      font-weight: $appFontWeightBold;
      justify-content: center;
      margin: 25px 0 0 0;
      p {
        display: block;
        margin-bottom: 5px;
        text-align: center;
        width: 100%;
      }
    }
    #{$this}__btns {
      display: flex;
      justify-content: center;
      margin: 45px 0 5px 0;
      width: 100%;
      .c-btn {
        width: 265px;
      }
    }
  }
}

@media (max-width: $appBreakPoint) {
  .p-error {
    $this: &;
    &--empty,
    &--failed {
      #{$this}__title {
        font-size: $appFontSizeL;
        line-height: 1.25em;
      }
      #{$this}__desc {
        font-size: $appFontSizeS;
        margin: 15px 0 0 0;
        text-align: center;
        p {
          display: inline;
        }
      }
      #{$this}__btns {
        margin: 35px 0 5px 0;
        .c-btn {
          width: 265px;
        }
      }
    }  
  }
}