.l-sidebar {
  $this: &;
  background: $appColorLightModeSideBarScale1;
  border-right: 1px $appColorGrayScale12 solid;
  border-radius: 0;
  // height: calc(100% - 45px);
  height: 100%;
  padding: 60px 0 65px 0;
  position: fixed;
  transition: background 300ms, width 300ms;
  visibility: visible;
  width: 200px;
  z-index: 3000;

  &.is-pc {
    display: block;
  }
  &.is-sp {
    display: none;
  }
  &.is-none {
    display: none;
  }
  &__content {
    height: 100%;
  }
  &__close {
    display: none;
  }

  &-list {
    border-radius: 0;
    padding-top: 20px;
    &__item {
      background: transparent;
      border: none;
      border-radius: 0;
      cursor: pointer;
      margin: 0 auto;
      padding: 0;
      position: relative;
      transition: width 300ms;
      width: 200px;

      &:hover {
        background: $appColorLightModeSideBarScale3;
      }
      &.is-active {
        background: $appColorLightModeSideBarScale2;
        &:hover {
          background: $appColorLightModeSideBarScale2;
        }
      }

      &--child {
        &.is-active {
          background: $appColorLightModeSideBarScale3;
        }
        &::before {
          display: none !important;
        }
        .col {
          padding: 12px 14px 12px 32px !important;
        }
      }
      .col {
        align-items: center;
        display: flex;
        justify-content: flex-start;
        padding: 16px 14px;
        position: relative;
        width: 100%;
        i {
          color: $appColorBlack;
          font-size: $appFontSizeXL;
          margin: 0 7.5px 0 5px;
          transition: margin-left 0.3s;
          &.is-deal,
          &.is-post {
            font-size: $appFontSizeM;
            margin: 2px 7.5px 0 7px;
          }
          &.dripicons-return {
            margin-top: -6px;
          }
        }
        .c-text {
          @include ellipsis;
          color: $appColorBlack;
          font-size: $appFontSizeM;
          font-weight: $appFontWeightExtraBold;
          transition: opacity 300ms;
        }
      }
    }
    &__item-group {
      position: relative;
      &.is-sp {
        display: none;
      }
    }
    &__item-group + &__item-group,
    &__item + &__item {
      &::before {
        border-top: 1px solid $appColorLightModeSideBarScale2;
        content: '';
        display: block;
        height: 1px;
        position: absolute;
        top: -1px;
        width: 100%;
        transition: opacity 300ms;
        z-index: 1000;
      }
      &.is-separate {
        &::before {
          border-top: 1px solid $appColorLightModeSideBarScale4;
        }
      }
    }
  }
}

@media (max-width: 1180px) {
  .l-sidebar {
    $this: &;
    width: 60px;
    &:hover {
      overflow: hidden;
      width: 200px;
      #{$this}-list {
        &__item {
          width: 200px;
          i {
            margin: 0 7.5px 0 5px;
            &.is-deal,
            &.is-post {
              font-size: $appFontSizeM;
              margin: 2px 7.5px 0 7px;
            }
          }
          &--child {
            display: flex;
            height: 45px;
            width: auto;
            overflow: inherit;
            &::before {
              display: none !important;
            }
            .col {
              padding: 12px 15px 12px 32px !important;
            }
          }
          .col {
            justify-content: flex-start;
            .c-text {
              overflow: inherit;
              opacity: 1;
              width: auto;
            }
          }
          &.has-child-menu {
            .col {
              padding: 20px 15px !important;
            }
          }
        }
      }
      #{$this}-list__item-group + #{$this}-list__item-group,
      #{$this}-list__item + #{$this}-list__item {
        &::before {
          opacity: 1;
          width: 100%;
        }
        &.is-separate {
          &::before {
            border-top: 1px solid $appColorLightModeSideBarScale4;
          }
        }
      }
    }
    &-list {
      &__item {
        width: 60px;
        &--child {
          height: 0;
          width: 0;
          overflow: hidden;
          transition: height 0.3s;
          &::before {
            display: none !important;
          }
          .col {
            padding: 20px 15px !important;
          }
        }
        .col {
          i {
            margin: 0 0 0 5px;
            &.is-deal,
            &.is-post {
              font-size: $appFontSizeM;
              margin: 2px 0 0 7px;
            }
            &.dripicons-return {
              margin-top: -6px;
            }
          }
          .c-text {
            overflow: hidden;
            opacity: 0;
            width: 0;
            transition: opacity 0.3s;
          }
        }
        &.has-child-menu {
          .col {
            padding: 20px 15px !important;
            &:after {
              display: none;
            }
          }
        }
      }

      &__item-group + &__item-group,
      &__item + &__item {
        &::before {
          opacity: 0;
          width: 0;
        }
      }
    }
  }
}

@media (max-width: $appBreakPoint) {
  .l-sidebar {
    $this: &;
    backdrop-filter: blur(10px);
    background: rgba(
      red($appColorLightModeSideBarScale1),
      green($appColorLightModeSideBarScale1),
      blue($appColorLightModeSideBarScale1),
      0.85
    );
    // background: rgba(
    //   red($appColorGrayScale15),
    //   green($appColorGrayScale15),
    //   blue($appColorGrayScale15),
    //   0.85
    // );
    border-right: none;
    box-sizing: border-box;
    height: calc(100% - 50px);
    padding: 0 0 0 0;
    position: fixed;
    right: -100%;
    top: 50px;
    transition: left 300ms, right 300ms;
    visibility: visible;
    width: 100%;
    z-index: 10000;
    &.is-pc {
      display: none;
    }
    &.is-sp {
      display: block;
    }

    &:hover {
      width: 100%;
      #{$this}-list {
        &__item {
          width: 100%;
          i {
            margin: 0 12.5px 0 5px;
            &.is-deal,
            &.is-post {
              font-size: $appFontSizeS;
              margin: 2px 13.5px 0 7px;
            }
          }
          .col {
            justify-content: flex-start;
            padding: 15px 20px !important;
            .c-text {
              max-height: 100%;
              max-width: 100%;
              opacity: 1;
              width: auto;
            }
          }
          &--child {
            &::before {
              display: block !important;
            }
            .col {
              padding: 15px 20px 15px 35px !important;
            }
          }
          &.has-child-menu {
            .col {
              padding: 15px 20px 15px 20px !important;
            }
          }
        }
      }
    }

    &.is-active {
      right: 0%;
      visibility: visible;
      width: 100%;
      #{$this}__close {
        right: 0;
      }
    }
    &__content {
      max-height: 100%;
      overflow-x: hidden;
      overflow-y: auto;
    }

    &__close {
      align-items: center;
      background: $appColorGrayScale2;
      box-sizing: border-box;
      bottom: 0;
      color: $appColorWhite;
      cursor: pointer;
      display: flex;
      height: 50px;
      justify-content: center;
      padding: 0;
      position: fixed;
      right: -50px;
      transition: right 300ms;
      width: 50px;
      z-index: 3001;
      i {
        font-size: 28px;
        margin: 0;
      }
      @media (max-height: 110px) {
        display: none;
      }
    }

    &-list {
      padding-top: 0;
      &__item {
        box-sizing: border-box;
        margin: 0 auto;
        width: 100%;
        &:hover {
          &:not(.is-active) {
            background: transparent;
          }
          width: 100%;
        }
        &--child {
          height: auto !important;
          &::before {
            display: block !important;
            top: 0 !important;
          }
          .col {
            padding: 15px 20px 15px 35px !important;
          }
        }
        .col {
          justify-content: flex-start;
          padding: 15px 20px;
          i {
            font-size: $appFontSizeL;
            margin-right: 12.5px;

            &.is-deal,
            &.is-post {
              font-size: $appFontSizeS;
              margin: 2px 13.5px 0 7px;
            }

            &.dripicons-return {
              margin-top: -4px;
            }
          }
          .c-text {
            font-size: $appFontSizeS;
            max-height: 100%;
            max-width: 100%;
            opacity: 1;
            width: auto;
          }
        }
        &.has-child-menu {
          .col {
            padding: 15px 20px 15px 20px !important;
          }
        }
      }

      &__item-group {
        &.is-sp {
          display: block;
        }
      }

      &__item--child,
      &__item-group + &__item-group,
      &__item + &__item {
        &::before {
          opacity: 1;
          width: 100%;
        }
        &.is-separate {
          &::before {
            border-top: 1px solid $appColorLightModeSideBarScale4;
            opacity: 1;
            width: 100%;
          }
        }
      }
    }
    #{$this}-list__item-group + #{$this}-list__item-group,
    #{$this}-list__item + #{$this}-list__item {
      &::before {
        opacity: 1;
        width: 100%;
      }
      &.is-separate {
        &::before {
          border-top: 1px solid $appColorLightModeSideBarScale4;
        }
      }
    }
  }
}
