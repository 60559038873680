.p-user-setting {
  $this: &;

  .separator {
    border-top: 1px $appColorGrayScale6 solid;
    // margin: 32px 0 32px 0;
    margin: 52px 0 2px 0;
    width: 100%;
  }

  &--edit,
  &--edit.card {
    background: $appColorWhite;
    border-radius: 8px;
    box-sizing: border-box;
    margin-top: 20px;
    padding: 45px 40px;
    position: relative;
    width: 100%;
    #{$this}__block {
      padding: 0;
      min-height: auto;
      &--head {
        border: 0;
        margin: 0 0 25px 0;
      }
    }

    #{$this}__link {
      color: $appColorBlack;
      text-decoration: underline;
      &:hover,
      &:active,
      &visited {
        color: $appColorBlack !important;
        text-decoration: underline !important;
      }
    }

    #{$this}__item {
      margin: 0;
      &--btns {
        #{$this}__link {
          right: 40.5px;
          position: absolute;
          top: 18.5px;
        }
        .c-btn {
          &--badge,
          &--badge-black,
          &--badge-gray {
            font-size: $appFontSizeM;
            height: 36px;
            padding-left: 14px;
            padding-right: 14px;
            position: absolute;
            right: 18.5px;
            top: 18.5px;
            .c-btn__text {
              font-size: $appFontSizeS;
            }
          }
        }
      }
    }
    #{$this}-password-policy {
      background: $appColorGrayScale9;
      border-bottom: 0;
      border-radius: $appRadiusM;
      box-sizing: border-box;
      padding: 25px;
      min-height: auto;
      &__desc {
        margin-bottom: 10px;
        &--warning {
          color: $appColorFreeRed;
          margin-bottom: 0;
        }
      }
      &__example {
        align-items: center;
        display: flex;
        flex-direction: row;
        margin-bottom: 25px;
        dt {
          font-weight: $appFontWeightNormal;
          margin-bottom: 0;
        }
        dd {
          margin-bottom: 0;
        }
      }
      &-list {
        box-sizing: border-box;
        margin: 0 0 0 0;
        padding: 15px 0 0 0;
        dt {
          font-weight: $appFontWeightNormal;
          margin: 18px 0 8px 0;
          &.condition {
            margin-top: 0;
          }
          &::before {
            // ※
            // content: '\203B';
            // ■
            content: '\25A0';
            padding-right: 5px;
          }

          &.available,
          &.example {
            border-top: 1px $appColorGrayScale6 solid;
            padding-top: 16px;
          }

          &.example {
            &::before {
              display: none;
            }
          }
        }
        dd {
          margin-bottom: 0;
          &::before {
            content: '\30FB';
          }
          &.warning {
            &::before {
              content: '\203B';
              padding-right: 5px;
            }
          }
        }
      }
    }

    #{$this}-form {
      align-items: flex-start;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      &--profile {
        flex-direction: row;
        padding-top: 20px;
      }
      &--work-experience {
        padding-top: 30px;
        &-list {
          margin: 0;
          padding-top: 0;
        }
      }
      &--project-condition {
        // padding-top: 30px;
        &-list {
          margin: 0;
          padding-top: 0;
        }
      }
      &__block {
        &--image {
          flex-grow: 0;
          margin: 0;
          width: 200px;
        }
        &--base-data {
          box-sizing: border-box;
          flex-grow: 1;
          margin: 0;
          padding: 0 0 0 50px;
          width: auto;
          #{$this}-form__item {
            &:first-child {
              padding-top: 0;
            }
          }
        }
      }
      &__supply-text {
        &--code {
          font-size: $appFontSizeL;
          margin: 0 0 0 0;
        }
        &--display-name-used {
          color: $appColorFreeRed;
          font-size: $appFontSizeS;
          font-weight: $appFontWeightExtraBold;
          margin: 3px 0 20px 0px;
        }
        &--tel {
          color: $appColorFreeRed;
          font-size: $appFontSizeS;
          font-weight: $appFontWeightExtraBold;
          margin: 5px 0 0 0;
        }
      }
      &__item {
        display: flex;
        flex-direction: column;
        margin: 0 0 25px 0;
        position: relative;
        width: 100%;
        .c-icon {
          z-index: 101;
        }
        &--name {
          flex-direction: row;
          flex-wrap: nowrap;
          gap: 0 18px;
          transition: gap 0.3s;
          .form-group {
            width: calc(50% - 9px);
            .invalid-feedback {
              bottom: 48px;
              top: auto;
            }
          }
          &.is-active {
            background: $appColorGrayScale8;
            border-radius: $appRadiusM;
            padding: 12px 12px;
            transition: background 0.3s;
            position: relative;
            &::after {
              background: $appColorPrimary1;
              border-radius: 0 $appRadiusM 0 0;
              color: $appColorWhite;
              content: '\9078\629E\4E2D';
              font-size: $appFontSizeS - 1;
              font-weight: $appFontWeightExtraBold;
              padding: 2px 12px;
              position: absolute;
              right: 0;
              top: 0;
              z-index: 1100;
            }
            .form-group {
              .invalid-feedback {
                z-index: 1200;
              }
            }
          }
        }

        &--display-name {
          &.is-active {
            background: $appColorGrayScale8;
            border-radius: $appRadiusM;
            padding: 12px 12px;
            transition: background 0.3s;
            position: relative;
            &::after {
              background: $appColorPrimary1;
              border-radius: 0 $appRadiusM 0 0;
              color: $appColorWhite;
              content: '\9078\629E\4E2D';
              font-size: $appFontSizeS - 1;
              font-weight: $appFontWeightExtraBold;
              padding: 2px 12px;
              position: absolute;
              right: 0;
              top: 0;
              z-index: 1100;
            }
            .form-group {
              .invalid-feedback {
                z-index: 1200;
              }
            }
          }
        }

        &--code {
          margin: 0 0 0 0;
          .form-group {
            &--row {
              margin-left: 10px;
              display: flex;
              align-items: center;
              gap: 4px;
              .form-group {
                flex-grow: 2;
              }
            }
          }
          .c-btn {
            &--badge-gray {
              float: right;
              margin-top: 10px;
              padding: 0 10px;
            }
          }
          .invalid-feedback {
            bottom: 48px;
            top: auto;
            z-index: 1000;
          }
          .supply-text {
            font-size: $appFontSizeS;
            color: $appColorGrayScale1;
            margin: 3px 0 0 10px;
          }
        }

        &--half {
          .form-group {
            width: 50%;
          }
        }
        &--is-display-name-used {
          margin: 0 0 0 0;
          padding: 10px 0;
        }
        &--image {
          .invalid-feedback {
            bottom: 100%;
            top: auto;
            width: 220px;
            z-index: 1000;
          }
        }

        &--location {
          margin-bottom: 25px;
        }
        &--work-type {
          margin-bottom: 0;
        }
        &--working-status-type {
          margin-bottom: 0;
        }
        &--new-password-confirm {
          margin-bottom: 0;
        }

        &--overview {
          margin-bottom: 0;
          .c-input {
            height: 250px;
            max-height: 400px;
            min-height: 250px;
          }
        }

        &--is-deliverable {
          margin-bottom: 0;
        }
        &--deliver-email {
          margin: 25px 0 0 0;
        }

        &--working-at {
          margin: 25px 0 0 0;
          width: 50%;
        }

        &--project-condition {
          margin: 35px 0 0 0;
        }

        &--work-experience {
          margin: 50px 0 0 0;
        }

        &--publish-profile-search-restriction {
          &-company {
            margin: 35px 0 0 0;
          }
          &-user {
            margin: 0;
          }
        }

        &--password {
          .c-icon {
            color: $appColorGrayScale6;
            font-size: $appFontSizeIconInputField1;
            left: 10px;
            position: absolute;
            top: 36px;
            &--eye {
              cursor: pointer;
              left: auto;
              right: 10px;
            }
          }
        }

        .form-group {
          margin-bottom: 0;
          label {
            font-size: $appFontSizeM;
            font-weight: $appFontWeightExtraBold;
            margin-bottom: 5px;
          }
          .c-switch {
            label {
              font-weight: $appFontWeightNormal;
            }
          }
          legend {
            font-size: $appFontSizeM;
            font-weight: $appFontWeightExtraBold;
            margin-bottom: 0;
          }
          &.c-checkbox,
          &.c-radio {
            legend label {
              font-size: $appFontSizeM;
              font-weight: $appFontWeightExtraBold;
              margin-bottom: 0;
            }
            label {
              font-weight: $appFontWeightNormal;
            }
            .custom-control-label {
              font-weight: $appFontWeightNormal;
            }
            .form-control {
              padding-bottom: 0 !important;
            }
          }
          .c-input {
            z-index: 100;
            &--password {
              padding-left: 41px;
            }
          }
        }
        &--btns {
          margin: 25px 0 5px 0;
          button.c-btn {
            font-size: $appFontSizeL;
          }
          .c-btn {
            margin: 0 auto;
            width: 265px;
          }
        }
      }
    }
  }
}

@media (max-width: 960px) {
  .p-user-setting {
    $this: &;
    &--edit,
    &--edit.card {
      #{$this}-form {
        &__item {
          &--name {
            gap: 0 12px;
            .form-group {
              width: calc(50% - 6px);
            }
          }
        }
      }
    }
  }
}

@media (max-width: $appBreakPoint) {
  .p-user-setting {
    $this: &;
    &--edit,
    &--edit.card {
      border-radius: $appRadiusM;
      padding: 32px 24px;

      #{$this}__item {
        margin: 0;
        &--btns {
          .c-btn {
            &--enter {
              margin: 0;
              position: relative;
              right: auto;
              top: auto;
              width: 100%;
            }
            &--badge,
            &--badge-black,
            &--badge-gray {
              font-size: $appFontSizeS;
              height: 32px;
              padding-left: 10px;
              padding-right: 10px;
              position: absolute;
              right: 14px;
              top: 16px;
              .c-btn__text {
                font-size: $appFontSizeS;
              }
            }
          }
        }
      }

      #{$this}-password-policy {
        font-size: $appFontSizeS;
        padding: 18px;
        &__desc {
          margin-bottom: 0;
        }
        &__example {
          margin-bottom: 0;
        }
        &-list {
          dt {
            font-weight: $appFontWeightNormal;
            margin: 14px 0 8px 0;
            &.condition {
              margin-top: 0;
            }
            &.example,
            &.available {
              padding-top: 12px;
            }
          }
        }
      }

      #{$this}-form {
        flex-direction: column;
        &--profile {
          padding-top: 50px;
        }
        &--work-experience {
          padding-top: 30px;
          &-list {
            margin: 0;
            padding-top: 0;
          }
        }
        &--project-condition {
          // padding-top: 30px;
          &-list {
            margin: 0;
            padding-top: 0;
          }
        }
        &__item {
          margin: 0 0 15px 0;
          &--image {
            .invalid-feedback {
              width: auto;
            }
          }
          &--code {
            margin: 0 0 0 0;
            .form-group{
              &--row {
                margin-left: 0;
                .form-group {
                  width: min-content;
                }
              }
            }
            .c-btn {
              &--badge-gray {
                font-size: $appFontSizeS;
              }
            }
            .supply-text {
              margin: 3px 0 0 0;
            }
          }
        }
        &__supply-text {
          &--display-name-used {
            font-size: $appFontSizeS - 1;
            margin: 0 0 4px 0px;
          }
          &--tel {
            font-size: $appFontSizeS - 1;
            margin: 5px 0 0 0;
          }
        }

        &__block {
          &--image {
            margin-bottom: 10px;
            width: 100%;
            #{$this}-form {
              &__item {
                .form-group {
                  display: flex;
                  justify-content: center;
                }
                .c-upload-supply {
                  display: none;
                }
              }
            }
          }
          &--base-data {
            padding-left: 0;
            width: 100%;
            #{$this}-form {
              &__item {
                .form-group {
                  label {
                    font-size: $appFontSizeS;
                    margin-bottom: 5px;
                  }
                  .c-input {
                    font-size: $appFontSizeS;
                  }
                }
                .c-radio {
                  legend {
                    font-size: $appFontSizeS;
                  }
                  .form-control {
                    padding: 10px 0 0 0 !important;
                  }
                  &__item {
                    margin: 0 0 5px 0;
                    width: 100%;
                  }
                }
                &--is-display-name-used {
                  margin: 0;
                }
                &--display-name {
                  &.is-active {
                    &::after {
                      font-size: $appFontSizeXS;
                    }
                  }
                }
                &--name {
                  flex-direction: column;
                  flex-wrap: wrap;
                  margin-bottom: 15px;
                  gap: 15px 0;
                  &.is-active {
                    &::after {
                      font-size: $appFontSizeXS;
                    }
                  }
                  .form-group {
                    width: 100%;
                    .invalid-feedback {
                      bottom: auto;
                      top: -15px;
                    }
                  }
                }
                &--location {
                  margin-bottom: 25px;
                }
              }
            }
          }
        }
        &__item {
          &--working-status-type {
            margin-bottom: 0;
          }
          &--new-password-confirm {
            margin-bottom: 0;
          }

          &--is-deliverable {
            margin-bottom: 0;
          }
          &--deliver-email {
            margin: 15px 0 0 0;
          }

          &--working-at {
            margin: 15px 0 0 0;
            width: 100%;
          }

          &--project-condition,
          &--work-experience {
            margin: 25px 0 15px 0;
            &-language,
            &-price {
              margin-bottom: 0;
            }
          }

          &--publish-profile-search-restriction {
            &-company {
              margin: 25px 0 0 0;
            }
            &-user {
              margin: 0 0 15px 0;
            }
          }

          &--password {
            .c-icon {
              top: 37px;
            }
          }

          &--btns {
            margin: 32px 0 0 0;
            .c-btn {
              margin-left: 0;
              margin-right: 0;
              width: 100%;
            }
          }
          .form-group {
            label {
              font-size: $appFontSizeS;
              margin-bottom: 5px;
            }
            .c-input {
              font-size: $appFontSizeS;
            }
            &.c-checkbox,
            &.c-radio {
              legend label {
                font-size: $appFontSizeS;
                margin-bottom: 5px;
              }
              .form-control {
                padding: 10px 0 0 0 !important;
              }
              &__item {
                margin: 0 0 5px 0;
                width: 100%;
              }
            }
            .c-checkbox,
            .c-radio {
              .form-control {
                padding: 10px 0 0 0 !important;
              }
              &__item {
                margin: 0 0 5px 0;
                width: 100%;
              }
            }
          }
        }
      }
    }
  }
}
