/**
 * .l-page要素以下の既存コンポーネントの初期スタイルをリセットする設定
 */
.l-page {
  .card {
    // box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.05);
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.075);
    margin: 0;
    padding: 0;
  }
}
