html {
  font-size: $appFontSizeBase;
  height: 100%;
}
body {
  background: $appColorWhite;
  color: $appColorBlack;
  font-family: $appFontFamily;
  font-size: $appFontSizeM;
  font-weight: $appFontWeightNormal;
  height: 100% !important;
  line-height: $appTextLineHeight;
  min-width: 360px;
  padding: 0;
  word-break: break-all;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: rgba(107, 83, 83, 0);

  &.l-body {
    background: $appColorGrayScale9;
    &--base {
      background: $appColorGrayScale15;
    }
    &--auth {
      // background: $appColorGrayScale8;
      background: $appColorGrayScale15;
    }
    &--dark {
      background: $appColorGrayScale15;
    }
    &--account-setting {
      background: $appColorGrayScale15;
    }
    &--chat-group-message {
      .l-footer {
        display: none;
      }
    }
  }

  #root {
    height: 100%;
    width: 100%;
  }

  .is-pc {
    display: block;
  }
  .is-sp {
    display: none;
  }

  a {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }

  *:focus {
    outline: none !important;
  }

  *::selection {
    background: $appColorPrimary3;
    color: $appColorWhite;
  }

  // START 全ページのCSSが最新になったら消す箇所 >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
  .custom-control-label {
    cursor: pointer;
    user-select: none;
  }
  .custom-control-input:checked ~ .custom-control-label {
    &::before {
      color: #008bcc;
      border-color: #008bcc;
      background-color: #008bcc;
    }
  }
  .custom-radio .custom-control-label::before,
  .custom-radio .custom-control-label::after {
    margin-top: -1px;
  }
  .custom-switch .custom-control-label::before,
  .custom-switch .custom-control-label::after {
    cursor: pointer;
    margin-top: -1px;
  }
  // <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<< END 全ページのCSSが最新になったら消す箇所

  .swiper {
    --swiper-navigation-size: 50px;
    --swiper-theme-color: #008bcc;
    &-slide-next,
    &-slide-prev {
      opacity: 0;
      // transition: opacity 0.05s;
    }
  }
}

@media (min-width: ($appBreakPoint + 1px)) {
  body {
    &.l-body--is-no-scrollbar {
      -ms-overflow-style: none;
      scrollbar-width: none;
      &::-webkit-scrollbar {
        display: none;
      }
    }
  }
}

@media (max-width: $appBreakPoint) {
  body {
    #root {
      height: 100%;
      width: 100%;
    }
  }
}

@media (min-height: 960px) {
  .is-pc {
    display: none;
  }
  .is-sp {
    display: block;
  }
  // .l-body {
  //   &--auth {
  //     .l-footer {
  //       // min-width: 360px;
  //       // position: fixed;
  //     }
  //   }
  // }
}
