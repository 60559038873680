@mixin ellipsis() {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@mixin ellipsisMultiple($clamp:2) {
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: $clamp;
}

@mixin fadeIn() {
  max-height: none;
  max-width: none;
  opacity: 1;
  overflow: inherit;
  visibility: visible;
}

@mixin fadeOut() {
  max-height: 0;
  max-width: 0;
  opacity: 0;
  overflow: hidden;
  visibility: hidden;
}