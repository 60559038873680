#sortSelect {
  background: none !important;
  border: none !important;
  padding: 0 !important;
  font-weight: 900;
  margin-top: -2px;
}

.post-header {
  .card-image-circle {
    float: left;
  }
}

.card-image-circle {
  border-radius: 50%;
  align-items: center;
  cursor: pointer;
  display: flex;
  justify-content: center;
  position: relative;
  .col {
    align-items: center;
    background: #fff;
    border-radius: 50%;
    display: flex;
    flex-basis: auto;
    flex-grow: 0;
    justify-content: center;
    margin: 0;
    overflow: hidden;
    padding: 0;
  }
  img {
    display: block;
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}

.create-post-form-group {
  &-new-post {
    // display: none;
    align-items: center;
    display: flex;
    justify-content: flex-start;
    padding: 0 12px;
    gap: 12px;
    .col {
      padding: 0;
    }
    .row {
      margin: 0;
    }
    .upload-image-outside {
      margin: 12px 0 0 -12px;
    }
    .profile-image {
      max-width: 48px;
      min-width: 48px;
      width: 48px;
    }
    .create-button {
      max-width: calc(100% - (48px + 22px + 24px));
      min-width: calc(100% - (48px + 22px + 24px));
      width: calc(100% - (48px + 22px + 24px));
    }
    .draft-button {
      max-width: 22px;
      min-width: 22px;
      width: 22px;
      .c-menu-icon {
        width: 22px;
        &__text {
          &::after {
            right: 16px;
          }
        }
      }
    }
  }
}

.create-post-button {
  margin: 0.2rem 0;
  padding: 8px 10px;
  font-size: 1.2rem;
  width: 100%;
  border: 1px solid;
  border-radius: 35px;
  text-align: left;
  font-weight: 600;
}

.header-post-button {
  background: none;
  border: none;
  box-shadow: none;
  color: #000000;
  font-size: 16px;
  border-radius: 15px;

  &:hover {
    background: #a8a8a8;
  }
}

.show-more-less-clickable {
  color: #008bcc;
  font-weight: bold;
}

.submit-post-button {
  border-radius: 35px;
  background: #008bcc !important;
  border: none;
  box-shadow: none;

  &:disabled {
    background: rgba(238, 238, 238, 1) !important;
    color: #444444;
    font-weight: 600;
  }
}

.bulk-action {
  border-radius: 50%;
  width: 39px;
  height: 29px;
  padding: 0;
  float: right;
  margin-top: -10px;

  i {
    font-size: 25px;
  }
}

.upload-image {
  &:hover {
    background: #f3f2ef;
  }
  &:focus {
    background: #f3f2ef;
  }
  &:active {
    background: #f3f2ef;
  }
}

.remove-upload-image {
  position: absolute;
  right: 3%;
  top: 1%;
  z-index: 1;
  background: #000;
  border-radius: 50%;
  width: 45px;
  height: 46px;
  i {
    color: #ffffff;
    font-size: 35px;
  }
}

.upload-image-box {
  width: 552px;
  height: 200px;
  text-align: center;

  .select-image-text {
    position: absolute;
    top: 50%;
    bottom: 0;
    left: 0;
    right: 0;
    font-weight: 900;
    font-size: 20px;
    color: #008bcc;
  }
}

.text {
  display: inline;
  width: 100%;
}

.read-or-hide {
  color: rgb(108, 108, 108);
  cursor: pointer;
  &:active,
  &:hover,
  &:focus {
    text-decoration: underline;
    font-weight: bold;
  }
}

.upload-image-outside {
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 167ms;
  align-items: center;
  border: none;
  border-radius: 2px;
  box-sizing: border-box;
  cursor: pointer;
  font-family: inherit;
  font-weight: 600;
  display: inline-flex;
  justify-content: center;
  min-width: 6.4rem;
  max-width: 480px;
  overflow: hidden;
  text-align: center;
  transition-property: background-color, box-shadow, color;
  vertical-align: middle;

  &:hover {
    background-color: #e1e1e1;
  }
}

.create-post-modal {
  .modal-body {
    height: 455px;
    overflow-y: auto;
  }

  .textarea-post-row {
    z-index: 999;
  }

  .modal-footer {
    min-width: 100%;
    .container {
      align-items: center;
      display: flex;
      flex-wrap: nowrap;
      min-width: 100%;
      justify-content: space-between;
      width: 100%;
      .left {
        align-items: center;
        display: flex;
        justify-content: center;
        width: 50px;
        .upload-image {
          height: 36px;
          width: 36px;
        }
      }
      .right {
        display: flex;
        justify-content: flex-end;
        gap: 12px;
        width: calc(100% - 50px);
        .submit-post-button {
          height: 36px;
          width: 90px;
        }
      }
    }
  }
}

.post-setting-modal {
  .modal-body {
    height: 455px;
    overflow-y: auto;
  }

  .textarea-post-row {
    z-index: 999;
  }
}

.tag-select {
  left: 10px;
  position: absolute !important;
}

.show-post-button {
  width: 150px;
  border-radius: 35px;
  background: #008bcc !important;
  border: none;
  box-shadow: none;
}

.post-user-link {
  color: #000000 !important;
  &:active,
  &:hover,
  &:focus {
    text-decoration: underline;
    font-weight: bold;
    color: #008bcc !important;
  }
}

.tag-link {
  color: #008bcc;
  &:active,
  &:hover,
  &:focus {
    text-decoration: underline;
    font-weight: bold;
    color: #008bcc !important;
  }
}

.post-wrapper-class {
  .editor-class {
    height: 274px;
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */
  }

  .editor-class::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }

  div {
    overflow: visible;
  }

  .rdw-suggestion-dropdown {
    bottom: unset !important;
  }
}

.textarea-post {
  height: fit-content !important;
}

.textarea-post-min-height {
  min-height: 360px;
}

.post-user-avatar {
  .card-image-circle {
    margin: -15% auto 12px;
    position: relative;
  }
}

.post-setting-modal {
  min-width: 600px;

  .setting-post-user-button {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background: none;
    border: none;
    box-shadow: none;
    color: #333333;
    font-size: 16px;
    padding: 6px 12px;

    &:hover {
      background: #a8a8a8;
    }

    .user-info {
      display: flex;
      align-items: center;

      .user-name {
        display: flex;
        align-items: center;

        .user-name {
          max-height: 20px;
        }
      }
    }

    .checkbox {
      width: 20px;
    }

    i {
      font-size: 26px;
    }
  }

  .post-sharing-icon {
    background: #dee2e6;
    border-radius: 50%;
    display: block;
    height: 37px;
    width: 37px;
  }

  .setting-sharing {
    font-size: 16px;
    font-weight: bold;
  }

  .post-sharing-button {
    display: flex;
    align-items: center;
    width: 100%;
    background: none;
    border: none;
    box-shadow: none;
    color: #333333;
    font-size: 16px;
    padding: 6px 12px;

    i {
      font-size: 26px;
    }

    &:hover {
      background: #a8a8a8;
    }

    .object-sharing {
      padding-left: 8px;
      width: 100%;
      display: flex;
      flex-direction: column;
      flex: 1;

      .name {
        font-weight: bold;
      }

      .description {
        font-size: 14px;
      }
    }

    .checkbox {
      width: 20px;
    }
  }

  .comment-controll-post-button {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background: none;
    border: none;
    box-shadow: none;
    color: #333333;
    font-size: 16px;
    padding: 6px 12px;

    &:hover {
      background: #a8a8a8;
    }

    .title {
      font-weight: bold;
    }

    i {
      font-size: 26px;
    }
  }
}

.actions-post-item {
  &:active {
    background-color: transparent !important;

    &:focus {
      box-shadow: none !important;
    }
  }
}

.show-all-post {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 16px 0;
  width: 100%;
  // button {
  //   font-size: 16px;
  // }
  .c-btn {
    width: 265px;
  }
}

.p-post-list {
  &__item {
    .dropdown {
      &-menu {
        &.show {
          top: 24px !important;
          left: auto !important;
          right: calc(-1rem) !important;
          transform: none !important;
        }
      }
    }
  }
}

.p-post-reaction {
  display: flex;
  flex-direction: column;
  width: 100%;
  &__item {
    align-items: center;
    display: flex;
    gap: 8px;
    justify-content: space-between;
    margin: 0;
    width: 100%;
    .col {
      padding: 0;
    }
  }
  &__profile {
    align-items: center;
    display: flex;
    justify-content: flex-start;
    gap: 8px;
    max-width: calc(100% - 48px);
    min-width: calc(100% - 48px);
    width: calc(100% - 48px);
    .text {
      align-items: center;
      display: flex;
      gap: 8px;
      justify-content: flex-start;
      margin: 0;
      position: relative;
      width: 100%;
      a {
        @include ellipsis;
        display: block;
        width: calc(100% - 100px);
      }
    }
  }
  &__button {
    margin: 4px -12px 0 0;
    max-width: 40px;
    min-width: 40px;
    width: 40px;
  }
}
.p-post-data {
  display: flex;
  flex-direction: column;
  width: 100%;
  &__container {
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: 100%;
  }
  &-head {
    align-items: center;
    display: flex;
    flex-direction: space-between;
    padding: 0 0 0 12px;
    position: relative;
    gap: 8px;
    .col {
      padding: 0;
    }
    &__profile-image {
      max-width: calc(48px);
      min-width: calc(48px);
      width: calc(48px);
    }
    &__title {
      max-width: calc(100% - (40px + 48px + 16px));
      min-width: calc(100% - (40px + 48px + 16px));
      width: calc(100% - (40px + 48px + 16px));
    }
    &__button {
      margin: 4px 0 0 0;
      max-width: 40px;
      min-width: 40px;
      width: 40px;
    }
  }
}

.p-post-modal-user-type-separator {
  //border-bottom: 4px $appColorGrayScale6 solid !important;
  color: $appColorBlack;
  font-size: $appFontSizeM;
  // font-weight: $appFontWeightExtraBold !important;
  background: $appColorGrayScale7;
  padding: 12px 12px 12px 12px;
  margin: 18px -12px;
  &::before {
    content: '\25A0';
    // content: '\25BC';
    font-size: $appFontSizeXS;
    padding-right: 4px;
  }
}

// 表示中のハッシュタグ
.c-post-tagbox {
  &-list {
    width: 100%;
    &-item {
      align-items: center;
      display: flex;
      justify-content: flex-start;
      gap: 0;
      width: 100%;
      &__icon {
        max-width: 72px;
        min-width: 72px;
        width: 72px;
        img {
          height: 48px;
          width: 48px;
        }
      }
      &__text {
        font-size: $appFontSize2XL;
        // font-weight: $appFontWeightExtraBold;
        justify-content: flex-start;
        max-width: calc(100% - 72px);
        min-width: calc(100% - 72px);
        padding: 0;
        width: calc(100% - 72px);
      }
    }
  }
}

@media screen and (max-width: 1441px) {
  .list-post-title {
    padding-left: 15px !important;
  }
}

@media screen and (max-width: 1025px) {
  .list-post-title {
    padding-left: 21px !important;
  }
}

@media (min-width: 992px) {
  .col-lg-10-custom {
    margin-left: 4% !important;
    max-width: 79.333333% !important;
  }
}

@media (min-width: 1440px) {
  .col-lg-10-custom {
    margin-left: 2% !important;
    max-width: 81.333333% !important;
  }
}

@media (min-width: 1919px) {
  .col-lg-10-custom {
    margin-left: 1% !important;
    max-width: 82.333333% !important;
  }
}

@media (max-width: $appBreakPoint) {
  .modal {
    &-content {
      border-radius: $appRadiusM;
      margin: 60px 0 60px 0;
    }
  }
  .create-post-modal,
  .post-setting-modal,
  .post-upload-image-modal {
    max-width: 600px;
    min-width: calc(360px * 0.92) !important;
    width: 92% !important;
    .modal-body {
      max-height: 340px;
    }
  }
  .create-post-modal {
    .modal-body {
      .textarea-post-row {
        .textarea-post {
          min-height: auto;
        }
      }
    }
  }

  .create-post-form-group {
    &-new-post {
      // display: none;
      align-items: center;
      display: flex;
      .upload-image-outside {
        margin: 12px 0 0 -12px;
      }
    }
  }

  .p-post-list {
    &__item {
      .dropdown {
        &-menu {
          &.show {
            top: 24px !important;
            left: auto !important;
            right: calc(-1rem) !important;
            transform: none !important;
          }
        }
      }
    }
  }
  .p-post-modal-user-type-separator {
    font-size: $appFontSizeS;
    &::before {
      font-size: $appFontSizeXS - 2;
    }
  }

  // 表示中のハッシュタグ
  .c-post-tagbox {
    &-list {
      &-item {
        gap: 0;
        &__text {
          font-size: $appFontSizeXL;
        }
      }
    }
  }
  .show-all-post {
    padding: 5px 0 15px 0;
    .c-btn {
      width: 100%;
    }
  }
}

.draft-btn {
  margin-top: 5px;
}
