.p-signin {
  $this: &;

  &,
  &.card {
    background: $appColorWhite;
    border-radius: $appRadiusXL;
    box-sizing: border-box;
    padding: 40px 40px;
    width: 500px;
  }

  .c-message {
    width: 100%;
    &--error {
      margin-bottom: 25px;
    }
  }

  &-nav {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 30px auto 0 auto;
    width: 100%;
    a {
      color: $appColorPrimary4;
      font-size: $appFontSizeM;
      font-weight: $appFontWeightExtraBold;
      position: relative;
      text-align: center;
      text-decoration: underline;
      &:hover,
      &:active,
      &:visited {
        color: $appColorPrimary4;
      }
      &:hover {
        text-decoration: underline;
      }
    }
    .supply {
      color: $appColorPrimary4;
      font-size: $appFontSizeM;
      font-weight: $appFontWeightExtraBold;
      padding-right: 5px;
      position: relative;
      text-align: center;
      text-decoration: none;
    }
    &--logo {
      align-items: center;
      display: flex;
      flex-direction: row;
      gap: 24px;
      justify-content: center;
      margin: 25px auto 0 auto;
      img {
        &.logo-sharing-economy {
          width: 200px;
        }
      }
    }
    // &--truste {
    //   display: flex;
    //   justify-content: center;
    //   margin: 25px auto 0 auto;
    // }
    // &--sharing-economy {
    //   display: flex;
    //   justify-content: center;
    //   img {
    //     max-width: 280px;
    //     width: 92%;
    //   }
    // }
  }

  &__title {
    align-items: center;
    display: flex;
    justify-content: center;
    margin: 0 0 25px 0;
    position: relative;
    width: 100%;
    .col {
      background: #fff;
      box-sizing: border-box;
      color: $appColorBlack;
      flex-basis: auto;
      flex-grow: inherit;
      font-size: $appFontSize3XL;
      font-weight: $appFontWeightBold;
      margin: 0;
      padding: 10px;
      width: auto;
      z-index: 101;
    }
    &::after {
      background: $appColorGrayScale6;
      // content: ' ';
      content: '\a0';
      height: 1px;
      position: absolute;
      width: 100%;
      z-index: 100;
    }
    &--sub {
      align-items: center;
      display: flex;
      justify-content: center;
      margin: 10px 0 20px 0;
      position: relative;
      width: 100%;
      .col {
        background: #fff;
        box-sizing: border-box;
        color: $appColorGrayScale6;
        flex-basis: auto;
        flex-grow: inherit;
        font-size: $appFontSizeL;
        font-weight: $appFontWeightBold;
        margin: 0;
        padding: 10px;
        width: auto;
        z-index: 101;
      }
      &::after {
        background: $appColorGrayScale6;
        // content: ' ';
        content: '\a0';
        height: 1px;
        position: absolute;
        width: 100%;
        z-index: 100;
      }
    }
  }

  &__item {
    margin: 0 0 35px 0;
    &--google,
    &--facebook {
      margin-bottom: 20px;
    }
    &--apple {
      margin-bottom: 0;
    }
  }

  &-form {
    &__item {
      display: flex;
      flex-direction: column;
      margin: 0 0 25px 0;
      position: relative;
      width: 100%;
      .c-icon {
        z-index: 101;
      }
      .form-group {
        margin-bottom: 0;
        label {
          font-size: $appFontSizeM;
          font-weight: $appFontWeightExtraBold;
          margin-bottom: 5px;
        }
        .c-input {
          z-index: 100;
          &--email {
            padding-left: 41px;
          }
        }
      }
      &--email {
        .c-icon {
          color: $appColorGrayScale6;
          font-size: $appFontSizeIconInputField1;
          left: 10px;
          position: absolute;
          top: 36px;
        }
      }
      &--password {
        margin-bottom: 8px;
        &-label {
          flex-direction: row;
          justify-content: space-between;
          margin-bottom: 0;
          label {
            font-size: $appFontSizeM;
            font-weight: $appFontWeightExtraBold;
            margin-bottom: 5px;
          }
        }
        .c-icon {
          color: $appColorGrayScale6;
          font-size: $appFontSizeIconInputField1;
          left: 10px;
          position: absolute;
          top: 10px;
          &--eye {
            cursor: pointer;
            left: auto;
            right: 10px;
          }
        }
        .c-input {
          padding: 0 41px;
        }
        .invalid-feedback {
          top: -40px !important;
        }
      }
      &--password-forget {
        margin: 0 0 0 0;
        a {
          box-sizing: border-box;
          color: $appColorFreeRed;
          display: flex;
          font-size: $appFontSizeS;
          font-weight: $appFontWeightNormal;
          margin-top: 0;
          justify-content: flex-end;
          position: relative;
          &::before {
            // content: '※';
            content: '\203B';
            display: inline;
            padding-right: 5px;
            position: relative;
          }
          &:hover,
          &:active,
          &:visited {
            color: $appColorFreeRed;
          }
          &:hover {
            text-decoration: underline;
          }
        }
      }
      &--login {
        margin-top: 25px;
        .c-btn {
          margin-left: 0;
          margin-right: 0;
        }
      }
    }
  }

  .c-btn {
    $this: &;
    width: 100%;
  }
}

@media (max-height: 900px) {
  .p-signin {
    &,
    &.card {
      margin-top: 30px;
    }
    &-nav {
      &--logo {
        padding-bottom: 90px;
      }
      // &--sharing-economy {
      //   padding-bottom: 90px;
      // }
    }
  }
}

@media (max-width: $appBreakPoint) {
  .p-signin {
    &,
    &.card {
      border-radius: $appRadiusM;
      margin: 60px auto 0 auto;
      padding: 32px 24px;
      width: 92%;
    }

    .c-message {
      &--error {
        margin-bottom: 15px;
      }
    }

    &-nav {
      margin-top: 30px;
      padding-bottom: 0;
      a,
      .supply {
        font-size: $appFontSizeS;
      }
      &--logo {
        flex-direction: column;
        gap: 24px;
        img {
          &.logo-sharing-economy {
            max-width: 280px;
            width: 92%;
          }
        }
      }
    }

    &__title {
      margin: 5px 0 20px 0;
      .col {
        font-size: $appFontSizeL;
        padding: 10px;
      }
      &--sub {
        margin: 5px 0 15px 0;
        .col {
          font-size: $appFontSizeM;
          padding: 10px;
        }
      }
    }

    &__item {
      margin: 0 0 15px 0;
      &--apple {
        margin-bottom: 0;
      }
    }

    &-form {
      &__item {
        margin: 0 0 15px 0;
        .form-group {
          label {
            font-size: $appFontSizeS;
            margin-bottom: 5px;
          }
          .c-input {
            font-size: $appFontSizeS;
          }
        }
        &--email {
          .c-icon {
            top: 37px;
          }
        }
        &--password {
          margin-bottom: 8px;
          &-label {
            margin-bottom: 0;
            label {
              font-size: $appFontSizeS;
              margin-bottom: 5px;
            }
          }
          .c-icon {
            top: 10px;
          }
          .invalid-feedback {
            top: -36px !important;
          }
        }
        &--password-forget {
          a {
            font-size: $appFontSizeS;
          }
        }
        &--login {
          margin-top: 20px;
        }
      }
    }
  }
}
