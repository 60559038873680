.c-tooltip {
  $this: &;
  display: flex;
  position: relative;

  &__icon {
    color: $appColorGrayScale2;
    // cursor: pointer;
    display: block;
    font-size: 24px;
    position: relative;
    width: 24px;
    z-index: 1000;
  }

  &__close {
    display: none;
  }

  &__container {
    background: $appColorGrayScale2;
    border-radius: $appRadiusM;
    color: $appColorWhite;
    // cursor: pointer;
    font-size: $appFontSizeS;
    max-height: 0;
    max-width: 0;
    opacity: 0;
    overflow: hidden;
    padding: 12px 12px;
    position: absolute;
    transition: opacity 0.3s;
    visibility: hidden;
    z-index: 1100;
    p {
      margin-bottom: 0;
    }
    a {
      color: $appColorPrimary4;
      position: relative;
      text-align: center;
      &:hover,
      &:active,
      &:visited {
        color: $appColorPrimary4;
      }
      &:hover {
        text-decoration: underline;
      }
    }
  }

  &.is-pc {
    #{$this}__icon:hover + #{$this}__container,
    #{$this}__container:hover {
      // bottom: 6px;
      left: 0;
      max-height: none;
      max-width: none;
      opacity: 1;
      overflow: auto;
      top: 0;
      visibility: visible;
      width: 100%;
    }
  }

  &.is-sp {
    display: none;
  }

  &--password {
    #{$this}__container {
      .title {
        font-weight: $appFontWeightExtraBold;
        margin-bottom: 2px;
      }
      .example,
      .condition {
        margin-bottom: 10px;
      }
      .available {
        margin-bottom: 0;
      }
    }
  }
}

@media (max-width: $appBreakPoint) {
  .c-tooltip {
    $this: &;

    &__icon {
      font-size: 20px;
      height: 20px;
      width: 20px;
    }

    &__close {
      color: $appColorWhite;
      display: flex;
      font-size: 20px;
      padding: 0;
      position: absolute;
      right: 6px;
      top: 6px;
    }

    &__container {
      font-size: $appFontSizeXS;
      padding: 8px 8px;
    }

    &.is-pc {
      display: none;
    }
    &.is-sp {
      display: flex;
      &.is-active {
        #{$this}__container {
          bottom: 6px;
          left: 0;
          max-height: none;
          max-width: none;
          opacity: 1;
          overflow: auto;
          visibility: visible;
        }
      }
    }

    &--password {
      #{$this}__container {
        .title {
          font-weight: $appFontWeightExtraBold;
          margin-bottom: 2px;
        }
        .example,
        .condition {
          margin-bottom: 6px;
        }
        .available {
          margin-bottom: 0;
        }
      }
    }
  }
}
