.p-work-experience {
  $this: &;
  box-sizing: border-box;
  display: flex;
  margin: 0;
  width: 100%;

  &-head {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin: 0;
    padding: 0 0 30px 0;
    position: relative;
    width: 100%;
    &__error {
      position: absolute;
      width: 100%;
      .form-group {
        .invalid-feedback {
          right: 0;
          top: -56px;
          z-index: 1201;
          &::before {
            right: 42px;
          }
        }
      }
    }
    .c-title {
      border: none;
      margin: 6px 0 0 0;
      padding: 0;
      position: relative;
      width: auto;
      &.is-required {
        align-items: center;
        display: flex;
        justify-content: flex-start;
        &::after {
          background: $appColorFreeRed;
          box-sizing: border-box;
          border-radius: 4px;
          color: #fff;
          content: '\5FC5\9808';
          font-size: 11px;
          font-weight: $appFontWeightExtraBold;
          line-height: 1em;
          margin: -5px 0 0 12px;
          padding: 6px 10px 6px 10px;
        }
      }
      &__text {
        border-radius: $appRadiusM;
        font-size: $appFontSize3XL;
        font-weight: $appFontWeightExtraBold;
        letter-spacing: -1px;
        margin-top: -2px;
      }
    }
    .c-btn {
      border-radius: $appRadiusL;
      height: $appBtnHeightS;
      margin: 0;
      width: 94px;
      &__text {
        font-size: $appFontSizeM;
      }
    }
  }

  &-panel {
    align-items: center;
    bottom: 0;
    display: flex;
    height: auto;
    justify-content: flex-end;
    position: absolute;
    right: 0;
    width: 120px;
    z-index: 1200;
    .c-btn {
      border-radius: $appRadiusL;
      cursor: pointer;
      height: 32px;
      width: 44px;
      i {
        font-size: $appFontSizeL;
      }
    }
    .c-btn + .c-btn {
      margin-left: 6px;
    }
  }

  &__block {
    align-items: center;
    border-radius: 4px 4px 0 0;
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 20px 0 0 0;
    width: 100%;
  }

  &-career,
  &-certificate,
  &-language,
  &-skill {
    border: none;
    box-sizing: border-box;
    margin: 12px 0 0 0;
    padding: 0 0 0 24px;
    position: relative;
    align-items: stretch;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 100%;
    &--empty {
      padding-left: 0;
    }
    &-list {
      margin: 0;
      padding: 0;
      position: relative;
      width: 100%;
      &--empty {
        width: 100% !important;
        #{$this}-career-list,
        #{$this}-certificate-list,
        #{$this}-language-list,
        #{$this}-skill-list {
          &__item {
            border: none;
            padding: 0;
            &::before {
              display: none;
            }
            .empty {
              background: $appColorGrayScale8;
              box-sizing: border-box;
              border-radius: $appRadiusM;
              padding: 24px 0 24px 0;
              margin: -10px 0 16px 0;
              text-align: center;
            }
          }
        }
      }
      &__item {
        border: none;
        margin: 0;
        padding: 0;
        padding: 0 0 36px 0;
        position: relative;
        overflow: visible;
        &::before {
          background: $appColorGrayScale8;
          content: '';
          height: 100%;
          left: -38px;
          position: absolute;
          top: 0;
          width: 2px;
        }
        // &--active {
        //   &::before {
        //     background: $appColorGlassBlue;
        //   }
        // }
        &:last-child {
          padding-bottom: 0;
        }
      }
    }
    &-data {
      display: flex;
      flex-direction: column;
      position: relative;
      width: 100%;
      &.card {
        box-shadow: none;
        margin-top: -4px;
      }
      .row {
        padding: 0;
        margin: 0;
        &.group {
          display: block;
        }
      }
      .col {
        line-height: 1.4em;
        padding: 0;
        margin: 0;
        &.company,
        &.affiliation,
        &.certificate,
        &.language,
        &.skill {
          display: inline;
          font-weight: $appFontWeightExtraBold;
        }
        &.affiliation {
          font-size: $appFontSizeL;
        }
        &.employment-type {
          display: inline;
          font-size: $appFontSizeM;
          &::before {
            content: '/';
            padding: 0 5px;
          }
        }
        &.passed-date,
        &.years-of-experience,
        &.financial-year {
          display: inline;
          font-size: $appFontSizeS;
          &::before {
            content: '/';
            padding: 0 5px;
          }
        }
        &.language-native {
          display: inline;
          padding-right: 12px;
          .c-badge {
            background: $appColorPrimary1;
            box-sizing: border-box;
            border-radius: 4px;
            color: #fff;
            content: '\6BCD\56FD\8A9E';
            display: inline;
            font-size: $appFontSizeS - 1;
            font-weight: bold;
            padding: 6px 8px;
          }
        }
        &.role {
          font-size: $appFontSizeS;
          font-weight: $appFontWeightExtraBold;
          padding-bottom: 2px;
          &.is-active {
            padding-bottom: 10px;
          }
        }
        &.period {
          align-items: center;
          content: &;
          display: flex;
          font-size: $appFontSizeS - 1;
          justify-content: flex-start;
          .from {
            &::after {
              content: '~';
              padding: 0 5px;
            }
          }
          &.is-active {
            .to {
              background: $appColorPrimary1;
              border-radius: 4px;
              box-sizing: border-box;
              color: $appColorWhite;
              font-size: $appFontWeightBold;
              font-weight: $appFontWeightBold;
              padding: 4px 12px;
            }
          }
        }
        &.note {
          font-size: $appFontSizeS;
        }
      }
      &__title {
        &.card-header {
          background: $appColorWhite;
          border: none;
          border-radius: 0;
          margin: 0;
          padding: 0 0 12px 0;
          position: relative;
        }
        &--is-editable {
          .row {
            &.group {
              width: calc(100% - 110px);
            }
          }
        }
        #{$this}-panel {
          bottom: 6px;
          right: 0;
        }
      }
      &__detail {
        border: 1px $appColorGrayScale6 solid;
        // background: $appColorGrayScale8;
        border-radius: $appRadiusXL;
        box-sizing: border-box;
        // border: none;
        display: flex;
        flex-direction: column;
        gap: 12px;
        margin: 0;
        padding: 12px;
        position: relative;
      }
    }
  }

  &-career {
    &__icon {
      left: -56px;
      position: absolute;
      top: -12px;
      z-index: 1000;
    }
    &-list {
      width: calc(100% - 42px);
    }
  }
  &-skill {
    margin-top: 0;
    overflow: hidden;
    padding-top: 12px;
    &__icon {
      left: -43px;
      position: absolute;
      top: -0px;
      z-index: 1000;
      &.is-large {
        left: -49px;
        position: absolute;
        top: -6px;
        z-index: 1000;
      }
    }
    &-list {
      width: calc(100% - 42px);
      &__item {
        padding-bottom: 24px;
        &::before {
          height: calc(100% + 10px);
          top: 8px;
        }
        #{$this}-skill-data__title {
          align-items: center;
          display: flex;
          justify-content: space-between;
          padding-bottom: 6px;
          // &.is-large {
          //   padding-top: 2px;
          // }
        }
        #{$this}-panel {
          bottom: auto;
          position: relative;
          right: auto;
        }
        #{$this}-skill__icon {
          top: 6px;
          &.is-large {
            top: 0px;
          }
        }
        &--no-detail {
          padding-bottom: 4px;
          &::before {
            top: 12px;
          }
          #{$this}-skill__icon {
            top: 6px;
            &.is-large {
              top: 0px;
            }
          }
          #{$this}-skill-data__title {
            padding-bottom: 12px;
          }
          &:last-child {
            padding-bottom: 0;
            #{$this}-skill-data__title {
              padding-bottom: 0;
            }
          }
        }
      }
    }
  }
  &-certificate {
    &-data {
      &__title {
        align-items: center;
        display: flex;
        justify-content: space-between;
      }
    }
    &-list {
      &__item {
        padding-bottom: 4px;
        &::before {
          display: none;
        }
        &::after {
          display: none;
        }
        #{$this}-panel {
          bottom: auto;
          position: relative;
          right: auto;
        }
      }
    }
  }
  &-language {
    &-data {
      &__title {
        align-items: center;
        display: flex;
        justify-content: space-between;
      }
    }
    &-list {
      &__item {
        padding-bottom: 4px;
        &::before {
          display: none;
        }
        &::after {
          display: none;
        }
        #{$this}-panel {
          bottom: auto;
          position: relative;
          right: auto;
        }
      }
    }
  }

  // -------------------------------------------
  // 経歴リストの新規登録・編集フォーム
  // -------------------------------------------
  &-form {
    &__item {
      display: flex;
      flex-direction: column;
      margin: 0 0 15px 0;
      position: relative;
      width: 100%;

      .c-input {
        &--note {
          min-height: 100px;
          max-height: 500px;
        }
        &--date {
          width: 215px;
        }
      }

      &--date-range {
        .wrap {
          align-items: center;
          display: flex;
          flex-wrap: nowrap;
          height: 40px;
          justify-content: space-between;
          .form-group {
            align-items: center;
            display: flex;
            justify-content: flex-start;
            .c-select {
              &--year {
                width: 90px;
              }
              &--month {
                width: 75px;
              }
            }
            &:first-child {
              width: 50%;
            }
            &:last-child {
              width: 50%;
            }
            .col {
              &.now,
              &.separator,
              &.unit {
                flex-basis: auto;
                flex-grow: 0;
                font-size: $appFontSizeS;
                padding: 0 5px;
                text-align: center;
                width: 27.5px;
              }
              &.separator {
                padding: 0 5px 0 0;
              }
              &.now {
                text-align: left;
                width: 50%;
              }
            }
          }
        }
        .form-group {
          &--parent {
            align-items: center;
            display: flex;
            flex-wrap: wrap;
            width: 100%;
            label {
              width: 100%;
            }
          }
          .col {
            &.now,
            &.unit {
              flex-basis: auto;
              flex-grow: 0;
              font-size: $appFontSizeS;
              padding: 0 5px;
              text-align: center;
              width: 30px;
            }
            &.now {
              width: 50%;
            }
          }
          .invalid-feedback {
            top: -42px;
          }
        }
      }

      &--is-active-career,
      &--is-native-language {
        margin-bottom: 5px;
      }

      &--employment-type {
        .form-group {
          .invalid-feedback {
            top: -15px;
          }
        }
      }

      .form-group {
        margin-bottom: 0;
        label {
          font-size: $appFontSizeM;
          font-weight: $appFontWeightExtraBold;
          margin-bottom: 5px;
        }
        .c-checkbox {
          &__item {
            label {
              font-weight: $appFontWeightNormal;
            }
          }
        }
        legend {
          font-size: $appFontSizeM;
          font-weight: $appFontWeightExtraBold;
          margin-bottom: 0;
        }
        &.c-radio {
          label {
            font-weight: $appFontWeightNormal;
          }
        }
      }
      &--btns {
        margin: 30px 0 20px 0;
        button.c-btn {
          font-size: $appFontSizeL;
        }
        .c-btn {
          margin: 0 auto;
          width: 265px;
        }
      }
    }
  }
}

@media (max-width: $appBreakPoint) {
  .p-work-experience {
    $this: &;
    box-sizing: border-box;
    display: flex;
    margin: 0;

    &-head {
      &__error {
        .form-group {
          .invalid-feedback {
            &::before {
              right: 12px;
            }
          }
        }
      }
      .c-title {
        &__text {
          // font-size: $appFontSizeS;
          font-size: $appFontSize2XL;
          // padding: 6px 12px;
        }
      }
      &::after {
        margin: 12px 0 21px 0;
      }
      .c-btn {
        border-radius: $appRadiusM;
        bottom: 28px;
        height: 33px;
        width: 33px;
        &__text {
          font-size: $appFontSizeS;
          line-height: 0;
        }
      }
    }

    &-panel {
      width: 80px;
      .c-btn {
        border-radius: $appRadiusM;
        cursor: pointer;
        height: 33px;
        width: 33px;
        i {
          font-size: $appFontSizeM;
        }
      }
      .c-btn + .c-btn {
        margin-left: 6px;
      }
    }

    &-career,
    &-certificate,
    &-language,
    &-skill {
      margin: 0 0 0 0;
      padding-left: 20px;
      &--empty {
        padding-left: 0;
      }
      &-list {
        width: 100%;
        &--empty {
          width: 100%;
          #{$this}-career-list,
          #{$this}-certificate-list,
          #{$this}-language-list,
          #{$this}-skill-list {
            &__item {
              &::before {
                display: none;
              }
              &::after {
                display: none;
              }
              .empty {
                font-size: $appFontSizeS;
                padding: 16px 0;
              }
            }
          }
        }
        &__item {
          padding: 0 0 28px 0;
          &::before {
            left: -22px;
          }
          &:last-child {
            padding-bottom: 0;
          }
        }
      }

      &-data {
        content: &;
        &.card {
          box-shadow: none;
          margin-top: -4px;
        }
        .col {
          &.affiliation,
          &.certificate,
          &.skill,
          &.language {
            display: block;
            font-size: $appFontSizeS;
          }
          &.employment-type,
          &.passed-date,
          &.years-of-experience {
            display: block;
            &::before {
              display: none;
            }
          }
          &.language-native {
            display: inline;
            padding-right: 12px;
            .c-badge {
              background: $appColorPrimary1;
              box-sizing: border-box;
              border-radius: 4px;
              color: #fff;
              content: '\6BCD\56FD\8A9E';
              display: inline;
              font-size: $appFontSizeXS;
              font-weight: bold;
              padding: 3px 4px 2px 4px;
            }
          }
          &.period,
          &.passed-date,
          &.years-of-experience {
            font-size: $appFontSizeXS;
          }
          &.affiliation {
            font-size: $appFontSizeM;
          }
          &.employment-type {
            font-size: $appFontSizeS;
          }
        }
        &__title {
          &.card-header {
            padding: 0 0 8px 0;
          }
          &--is-editable {
            .row {
              &.group {
                width: calc(100% - 90px);
              }
            }
          }
          #{$this}-panel {
            bottom: 6px;
            right: 0;
          }
        }
        &__detail {
          // background: $appColorGrayScale8;
          // border: none;
          display: flex;
          flex-direction: column;
          gap: 12px;
          margin: 0;
          padding: 12px;
          position: relative;
        }
      }
    }

    &-career {
      &__icon {
        left: -48px;
        top: -6px;
      }
      &-list {
        width: calc(100% - 32px);
        &__item {
          &::before {
            left: -32px;
          }
        }
      }
    }

    &-skill {
      margin-top: -12px;
      &__icon {
        left: -37px;
        top: 4px;
        &.is-large {
          left: -43px;
          top: -2px;
        }
      }
      &-list {
        width: calc(100% - 32px);
        &__item {
          padding-bottom: 24px;
          &::before {
            height: calc(100% + 10px);
            left: -32px;
            top: 6px;
          }
          #{$this}-skill-data__title {
            padding-bottom: 4px;
          }
          &--no-detail {
            padding-bottom: 8px;
            #{$this}-skill__icon {
              top: 6px;
              &.is-large {
                top: 0;
              }
            }
            #{$this}-skill-data__title {
              padding-bottom: 8px;
            }
            &:last-child {
              padding-bottom: 0;
              #{$this}-skill-data__title {
                padding-bottom: 0;
              }
            }
          }
        }
      }
    }

    &-certificate {
      &-list {
        &__item {
          padding-bottom: 8px;
          &::before {
            display: none;
          }
          &::after {
            display: none;
          }
          #{$this}-panel {
            bottom: auto;
            position: relative;
            right: auto;
          }
        }
      }
    }

    &-language {
      &-list {
        width: 100%;
        &__item {
          padding-bottom: 8px;
          &::before {
            display: none;
          }
          #{$this}-panel {
            bottom: auto;
            position: relative;
            right: auto;
          }
        }
      }
      &-data {
        &.is-native {
          .row {
            &.group {
              align-items: center;
              display: flex;
              justify-content: flex-start;
            }
          }
          .col {
            &.language {
              display: block;
            }
            &.language-native {
              display: block;
              margin-right: 8px;
              max-width: 52px;
              padding-right: 0;
              .c-badge {
                display: block;
                width: 100%;
              }
            }
          }
        }
      }
    }

    &-form {
      &__item {
        margin: 0 0 15px 0;
        .form-group {
          label {
            font-size: $appFontSizeS;
            margin-bottom: 5px;
          }
          .c-input {
            font-size: $appFontSizeS;
          }
          .c-select {
            &__menu {
              font-size: $appFontSizeS;
            }
            &__single-value {
              color: $appColorBlack !important;
              font-size: $appFontSizeS !important;
            }
          }
        }
        &--date-range {
          .wrap {
            display: flex;
            flex-direction: column;
            height: auto;
            .form-group {
              width: 100% !important;
              .c-select {
                &--year,
                &--month {
                  width: 100%;
                }
              }
            }
            .now {
              padding: 0;
            }
          }
        }
        &--is-active-career,
        &--is-native-language {
          margin: 10px 0 0 0;
        }
        &--btns {
          margin: 10px 0 10px 0;
          .c-btn {
            margin-left: 0;
            margin-right: 0;
            width: 100%;
          }
          .c-btn + .c-btn {
            margin-top: 15px;
          }
        }
      }
    }
  }
}

@media (max-width: 420px) {
  .p-work-experience {
    $this: &;
    &-career {
      &-data {
        &__title {
          &.card-header {
            display: flex;
            flex-direction: column;
            padding: 0 0 8px 0;
          }
          &--is-editable {
            .row {
              &.group {
                width: 100%;
              }
            }
          }
          #{$this}-panel {
            bottom: auto;
            position: relative;
            right: auto;
            width: 100%;
          }
        }
      }
    }
  }
}
