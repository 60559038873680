.p-user,
.p-user.card {
  $this: &;

  background: $appColorWhite;
  border-radius: $appRadiusXL;
  box-sizing: border-box;
  color: $appColorBlack;
  padding: 45px 40px;
  position: relative;
  width: 100%;
  z-index: 100;

  &__image {
    align-items: center;
    background: $appColorWhite;
    border: 8px solid $appColorGrayScale8;
    border-radius: 50%;
    box-sizing: border-box;
    display: flex;
    height: 200px;
    justify-content: center;
    margin: 0 auto;
    overflow: hidden;
    width: 200px;
    img {
      width: 100%;
    }
    &--no-image {
      background: $appColorGrayScale9;
      i {
        color: $appColorGrayScale8;
        font-size: 130px;
      }
    }
  }

  &__last-login {
    align-items: center;
    color: $appColorBlack;
    display: flex;
    font-size: $appFontSizeM;
    justify-content: center;
    margin-top: 20px;
    padding: 0;
    &::before {
      background: $appColorBlack;
      border-radius: 50%;
      content: '';
      height: 15px;
      margin: 0 8px 0 0;
      width: 15px;
    }
    &--online {
      &::before {
        background: $appColorSecondary1;
      }
    }
    &--within-1-day {
      &::before {
        background: $appColorFreeYellow;
      }
    }
    &--within-3-day,
    &--within-1-week,
    &--1-month-ago {
      &::before {
        background: $appColorGrayScale12;
      }
    }
    // 最終ログインが1ヶ月前の場合は、最終ログイン時間を表示しない
    &--1-month-ago {
      display: none;
      overflow: hidden;
      visibility: hidden;
    }
  }

  &__data-group {
    align-items: center;
    border-bottom: 1px solid $appColorGrayScale6;
    display: flex;
    flex-grow: 1;
    justify-content: flex-start;
    margin: 0;
    padding: 12.5px 10px;
    width: 100%;

    &--btns {
      border-bottom: none;
      padding-top: 24px;
      .c-btn {
        height: 36px;
        width: 220px;
        &__text {
          font-size: $appFontSizeM;
        }
      }
    }
  }

  &__data {
    display: inline-block;
    margin: 0;
    padding: 0;
    .label,
    .value {
      box-sizing: border-box;
      display: inline;
      font-size: $appFontSizeM;
      margin-right: 20px;
      padding: 0;
      width: auto;
    }
    .label {
      color: $appColorGrayScale11;
      font-size: $appFontSizeXS;
      font-weight: $appFontWeightExtraBold;
      margin-right: 10px;
    }
    &--name {
      box-sizing: border-box;
      .value {
        color: $appColorBlack;
        font-size: $appFontSizePageTitlePc;
        font-weight: $appFontWeightBold;
        line-height: 1.25em;
        text-decoration: none;
      }
    }
    &--last-career {
      .value-list {
        background: none;
        display: inline;
        list-style: none;
        margin: 0;
        padding: 0;
      }
      .value {
        background: none;
        border: none;
        margin-right: 0;
        &::after {
          content: '/';
          padding: 5px;
        }
        &:last-child {
          &::after {
            display: none;
          }
        }
      }
    }
  }

  &__item {
    &--btns {
      position: absolute;
      right: 30px;
      top: 20px;

      .c-btn {
        &--favorite {
          border-radius: 50%;
          height: 40px;
          margin: 0 5px;
          transition: right 0.3s, top 0.3s, transform 300ms;
          width: 40px;
          z-index: 1100;
          &:hover {
            transform: scale(1.1);
          }
          i {
            color: #fff;
            font-size: 24px;
            transition: color 300ms;
          }
        }
        &--enter {
          height: 40px;
          margin: 0 5px;
          width: 152px;
          z-index: 1100;
          // .c-btn__text {
          //   font-size: $appFontSizeM;
          // }
        }
        &--connect,
        &--inprogress,
        &--reject {
          height: 40px;
          margin: 0 5px;
          width: 152px;
          z-index: 1100;
          // .c-btn__text {
          //   font-size: $appFontSizeM;
          // }
        }
        &--user-detail-dm {
          height: 40px;
          margin: 0 5px;
          width: 152px;
          z-index: 1100;
        }

        &--conversion {
          width: 250px;
        }
      }
    }
  }

  &-body {
    border: none;
    border-radius: 0;
    padding: 0;
    &__block {
      align-items: flex-start;
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-between;
      margin: 0;
      padding: 20px 0 35px 0;
      width: 100%;
    }
    &__item {
      display: flex;
      margin: 0;
      width: 100%;
      &--image {
        flex-direction: column;
        flex-grow: 0;
        margin-top: 0;
        min-width: 200px;
        width: 200px;
      }

      &--base-data {
        flex-direction: column;
        flex-grow: 1;
        padding-left: 30px;
        width: auto;
      }

      &--joined-company,
      &--experience,
      &--overview {
        margin-top: 35px;
        .c-title {
          border: none;
          margin: 0 0 15px 0;
          padding: 0 0 0 5px;
          width: 100%;
          &__text {
            font-size: $appFontSizeXL;
            font-weight: $appFontWeightExtraBold;
            // letter-spacing: -0.05em;
          }
        }
        .c-text {
          background: $appColorGrayScale9;
          border-radius: $appRadiusXL;
          font-size: $appFontSizeM;
          font-weight: $appFontWeightNormal;
          padding: 25px 20px;
          width: 100%;
        }
      }

      &--joined-company {
        .c-title {
          border-bottom: 1px solid #dee2e6;
          margin-bottom: 24px;
          padding-bottom: 15px;
        }
      }

      &--activity {
        position: relative;
        .c-loader-container {
          padding: 80px;
          width: 100%;
        }
      }

      &--experience {
        margin-top: 50px;
        &-career {
          margin-top: 24px;
        }

        .p-work-experience {
          &-skill {
            &-list {
              &__item {
                .p-work-experience-skill-data__title {
                  &.card-header {
                    padding-top: 4px;
                    padding-bottom: 12px;
                  }
                }
                .p-work-experience-skill__icon {
                  top: 4px;
                  &.is-large {
                    top: -2px;
                  }
                }
                &--no-detail {
                  padding-bottom: 12px;
                  .p-work-experience-skill-data__title {
                    &.card-header {
                      padding-top: 4px;
                      padding-bottom: 0;
                    }
                  }
                  &:last-child {
                    padding-bottom: 0;
                    .p-work-experience-skill-data__title {
                      padding-bottom: 0;
                      &.is-large {
                        padding-bottom: 6px;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      &--overview {
        margin-top: 15px;
      }
    }
  }

  &-footer {
    border: none;
    padding: 0;
    &__item {
      align-items: center;
      display: flex;
      justify-content: center;
      padding: 0;
      width: 100%;
      &--btns {
        margin: 45px 0 5px 0;
        .c-btn {
          margin: 0;
          width: 265px;
        }
      }
    }
  }

  // -------------------------------------------
  // 新規登録誘導モーダル
  // -------------------------------------------
  &-public {
    &-signup {
      align-items: center;
      display: flex;
      flex-direction: column;
      gap: 24px 0;
      justify-content: center;
      min-height: 240px;
      padding: 32px 0;
      width: 100%;

      &__title {
        font-size: $appFontSize3XL;
        // font-weight: $appFontWeightExtraBold;
        line-height: 1.6em;
        text-align: center;
      }

      &__btns {
        display: block;
        width: 100%;

        .c-btn {
          margin: 0 auto;
          width: 265px;
        }
      }
    }
  }

  // -------------------------------------------
  // ユーザースカウトフォーム
  // -------------------------------------------
  &-scout {
    &-form {
      .c-message {
        &--error {
          margin-bottom: 20px;
        }
      }

      &__item {
        display: flex;
        flex-direction: column;
        margin: 0 0 15px 0;
        position: relative;
        width: 100%;
        .c-input {
          &--message {
            min-height: 200px;
            max-height: 500px;
          }
        }
        .form-group {
          margin-bottom: 0;
          label {
            font-size: $appFontSizeM;
            font-weight: $appFontWeightExtraBold;
            margin-bottom: 5px;
          }
        }
        &.is-empty {
          display: flex;
          flex-direction: column;
          flex-wrap: wrap;
          justify-content: center;
          margin: 0;
          padding: 30px 0;
          width: 100%;
          p {
            margin: 0;
            padding: 0;
            text-align: center;
          }
        }
        &--btns {
          margin: 30px 0 20px 0;
          button.c-btn {
            font-size: $appFontSizeL;
          }
          .c-btn {
            margin: 0 auto;
            text-align: center;
            width: 265px;
          }
        }
      }
      &--preview {
        .p-user-scout-form {
          &__item {
            .label,
            .value {
              flex-basis: inherit;
              flex-grow: initial;
              font-size: $appFontSizeM;
              margin-bottom: 5px;
              padding: 0;
            }
            .label {
              font-weight: $appFontWeightExtraBold;
            }
            &--message {
              .value {
                background: $appColorGrayScale8;
                border-radius: $appRadiusM;
                box-sizing: border-box;
                padding: 12px;
                overflow-y: auto;
                max-height: 200px;
              }
            }
            &--btns {
              align-items: center;
              display: flex;
              flex-direction: row;
              justify-content: center;
              .c-btn {
                margin: 0 0;
                width: 190px;
              }
              .c-btn + .c-btn {
                margin-left: 7.5px;
              }
            }
          }
        }
      }
      &--complete,
      &--empty {
        flex-direction: column;
        padding: 45px 0 80px 0;
        .p-user-scout-form {
          &__item {
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            justify-content: center;
            margin: 0;
            padding: 0;
            width: 100%;
            &--image {
              margin-bottom: 30px;
              i {
                color: $appColorBlack;
                font-size: 60px;
                line-height: 60px;
                margin: 0 auto;
              }
            }
            &--caption {
              font-size: $appFontSize3XL;
              font-weight: $appFontWeightBold;
              margin-bottom: 25px;
              text-align: center;
            }
            &--text {
              text-align: center;
              width: 100%;
              p {
                margin-bottom: 0;
              }
            }
            &--btns {
              justify-content: center;
              margin: 35px 0 20px 0;
              .c-btn {
                width: 265px;
              }
            }
          }
        }
      }
      &--empty {
        padding: 45px 0 45px 0;
        .p-user-scout-form {
          &__item {
            &--btns {
              .c-btn {
                width: 350px;
              }
            }
          }
        }
      }
    }
  }
}
@media (max-width: $appBreakPoint) {
  .p-user,
  .p-user.card {
    $this: &;
    border-radius: $appRadiusM;
    padding: 48px 24px 32px 24px;

    &--public,
    &--public.card {
      padding-top: 82px;
    }

    &__image {
      height: 200px;
      width: 200px;
      img {
        width: 100%;
      }
      &--no-image {
        i {
          font-size: 130px;
        }
      }
    }

    &__last-login {
      font-size: $appFontSizeS;
      &::before {
        // background: $appColorBlack;
        border-radius: 50%;
        content: '';
        height: 15px;
        margin: 0 8px 0 0;
        width: 15px;
      }
    }

    &__data-group {
      flex-direction: column;
      justify-content: flex-start;
      padding: 12.5px 0;
      width: 100%;
    }

    &__data {
      width: 100%;
      .label,
      .value {
        box-sizing: border-box;
        display: inline;
        font-size: $appFontSizeS;
        margin-right: 0;
        padding: 0;
        width: auto;
      }
      .label {
        font-size: $appFontSizeXS;
        margin-right: 10px;
      }

      &--name {
        .value {
          font-size: $appFontSize2XL;
          line-height: 1.25em;
        }
      }
    }

    &__item {
      &--btns {
        .c-btn {
          &--favorite {
            right: 14.5px;
            top: 14.5px;
            &:hover {
              transform: scale(1);
            }
          }
          &--enter {
            display: none;
          }
          // &--conversion {
          //   display: none;
          // }
        }
      }
    }

    &-body {
      &__block {
        flex-direction: column;
        flex-wrap: wrap;
        padding: 0 0 0 0;
      }

      &__item {
        &--image {
          display: flex;
          flex-grow: 1;
          justify-content: center;
          margin: 10px 0 0 0;
          min-width: auto;
          width: 100%;
        }

        &--base-data {
          flex-direction: column;
          flex-grow: 1;
          margin: 20px 0;
          padding: 0;
          width: 100%;
        }

        &--experience,
        &--overview {
          margin: 0 0 30px 0;
          .c-title {
            margin: 0 0 10px 0;
            padding-left: 0;
            &__text {
              font-size: $appFontSizeL;
            }
          }
          .c-text {
            font-size: $appFontSizeS;
            padding: 15px;
            width: 100%;
          }
          .empty {
            font-size: $appFontSizeS;
          }
        }
        &--experience {
          .p-work-experience {
            &-skill {
              &-list {
                &__item {
                  .p-work-experience-skill-data__title {
                    &.card-header {
                      padding-top: 0;
                      padding-bottom: 4px;
                    }
                  }
                }
              }
            }
            &-language {
              &-data {
                &__title {
                  &.card-header {
                    .group {
                      width: 100%;
                    }
                  }
                }
              }
            }
          }
        }

        &--overview {
          margin-top: 10px;
        }
      }
    }

    &-footer {
      margin-top: -10px;
      padding: 0;
      &__item {
        &--btns {
          margin: 0;
          .c-btn {
            margin: 0;
            width: 100%;
          }
        }
      }
    }

    // -------------------------------------------
    // 新規登録誘導モーダル
    // -------------------------------------------
    &-public {
      &-signup {
        gap: 24px 0;
        min-height: 240px;
        padding: 24px 0;

        &__title {
          font-size: $appFontSize2XL;
          line-height: 1.6em;
          text-align: center;
        }

        &__btns {
          .c-btn {
            margin: 0 auto;
          }
        }
      }
    }

    // -------------------------------------------
    // 経歴リストの新規登録・スカウトフォーム
    // -------------------------------------------
    &-scout {
      &-form {
        &__item {
          margin: 0 0 15px 0;
          .form-group {
            label {
              font-size: $appFontSizeS;
              margin-bottom: 5px;
            }
            .c-input {
              font-size: $appFontSizeS;
              &--message {
                min-height: 150px;
                max-height: 150px;
              }
            }
            .c-select {
              &__menu {
                font-size: $appFontSizeS;
              }
              &__single-value {
                color: $appColorBlack !important;
                font-size: $appFontSizeS !important;
              }
            }
          }
          &--btns {
            margin: 20px 0 10px 0;
            .c-btn {
              margin-left: 0;
              margin-right: 0;
              width: 100%;
            }
            .c-btn + .c-btn {
              margin-top: 15px;
            }
          }
        }
        &--preview {
          .p-user-scout-form {
            &__item {
              margin-bottom: 10px;
              .label,
              .value {
                font-size: $appFontSizeS;
                margin-bottom: 0;
              }
              &--message {
                .value {
                  padding: 10px;
                  min-height: 150px;
                  max-height: 150px;
                }
              }
              &--btns {
                // flex-direction: column-reverse !important;
                flex-direction: column !important;
                .c-btn {
                  // margin: 15px 0 0 0;
                  margin: 0;
                  width: 100%;
                }
                .c-btn + .c-btn {
                  // margin: 0 0;
                  margin: 15px 0 0 0;
                }
              }
            }
          }
        }
        &--complete,
        &--empty {
          flex-direction: column;
          padding: 20px 0 10px 0;
          .p-user-scout-form {
            &__item {
              display: flex;
              flex-direction: column;
              flex-wrap: wrap;
              justify-content: center;
              margin: 0;
              padding: 0;
              width: 100%;
              &--image {
                margin-bottom: 30px;
                i {
                  color: $appColorBlack;
                  font-size: 50px;
                  line-height: 50px;
                  margin: 0 auto;
                }
              }
              &--caption {
                font-size: $appFontSizeL;
                margin-bottom: 10px;
              }
              &--text {
                font-size: $appFontSizeS;
              }
              &--btns {
                margin: 35px 0 0 0;
                .c-btn {
                  width: 100%;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 420px) {
  .p-user,
  .p-user.card {
    $this: &;

    &--public,
    &--public.card {
      .p-user__item {
        &--btns {
          width: calc(100% - 40px);
          .c-btn {
            &--enter {
              width: 100%;
            }
            &--conversion {
              width: 100%;
            }
          }
        }
      }
    }
  }
}
