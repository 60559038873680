.c-menu-icon {
  $this: &;
  align-items: center;
  background: $appColorWhite;
  border-radius: 50%;
  color: $appColorBlack;
  cursor: pointer;
  display: flex;
  flex-basis: auto;
  flex-grow: inherit;
  height: 40px;
  justify-content: center;
  overflow: hidden;
  margin: 0 0 0 0;
  padding: 0;
  position: relative;
  width: 40px;

  &.is-disabled {
    cursor: not-allowed;
    i {
      opacity: 0.2;
    }
    #{$this}__icon {
      opacity: 0.2;
    }
    #{$this}__text {
      opacity: 0.2;
    }
  }

  &--blank {
    background: transparent;
  }

  &__icon {
    align-items: center;
    display: flex;
    justify-content: center;
    text-align: center;
    img {
      margin: 0 auto;
      max-width: 28px;
      width: 50%;
    }
  }

  &__text {
    background: $appColorGrayScale2;
    border-radius: $appRadiusM;
    bottom: 46px;
    box-sizing: border-box;
    color: $appColorWhite;
    font-size: $appFontSizeS;
    font-weight: $appFontWeightExtraBold;
    opacity: 0;
    padding: 4.5px 6.5px;
    position: absolute;
    right: -10px;
    text-align: center;
    transition: opacity 0.3s, top 0.3s;
    width: 120px;
    visibility: hidden;
    &::after {
      border: 5px solid transparent;
      border-top: 6px solid $appColorGrayScale2;
      bottom: -9px;
      content: '';
      opacity: 0;
      position: absolute;
      transition: opacity 0.3s, top 0.3s;
      right: 25px;
      visibility: hidden;
      z-index: auto;
    }
  }

  &:hover {
    overflow: visible;
    #{$this}__text {
      opacity: 1;
      visibility: visible;
      &::after {
        opacity: 1;
        visibility: visible;
      }
    }
  }

  &--edit {
    i {
      font-size: 21px;
    }
  }

  &--delete {
    i {
      font-size: 26px;
    }
  }

  &--account-edit {
    i {
      font-size: 26px;
    }
  }

  &--copy {
    i {
      font-size: 20px;
    }
  }
}
@media (max-width: $appBreakPoint) {
  .c-menu-icon {
    $this: &;

    &__icon {
      img {
        max-width: 24px;
      }
    }
    &__text {
      font-size: $appFontSizeXS;
    }
  }
}
