//
// 注意. テンプレートのSass変数定義と重複しないように接頭辞「app」を付けて定義する事
//

$appBreakPoint: 750px;

$appColorAccountSettingScale1: #79777c;
$appColorAccountSettingScale2: #96959b;
$appColorAccountSettingScale3: #b2b2ba;
$appColorAccountSettingScale4: #d0d2d8;
$appColorAccountSettingScale5: #eef2f7;

$appColorLightModeSideBarScale1: #f2f2f2;
$appColorLightModeSideBarScale2: #e3e3e3;
$appColorLightModeSideBarScale3: #e9e9e9;
$appColorLightModeSideBarScale4: #ccc;

// ----------------------------------------------------------
// カラーパレット
// ----------------------------------------------------------
$appColorPrimary1: #0298de;
// $appColorPrimary1: #008bcc;
$appColorPrimary2: #1996d0;
$appColorPrimary3: #23a8e7;
// $appColorPrimary4: #018ccd;
$appColorPrimary4: #08a3ea;
$appColorPrimary5: #29bbff;
$appColorHoverPrimary: rgba(35, 168, 231, 0.05);
$appColorHoverGray: rgba(233, 236, 239, 0.3);
$appColorGlassBlue: #ccf0ff;
$appColorGlassBlue2: #c7e6f3;
$appColorGlassBlueAlpha: rgba(204, 240, 255, 0.2);
// $appColorGlassBlueAlpha: rgba(234, 118, 68, 0.1);

$appColorSecondary1: #6cc7a5;
$appColorSecondary2: #1cd0b0;
$appColorSecondary3: #79e1cf;
$appColorSecondary4: #b8eae1;
$appColorSecondary5: #38b48b;
$appColorSecondary6: #3ec99b;

$appColorGrayScale1: #333333;
$appColorGrayScale2: #313a46;
$appColorGrayScale2Alpha: rgba(49, 58, 70, 0.1);
$appColorGrayScale3: #3e4d61;
$appColorGrayScale3Alpha: rgba(62, 77, 97, 0.1);
$appColorGrayScale4: #8293aa;
$appColorGrayScale5: #c4d1e1;
$appColorGrayScale6: #dee2e6;
$appColorGrayScale7: #e9ecef;
$appColorGrayScale8: #eef2f7;
$appColorGrayScale9: #fafbfe;
$appColorGrayScale10: #444;
$appColorGrayScale11: #666;
$appColorGrayScale12: #ccc;
$appColorGrayScale13: #aaa;
$appColorGrayScale14: #888;
$appColorGrayScale15: #fafafc;
$appColorGrayScale16: #f2f2f9;

$appColorFreePurple: #a865eb;
$appColorFreeRed: #e25464;
$appColorFreeRed2: #fa4053;
$appColorFreeRed3: #e95464;
$appColorFreeGlassRed: #fdeef0;
$appColorFreeOrange: #ea7644;
$appColorFreeYellow: #fbd81f;
$appColorFreeGreen: #38b48b;

$appColorBlack: #333;
$appColorWhite: #fff;
$appColorAutoFill: #f3fbff;

$appColorDocumentFileIcon: #c4d1e1;
$appColorDocumentWordIcon: #1c64c7; // rgba(28, 100, 199;
$appColorDocumentExcelIcon: #128649; // rgba(18, 134, 73;
$appColorDocumentPowerPointIcon: #ca441f; // rgba(202, 68, 31;
$appColorDocumentPdfIcon: #b30b00; // rgba(179, 11, 0);

// ----------------------------------------------------------
// font
// ----------------------------------------------------------
// 使用フォント
// $appFontFamily: 'Nunito', 'Noto Sans JP', sans-serif;
$appFontFamily: 'Nunito', 'Noto Sans JP', Meiryo, メイリオ, sans-serif;
// $appFontFamily: 'Nunito', Meiryo, メイリオ, 'Noto Sans JP', sans-serif;

$appFontSizeBase: 14px;
$appFontSizeXS: 10px;
$appFontSizeS: 12px;
$appFontSizeM: 14px;
$appFontSizeL: 16px;
$appFontSizeXL: 18px;
$appFontSize2XL: 20px;
$appFontSize3XL: 24px;
$appFontSizeIconInputField1: 24px;
$appFontSizePageTitlePc: 28px;

$appFontWeightNormal: 500;
$appFontWeightBold: 600;
// $appFontWeightExtraBold: 800;
$appFontWeightExtraBold: 700;
$appTextLineHeight: 1.5em;

// ----------------------------------------------------------
// part
// ----------------------------------------------------------
$appBtnHeightS: 32px;
$appBtnHeightM: 45px;
$appInputHeightM: 40px;
$appSelectorHeightM: 40px;
$appRadioSizeM: 18px;

// ----------------------------------------------------------
// radius
// ----------------------------------------------------------
$appRadiusS: 2px;
$appRadiusM: 4px;
$appRadiusL: 6px;
$appRadiusXL: 8px;

:root {
  // app color: primary
  --app-color-primary-1: #008bcc;
  --app-color-primary-2: #1996d0;
  --app-color-primary-3: #23a8e7;
  --app-color-primary-4: #08a3ea;
  --app-color-hover-primary: rgba(35, 168, 231, 0.05);
  --app-color-hover-gray: rgba(233, 236, 239, 0.3);
  --app-color-glass-blue: #ccf0ff;
  --app-color-glass-blue-2: #c7e6f3;
  --app-color-glass-blue-alpha-1: rgba(204, 240, 255, 0.2);

  // app color: secondary
  --app-color-secondary-1: #6cc7a5;
  --app-color-secondary-2: #1cd0b0;
  --app-color-secondary-3: #79e1cf;
  --app-color-secondary-4: #b8eae1;

  // app color: gray scale
  --app-color-gray-scale-1: #333333;
  --app-color-gray-scale-2: #313a46;
  --app-color-gray-scale-3: #3e4d61;
  --app-color-gray-scale-4: #8293aa;
  --app-color-gray-scale-5: #c4d1e1;
  --app-color-gray-scale-6: #dee2e6;
  --app-color-gray-scale-7: #e9ecef;
  --app-color-gray-scale-8: #eef2f7;
  --app-color-gray-scale-9: #fafbfe;
  --app-color-gray-scale-10: #444;
  --app-color-gray-scale-11: #666;
  --app-color-gray-scale-12: #ccc;
  --app-color-gray-scale-13: #aaa;
  --app-color-gray-scale-14: #888;
  --app-color-gray-scale-15: #fafafc;
  --app-color-gray-scale-16: #f2f2f9;

  // app color: free
  --app-color-free-purple: #a865eb;
  --app-color-free-red: #e25464;
  --app-color-free-red-2: #fa4053;
  --app-color-free-red-3: #e95464;
  --app-color-free-glass-red: #fdeef0;
  --app-color-free-orange: #ea7644;
  --app-color-free-yellow: #fbd81f;
  --app-color-free-green: #38b48b;

  // app color: base
  --app-color-black: #333;
  --app-color-white: #fff;

  // app color: other
  --app-color-auto-fill: #f3fbff;
  --app-color-document-file-icon: #c4d1e1;
  --app-color-document-word-icon: #1c64c7; // rgba(28, 100, 199;
  --app-color-document-excel-icon: #128649; // rgba(18, 134, 73;
  --app-color-document-power-point-icon: #ca441f; // rgba(202, 68, 31;
  --app-color-document-pdf-icon: #b30b00; // rgba(179, 11, 0);

  // font
  // --app-font-family: 'Nunito', 'Noto Sans JP', sans-serif;
  --app-font-family: 'Nunito', 'Noto Sans JP', Meiryo, メイリオ, sans-serif;
  // --app-font-family: 'Nunito', Meiryo, メイリオ, 'Noto Sans JP', sans-serif;
  --app-font-size-base: 14px;
  --app-font-size-xs: 10px;
  --app-font-size-s: 12px;
  --app-font-size-m: 14px;
  --app-font-size-l: 16px;
  --app-font-size-xl: 18px;
  --app-font-size-2xl: 20px;
  --app-font-size-3xl: 24px;
  --app-font-size-icon-input-field-1: 24px;
  --app-font-size-page-title-pc: 28px;
  --app-font-weight-normal: 500;
  --app-font-weight-bold: 600;
  // --app-font-weight-extra-bold: 800;
  --app-font-weight-extra-bold: 700;
  --app-text-line-height: 1.5em;

  // other
  --app-btn-height-s: 32px;
  --app-btn-height-m: 45px;
  --app-input-height-m: 40px;
  --app-selector-height-m: 40px;
  --app-radio-size-m: 18px;
  --app-radius-s: 2px;
  --app-radius-m: 4px;
  --app-radius-l: 6px;
  --app-radius-xl: 8px;
  --app-break-point: 750px;
}
