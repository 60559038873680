.c-loader,
.loader-wrapper {
  width: 100%;
  .preloader {
    position: fixed
  }
  &--no-loader-item {
    .bouncing-loader {
      display: none;
    }    
  }
}
.c-loader {
  &-container {
    padding: 40vh 0;
    position: relative;
    z-index: 2500;
    .col {
      padding: 0;      
    }
  }
}

.preloader {
  background: rgba(255, 255, 255, .5);
}

.bouncing-loader {
  div {
    background: $appColorPrimary1 !important;
  }
  div:nth-child(2) {
    background: $appColorPrimary2 !important;
  }
  div:nth-child(3) {
    background: $appColorPrimary3 !important;
  }
}

@media (max-width: $appBreakPoint) {
  .c-loader {
    &-container {
      padding: 30vh 0;
    }
  }
}