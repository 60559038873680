.p-company-deal,
.p-company-deal.card {
  $this: &;
  background: $appColorWhite;
  border-radius: $appRadiusXL;
  box-sizing: border-box;
  color: $appColorBlack;
  padding: 45px 40px;
  position: relative;
  width: 100%;
  z-index: 100;

  &-list {
    align-items: center;
    border: none;
    display: flex;
    flex-direction: column;
    gap: 16px 0;
    margin: 0;
    padding: 0;
    width: 100%;
    &-item {
      align-items: stretch;
      background-color: $appColorWhite;
      border-radius: $appRadiusXL;
      box-sizing: border-box;
      box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.075);
      display: flex;
      flex-direction: row;
      gap: 20px;
      width: 100%;
      &.card {
        padding: 24px 24px 24px 24px;
      }
      &__block {
        &.col,
        &.row {
          margin: 0;
          padding: 0;
        }
        &--image {
          align-items: flex-start;
          box-sizing: border-box;
          display: flex;
          justify-content: center;
          max-width: 80px;
          min-width: 80px;
          width: 80px;
        }
        &--data {
          align-items: center;
          box-sizing: border-box;
          display: flex;
          flex-direction: column;
          gap: 10px;
          justify-content: center;
          max-width: calc(100% - 80px - 100px - (20px * 2));
          min-width: calc(100% - 80px - 100px - (20px * 2));
          padding: 0 0 0 8px;
          width: calc(100% - 80px - 100px - (20px * 2));
        }
        &--btn {
          align-items: center;
          display: flex;
          flex-direction: row;
          justify-content: flex-end;
          max-width: 100px;
          min-width: 100px;
          position: relative;
          width: 100px;
          &.row {
            padding: 0 0 0 20px;
          }
          &::before {
            border-right: 1px var(--app-color-gray-scale-6) solid;
            content: '';
            height: 100%;
            left: -4px;
            position: absolute;
            top: 0;
          }
          .c-btn {
            height: 40px;
            &__text {
              font-size: $appFontSizeM;
            }
          }
        }
      }
      &__logo {
        border-radius: $appRadiusL;
        max-width: 80px;
        min-width: 80px;
        overflow: hidden;
        width: 80px;
        img {
          width: 100%;
        }
        &--no-image {
          align-items: center;
          background: $appColorGrayScale9;
          border-radius: $appRadiusL;
          display: flex;
          justify-content: center;
          height: 80px;
          i {
            color: $appColorGrayScale8;
            font-size: 52px;
          }
        }
      }
      &__title {
        @include ellipsis;
        display: block;
        font-size: $appFontSizeXL;
        font-weight: $appFontWeightExtraBold;
        width: 100%;
      }
      &__caption {
        @include ellipsisMultiple(3);
        font-size: $appFontSizeM;
        width: 100%;
      }
      &__tags {
        align-items: flex-start;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        gap: 8px;
        width: 100%;
      }
    }
  }

  &-container {
    display: flex;
    flex-direction: column;
    gap: 32px;
    padding: 0;
    .col,
    .row {
      margin: 0;
      padding: 0;
    }
    &-head {
      display: flex;
      flex-direction: row;
      gap: 30px;
      justify-content: space-between;
      &__item {
        &--logo {
          max-width: 200px;
          min-width: 200px;
          width: 200px;
        }
        &--summary {
          box-sizing: border-box;
          display: flex;
          flex-direction: column;
          gap: 16px;
          max-width: calc(100% - 230px);
          min-width: calc(100% - 230px);
          width: calc(100% - 230px);
          &.row {
            padding: 12px 0;
          }
          .c-btn {
            width: 250px;
          }
        }
      }
      // &__btn {
      //   margin-top: 8px;
      // }
    }
    &-body {
      display: flex;
      flex-direction: column;
      gap: 30px;
      justify-content: space-between;
      width: 100%;
      &::before {
        border-top: 1px $appColorGrayScale6 solid;
        content: '';
        display: block;
        height: 0;
      }
    }
    &-foot {
      display: flex;
      justify-content: center;
      .c-btn {
        width: 250px;
      }
    }
  }

  &-data {
    &-list {
      display: flex;
      flex-direction: column;
      gap: 36px;
      width: 100%;
      &-item {
        display: flex;
        flex-direction: column;
        gap: 12px;
        width: 100%;
        &__title {
          align-items: center;
          display: flex;
          font-size: $appFontSizeL;
          font-weight: $appFontWeightExtraBold;
          justify-content: flex-start;
          margin: 0;
          padding: 0;
        }
        &__value {
          background: $appColorGrayScale9;
          border-radius: $appRadiusXL;
          box-sizing: border-box;
          font-size: $appFontSizeM;
          margin: 0;
          padding: 25px 20px;
          width: 100%;
          &.col {
            padding: 25px 20px;
          }
          a {
            color: $appColorPrimary4;
            font-size: $appFontSizeM;
            font-weight: $appFontWeightExtraBold;
            position: relative;
            text-align: center;
            text-decoration: underline;
            &:hover,
            &:active,
            &:visited {
              color: $appColorPrimary4;
            }
            &:hover {
              text-decoration: underline;
            }
          }
        }
        &--supervisor {
          gap: 16px;
          .p-company-deal-data-list-item {
            &__title {
              border-bottom: 1px solid $appColorGrayScale6;
              padding-bottom: 16px;
            }
            &__value {
              background: transparent;
              border-radius: 0;
              padding: 0;
            }
          }
        }
      }
    }
  }

  &-introduction {
    &-list {
      align-items: flex-start;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 12px;
      justify-content: flex-start;
      &-item {
        background-color: $appColorGrayScale9;
        cursor: pointer;
        display: block;
        height: 145px;
        max-width: 258px;
        min-width: 258px;
        width: 258px;
        position: relative;
        &:hover {
          opacity: 0.8;
        }
        &__thumb {
          align-items: center;
          background-color: $appColorGrayScale9;
          background-position: center center;
          background-repeat: no-repeat;
          background-size: cover;
          display: flex;
          flex-direction: column;
          justify-content: center;
          height: 100%;
          width: 100%;
          &--no-image {
            background: $appColorGrayScale9;
            i {
              color: $appColorGrayScale8;
              font-size: 68px;
            }
          }
        }
        &__play {
          align-items: center;
          display: flex;
          height: 100%;
          justify-content: center;
          left: 0;
          position: absolute;
          top: 0;
          width: 100%;
          i {
            color: $appColorGrayScale9;
            font-size: 68px;
          }
        }
      }
    }
  }

  &-supervisor {
    &-list {
      align-items: flex-start;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 12px;
      justify-content: flex-start;
      a {
        color: $appColorPrimary4;
        font-weight: $appFontWeightNormal;
        text-decoration: none;

        &:hover,
        &:active,
        &:visited {
          color: $appColorPrimary4;
        }
        &:hover {
          text-decoration: underline;
        }
      }
      &-item {
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 12px;
        max-width: 140px;
        min-width: 140px;
        width: 140px;
        &__image {
          align-items: center;
          background: $appColorWhite;
          border: 8px solid $appColorGrayScale8;
          border-radius: 50%;
          box-sizing: border-box;
          cursor: pointer;
          display: flex;
          max-height: 130px;
          min-height: 130px;
          height: 130px;
          justify-content: center;
          margin: 0 auto;
          overflow: hidden;
          padding: 0;
          max-width: 130px;
          min-width: 130px;
          width: 130px;
          &:hover {
            opacity: 0.9;
          }
          img {
            width: 100%;
          }
          &--no-image {
            background: $appColorGrayScale9;
            i {
              color: $appColorGrayScale8;
              font-size: 97.5px;
            }
          }
        }
        &__name {
          align-items: center;
          box-sizing: border-box;
          color: $appColorPrimary4;
          cursor: pointer;
          display: flex;
          font-size: $appFontSizeM;
          justify-content: center;
          text-align: center;
          width: 130px;
        }
        &-list {
          align-items: stretch;
          background: transparent;
          border: none;
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          list-style: none;
          justify-content: flex-start;
          margin: 0;
          padding: 0;
          width: 100%;
          &__item {
            background: transparent;
            border: none;
            margin: 0 20px 15px 0;
            padding: 0;
          }
        }
      }
    }
  }

  &__logo {
    img {
      width: 100%;
    }
    &--no-image {
      align-items: center;
      background: $appColorGrayScale9;
      border-radius: $appRadiusL;
      display: flex;
      justify-content: center;
      height: 200px;
      i {
        color: $appColorGrayScale8;
        font-size: 104px;
      }
    }
  }

  &__title {
    box-sizing: border-box;
    border-bottom: 1px var(--app-color-gray-scale-6) solid;
    font-size: $appFontSize3XL + 4px;
    font-weight: $appFontWeightExtraBold;
    margin: 0;
    padding-bottom: 12px;
  }

  &__caption {
    box-sizing: border-box;
    font-size: $appFontSizeM;
    margin: 0;
    padding: 4px 0;
  }

  &__tags {
    align-items: flex-start;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: 8px;
    width: 100%;
  }
}

@media (max-width: 960px) {
  .p-company-deal,
  .p-company-deal.card {
    $this: &;
    &-container {
      display: flex;
      flex-direction: column;
    }
  }
}

@media (max-width: $appBreakPoint) {
  //   .p-company,
  //   .p-company.card {
  //     $this: &;
  //     border-radius: $appRadiusM;
  //     padding: 48px 24px 32px 24px;

  //     .c-badge {
  //       font-size: $appFontSizeXS;
  //     }

  //     &__image {
  //       height: 200px;
  //       width: 200px;
  //       img {
  //         width: 100%;
  //       }
  //       &--no-image {
  //         i {
  //           font-size: 104px;
  //         }
  //       }
  //     }

  //     &__data-group {
  //       flex-direction: column;
  //       justify-content: flex-start;
  //       padding: 12.5px 0;
  //       width: 100%;
  //       &--tag {
  //         padding-bottom: 0;
  //       }
  //     }

  //     &__data {
  //       width: 100%;
  //       .label,
  //       .value {
  //         box-sizing: border-box;
  //         display: inline;
  //         font-size: $appFontSizeS;
  //         margin-right: 0;
  //         padding: 0;
  //         width: auto;
  //       }
  //       .label {
  //         font-size: $appFontSizeXS;
  //         margin-right: 10px;
  //       }

  //       &--name {
  //         a {
  //           font-size: $appFontSize2XL;
  //           line-height: 1.25em;
  //         }
  //       }

  //       &--address {
  //         .value {
  //           p {
  //             padding-right: 10px;
  //           }
  //         }
  //       }
  //       &--site-url {
  //         a {
  //           font-size: $appFontSizeS;
  //         }
  //         i {
  //           font-size: $appFontSizeM;
  //         }
  //       }
  //     }

  //     &__item {
  //       &--btns {
  //         .c-btn {
  //           &--favorite {
  //             right: 14.5px;
  //             top: 14.5px;
  //             &:hover {
  //               transform: scale(1);
  //             }
  //           }
  //           &--enter {
  //             display: none;
  //           }
  //         }
  //       }
  //     }

  //     &-member {
  //       &__value {
  //         font-size: $appFontSizeS;
  //       }
  //       &-list {
  //         &__item {
  //           margin: 0 12px 12px 0;
  //         }
  //       }
  //     }

  //     &-body {
  //       &__block {
  //         flex-direction: column;
  //         flex-wrap: wrap;
  //         padding: 0 0 0 0;
  //       }

  //       &__item {
  //         margin: 0 0 22px 0;
  //         &--image {
  //           display: flex;
  //           flex-grow: 1;
  //           justify-content: center;
  //           margin: 10px 0 0 0;
  //           min-width: auto;
  //           width: 100%;
  //         }

  //         &--base-data {
  //           flex-direction: column;
  //           flex-grow: 1;
  //           margin: 20px 0 30px 0;
  //           padding: 0;
  //           width: 100%;
  //         }
  //         &--tag,
  //         &--overview,
  //         &--member,
  //         &--mission,
  //         &--project,
  //         &--purpose,
  //         &--vision {
  //           .c-title {
  //             margin: 0 0 10px 0;
  //             padding-left: 0;
  //             &__text {
  //               font-size: $appFontSizeL;
  //             }
  //           }
  //           .c-text {
  //             font-size: $appFontSizeS;
  //             padding: 15px;
  //             width: 100%;
  //           }
  //           .empty {
  //             font-size: $appFontSizeS;
  //           }
  //         }
  //         &--tag {
  //           margin: 10px 0 22px 0;
  //           padding-bottom: 0;
  //         }

  //         &--member {
  //           margin: 10px 0 22px 0;
  //           padding-bottom: 0;
  //           .c-title {
  //             margin-bottom: 20px;
  //           }
  //           .empty {
  //             margin: -10px 0 20px 0;
  //           }
  //         }
  //         &--project {
  //           margin: 10px 0 22px 0;
  //           padding-bottom: 0;
  //           .c-title {
  //             margin-bottom: 20px;
  //           }
  //           .empty {
  //             margin-top: -10px;
  //           }
  //         }
  //         &--more {
  //           margin: 20px 0 0 0;
  //           .c-btn {
  //             width: 100%;
  //           }
  //         }
  //       }
  //     }
  //   }
  // }

  // @media (max-width: 572px) {
  //   .p-company,
  //   .p-company.card {
  //     &-member {
  //       &__image {
  //         height: 140px;
  //         width: 140px;
  //         &--no-image {
  //           i {
  //             font-size: 92.5px;
  //           }
  //         }
  //       }
  //       &-list {
  //         flex-wrap: wrap;
  //         justify-content: space-between;
  //         width: 100%;
  //         &__item {
  //           background: transparent;
  //           border: none;
  //           margin: 0 0 2% 0;
  //           min-width: 140px;
  //           padding: 0;
  //           width: 50%;
  //         }
  //       }
  //     }

  //     &-body {
  //       &__item {
  //         &--project {
  //           .p-search {
  //             margin-bottom: -12px;
  //             &-list {
  //               margin-bottom: 0;
  //               width: 100%;
  //               &__item {
  //                 box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  //                 margin: 0 0 12px 0;
  //                 max-width: none;
  //                 width: 100%;
  //                 &:nth-child(odd) {
  //                   margin: 0 0 12px 0;
  //                 }
  //                 &:nth-child(even) {
  //                   margin: 0 0 12px 0;
  //                 }
  //                 .c-text {
  //                   background: transparent;
  //                   border-radius: none;
  //                   padding: 0;
  //                 }
  //               }
  //             }
  //           }
  //         }
  //       }
  //     }
  //   }
}
