.c-sort-icon {
  align-items: center;
  display: flex;
  justify-content: flex-start;
  margin-left: 5px;
  &__item {
    cursor: pointer;
    margin: 0 !important;
    padding: 0 !important;
    &.is-active {
      i {
        color: $appColorPrimary1;
      }
    }
    i {
      color: $appColorGrayScale6;
      margin: 0 !important;
      padding: 0 !important;
    }
  }
}