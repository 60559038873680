//
// slimscroll.scss
//

@import '~simplebar/dist/simplebar.min.css';

.simplebar-scrollbar {
  &:before {
    background: rgb(158, 165, 171);
  }
}
.simplebar-vertical {
  .simplebar-scrollbar {
    // width: 4px !important;
    width: 8px;
  }
}