.p-company-joined-member {
  $this: &;
  display: block;
  margin: 0 auto;
  padding: 0;
  width: 100%;

  &-list {
    align-items: stretch;
    border: none;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 0 auto;
    padding: 0;
    width: 100%;
    &__item {
      background: transparent;
      border: none;
      margin: 0 10px 12px 0;
      padding: 0;
    }
  }

  &-data {
    $this: &;
    &:hover {
      #{$this}__image {
        opacity: 0.8;
      }
      #{$this}__name,
      #{$this}__supply {
        text-decoration: underline;
      }
    }
    &__name,
    &__supply {
      align-items: center;
      box-sizing: border-box;
      color: $appColorPrimary4;
      cursor: pointer;
      display: flex;
      font-size: 14px;
      justify-content: center;
      margin: 0 auto;
      padding: 0 4px;
      text-align: center;
      width: 140px;
    }

    &__name {
      margin-top: 12px;
    }
    &__supply {
      color: $appColorBlack;
      margin-top: 4px;
    }

    &__image {
      align-items: center;
      background: $appColorWhite;
      border: 8px solid $appColorGrayScale8;
      border-radius: 50%;
      box-sizing: border-box;
      cursor: pointer;
      display: flex;
      height: 130px;
      justify-content: center;
      margin: 0 auto;
      overflow: hidden;
      padding: 0;
      width: 130px;
      &:hover {
        opacity: 0.9;
      }
      img {
        width: 100%;
      }
      &--no-image {
        background: $appColorGrayScale9;
        i {
          color: $appColorGrayScale8;
          font-size: 97.5px;
        }
      }
    }
  }
}
@media (max-width: $appBreakPoint) {
  .p-company-joined-member {
    $this: &;
    &-data {
      &__name,
      &__supply {
        font-size: $appFontSizeS;
      }
    }
  }
}
@media (max-width: 572px) {
  .p-company-joined-member {
    $this: &;
    &-list {
      flex-wrap: wrap;
      justify-content: space-between;
      width: 100%;
      &__item {
        background: transparent;
        border: none;
        margin: 0 0 2% 0;
        min-width: 140px;
        padding: 0;
        width: 50%;
      }
    }
    &-data {
      &__image {
        height: 140px;
        width: 140px;
        &--no-image {
          i {
            font-size: 92.5px;
          }
        }
      }
    }
  }
}
