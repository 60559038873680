.p-onboarding {
  $this: &;
  &__modal {
    &.c-modal {
      max-width: 560px;
      width: 560px;
      .modal {
        &-header {
          border: none;
          display: flex;
          padding: 16px 20px 0 20px;
          width: 100%;
          .modal-title {
            display: flex;
            flex: column;
            justify-content: flex-end;
            .skip {
              color: $appColorPrimary4;
              cursor: pointer;
              font-size: $appFontSizeM;
              font-weight: $appFontWeightNormal;
              display: inline;
              text-align: right;
              width: 100%;
              &:hover {
                text-decoration: underline;
              }
            }
          }
          .modal-body {
            display: flex;
            flex-direction: column;
          }
        }
        &-body {
          .c-btn {
            margin: 0 auto;
            width: 280px;
            // width: 100%;
          }
        }
      }
    }
  }
  &-content {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    gap: 32px 0;
    padding: 0 12px 12px 12px;
    .row,
    .col {
      margin: 0;
      padding: 0;
      width: 100%;
    }
    &__image {
      align-items: center;
      display: flex;
      justify-content: center;
      position: relative;
      width: 100%;
      &.row {
        padding-bottom: 16px;
      }
      .image {
        background-color: $appColorGrayScale15;
        // border-radius: 50%;
        min-height: 278px;
        overflow: hidden;
        // max-width: 200px;
        width: 100%;
        &--link {
          cursor: pointer;
        }
        img {
          width: 100%;
        }
      }
    }
    &__caption {
      color: $appColorBlack;
      font-weight: $appFontWeightExtraBold;
      font-size: $appFontSize3XL + 4px;
      letter-spacing: -0.05em;
      justify-content: center;
      &--link {
        cursor: pointer;
      }
    }
    &__text {
      display: inline;
      font-size: $appFontSizeL;
      text-align: center;
      justify-content: center;
      span {
        font-size: $appFontSizeM;
        margin-left: 4px;
      }
      &--link {
        cursor: pointer;
      }
    }
    &__btn {
      display: flex;
      flex-direction: column;
    }

    &-nav {
      margin-top: -32px;
      position: absolute;
      width: 100%;
      &__item {
        align-items: center;
        justify-content: center;
        box-sizing: border-box;
        display: flex;
        height: 48px;
        position: absolute;
        z-index: 2000;
        padding: 12px;
        max-width: 48px;
        width: 48px;
        i {
          color: $appColorGlassBlue;
          cursor: pointer;
          font-size: 36px;
          &:hover {
            color: $appColorPrimary1;
          }
        }
        &--prev {
          left: -6px;
          top: calc(50% - 6px);
        }
        &--next {
          right: -6px;
          top: calc(50% - 6px);
        }
      }
    }
  }
  &-step-list {
    align-items: center;
    border: none;
    display: flex;
    flex-direction: row;
    gap: 8px;
    justify-content: center;
    margin: 0;
    padding: 0 0 18px 0;
    &__item {
      background: $appColorGrayScale8;
      border-radius: 50% !important;
      border: none;
      cursor: pointer;
      line-height: 0;
      height: 12px;
      margin: 0;
      padding: 0;
      transition: background 0.3s;
      width: 12px;
      &.is-active {
        background: $appColorPrimary1;
      }
    }
  }
}

@media (max-width: $appBreakPoint) {
  .p-onboarding {
    $this: &;
    &__modal {
      &.c-modal {
        max-width: 92%;
        width: 92%;
        .modal {
          &-header {
            padding: 16px 16px 0 16px;
          }
        }
      }
    }
    &-content {
      gap: 24px 0;
      padding: 0 8px 8px 8px;
      &__image {
        .image {
          min-height: auto;
        }
      }
      &__caption {
        font-size: $appFontSize3XL;
        line-height: 1.4em;
      }
      &__text {
        font-size: $appFontSizeBase;
        span {
          font-size: $appFontSizeS;
          margin-left: 2px;
        }
      }
    }
  }
}
