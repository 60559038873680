.p-connection {
  $this: &;
  margin: 0 auto 15px auto;

  .row {
    margin: 0;
  }

  &--done {
    #{$this}-list {
      &__item {
        .label,
        .value {
          &-group {
            &--title {
              width: calc(100% - 135px);
            }
            .value {
              &--title {
                justify-content: flex-start;
                min-width: 240px;
              }
            }
          }
        }
      }
    }
  }

  &--recommend {
    #{$this}-list {
      &__item {
        .label,
        .value {
          &-group {
            &--title {
              width: calc(100% - 170px);
            }
            .value {
              &--title {
                justify-content: flex-start;
                min-width: 240px;
              }
            }
          }
        }
      }
    }
  }

  &--request {
    #{$this}-list {
      &__item {
        .label,
        .value {
          &-group {
            &--title {
              width: calc(100% - 402px);
            }
            .value {
              &--title {
                justify-content: flex-start;
                min-width: 240px;
              }
            }
          }
        }
      }
    }
  }

  &-list {
    background: none;
    border-radius: $appRadiusM;
    list-style: none;
    margin: 0;
    padding: 0;
    &__item {
      align-items: center;
      background: $appColorWhite;
      border: none;
      border-bottom: 1px $appColorGrayScale6 solid;
      border-radius: 0;
      display: flex;
      flex-wrap: wrap;
      list-style: none;
      margin: 0;
      padding: 17.5px 0 10px 0;
      // padding: 12.5px 0 5px 0;
      width: 100%;

      &:last-child {
        border-bottom: none;
      }

      &:hover {
        background: $appColorGrayScale9;
      }

      &--head {
        background: $appColorGrayScale9;
        padding: 20px 0 12.5px 0;
        .label,
        .value {
          font-weight: $appFontWeightExtraBold;
        }
        &:hover {
          background: $appColorGrayScale9;
        }
      }

      &--empty {
        &:hover {
          background: $appColorWhite;
        }
        .col.value {
          max-width: 100%;
          min-width: 100%;
          padding: 15px 15px !important;
          text-align: center;
          width: 100%;
        }
      }

      &-image {
        align-items: center;
        background: $appColorWhite;
        box-sizing: border-box;
        border: 4px solid $appColorGrayScale8;
        border-radius: 50%;
        display: flex;
        flex-grow: initial;
        justify-content: center;
        height: 64px;
        margin: 0;
        overflow: hidden;
        padding: 0;
        width: 64px;
        img {
          width: 100%;
        }
        i {
          color: $appColorGrayScale8;
          font-size: 48px;
        }
      }

      .col.label,
      .col.value {
        padding: 0 12.5px 5px 12.5px;
      }

      .label,
      .value {
        align-items: center;
        display: flex;
        flex-direction: column;
        flex-grow: 0;
        justify-content: flex-start;
        word-break: break-all;
        &-group {
          align-items: stretch;
          display: flex;
          .value {
            align-items: flex-start;
            display: flex;
            justify-content: center;
            &--title {
              align-items: center;
            }
          }
          &--title {
            flex-grow: 1;
          }
          &--btns-recommend,
          &--btns-request {
            align-items: center;
            box-sizing: border-box;
            display: flex;
            max-width: 170px;
            min-width: 170px;
            padding: 0 12px;
            width: auto;
            .c-btn {
              height: 40px; 
              margin: 0;
              width: 140px;
              &__text {
                font-size: $appFontSizeM;
              }
            }
            .c-btn + .c-btn {
              margin-left: 8px;
            }
          }
          &--btns-request {
            max-width: 252px;
            min-width: 252px;
            .c-btn {
              width: 110px;
            }
          }
        }
        &--title {
          align-items: center;
          flex-direction: row;
          flex-grow: 1;
          min-width: 250px;
          width: auto;
          a {
            // @include ellipsis;
            color: $appColorBlack;
            text-decoration: none;
            // width: auto;
            &:visited,
            &:active {
              color: $appColorBlack;
            }
            &:hover {
              color: $appColorBlack;
              text-decoration: underline;
            }
          }
          i {
            margin-left: 5px;
          }
        }
        &--connection-type {
          max-width: 135px; 
          min-width: 135px;
          width: auto;
        }
        &--created-at { 
          max-width: 150px;
          min-width: 150px;
          width: auto;
        }
      }
 
      .c-badge {
        border-radius: $appRadiusM;
        font-size: $appFontSizeS;
        height: 30px;
        min-width: 70px;
        padding: 0 10px;
        width: auto;
      }

      .value {
        &--image {
          padding-right: 0 !important;
        }
        &--project-status {
          align-items: center;
          display: flex;
        }
        &--control {
          align-items: center;
          cursor: pointer;
          display: flex;
          text-align: center;
          i {
            font-size: 26px;
          }
        }
        &--project-expired-on {
          .date,
          .diff {
            display: block;
            width: 100%;
          }
        }
        &--project-prices {
          .max,
          .min {
            display: block;
            width: 100%;
          }
        }
      }

      .label {
        align-items: flex-start;
      }
    }
  }
}

@media (max-width: $appBreakPoint) {
  .p-connection {
    $this: &;
    margin: 0 auto 16px auto;
    &-list {
      .label,
      .value {
        &-group {
          justify-content: space-between;
          width: 100%;
          &--title {
            width: 100% !important;
          }
          &--request {
            width: 100%;
          }
          &--btns-recommend {
            justify-content: flex-end;
            max-width: 100%;
            min-width: 100%;
            padding: 6px 0 0 0;
            width: 100%;
            .c-btn {
              height: 32px; 
              margin: 0;
              width: 160px;
              &__text {
                font-size: $appFontSizeS;
              }
            }
          }
          &--btns-request {
            justify-content: flex-end;
            max-width: 100%;
            min-width: 100%;
            padding: 6px 0 0 0;
            width: 100%;
            .c-btn {
              height: 32px; 
              margin: 0;
              width: 110px;
              &__text {
                font-size: $appFontSizeS;
              }
            }
          }
        }
      }

      .label {
        &-group {
          &--btns-recommend, 
          &--btns-request {
            display: none;
          }
        }
      }

      .value {
        &-group {
          &--title {
            margin-bottom: 8px;
          }
        }
      }

      &__item {
        padding: 12.5px 10px 10px 10px !important;
        .col {
          font-size: $appFontSizeS;
          padding: 2.5px !important;
        }
        .col.value--image + .col.value--title {
          padding-left: 10px !important;
        }
      }
    }
  }
}