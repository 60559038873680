.c-input-card,
.c-input-card.card {
  $this: &;
  border: 1px solid #dee2e6;
  border-radius: $appRadiusM;
  box-shadow: none !important;
  height: auto;
  margin: 0;
  padding: 15px 15px 5px 15px;
  width: 100%;

  &__container {
    width: 500px !important;
  }

  &-header {
    border-bottom: 1px solid $appColorGrayScale6;
    border-radius: 0;
    box-sizing: border-box;
    padding: 10px 0 25px 0;
    width: 100%;    
  }
  &-body {
    border: none;
    border-radius: 0;
    padding: 0;
    width: 100%;
  }

  &-footer {
    align-items: center;
    border: none;
    border-radius: 0;
    display: flex;
    justify-content: center;
    padding: 15px 0 20px 0;
    width: 100%;
  }

  &-brand-list {
    align-items: center;
    display: flex;
    gap: 6px;
    // justify-content: space-between;
    justify-content: flex-start;
    margin: 0 auto;
    width: 100%;
    img {
      margin-right: 5px;
      width: 60px;
      &:last-child {
        margin-right: 0;
      }
    }
  }

  &__item {
    align-items: center;
    border-bottom: 1px solid $appColorGrayScale6;
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    height: auto;
    margin: 0;
    justify-content: flex-start;
    padding: 15px 0 15px 0;
    width: 100%;
    .field {
      align-content: center;
      border: 1px solid $appColorGrayScale6;
      border-radius: $appRadiusM;
      display: flex;
      flex-grow: 1;
      height: 40px;
      padding: 11px 10px 9px 10px;
      position: relative;

      &-label {
        font-size: $appFontSizeM;
        font-weight: bold;
        width: 100%;
      }

      .input-card-brand {
        position: absolute;
        right: 10px;
        top: 7px;
        width: 30px;
        img {
          width: 100%;
        }
      }
      #card-cvc-element,
      #card-expiry-element,
      #card-number-element {
        width: 100%;
      }
    }

    .reference {
      cursor: pointer;
      img {
        margin: -4px 0 0 10px;
        width: 35px;
      }
    }

    &--number {
      .field-label {
        margin: 5px 0 0 0 !important;
        width: 90px;
      }
    }

    &--cvc,
    &--expired {
      border-bottom: none;
      padding: 0;
      width: 50%;
    }

    &--cvc {
      padding-left: 15px;
    }

    &--expired {
      border-right: 1px solid $appColorGrayScale6;;
      padding-right: 15px;
    }

    &-group {
      margin: 0;
      padding: 15px 0 15px 0;
    }
  }
}


@media (max-width: $appBreakPoint) {
  .c-input-card,
  .c-input-card.card {
    $this: &;
    &__container {
      width: 100% !important;
    }
    &-header {
      padding: 5px 0 15px 0;
    }
    &-footer {
      padding: 5px 0 15px 0;
    }
  
    &-brand-list {
      justify-content: flex-start;
      gap: 4px;
      width: 100%;
      img {
        margin-right: 5px;
        width: 40px;
      }
    }
  
    &__item {
      align-items: center;
      border-bottom: 1px solid $appColorGrayScale6;
      box-sizing: border-box;
      display: flex;
      flex-wrap: wrap;
      height: auto;
      margin: 0;
      justify-content: flex-start;
      padding: 15px 0 15px 0;
      width: 100%;
      .field {
        align-content: center;
        border: 1px solid $appColorGrayScale6;
        border-radius: $appRadiusM;
        display: flex;
        flex-grow: 1;
        height: 40px;
        padding: 11px 10px 9px 10px;
        position: relative;
  
        &-label {
          font-size: $appFontSizeM;
          font-weight: bold;
          width: 100%;
        }
  
        .input-card-brand {
          position: absolute;
          right: 10px;
          top: 7px;
          width: 30px;
          img {
            width: 100%;
          }
        }
        #card-cvc-element,
        #card-expiry-element,
        #card-number-element {
          width: 100%;
        }
      }
  
      .reference {
        cursor: pointer;
        img {
          margin: -4px 0 0 10px;
          width: 35px;
        }
      }
  
      &--number {
        .field-label {
          margin: 5px 0 0 0 !important;
          width: 75px;
        }
      }
  
      &--cvc,
      &--expired {
        border-bottom: none;
        padding: 0;
        width: 50%;
      }
  
      &--cvc {
        padding-left: 15px;
      }
  
      &--expired {
        border-right: 1px solid $appColorGrayScale6;;
        padding-right: 15px;
      }
  
      &-group {
        margin: 0;
        padding: 15px 0 15px 0;
      }
    }
  }
}
