.c-btn {
  $this: &;
  align-items: center;
  border: none;
  border-radius: 999px;
  box-sizing: border-box;
  box-shadow: none !important;
  color: #fff;
  display: flex;
  font-size: $appFontSizeL;
  font-weight: $appFontWeightExtraBold;
  height: $appBtnHeightM;
  justify-content: center;
  padding: 10px 0;
  transition: background 0.3s, border 0.3s, opacity 0.3s;
  width: 100%;
  &:focus,
  &:hover,
  &:active,
  &:visited {
    color: #fff;
    text-decoration: none;
  }

  &--circle {
    border-radius: 50%;
  }

  &--go-border {
    background: $appColorWhite !important;
    border: 1px solid $appColorGrayScale3;
    border-radius: 4px;
    &:hover,
    &:disabled {
      background: $appColorGrayScale3 !important;
      border: 1px solid $appColorGrayScale3 !important;
      #{$this}__text {
        color: $appColorWhite;
      }
    }
    &:focus,
    &:active,
    &:visited {
      background: $appColorWhite !important;
      border: 1px solid $appColorGrayScale3 !important;
      color: $appColorWhite;
      #{$this}__text {
        color: $appColorBlack;
      }
    }
    #{$this}__text {
      color: $appColorBlack;
    }
  }

  &--invisible {
    background: transparent;
    border: none;
    &:hover,
    &:disabled {
      background: transparent !important;
      border: none;
      opacity: 0.98;
    }
    &:focus,
    &:active,
    &:visited {
      background: transparent;
      border: none;
    }
    i {
      color: $appColorBlack;
    }
    #{$this}__text {
      color: $appColorBlack;
    }
  }

  &--panel {
    background: $appColorGrayScale8;
    border: none;
    &:hover,
    &:disabled {
      background: $appColorGrayScale9 !important;
      border: none;
    }
    &:focus,
    &:active,
    &:visited {
      background: $appColorGrayScale8 !important;
      border: none;
    }
    i {
      color: $appColorBlack;
    }
    #{$this}__text {
      color: $appColorBlack;
    }
  }

  &--glass-blue {
    background: $appColorGlassBlue;
    border: none;
    &:hover {
      background: $appColorGlassBlueAlpha;
      border: none;
    }
    &:disabled {
      background: $appColorGlassBlue !important;
      border: none;
    }
    &:focus,
    &:active,
    &:visited {
      background: $appColorGlassBlue !important;
      border: none;
    }
    i {
      color: $appColorBlack;
    }
    #{$this}__text {
      color: $appColorBlack;
    }
  }

  &--white {
    background: $appColorWhite;
    border: 2px solid $appColorWhite;
    &:hover,
    &:disabled {
      background: $appColorGrayScale8 !important;
      border: 2px solid $appColorGrayScale8 !important;
      opacity: 0.98;
    }
    &:focus,
    &:active,
    &:visited {
      background: $appColorWhite !important;
      border: 2px solid $appColorWhite !important;
    }
    i {
      color: $appColorBlack;
    }
    #{$this}__text {
      color: $appColorBlack;
    }
  }

  &--go,
  &--back,
  &--cancel,
  &--delete,
  &--dropdown {
    background: $appColorGrayScale3;
    border: 2px solid $appColorGrayScale3;
    &:hover,
    &:disabled {
      background: $appColorGrayScale4 !important;
      border: 2px solid $appColorGrayScale4 !important;
    }
    &:focus,
    &:active,
    &:visited {
      background: $appColorGrayScale3 !important;
      border: 2px solid $appColorGrayScale3 !important;
    }
    #{$this}__text {
      color: $appColorWhite;
    }
  }

  &--warning {
    background: $appColorFreeRed;
    border: 2px solid $appColorFreeRed;
    &:hover,
    &:disabled {
      background: $appColorFreeRed2 !important;
      border: 2px solid $appColorFreeRed2 !important;
    }
    &:focus,
    &:active,
    &:visited {
      background: $appColorFreeRed !important;
      border: 2px solid $appColorFreeRed !important;
    }
    #{$this}__text {
      color: $appColorWhite;
    }
  }

  &--reject {
    background: $appColorFreeRed;
    border: 2px solid $appColorFreeRed;
    &:hover,
    &:disabled {
      background: $appColorFreeRed2 !important;
      border: 2px solid $appColorFreeRed2 !important;
    }
    &:focus,
    &:active,
    &:visited {
      background: $appColorFreeRed !important;
      border: 2px solid $appColorFreeRed !important;
    }
    #{$this}__text {
      color: $appColorWhite;
    }
  }

  &--inprogress {
    background: $appColorFreeYellow;
    border: 2px solid $appColorFreeYellow;
    &:hover,
    &:disabled {
      background: $appColorFreeYellow !important;
      border: 2px solid $appColorFreeYellow !important;
    }
    &:focus,
    &:active,
    &:visited {
      background: $appColorFreeYellow !important;
      border: 2px solid $appColorFreeYellow !important;
    }
    #{$this}__text {
      color: $appColorWhite;
    }
  }

  &--add,
  &--enter {
    background: $appColorPrimary1;
    border: 2px solid $appColorPrimary1;
    &:hover,
    &:disabled {
      background: $appColorPrimary3 !important;
      border: 2px solid $appColorPrimary3 !important;
    }
    &:focus,
    &:active,
    &:visited {
      background: $appColorPrimary1 !important;
      border: 2px solid $appColorPrimary1 !important;
    }
    #{$this}__text {
      color: $appColorWhite;
    }
  }
  &--connect,
  &--user-detail-dm {
    background: $appColorPrimary1;
    border: 2px solid $appColorPrimary1;
    &:hover,
    &:disabled {
      background: $appColorPrimary3 !important;
      border: 2px solid $appColorPrimary3 !important;
    }
    &:focus,
    &:active,
    &:visited {
      background: $appColorPrimary1 !important;
      border: 2px solid $appColorPrimary1 !important;
    }
    #{$this}__text {
      color: $appColorWhite;
    }
  }

  &--dm {
    height: 30px;
    width: 100px;
  }

  &--conversion {
    background: linear-gradient(
      92.43deg,
      #25aae1 -9.19%,
      rgba(64, 228, 149, 0.8) 87.61%,
      rgba(48, 221, 138, 0.5) 121.75%
    );
    position: relative;
    z-index: 0;
    &::after {
      background: linear-gradient(
        92.43deg,
        rgba(48, 221, 138, 0.5) -9.19%,
        rgba(64, 228, 149, 0.8) 37.61%,
        #25aae1 91.75%
      );
      border-radius: 999px;
      content: '';
      display: block;
      height: 100%;
      left: 0;
      opacity: 0;
      position: absolute;
      top: 0;
      transition: 1s;
      width: 100%;
      z-index: -1;
    }
    &:disabled {
      background: linear-gradient(
        92.43deg,
        #25aae1 -9.19%,
        rgba(64, 228, 149, 0.8) 87.61%,
        rgba(48, 221, 138, 0.5) 121.75%
      );
      border: none !important;
      opacity: 0.9;
      &::after {
        display: none;
      }
    }
    &:hover {
      &::after {
        opacity: 1;
      }
    }
    #{$this}__text {
      color: $appColorWhite;
    }
  }

  &--grad-blue {
    background: linear-gradient(92.18deg, #0298de 1.6%, #29bbff 100.84%);
    position: relative;
    z-index: 0;
    &::after {
      background: linear-gradient(92.18deg, #29bbff 50.6%, #0298de 100.84%);
      border-radius: 999px;
      content: '';
      display: block;
      height: 100%;
      left: 0;
      opacity: 0;
      position: absolute;
      top: 0;
      transition: 1s;
      width: 100%;
      z-index: -1;
    }
    &:hover {
      &::after {
        opacity: 1;
      }
    }
    &:disabled {
      background: linear-gradient(
        92.18deg,
        #008bcc 1.6%,
        #23a8e6 100.84%
      ) !important;
      border: none !important;
      opacity: 0.9;
      &::after {
        display: none;
      }
    }
    #{$this}__text {
      color: $appColorWhite;
    }
  }

  &__text {
    color: $appColorBlack;
    font-size: $appFontSizeL;
    font-weight: $appFontWeightExtraBold;
    line-height: 1em;
  }

  &--icon-left {
    align-items: center;
    display: flex;
    position: relative;
    #{$this}__icon {
      position: absolute;
      left: 20px;
      img {
        width: 22px;
      }
    }
  }

  &--login-google {
    background: $appColorWhite;
    border: 2px solid $appColorGrayScale7;
    &:hover {
      background: $appColorGrayScale7 !important;
      border: 2px solid $appColorGrayScale7 !important;
    }
    &:focus,
    &:active,
    &:visited {
      background: $appColorWhite;
      border: 2px solid $appColorGrayScale7;
    }
    &:disabled {
      background: $appColorGrayScale7 !important;
      border: 2px solid $appColorGrayScale7 !important;
      opacity: 0.9;
    }
  }

  &--login-facebook {
    background: #3b5998;
    border: 2px solid #3b5998;
    #{$this}__text {
      color: $appColorWhite;
    }
    &:hover {
      background: #293e69 !important;
      border: 2px solid #293e69 !important;
    }
    &:focus,
    &:active,
    &:visited {
      background: #3b5998;
      border: 2px solid #3b5998;
    }
    &:disabled {
      background: #293e69 !important;
      border: 2px solid #293e69 !important;
      opacity: 0.9;
    }
  }

  &--login-apple {
    background: rgba(51, 51, 51, 1);
    border: 2px solid rgba(51, 51, 51, 1);

    #{$this}__text {
      color: $appColorWhite;
    }

    #{$this}__icon {
      margin-left: 1px;
      img {
        width: 20px;
      }
    }
    &:hover {
      background: rgba(51, 51, 51, 0.9) !important;
      border: 2px solid rgba(51, 51, 51, 0.9) !important;
    }
    &:focus,
    &:active,
    &:visited {
      background: rgba(51, 51, 51, 0.9) !important;
      border: 2px solid rgba(51, 51, 51, 0.9) !important;
    }
    &:disabled {
      background: rgba(51, 51, 51, 0.9) !important;
      border: 2px solid rgba(51, 51, 51, 0.9) !important;
      opacity: 0.9;
    }
  }

  &.is-loading {
    #{$this}__text {
      display: none;
    }
    i {
      display: none;
    }
    .loader {
      display: block;
    }
  }

  .loader {
    display: none;
  }

  &.is-disabled {
    background: $appColorGrayScale6 !important;
    border: 2px $appColorGrayScale6 solid !important;
    opacity: 0.9;
    &:hover,
    &:active,
    &:focus {
      background: $appColorGrayScale6 !important;
      border: 2px $appColorGrayScale6 solid !important;
      opacity: 0.9;
    }
    &::after {
      display: none;
    }
  }

  &--favorite {
    background: $appColorGrayScale6;
    border: none !important;
    line-height: 0;
    &:disabled {
      background: $appColorGrayScale6 !important;
      opacity: 1;
    }
    &.is-active {
      background: $appColorFreeYellow !important;
      &:hover,
      &:active,
      &:focus {
        background: $appColorFreeYellow !important;
      }
      &:disabled {
        background: $appColorFreeYellow !important;
        opacity: 1;
      }
    }
    &:hover,
    &:active,
    &:focus {
      background: $appColorGrayScale6 !important;
    }
  }

  &--checkbox {
    background: $appColorGrayScale6;
    border: none !important;
    line-height: 0;
    &:disabled {
      background: $appColorGrayScale6 !important;
      opacity: 1;
    }
    &.is-active {
      background: $appColorPrimary1 !important;
      color: $appColorWhite;
      &:hover,
      &:active,
      &:focus {
        background: $appColorPrimary1 !important;
      }
      &:disabled {
        background: $appColorGrayScale6 !important;
        opacity: 1;
      }
    }
    &:hover {
      background: $appColorPrimary1 !important;
    }
    &:active,
    &:focus {
      background: $appColorGrayScale6 !important;
    }
  }

  // くり抜きバージョン
  &--badge {
    align-items: center;
    background: $appColorWhite;
    border: 1px solid $appColorPrimary1;
    border-radius: 4px;
    color: $appColorPrimary1;
    cursor: pointer;
    font-size: $appFontSizeS;
    display: flex;
    height: 30px;
    justify-content: center;
    line-height: $appFontSizeS;
    padding: 2px 10px 0 10px;
    text-align: center;
    transition: background 0.3s, font-size 0.3s, width 0.3s;
    width: auto;
    &:hover,
    &:disabled {
      background: $appColorPrimary1 !important;
      border: 1px solid $appColorPrimary1 !important;
      color: $appColorWhite !important;
      #{$this}__text {
        color: $appColorWhite !important;
      }
    }
    &:focus,
    &:active,
    &:visited {
      background: $appColorWhite;
      border: 1px solid $appColorPrimary1;
      color: $appColorPrimary1;
      #{$this}__text {
        color: $appColorPrimary1;
      }
    }
    #{$this}__text {
      color: $appColorPrimary1;
    }
  }

  // くり抜きバージョン
  &--badge-gray {
    align-items: center;
    background: $appColorWhite;
    border: 1px solid $appColorGrayScale3;
    border-radius: 4px;
    color: $appColorGrayScale3;
    cursor: pointer;
    font-size: $appFontSizeS;
    display: flex;
    height: 30px;
    justify-content: center;
    line-height: $appFontSizeS;
    padding: 2px 10px 0 10px;
    text-align: center;
    transition: background 0.3s, font-size 0.3s, width 0.3s;
    width: auto;
    &:hover,
    &:disabled {
      background: $appColorGrayScale3 !important;
      border: 1px solid $appColorGrayScale3 !important;
      color: $appColorWhite !important;
      #{$this}__text {
        color: $appColorWhite !important;
      }
    }
    &:focus,
    &:active,
    &:visited {
      background: $appColorWhite;
      border: 1px solid $appColorGrayScale3;
      color: $appColorGrayScale3;
      #{$this}__text {
        color: $appColorGrayScale3;
      }
    }
    #{$this}__text {
      color: $appColorGrayScale3;
    }
  }

  // くり抜きバージョン
  &--badge-black {
    align-items: center;
    background: $appColorWhite;
    border: 1px solid $appColorBlack;
    border-radius: 4px;
    color: $appColorBlack;
    cursor: pointer;
    font-size: $appFontSizeS;
    display: flex;
    height: 30px;
    justify-content: center;
    line-height: $appFontSizeS;
    padding: 2px 10px 0 10px;
    text-align: center;
    transition: background 0.3s, font-size 0.3s, width 0.3s;
    width: auto;
    &:hover,
    &:disabled {
      background: $appColorBlack !important;
      border: 1px solid $appColorBlack !important;
      color: $appColorWhite !important;
      #{$this}__text {
        color: $appColorWhite !important;
      }
    }
    &:focus,
    &:active,
    &:visited {
      background: $appColorWhite;
      border: 1px solid $appColorBlack;
      color: $appColorBlack;
      #{$this}__text {
        color: $appColorBlack;
      }
    }
    #{$this}__text {
      color: $appColorBlack;
    }
  }
}

@media (max-width: $appBreakPoint) {
  .c-btn {
    font-size: $appFontSizeM;
    &__text {
      font-size: $appFontSizeM;
    }
    &--badge {
      font-size: $appFontSizeXS;
      padding: 0 12px;
    }
  }
}
