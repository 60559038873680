.l-page {
  $this: &;
  &--no-login {
    box-sizing: border-box;
    display: flex;
    padding: 100px 0 135px 0;
    #{$this}-content {
      margin: 0 auto;
      max-width: 1220px;
      width: 92%;
      &--full-width {
        max-width: 92%;
        width: 100%;
      }
      .c-progress-bar {
        margin: 0 auto 25px auto;
      }
    }
  }
}

@media (max-width: $appBreakPoint) {
  .l-page {
    $this: &;
    &--no-login {
      padding: 100px 0 0 0;
      #{$this}-content {
        .c-progress-bar {
          margin: -10px auto 15px auto;
        }
      }
    }
  }
}
