.p-project-setting {
  $this: &;
  &--edit,
  &--edit.card,
  &--edit-option,
  &--edit-option.card,
  &--preview,
  &--preview.card,
  &--complete,
  &--complete.card {
    background: $appColorWhite;
    border-radius: 8px;
    box-sizing: border-box;
    margin-top: 20px;
    padding: 45px 40px;
    width: 100%;
    #{$this}__block {
      padding: 0;
      min-height: auto;
    }

    #{$this}-terms {
      background: $appColorGrayScale9;
      border-bottom: 0;
      border-radius: $appRadiusM;
      box-sizing: border-box;
      padding: 25px;
      margin-bottom: 25px;
      min-height: auto;
      &__item {
        align-items: center;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        margin: 0;
        padding: 0;
        width: 100%;
        .c-text {
          margin: 0;
          padding: 0;
          width: 100%;
        }
        .c-text + .c-text {
          margin-top: 5px;
        }
      }
    }

    #{$this}-form {
      &__item {
        display: flex;
        flex-direction: column;
        margin: 0 0 25px 0;
        position: relative;
        width: 100%;

        .form-group {
          width: 100%;
        }

        // ------------------------------------
        // 吹き出し位置の調整
        // ------------------------------------
        // X軸位置揃え
        &--casual-interview,
        &--interview-type,
        &--participation-type,
        &--publish-scope,
        &--publish-scope-range,
        &--price-type,
        &--type,
        &--work-average-type,
        &--work-type {
          .form-group {
            .invalid-feedback {
              left: 100px;
              right: auto;
              &::before {
                left: auto;
                right: 30px;
              }
            }
          }
        }
        &--casual-interview .form-group .invalid-feedback {
          left: 140px;
        }

        &--participation-type .form-group .invalid-feedback {
          left: 220px;
        }
        &--work-average-type .form-group .invalid-feedback {
          left: 120px;
        }
        &--status .form-group .invalid-feedback {
          left: 100px;
        }
        &--publish-scope .form-group .invalid-feedback {
          &::before {
            left: 30px;
            right: auto;
          }
        }
        &--publish-scope-range .form-group .invalid-feedback {
          left: 100px;
        }

        // Y軸位置揃え: 画面幅によって、エラー文言が複数行にな吹き出し対応
        &--period,
        &--price-amount,
        &--published-datetime,
        &--recruit-amount {
          .form-group {
            .invalid-feedback {
              bottom: 48px;
              top: auto;
            }
          }
        }

        &--name,
        &--price-amount {
          .form-group {
            .invalid-feedback {
              bottom: 48px;
              top: auto;
              z-index: 1000;
            }
          }
        }

        // ------------------------------------
        // 複数要素が存在するフォームパーツの調整
        // ------------------------------------
        &--period,
        &--price-amount,
        &--published-datetime,
        &--recruit-amount {
          align-items: center;
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;
          justify-content: space-between;
          width: 100%;
          .from,
          .from-time,
          .max,
          .min,
          .separator,
          .to {
            padding: 0;
          }
          .separator {
            align-items: center;
            box-sizing: border-box;
            max-width: 50px;
            padding: 25px 0 0 0;
            text-align: center;
            width: 50px;
          }
          .unit {
            align-items: center;
            box-sizing: border-box;
            max-width: 35px;
            padding: 25px 0 0 0;
            text-align: center;
            width: 35px;
          }
        }

        // &--price-amount {
        //   margin-top: -15px;
        // }

        &--casual-interview {
          .c-checkbox .form-control {
            padding-top: 10px !important;
          }
        }

        &--published-datetime {
          .from-time {
            max-width: 200px;
            margin-left: 15px;
          }
          .c-select {
            margin-top: 25px;
            &__menu {
              &-list {
                max-height: 200px;
              }
            }
          }
        }

        &--recruit-amount {
          justify-content: flex-start;
          .form-group {
            min-width: 200px;
            width: 25%;
          }
        }

        &--price-amount {
          justify-content: flex-start;
        }

        &--goal {
          .c-input {
            height: 64px;
            min-height: 64px;
          }
        }

        &--overview {
          .c-input {
            height: 250px;
            min-height: 250px;
          }
        }

        &--credit-card {
          .caption {
            font-size: $appFontSizeL;
            font-weight: $appFontWeightExtraBold;
          }
          .c-input-card {
            margin-top: 25px;
          }
        }

        &--purchased-option {
          .list-group {
            background: $appColorGrayScale7;
            border: none;
            border-radius: $appRadiusM;
            box-sizing: border-box;
            display: flex;
            flex-direction: column;
            list-style: none;
            margin: 0;
            padding: 20px 20px 18px 20px;
            width: 100%;
            &-item {
              background: none;
              border: none;
              border-radius: 0;
              display: flex;
              padding: 0 0;
              margin: 0 0 3px 0;
              width: 100%;
              .name,
              .price,
              .tax {
                display: inline;
              }
              .name {
                padding-right: 8px;
              }
              &.total {
                margin: 5px 0 0 0;
              }
              &:last-child {
                margin-bottom: 0;
              }
            }
          }
        }

        // 有料オプション選択フィールド
        &--charge-option {
          .caption {
            font-size: $appFontSizeL;
            font-weight: $appFontWeightExtraBold;
          }

          #{$this}-option {
            &__header {
              background: transparent;
              border: none;
              border-radius: 0;
              padding: 10px 0;
            }
            &__body {
              background: $appColorGrayScale9;
              border-top: 1px $appColorGrayScale6 solid;
              color: $appColorBlack;
              cursor: pointer;
              padding: 20px 20px;
              *::selection {
                background: none !important;
                color: $appColorBlack;
              }
              .desc {
                font-size: $appFontSizeM;
                font-weight: normal;
                margin-bottom: 10px;
              }
              .price {
                align-items: flex-end;
                display: flex;
                flex-direction: wrap;
                width: 100%;
                .free {
                  display: block;
                  font-weight: $appFontWeightExtraBold;
                  font-size: $appFontSizeXL;
                  line-height: $appFontSizeXL;
                  padding-right: 5px;
                }
                .number {
                  display: block;
                  font-weight: $appFontWeightExtraBold;
                  font-size: $appFontSize3XL;
                  line-height: $appFontSize3XL;
                  padding-right: 5px;
                }
                .unit,
                .tax {
                  display: block;
                  font-size: $appFontSizeM;
                  font-weight: bold;
                  line-height: $appFontSizeM;
                  padding-bottom: 3px;
                }
              }
            }
            &-list {
              width: 100%;
              &__item {
                background: none;
                border: none;
                box-shadow: none;
                margin-bottom: 15px;
                width: 100%;
              }
              .c-checkbox {
                .form-control {
                  align-items: center;
                  display: flex;
                  flex-direction: column;
                }
              }
            }
          }
        }

        .form-group {
          margin-bottom: 0;
          label {
            font-size: $appFontSizeM;
            font-weight: $appFontWeightExtraBold;
            margin-bottom: 5px;
          }
          legend {
            font-size: $appFontSizeM;
            font-weight: $appFontWeightExtraBold;
            margin-bottom: 0;
          }
          &.c-checkbox,
          &.c-radio {
            legend label {
              font-size: $appFontSizeM;
              font-weight: $appFontWeightExtraBold;
              margin-bottom: 0;
            }
            label {
              font-weight: $appFontWeightNormal;
            }
            .custom-control-label {
              font-weight: $appFontWeightNormal;
            }
            .form-control {
              padding-bottom: 0 !important;
            }
          }
        }

        &--btns {
          flex-direction: row;
          margin: 45px 0 5px 0;
          justify-content: center;
          button.c-btn {
            font-size: $appFontSizeL;
          }
          .c-btn {
            margin: 0 7.5px;
            width: 265px;
          }
        }
      }
    }
  }

  &--preview,
  &--preview.card {
    padding-top: 55px;
    #{$this}-form {
      flex-wrap: wrap;
      &__item {
        border-bottom: 1px solid $appColorGrayScale6;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        margin-bottom: 20px;
        padding-bottom: 15px;
        .label {
          flex-basis: auto;
          flex-grow: 0;
          font-size: $appFontSizeM;
          font-weight: $appFontWeightExtraBold;
          padding: 0 0 0 0;
          min-width: 220px;
          width: 220px;
        }
        .value {
          flex-basis: auto;
          flex-grow: 1;
          font-size: $appFontSizeM;
          padding: 0 0 0 0;
          width: calc(100% - 220px);
          a {
            color: $appColorPrimary4;
            text-decoration: none;
            &:hover {
              color: $appColorPrimary4;
              text-decoration: underline;
            }
            &:active,
            &:visited {
              text-decoration: none;
            }
          }
        }
        &--charge-option {
          .value {
            .list-group {
              background: none;
              border: none;
              border-radius: 0;
              display: flex;
              flex-direction: column;
              list-style: none;
              margin: 0;
              padding: 0;
              width: 100%;
              &-item {
                background: none;
                border: none;
                border-radius: 0;
                display: flex;
                padding: 0 0;
                margin: 0 0 3px 0;
                width: 100%;
                .name,
                .price,
                .tax {
                  display: inline;
                }
                .name {
                  padding-right: 8px;
                }
                &.total {
                  margin: 5px 0 0 0;
                }
              }
            }
          }
        }
        &--consent,
        &--btns {
          border-bottom: 0;
        }
        &--btns {
          justify-content: center;
          margin-bottom: 5px;
          padding-bottom: 0;
          .c-btn {
            margin: 0 7.5px;
          }
        }

        &--document {
          .preview {
            align-items: center;
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-start;
            width: 100%;

            &--image {
              .image {
                min-width: 200px;
                width: 200px;
                img {
                  width: 100%;
                }
              }
              .info {
                width: calc(100% - 200px);
              }
            }

            &--document {
              .document {
                min-width: 80px;
                width: 80px;
                i {
                  color: $appColorDocumentFileIcon;
                  font-size: 80px;
                  line-height: 80px;
                  transition: color 300ms;
                }
                &--excel i {
                  color: $appColorDocumentExcelIcon;
                }
                &--pdf i {
                  color: $appColorDocumentPdfIcon;
                }
                &--powerpoint i {
                  color: $appColorDocumentPowerPointIcon;
                }
                &--word i {
                  color: $appColorDocumentWordIcon;
                }
              }
              .info {
                width: calc(100% - 100px);
              }
            }

            .info,
            .name,
            .size {
              background: transparent;
              border: none;
              border-radius: 0;
              box-shadow: none;
              box-sizing: border-box;
              list-style: none;
              margin: 0;
              padding: 0;
            }

            .info {
              padding-left: 15px;
              .name {
                width: 100%;
              }
              .size {
                width: 100%;
              }
            }
          }
        }

        &--consent {
          margin-bottom: 0;
          padding-bottom: 0;
          .nav {
            align-items: center;
            box-sizing: border-box;
            display: flex;
            justify-content: flex-start;
            padding-left: 33px;
            width: 100%;
            .separator {
              display: block;
              flex-basis: auto;
              flex-grow: 0;
              text-align: center;
              width: 30px;
            }
            a {
              color: $appColorPrimary4;
              font-size: $appFontSizeM;
              // font-weight: $appFontWeightExtraBold;
              position: relative;
              text-align: center;
              &:hover,
              &:active,
              &:visited {
                color: $appColorPrimary4;
              }
              &:hover {
                text-decoration: underline;
              }
            }
          }
          .form-group {
            background: $appColorGrayScale9;
            border-radius: $appRadiusM;
            box-sizing: border-box;
            padding: 25px;
            width: 100%;
            .invalid-feedback {
              left: 25px;
              right: auto;
              top: -10px;
              &::before {
                left: auto;
                right: auto;
              }
            }
          }
          .c-checkbox {
            &.form-group {
              .form-control {
                padding: 15px 0 !important;
              }
            }
            &__item {
              .custom-control-label {
                font-weight: $appFontWeightNormal;
                padding-left: 32px;
                &::before,
                &::after {
                  margin-top: 1px;
                }
              }
            }
          }
        }
      }
    }
  }

  &--complete,
  &--complete.card {
    #{$this}-form {
      flex-direction: column;
      &__item {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        margin: 0;
        padding: 0;
        width: 100%;
        &--image {
          color: $appColorGrayScale3;
          padding: 20px 0 30px 0;
          i {
            font-size: 110px;
            line-height: 110px;
            margin: 0 auto;
          }
        }
        &--text {
          font-size: $appFontSize2XL;
          justify-content: center;
        }
        &--btns {
          justify-content: center;
          margin: 45px 0;
          .c-btn {
            width: 265px;
          }
        }
      }
    }
  }
}

@media (max-width: 890px) {
  .p-project-setting {
    $this: &;
    &--edit,
    &--edit.card,
    &--edit-option,
    &--edit-option.card,
    &--preview,
    &--preview.card,
    &--complete,
    &--complete.card {
      #{$this}-form {
        &__item {
          &--period {
            display: flex;
            flex-wrap: wrap;
            .from,
            .separator {
              margin-bottom: 25px;
            }
            .from,
            .to {
              max-width: calc(100% - 50px);
              min-width: calc(100% - 50px);
              width: calc(100% - 50px);
            }
          }
          &--published-datetime {
            display: flex;
            flex-wrap: wrap;
            .from,
            .from-time,
            .separator {
              margin-bottom: 25px;
            }
            .to {
              max-width: calc(100% - 50px);
              min-width: calc(100% - 50px);
              width: calc(100% - 50px);
            }
          }
          &--btns {
            .c-btn {
              width: 240px;
            }
          }
        }
      }
    }

    &--preview,
    &--preview.card {
      #{$this}-form {
        &__item {
          &--btns {
            display: flex;
            flex-direction: row-reverse;
            flex-wrap: wrap;
            margin: 20px 0 0 0;
            .c-btn {
              &--conversion {
                margin-top: 15px;
                width: 495px;
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: $appBreakPoint) {
  .p-project-setting {
    $this: &;
    &--edit,
    &--edit.card,
    &--preview,
    &--preview.card,
    &--complete,
    &--complete.card {
      border-radius: $appRadiusM;
      padding: 32px 24px;

      #{$this}-terms {
        padding: 15px;
        margin-bottom: 15px;
        &__item {
          .c-text {
            font-size: $appFontSizeS;
          }
          .c-text + .c-text {
            margin-top: 2px;
          }
        }
      }

      #{$this}-form {
        &__item {
          margin: 0 0 15px 0;
          .form-group {
            label {
              font-size: $appFontSizeS;
              margin-bottom: 5px;
            }
            .c-input {
              font-size: $appFontSizeS;
            }
            &.c-checkbox,
            &.c-radio {
              legend label {
                font-size: $appFontSizeS;
                margin-bottom: 5px;
              }
              .form-control {
                padding: 10px 0 0 0 !important;
              }
              &__item {
                margin: 0 0 5px 0;
                width: 100%;
              }
            }
          }

          .c-checkbox,
          .c-radio {
            .form-control {
              padding: 10px 0 0 0 !important;
            }
            &__item {
              margin: 0 0 5px 0;
              width: 100%;
            }
          }

          &--period {
            .from,
            .separator {
              margin-bottom: 15px;
            }
            .separator {
              max-width: 30px;
              width: 30px;
            }
            .from,
            .to {
              max-width: calc(100% - 30px);
              min-width: calc(100% - 30px);
              width: calc(100% - 30px);
            }
          }

          &--published-datetime {
            .from,
            .from-time,
            .separator {
              margin-bottom: 15px;
            }
            .separator {
              max-width: 30px;
              width: 30px;
            }
            .to {
              max-width: calc(100% - 30px);
              min-width: calc(100% - 30px);
              width: calc(100% - 30px);
            }
          }

          &--price-amount {
            display: flex;
            flex-wrap: wrap;
            .min,
            .separator {
              margin-bottom: 15px;
            }
            .min,
            .max {
              max-width: calc(100% - 30px);
              min-width: calc(100% - 30px);
              width: calc(100% - 30px);
            }
            .unit {
              max-width: 30px;
              width: 30px;
            }
          }

          &--credit-card {
            .caption {
              font-size: $appFontSizeM;
            }
            .c-input-card {
              margin-top: 15px;
            }
          }

          &--purchased-option {
            .list-group {
              display: block;
              padding: 15px;
              &-item {
                display: inline-block;
                font-size: $appFontSizeS;
                margin: 0 0 0 0;
              }
            }
          }

          // 有料オプション選択フィールド
          &--charge-option {
            margin-bottom: 15px;

            .caption {
              font-size: $appFontSizeM;
            }

            #{$this}-option {
              &__header {
                background: transparent;
                border: none;
                border-radius: 0;
                padding: 10px 0 5px 0;
              }
              &__body {
                padding: 15px 15px;
                .desc {
                  font-size: $appFontSizeS;
                  margin-bottom: 6px;
                }
                .price {
                  .free {
                    font-size: $appFontSizeL;
                    line-height: $appFontSizeL;
                    padding-right: 3px;
                  }
                  .number {
                    font-size: $appFontSize2XL;
                    line-height: $appFontSize2XL;
                    padding-right: 3px;
                  }
                  .unit,
                  .tax {
                    font-size: $appFontSizeS;
                    line-height: $appFontSizeS;
                    padding-bottom: 4px;
                  }
                }
              }
              &-list {
                width: 100%;
                &__item {
                  background: none;
                  border: none;
                  box-shadow: none;
                  margin-bottom: 15px;
                  width: 100%;
                }
                .c-checkbox {
                  .form-control {
                    align-items: center;
                    display: flex;
                    flex-direction: column;
                  }
                }
              }
            }
          }

          &--btns {
            display: flex;
            // flex-direction: column-reverse;
            flex-direction: column;
            margin: 32px 0 0 0;
            .c-btn {
              margin: 0;
              width: 100%;
            }
            .c-btn + .c-btn {
              // margin: 0 0 15px 0;
              margin: 15px 0 0 0;
            }
          }
        }
      }
    }

    &--preview,
    &--preview.card {
      #{$this}-form {
        &__item {
          flex-direction: column;
          margin-bottom: 15px;
          padding-bottom: 10px;

          .label {
            font-size: $appFontSizeS;
            min-width: 100%;
            width: 100%;
            // min-width: 160px;
            // width: 160px;
          }
          .value {
            box-sizing: border-box;
            font-size: $appFontSizeS;
            // padding-left: 15px;
            padding-left: 0;
            width: 100%;
          }

          &--charge-option {
            .value {
              .list-group {
                display: block;
                &-item {
                  display: inline-block;
                  margin: 0 0 0 0;
                }
              }
            }
          }

          &--btns {
            display: flex;
            // flex-direction: column-reverse;
            flex-direction: column;
            margin: 32px 0 0 0;
            .c-btn {
              margin: 0;
              width: 100%;
            }
            .c-btn + .c-btn {
              // margin: 0 0;
              margin: 15px 0 0 0;
            }
          }

          &--document {
            .preview {
              flex-direction: column;
              &--image {
                .image {
                  margin-top: 5px;
                  width: 100%;
                }
                .info {
                  padding: 10px 0 0 0;
                  width: 100%;
                }
              }
              &--document {
                .document {
                  margin-top: 5px;
                }
                .info {
                  padding: 10px 0 0 0;
                  width: 100%;
                }
              }
            }
          }

          &--consent {
            margin-bottom: 0;
            padding-bottom: 0;
            .nav {
              padding-left: 33px;
              width: 100%;
              a {
                font-size: $appFontSizeS;
              }
            }
            .form-group {
              padding: 5px 20px;
              .invalid-feedback {
                top: -25px;
              }
            }
            .c-checkbox {
              &.form-group {
                .form-control {
                  padding: 15px 0 !important;
                }
              }
              &__item {
                margin-bottom: 0;
                .custom-control-label {
                  font-size: $appFontSizeS;
                }
              }
            }
          }
        }
      }
    }

    &--complete,
    &--complete.card {
      #{$this}-form {
        &__item {
          &--image {
            i {
              font-size: 80px;
              line-height: 80px;
              margin: 0 auto;
            }
          }
          &--text {
            font-size: $appFontSizeL;
            justify-content: center;
          }
          &--btns {
            // flex-direction: column-reverse;
            flex-direction: column;
            justify-content: center;
            margin: 45px 0 20px 0;
            .c-btn {
              margin: 0;
              width: 100%;
            }
            .c-btn + .c-btn {
              // margin: 0 0;
              margin: 15px 0 0 0;
            }
          }
        }
      }
    }
  }
}
