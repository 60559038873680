.p-company-deal-setting {
  $this: &;
  &--edit,
  &--edit.card,
  &--edit-option,
  &--edit-option.card,
  &--preview,
  &--preview.card,
  &--complete,
  &--complete.card {
    background: $appColorWhite;
    border-radius: 8px;
    box-sizing: border-box;
    margin-top: 20px;
    padding: 45px 40px;
    width: 100%;
    #{$this}__block {
      padding: 0;
      min-height: auto;
    }
    #{$this}-form {
      align-items: flex-start;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;

      &__supply {
        &-text {
          color: $appColorFreeRed;
          font-size: $appFontSizeS;
          font-weight: $appFontWeightExtraBold;
          margin: 8px 0 20px 0;
          p {
            margin: 0;
            padding: 0;
          }
        }
      }

      &__block {
        margin: 0;
        &--image {
          flex-grow: 0;
          max-width: 200px;
          min-width: 200px;
          width: 200px;
        }
        &--base-data {
          box-sizing: border-box;
          flex-grow: 1;
          max-width: calc(100% - 200px);
          min-width: calc(100% - 200px);
          padding: 0 0 0 50px;
          width: calc(100% - 200px);
        }
        &--detail-data {
          margin-top: 25px;
          width: 100%;
        }
      }

      &__item {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        margin: 0 0 25px 0;
        position: relative;
        width: 100%;

        .form-group {
          width: 100%;
        }

        // ------------------------------------
        // 吹き出し位置の調整
        // ------------------------------------
        // X軸位置揃え
        &--establishment-on {
          .form-group {
            .invalid-feedback {
              right: 10px;
            }
          }
        }

        // Y軸位置揃え: 画面幅によって、エラー文言が複数行にな吹き出し対応
        &--email,
        &--delegate-name,
        &--zip {
          .form-group {
            .invalid-feedback {
              bottom: 48px;
              top: auto;
            }
          }
        }

        &--logo {
          .form-group {
            .invalid-feedback {
              bottom: 100%;
              top: auto;
              width: 220px;
              z-index: 1000;
            }
          }
        }

        // ------------------------------------
        // 複数要素が存在するフォームパーツの調整
        // ------------------------------------
        &--establishment-on,
        &--delegate-name {
          align-items: center;
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;
          justify-content: space-between;
          width: 100%;
          .year,
          .month,
          .first,
          .last {
            padding: 0;
          }
          .unit {
            align-items: center;
            box-sizing: border-box;
            max-width: 35px;
            padding: 25px 0 0 0;
            text-align: center;
            width: 35px;
          }
        }

        &--zip {
          align-items: flex-end;
          flex-direction: row;
          justify-content: flex-start;
          .c-btn {
            height: 40px;
            margin: 0 0 0 10px;
            width: 150px;
            &__text {
              font-size: $appFontSizeBase;
            }
          }
          .form-group {
            min-width: 200px;
            width: 50%;
          }
        }

        &--establishment-on {
          justify-content: flex-start;
          .form-group {
            align-items: center;
            display: flex;
            justify-content: flex-start;
            width: auto;
          }
          .year {
            max-width: 150px;
            min-width: 150px;
            width: 150px;
          }
          .month {
            max-width: 100px;
            min-width: 100px;
            width: 100px;
            label {
              height: 21px;
              overflow: hidden;
              visibility: hidden;
            }
          }
        }

        &--delegate-name {
          .first,
          .last {
            max-width: calc(50% - 10px);
            min-width: calc(50% - 10px);
            width: calc(50% - 10px);
          }
        }

        &--mission,
        &--purpose,
        &--vision {
          .c-input {
            height: 210px;
            min-height: 210px;
          }
          .form-group {
            .invalid-feedback {
              top: -40px;
            }
          }
          .label-group {
            display: flex;
            gap: 0 8px;
            justify-content: flex-start;
            .c-tooltip {
              &__container {
                width: 480px;
                &:hover {
                  width: 480px;
                }
              }
            }
          }
        }

        &--overview {
          .c-input {
            height: 210px;
            min-height: 210px;
          }
        }

        .form-group {
          margin-bottom: 0;
          label {
            font-size: $appFontSizeM;
            font-weight: $appFontWeightExtraBold;
            margin-bottom: 5px;
          }
        }

        &--btns {
          flex-direction: row;
          margin: 20px 0 5px 0;
          // margin: 45px 0 5px 0;
          justify-content: center;
          button.c-btn {
            font-size: $appFontSizeL;
          }
          .c-btn {
            margin: 0 7.5px;
            width: 265px;
          }
        }
      }
    }
  }

  &--preview,
  &--preview.card {
    padding-top: 55px;
    flex-wrap: wrap;
    #{$this}-form {
      flex-wrap: wrap;

      &__block {
        &--detail-data {
          margin-top: 50px;
        }
      }

      &__item {
        border-bottom: 1px solid $appColorGrayScale6;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        margin-bottom: 20px;
        padding-bottom: 15px;
        .label {
          flex-basis: auto;
          flex-grow: 0;
          font-size: $appFontSizeM;
          font-weight: $appFontWeightExtraBold;
          padding: 0 0 0 0;
          min-width: 220px;
          width: 220px;
        }
        .value {
          flex-basis: auto;
          flex-grow: 1;
          font-size: $appFontSizeM;
          padding: 0 0 0 0;
          width: calc(100% - 220px);
        }
        &--image {
          border: 8px solid $appColorGrayScale8;
          border-radius: 50%;
          box-sizing: border-box;
          height: 200px;
          margin: 0;
          overflow: hidden;
          padding: 0;
          position: relative;
          width: 200px;
          z-index: 100;
          .p-profile {
            align-items: center;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            height: 100%;
            padding: 0;
            width: 100%;
            .preview {
              width: 100%;
            }
          }
          .no-image {
            align-items: center;
            background: $appColorGrayScale9;
            display: flex;
            flex-direction: column;
            height: 100%;
            justify-content: center;
            transition: background 300ms;
            width: 100%;
            i {
              color: $appColorGrayScale8;
              // font-size: 90px;
              font-size: 104px;
              line-height: 60px;
              margin-top: 10px;
              transition: color 300ms;
            }
            .text {
              color: $appColorGrayScale5;
              font-size: $appFontSizeM;
              font-weight: $appFontWeightExtraBold;
              margin-top: 10px;
              position: absolute;
              transition: color 300ms, opacity 300ms;
              width: 145px;
            }
          }
        }
        &--consent,
        &--btns {
          border-bottom: 0;
        }
        &--btns {
          justify-content: center;
          margin: 45px 0 5px 0;
          padding-bottom: 0;
          .c-btn {
            margin: 0 7.5px;
          }
        }

        &--consent {
          margin-bottom: 0;
          padding-bottom: 0;
          .nav {
            align-items: center;
            box-sizing: border-box;
            display: flex;
            justify-content: flex-start;
            padding-left: 33px;
            width: 100%;
            .separator {
              display: block;
              flex-basis: auto;
              flex-grow: 0;
              text-align: center;
              width: 30px;
            }
            a {
              color: $appColorPrimary4;
              font-size: $appFontSizeM;
              // font-weight: $appFontWeightExtraBold;
              position: relative;
              text-align: center;
              &:hover,
              &:active,
              &:visited {
                color: $appColorPrimary4;
              }
              &:hover {
                text-decoration: underline;
              }
            }
          }
          .form-group {
            background: $appColorGrayScale9;
            border-radius: $appRadiusM;
            box-sizing: border-box;
            padding: 25px;
            width: 100%;
            .invalid-feedback {
              left: 25px;
              right: auto;
              top: -10px;
              &::before {
                left: auto;
                right: auto;
              }
            }
          }
          .c-checkbox {
            .form-control {
              padding: 15px 0 !important;
            }
            &__item {
              .custom-control-label {
                display: flex;
                flex-direction: column;
                font-weight: $appFontWeightNormal;
                padding-left: 32px;
                &::before,
                &::after {
                  margin-top: 1px;
                  top: 2px;
                }
                p {
                  margin-bottom: 0;
                  width: 100%;
                }
              }
            }
          }
        }
      }
    }
  }

  &--complete,
  &--complete.card {
    #{$this}-form {
      flex-direction: column;
      &__item {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        margin: 0;
        padding: 0;
        width: 100%;
        &--image {
          color: $appColorGrayScale3;
          padding: 20px 0 30px 0;
          i {
            font-size: 110px;
            line-height: 110px;
            margin: 0 auto;
          }
        }
        &--text {
          font-size: $appFontSize2XL;
          justify-content: center;
        }
        &--supplement {
          flex-direction: column;
          font-size: $appFontSizeBase;
          margin-top: 36px;
          justify-content: center;
          p {
            margin-bottom: 4px;
            text-align: center;
            width: 100%;
            &.warning {
              color: $appColorFreeRed;
              font-weight: $appFontWeightExtraBold;
            }
          }
        }
        &--btns {
          justify-content: center;
          margin: 45px 0;
          .c-btn {
            width: 265px;
          }
        }
      }
    }
  }
}

@media (max-width: $appBreakPoint) {
  .p-company-deal-setting {
    $this: &;
    &--edit,
    &--edit.card,
    &--edit-option,
    &--edit-option.card,
    &--preview,
    &--preview.card,
    &--complete,
    &--complete.card {
      border-radius: $appRadiusM;
      padding: 32px 24px;
      #{$this}-form {
        flex-direction: column;

        &__supply {
          &-text {
            color: $appColorFreeRed;
            font-size: $appFontSizeS - 1;
            font-weight: $appFontWeightExtraBold;
            margin: 8px 0 5px 0;
          }
        }

        &__block {
          &--image {
            margin-bottom: 10px;
            max-width: 100%;
            min-width: 100%;
            width: 100%;
            #{$this}-form {
              &__item {
                .form-group {
                  display: flex;
                  justify-content: center;
                }
                .c-upload-supply {
                  display: none;
                }
              }
            }
          }
          &--base-data {
            max-width: 100%;
            min-width: 100%;
            padding-left: 0;
            width: 100%;
          }
          &--detail-data {
            margin-top: 0;
          }
        }

        &__item {
          margin: 0 0 15px 0;
          .form-group {
            label {
              font-size: $appFontSizeS;
              margin-bottom: 5px;
            }
            .c-input {
              font-size: $appFontSizeS;
            }
          }

          &--logo {
            .form-group {
              .invalid-feedback {
                width: auto;
              }
            }
          }

          &--zip {
            .c-btn {
              &__text {
                font-size: $appFontSizeS;
              }
            }
          }

          &--establishment-on,
          &--delegate-name {
            align-items: center;
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            justify-content: space-between;
            width: 100%;
            .unit {
              max-width: 30px;
              width: 30px;
            }
          }

          &--establishment-on {
            .year {
              max-width: 100px;
              min-width: 100px;
              width: 100px;
            }
            .month {
              max-width: 80px;
              min-width: 80px;
              width: 80px;
              label {
                height: 21px;
                overflow: hidden;
                visibility: hidden;
              }
            }
          }

          &--delegate-name {
            flex-direction: column;
            flex-wrap: wrap;
            .last {
              margin-bottom: 15px;
            }
            .first,
            .last {
              max-width: 100%;
              min-width: 100%;
              width: 100%;
            }
          }

          &--mission,
          &--purpose,
          &--vision {
            .form-group {
              .invalid-feedback {
                top: -38px;
              }
            }
            .label-group {
              display: flex;
              gap: 0 8px;
              justify-content: flex-start;
              .c-tooltip {
                max-width: 400px;
                &__container {
                  box-sizing: border-box;
                  bottom: auto;
                  height: auto;
                  padding-right: 25px;
                  top: 0;
                  width: calc(100%);
                  &:hover {
                    width: calc(100%);
                  }
                }
              }
            }
          }

          &--purpose {
            .label-group {
              .c-tooltip {
                width: calc(100% - 66px);
              }
            }
          }

          &--mission {
            .label-group {
              .c-tooltip {
                width: calc(100% - 60px);
              }
            }
          }

          &--vision {
            .label-group {
              .c-tooltip {
                width: calc(100% - 54px);
              }
            }
          }

          &--btns {
            display: flex;
            // flex-direction: column-reverse;
            flex-direction: column;
            margin: 17px 0 0 0;
            .c-btn {
              margin: 0;
              width: 100%;
            }
            .c-btn + .c-btn {
              // margin: 0 0 15px 0;
              margin: 15px 0 0 0;
            }
          }
        }
      }
    }

    &--preview,
    &--preview.card {
      #{$this}-form {
        &__block {
          &--image {
            display: flex;
            justify-content: center;
            margin-bottom: 35px;
            width: 100%;
          }
        }

        &__item {
          flex-direction: column;
          margin-bottom: 15px;
          padding-bottom: 10px;

          .label {
            font-size: $appFontSizeS;
            min-width: 100%;
            width: 100%;
            // min-width: 160px;
            // width: 160px;
          }
          .value {
            box-sizing: border-box;
            font-size: $appFontSizeS;
            // padding-left: 15px;
            padding-left: 0;
            width: 100%;
          }

          &--btns {
            display: flex;
            // flex-direction: column-reverse;
            flex-direction: column;
            margin: 32px 0 0 0;
            .c-btn {
              margin: 0;
              width: 100%;
            }
            .c-btn + .c-btn {
              // margin: 0 0 15px 0;
              margin: 15px 0 0 0;
            }
          }

          &--consent {
            margin-bottom: 0;
            padding-bottom: 0;
            .nav {
              padding-left: 33px;
              width: 100%;
              a {
                font-size: $appFontSizeS;
              }
            }
            .form-group {
              padding: 5px 20px;
              .invalid-feedback {
                top: -25px;
              }
            }
            .c-checkbox {
              &.form-group {
                .form-control {
                  padding: 15px 0 !important;
                }
              }
              &__item {
                margin-bottom: 0;
                .custom-control-label {
                  font-size: $appFontSizeS;
                }
              }
            }
          }
        }
      }
    }

    &--complete,
    &--complete.card {
      #{$this}-form {
        &__item {
          &--image {
            i {
              font-size: 80px;
              line-height: 80px;
              margin: 0 auto;
            }
          }
          &--text {
            font-size: $appFontSizeL;
            justify-content: center;
          }
          &--supplement {
            font-size: $appFontSizeS;
            margin-top: 18px;
          }
          &--btns {
            // flex-direction: column-reverse;
            flex-direction: column;
            justify-content: center;
            margin: 45px 0 20px 0;
            .c-btn {
              margin: 0;
              width: 100%;
            }
            .c-btn + .c-btn {
              // margin: 0 0 15px 0;
              margin: 15px 0 0 0;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 450px) {
  .p-company-deal-setting {
    $this: &;
    &--edit,
    &--edit.card {
      #{$this}-form {
        &__item {
          &--zip {
            flex-direction: column;
            .c-btn {
              margin: 15px 0;
              width: 100%;
            }
            .form-group {
              width: 100%;
            }
          }
        }
      }
    }
  }
}
