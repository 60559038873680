.create-works__item {
  border: 1px solid #eee;
  border-bottom: 1px solid #eee;
  position: relative;

  .badge {
    position: absolute;
    right: 10px;
    top: 20px;
  }
}

.create-works__label {
  display: flex;
  align-items: center;
  height: 100%;
  min-height: 50px;
  padding: 0 20px;
  background: #f3f3f3;
  color: black;
}

.create-works__field-wrapper {
  display: flex;
  align-items: center;
  padding: 15px;
  min-height: 75px;
  color: black;

  label {
    font-weight: 400;
    color: #6c757d;
  }

  .label-in-form {
    min-width: max-content;
  }

  .button-in-form {
    margin-left: 20px;

    button {
      min-width: max-content;
    }
  }
}

.attention-option__wrapper {
  .form-check-input {
    position: static;
    margin: 0;
  }
}
