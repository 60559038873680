.l-header {
  $this: &;
  &--dark {
    background: $appColorBlack;
    border-bottom: 1px $appColorBlack solid;
    #{$this}__logo {
      img {
        &.black {
          display: none;
        }
        &.white {
          display: block;
        }
      }
    }
    #{$this}__item {
      background: $appColorBlack;
      border-bottom: 1px $appColorBlack solid;
      &--invitation,
      &--notification {
        .col {
          i {
            color: $appColorWhite;
          }
          &:hover {
            background: $appColorGrayScale10;
            i {
              color: $appColorWhite;
            }
          }
        }
      }
    }
    #{$this}-toggle {
      &-list {
        &__item {
          .c-text {
            color: $appColorWhite;
            &.is-active {
              color: $appColorWhite;
            }
          }
          .c-switch {
            .custom-control-input:focus:not(:checked)
              ~ .custom-control-label::before {
              border-color: $appColorGrayScale2;
            }
            .custom-control-input:checked ~ .custom-control-label {
              &::before {
                background: $appColorPrimary1;
                border-color: $appColorPrimary1;
              }
              &::after {
                background: $appColorWhite;
                border-color: $appColorWhite;
              }
            }
          }
        }
      }
    }
    #{$this}-search {
      .c-btn {
        &--enter {
          background: $appColorPrimary1;
          border-color: $appColorPrimary1;
          &:hover {
            background: $appColorPrimary3 !important;
            border-color: $appColorPrimary3 !important;
          }
        }
      }
      .c-input {
        border: 1px $appColorGrayScale12 solid !important;
      }
    }
    #{$this}-menu {
      &-list {
        &__item {
          .col {
            border-left-color: rgba(255, 255, 255, 0.1);
            &:hover,
            &:active,
            &:visited {
              background: $appColorGrayScale10;
              i {
                color: $appColorWhite;
              }
            }
            i {
              color: $appColorWhite;
            }
          }
        }
      }
    }
  }
}

.l-sidebar {
  $this: &;
  &--dark {
    background: $appColorGrayScale2;
    border-right: 1px $appColorGrayScale2 solid;
    #{$this} {
      &-list {
        &__item-group {
          &::before {
            border-top: 1px solid rgba(255, 255, 255, 0.1);
            display: block !important;
          }
          &.is-separate {
            &::before {
              border-top: 1px solid rgba(255, 255, 255, 0.3);
            }
          }
        }
        &__item {
          &:hover {
            background: rgba(
              red($appColorGrayScale3),
              green($appColorGrayScale3),
              blue($appColorGrayScale3),
              0.3
            );
          }
          .col {
            i,
            .c-text {
              color: $appColorWhite;
            }
          }
          &.is-active {
            background: rgba(
              red($appColorGrayScale3),
              green($appColorGrayScale3),
              blue($appColorGrayScale3),
              0.4
            );
            &:hover {
              background: rgba(
                red($appColorGrayScale3),
                green($appColorGrayScale3),
                blue($appColorGrayScale3),
                0.4
              );
            }
          }
          &--child {
            &:hover {
              background: $appColorGrayScale3;
            }
            &.is-active {
              background: $appColorGrayScale3;
            }
          }
        }
      }
    }
  }
}

.l-footer {
  $this: &;
  &--dark {
    background: $appColorBlack;
    border-top: 1px $appColorBlack solid;
    .list-group {
      &-item {
        a {
          color: $appColorWhite !important;
          &:hover,
          &:active,
          &:visited {
            color: $appColorWhite !important;
          }
        }
      }
    }
    #{$this} {
      &__copyright {
        color: $appColorWhite !important;
      }
    }
  }
}

.c-profile-dropdown {
  $this: &;
  &--dark {
    #{$this} {
      &__label {
        color: $appColorWhite;
      }
      &__separator {
        background: rgba(255, 255, 255, 0.1);
      }
      &__image {
        .col {
          background: $appColorWhite;
          i {
            color: $appColorGrayScale6;
          }
        }
        &:hover {
          background: $appColorGrayScale10;
          .col {
            background: $appColorWhite;
            i {
              color: $appColorGrayScale6 !important;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1180px) {
  .c-profile-dropdown {
    $this: &;
    &--dark {
      #{$this} {
        &__label {
          color: $appColorWhite;
        }
      }
    }
  }
}

@media (max-width: $appBreakPoint) {
  .l-sidebar {
    $this: &;
    &--dark {
      background: rgba(0, 0, 0, 0.8);
      border-right: none;
      #{$this} {
        &-list {
          &__item-group {
            &::before {
              border-top: 1px solid rgba(255, 255, 255, 0.1);
              display: block !important;
            }
          }
          &__item {
            &--child {
              &::before {
                border-top: 1px solid rgba(255, 255, 255, 0.1);
                display: block !important;
              }
            }
          }
        }
      }
      #{$this}-list__item-group + #{$this}-list__item-group,
      #{$this}-list__item + #{$this}-list__item {
        &.is-separate {
          &::before {
            border-top: 1px solid rgba(255, 255, 255, 0.6);
          }
        }
      }
    }
  }
  .l-footer {
    $this: &;
    &--dark {
      .list-group {
        &-item {
          border-color: $appColorGrayScale10 !important;
        }
      }
    }
  }
}
