.p-search {
  $this: &;
  margin-bottom: 3px;

  &-head {
    align-items: flex-start;
    display: flex;
    flex-direction: rows;
    justify-content: space-between;
    width: 100%;
    &-link-list {
      display: flex;
      gap: 12px;
      justify-content: flex-end;
      margin-top: -2px;
      padding-bottom: 0;
      width: calc(100% - 465px);
      &__item {
        // width: calc((100% - 24px) / 3);
        width: 100%;
        max-width: 240px;
      }
    }
  }

  &-list {
    align-items: stretch;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    width: calc(100% + 12px);
    &__item {
      background: $appColorWhite;
      border-radius: $appRadiusM;
      box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.075);
      max-width: 260px;
      margin: 0 12px 12px 0;
      overflow: hidden;
      position: relative;
      width: 260px;
      a {
        color: $appColorBlack;
        text-decoration: none;
        &:hover,
        &:active,
        &:visited {
          color: $appColorBlack;
          text-decoration: none;
        }
      }
    }
  }
  // お気に入りボタン
  .c-btn {
    &--favorite {
      border-radius: 50%;
      height: 40px;
      margin: 0;
      position: absolute;
      right: 12.5px;
      top: 12.5px;
      width: 40px;
      z-index: 1100;
      transition: transform 300ms;
      i {
        color: #fff;
        font-size: 24px;
        transition: color 300ms;
      }
      &:hover {
        transform: scale(1.1);
      }
    }
    &--checkbox {
      border-radius: 50%;
      border-radius: $appRadiusM;
      height: 30px;
      margin: 0;
      position: absolute;
      right: 12.5px;
      top: 12.5px;
      width: 30px;
      z-index: 1100;
      i {
        font-size: 16px;
      }
    }
  }
  &-item {
    border-radius: $appRadiusM;
    cursor: pointer;
    height: 100%;
    max-width: 100%;
    transition: height 300ms;
    width: 100%;
    z-index: 1000;
    &.card {
      box-shadow: none;
    }
    &::before {
      border: 4px solid #333;
      box-sizing: border-box;
      border-radius: $appRadiusM;
      content: '';
      display: block;
      height: 100%;
      opacity: 0;
      position: absolute;
      transition: border 150ms, opacity 300ms;
      width: 100%;
      z-index: 1000;
    }
    &:hover {
      &::before {
        border: 6px solid #333;
        opacity: 0.3;
      }
    }
    .c-badge {
      border-radius: 0;
      font-size: $appFontSizeS;
      height: 30px;
      &-list {
        gap: 0 1px;
        left: 0;
        position: absolute;
        top: 0;
        z-index: 1001;
        &--category {
          position: relative;
          .c-badge {
            border-radius: $appRadiusM;
            padding: 0 10px;
            width: auto;
            &-list {
              &__item {
                margin: 0 8px 8px 0;
              }
            }
          }
        }
      }
    }
    &-header {
      border: none;
      box-sizing: border-box;
      padding: 20px 15px 0 15px;
      position: relative;
      &::after {
        background: $appColorGrayScale6;
        content: '';
        display: block;
        height: 1px;
        margin-top: 20px;
        width: 100%;
      }
    }
    &-body {
      box-sizing: border-box;
      padding: 15px 15px;
      &__item {
        align-items: flex-end;
        display: flex;
        flex-direction: row;
        margin: 0;
        &:last-child {
          .col {
            margin-bottom: 0;
          }
        }
        .col {
          flex-basis: auto;
          flex-grow: 0;
          margin: 0 0 5px 0;
          padding: 0;
          width: auto;
          &.label {
            color: $appColorGrayScale11;
            font-size: $appFontSizeXS;
            font-weight: $appFontWeightExtraBold;
            min-width: 50px;
            width: 50px;
          }
          &.value {
            flex-grow: 1;
            font-size: $appFontSizeM;
          }
        }
      }
    }
    &-footer {
      border: none;
      box-sizing: border-box;
      padding: 0px 15px 4px 15px;
      &::before {
        background: $appColorGrayScale6;
        content: '';
        display: block;
        height: 1px;
        margin-bottom: 12px;
        width: 100%;
      }
    }
  }
  &--project {
    #{$this}-item {
      &.is-sticker {
        &:hover {
          &::before {
            border: 6px solid $appColorFreeRed;
            opacity: 0.3;
          }
        }
      }
      .c-badge {
        &--distribution {
          border-radius: $appRadiusM;
          font-size: $appFontSizeS;
          position: absolute;
          right: 12.5px;
          top: -15px;
          width: 100px;
        }
        &--casual-interview {
          border-radius: $appRadiusM;
          font-size: $appFontSizeS;
          position: absolute;
          right: 118.5px;
          top: -15px;
          width: 100px;
        }
      }
      &-image {
        align-items: center;
        background-position: center center;
        background-size: cover;
        border-radius: $appRadiusM $appRadiusM 0 0;
        display: flex;
        margin: 0;
        min-height: 100px;
        overflow: hidden;
        width: 100%;
      }
      &-header {
        padding-top: 35px;
        &__title {
          .c-text {
            @include ellipsis;
            font-size: $appFontSizeL;
            font-weight: $appFontWeightExtraBold;
            position: relative;
            width: 100%;
          }
          .number {
            color: $appColorGrayScale11;
            font-size: $appFontSizeS;
            // font-weight: $appFontWeightExtraBold;
            margin: -3px 0 3px 0;
          }
        }
      }
      &-body {
        &__item {
          align-items: flex-start;
          flex-wrap: nowrap;
          &--owner {
            .label {
              min-width: 110px;
              width: 110px;
            }
          }
          .value {
            .row {
              margin: 0;
            }
          }
        }
      }
    }
  }
  &--joined-project {
    #{$this}-item {
      .row {
        margin: 0;
      }
      .c-badge {
        &--distribution {
          border-radius: $appRadiusM;
          font-size: $appFontSizeS;
          position: absolute;
          right: 12.5px;
          top: -15px;
          width: 100px;
        }
        &--project-publish-scope {
          border-radius: $appRadiusS;
          font-size: $appFontSizeXS;
          width: 70px;
          &-anyone {
            width: 60px;
          }
          &-regular {
            width: 60px;
          }
          &-alumni {
            width: 70px;
          }
          &-collaborator {
            width: 92px;
          }
        }
        &--casual-interview {
          border-radius: $appRadiusM;
          font-size: $appFontSizeS;
          position: absolute;
          right: 118.5px;
          top: -15px;
          width: 100px;
        }
      }
      &-image {
        align-items: center;
        background-position: center center;
        background-size: cover;
        border-radius: $appRadiusM $appRadiusM 0 0;
        display: flex;
        margin: 0;
        min-height: 100px;
        overflow: hidden;
        width: 100%;
      }
      &-header {
        padding-top: 35px;
        &__title {
          .c-text {
            @include ellipsis;
            font-size: $appFontSizeL;
            font-weight: $appFontWeightExtraBold;
            position: relative;
            width: 100%;
          }
          .number {
            color: $appColorGrayScale11;
            font-size: $appFontSizeS;
            // font-weight: $appFontWeightExtraBold;
            margin: -3px 0 3px 0;
          }
        }
      }
      &-body {
        &__item {
          align-items: flex-start;
          flex-wrap: nowrap;
          &--id {
            .label {
              min-width: 30px;
              width: 30px;
            }
          }
          &--expired-on {
            .label {
              min-width: 70px;
              width: 70px;
            }
          }
          &--organization {
            .label {
              min-width: 110px;
              width: 110px;
            }
          }
          &--price {
            .label {
              min-width: 50px;
              width: 50px;
            }
          }
          &--status {
            .label {
              min-width: 60px;
              width: 60px;
            }
          }
          .value {
            .row {
              margin: 0;
            }
            p {
              display: block;
              margin: 0;
              padding: 0;
            }
          }
        }
      }
      &-footer {
        padding-bottom: 12px;
        position: relative;

        &--scope {
          align-items: center;
          display: flex;
          flex-direction: column;
          justify-content: center;
          gap: 4px;
          width: 100%;
          &::before {
            display: none;
          }
        }

        &__item {
          &-group {
            align-items: center;
            display: flex;
            justify-content: flex-start;
            flex-wrap: nowrap;
            gap: 4px;
            width: 100%;
          }
          &--control {
            align-items: center;
            display: flex;
            gap: 8px;
            justify-content: flex-end;
            .c-menu-icon {
              background: $appColorGrayScale8;
              box-sizing: border-box;
              border-radius: $appRadiusS;
              height: 36px;
              position: relative;
              width: 48px;
              z-index: 1200;
              i {
                font-size: $appFontSizeL;
              }
              &__text {
                &::after {
                  right: 30px;
                }
              }
            }
          }
        }
      }
    }
  }
  &--joined-project-recruit {
    #{$this}-item {
      cursor: default;
      position: relative;

      &::before {
        display: none;
      }

      &.is-not-important {
        #{$this}-item {
          &-header,
          &-body {
            position: relative;
            &::before {
              background: rgba(255, 255, 255, 0.6);
              content: '';
              left: 0;
              position: absolute;
              top: 0;
              height: 100%;
              width: 100%;
              z-index: 1;
            }
          }
        }
      }

      &-image {
        align-items: center;
        background: $appColorWhite;
        box-sizing: border-box;
        border: 4px solid $appColorGrayScale8;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        height: 80px;
        margin: 0 0 0 -5px;
        overflow: hidden;
        width: 80px;
        img {
          width: 100%;
        }
        i {
          color: $appColorGrayScale8;
          font-size: 60px;
        }
      }
      &-header {
        align-items: center;
        display: flex;
        flex-wrap: wrap;
        padding-top: 15px;
        position: relative;
        &::after {
          margin-top: 15px;
        }
        &__image {
          flex-grow: 0;
          width: 80px;
          img {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
          }
          &:hover {
            opacity: 0.8;
          }
        }
        &__data {
          box-sizing: border-box;
          flex-grow: 1;
          margin: 0 0 0 0;
          max-width: calc(100% - 80px);
          padding: 0 0 0 5px;
          width: auto;
        }
        &__item {
          margin: 0;
          width: 100%;
          .col {
            padding: 0;
            &.value {
              font-size: $appFontSizeM;
            }
          }
          &--name {
            @include ellipsisMultiple(3);
            a {
              color: var(--app-color-black) !important;
              &:hover {
                color: var(--app-color-primary-4) !important;
                text-decoration: underline !important;
              }
              &:active,
              &:visited {
                text-decoration: none !important;
              }
            }
          }
        }
      }
      &-body {
        &__item {
          .label {
            width: 60px;
          }
        }
      }
      &-footer {
        padding-bottom: 12px;
        position: relative;
        &__item {
          &--control {
            align-items: center;
            display: flex;
            gap: 8px;
            justify-content: flex-end;
            .c-menu-icon {
              background: $appColorGrayScale8;
              box-sizing: border-box;
              border-radius: $appRadiusS;
              height: 36px;
              position: relative;
              width: 48px;
              z-index: 1200;
              i {
                font-size: $appFontSizeL;
              }
              &--edit {
                i {
                  margin-top: -4px;
                  transform: rotate(240deg);
                }
              }
              &--message {
                i {
                  margin-top: 2%;
                  font-size: $appFontSizeXL;
                }
              }
              &__text {
                &::after {
                  right: 30px;
                }
              }
            }
          }
        }
      }
    }
  }
  &--user {
    #{$this}-item {
      .c-badge {
        &--status {
          width: 90px;
        }
      }
      &-image {
        align-items: center;
        background: $appColorWhite;
        box-sizing: border-box;
        border: 4px solid $appColorGrayScale8;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        height: 80px;
        margin: 25px 0 0 -5px;
        overflow: hidden;
        width: 80px;
        img {
          width: 100%;
        }
        i {
          color: $appColorGrayScale8;
          font-size: 60px;
        }
      }
      &-header {
        align-items: flex-start;
        display: flex;
        flex-wrap: wrap;
        &::after {
          margin-top: 15px;
        }
        &__image {
          flex-grow: 0;
          width: 80px;
          img {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
          }
        }
        &__data {
          box-sizing: border-box;
          flex-grow: 1;
          margin: 40px 0 0 0;
          max-width: calc(100% - 80px);
          padding: 4px 0 0 5px;
          width: auto;
        }
        &__item {
          margin: 0;
          width: 100%;
          .col {
            padding: 0;
            &.value {
              font-size: $appFontSizeM;
            }
          }
          &--name {
            .col {
              &.value {
                @include ellipsis;
                &--within-1-day,
                &--within-3-day,
                &--within-1-week,
                &--1-month-ago {
                  margin-top: 12px;
                }
              }
            }
          }
          &--last-login {
            .col {
              padding: 0;
              &.value {
                align-items: center;
                color: $appColorGrayScale11;
                display: flex;
                font-size: $appFontSizeXS;
                font-weight: $appFontWeightExtraBold;
                justify-content: flex-start;
                // margin-bottom: 5px !important;
                margin-bottom: 0 !important;
                &::before {
                  background: $appColorBlack;
                  border-radius: 50%;
                  content: '';
                  height: 10px;
                  margin-right: 5px;
                  width: 10px;
                }
                &--online {
                  &::before {
                    background: $appColorSecondary1;
                  }
                }
                &--within-1-day {
                  &::before {
                    background: $appColorFreeYellow;
                  }
                }
                &--within-3-day,
                &--within-1-week,
                &--1-month-ago {
                  &::before {
                    background: $appColorGrayScale12;
                  }
                }
                // 一覧では、最終ログインがオンライン以外の場合は、最終ログイン時間を表示しない
                &--within-1-day,
                &--within-3-day,
                &--within-1-week,
                &--1-month-ago {
                  display: none;
                  overflow: hidden;
                  visibility: hidden;
                }
              }
            }
          }
        }
      }
      &-body {
        &__item {
          &--last-career {
            align-items: flex-start;
            .col {
              p {
                display: inline;
                margin-bottom: 0;
                &:nth-child(2) {
                  &::before {
                    content: '/';
                    padding: 0 5px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  &--company {
    #{$this}-list {
      &__item {
        overflow: visible;
      }
    }
    #{$this}-item {
      &-image {
        align-items: center;
        background: $appColorWhite;
        box-sizing: border-box;
        border: 4px solid $appColorGrayScale8;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        height: 80px;
        margin: 15px 0 0 -5px;
        overflow: hidden;
        width: 80px;
        img {
          width: 100%;
        }
        i {
          color: $appColorGrayScale8;
          font-size: 48px;
        }
      }
      &-header {
        align-items: flex-start;
        display: flex;
        flex-wrap: wrap;
        &::after {
          margin-top: 15px;
        }
        &__image {
          flex-grow: 0;
          width: 80px;
          img {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
          }
        }
        &__data {
          box-sizing: border-box;
          flex-grow: 1;
          margin: 18px 0 0 0;
          max-width: calc(100% - 80px);
          padding: 4px 0 0 5px;
          width: auto;
        }
        &__item {
          margin: 0;
          width: 100%;
          .col {
            padding: 0;
            &.value {
              font-size: $appFontSizeM;
            }
          }
          &--name {
            .col {
              &.value {
                margin-top: 22px;
                overflow: visible;
                position: relative;
                z-index: 1100;
                .full-name {
                  background: $appColorGrayScale2;
                  border-radius: 4px;
                  box-sizing: border-box;
                  color: $appColorWhite;
                  font-weight: $appFontWeightBold;
                  height: 0;
                  left: -100px;
                  opacity: 0;
                  overflow: hidden;
                  padding: 0;
                  position: absolute;
                  top: 20px;
                  transition: opacity 300ms;
                  width: 0;
                  &::after {
                    border: 6px solid transparent;
                    border-bottom: 6px solid $appColorGrayScale2;
                    content: '';
                    right: 100px;
                    position: absolute;
                    top: -10px;
                  }
                }
                &:hover {
                  .full-name {
                    height: auto;
                    opacity: 0.98;
                    overflow: visible;
                    padding: 8px;
                    width: 260px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  &--joined-company {
    #{$this}-list {
      &__item {
        overflow: visible;
      }
    }
    #{$this}-item {
      position: relative;
      .c-badge-list {
        border-radius: 4px 0 0 0;
        overflow: hidden;
      }

      &-image {
        align-items: center;
        background: $appColorWhite;
        box-sizing: border-box;
        border: 4px solid $appColorGrayScale8;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        height: 80px;
        margin: 0 0 0 -5px;
        overflow: hidden;
        width: 80px;
        img {
          width: 100%;
        }
        i {
          color: $appColorGrayScale8;
          font-size: 60px;
        }
      }
      &-header {
        align-items: center;
        display: flex;
        flex-wrap: wrap;
        padding-top: 45px;
        position: relative;
        &::after {
          margin-top: 15px;
        }
        &__image {
          flex-grow: 0;
          width: 80px;
          img {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
          }
        }
        &__data {
          box-sizing: border-box;
          flex-grow: 1;
          margin: 0 0 0 0;
          max-width: calc(100% - 80px);
          padding: 0 0 0 5px;
          width: auto;
        }
        &__item {
          margin: 0;
          width: 100%;
          .col {
            padding: 0;
            &.value {
              font-size: $appFontSizeM;
            }
          }
          &--name {
            // @include ellipsisMultiple(3);
            .col {
              &.value {
                overflow: visible;
                position: relative;
                z-index: 1300;
                .full-name {
                  background: $appColorGrayScale2;
                  border-radius: 4px;
                  box-sizing: border-box;
                  color: $appColorWhite;
                  font-weight: $appFontWeightBold;
                  height: 0;
                  left: -100px;
                  opacity: 0;
                  overflow: hidden;
                  padding: 0;
                  position: absolute;
                  top: 20px;
                  transition: opacity 300ms;
                  width: 0;
                  &::after {
                    border: 6px solid transparent;
                    border-bottom: 6px solid $appColorGrayScale2;
                    content: '';
                    right: 100px;
                    position: absolute;
                    top: -10px;
                  }
                }
                &:hover {
                  .full-name {
                    height: auto;
                    opacity: 0.98;
                    overflow: visible;
                    padding: 8px;
                    width: 260px;
                  }
                }
              }
            }
          }
        }
      }
      &-body {
        &__item {
          .label {
            width: 60px;
          }
          &--profile {
            .label {
              width: 80px;
            }
          }
          &--sticker {
            padding-bottom: 8px;
            width: 100%;
            .value {
              width: 100%;
            }
          }
        }
      }
      &-footer {
        padding-bottom: 12px;
        position: relative;
        &__item {
          &--control {
            align-items: center;
            display: flex;
            gap: 8px;
            justify-content: flex-end;
            .c-menu-icon {
              background: $appColorGrayScale8;
              box-sizing: border-box;
              border-radius: $appRadiusS;
              height: 36px;
              position: relative;
              width: 48px;
              z-index: 1200;
              i {
                font-size: $appFontSizeL;
              }
              &__text {
                &::after {
                  right: 30px;
                }
              }
            }
          }
        }
      }
    }
  }
  &--joined-company-member {
    #{$this}-item {
      position: relative;
      &-image {
        align-items: center;
        background: $appColorWhite;
        box-sizing: border-box;
        border: 4px solid $appColorGrayScale8;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        height: 80px;
        margin: 0 0 0 -5px;
        overflow: hidden;
        width: 80px;
        img {
          width: 100%;
        }
        i {
          color: $appColorGrayScale8;
          font-size: 60px;
        }
      }
      &-header {
        align-items: center;
        display: flex;
        flex-wrap: wrap;
        padding-top: 45px;
        position: relative;
        &::after {
          margin-top: 15px;
        }
        &__image {
          flex-grow: 0;
          width: 80px;
          img {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
          }
        }
        &__data {
          box-sizing: border-box;
          flex-grow: 1;
          margin: 0 0 0 0;
          max-width: calc(100% - 80px);
          padding: 0 0 0 5px;
          width: auto;
        }
        &__item {
          margin: 0;
          width: 100%;
          .col {
            padding: 0;
            &.value {
              font-size: $appFontSizeM;
            }
          }
          &--name {
            @include ellipsisMultiple(3);
          }
        }
      }
      &-body {
        &__item {
          .label {
            width: 60px;
          }
        }
      }
      &-footer {
        padding-bottom: 12px;
        position: relative;
        &__item {
          &--control {
            align-items: center;
            display: flex;
            gap: 8px;
            justify-content: flex-end;
            .c-menu-icon {
              background: $appColorGrayScale8;
              box-sizing: border-box;
              border-radius: $appRadiusS;
              height: 36px;
              position: relative;
              width: 48px;
              z-index: 1200;
              i {
                font-size: $appFontSizeL;
              }
              &__text {
                &::after {
                  right: 30px;
                }
              }
            }
          }
        }
      }
    }
  }
  &--joined-company-deal {
    #{$this}-item {
      position: relative;
      &-image {
        align-items: center;
        background: $appColorWhite;
        box-sizing: border-box;
        border: none;
        border-radius: $appRadiusS;
        display: flex;
        justify-content: center;
        height: 80px;
        margin: 0 0 0 -5px;
        overflow: hidden;
        width: 80px;
        &--no-image {
          background: $appColorGrayScale9;
        }
        img {
          width: 100%;
        }
        i {
          color: $appColorGrayScale8;
          font-size: 42px;
        }
      }
      &-header {
        align-items: center;
        display: flex;
        flex-wrap: wrap;
        padding-top: 18px;
        position: relative;
        &::after {
          margin-top: 15px;
        }
        &__image {
          flex-grow: 0;
          width: 80px;
          img {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
          }
        }
        &__data {
          box-sizing: border-box;
          flex-grow: 1;
          margin: 0 0 0 0;
          max-width: calc(100% - 80px);
          padding: 0 0 0 5px;
          width: auto;
        }
        &__item {
          margin: 0;
          width: 100%;
          .col {
            padding: 0;
            &.value {
              font-size: $appFontSizeM;
            }
          }
          &--name {
            @include ellipsisMultiple(3);
          }
        }
      }
      &-body {
        &__item {
          .label {
            width: 60px;
          }
        }
      }
      &-footer {
        padding-bottom: 12px;
        position: relative;
        &__item {
          &--control {
            align-items: center;
            display: flex;
            gap: 8px;
            justify-content: flex-end;
            .c-menu-icon {
              background: $appColorGrayScale8;
              box-sizing: border-box;
              border-radius: $appRadiusS;
              height: 36px;
              position: relative;
              width: 48px;
              z-index: 1200;
              i {
                font-size: $appFontSizeL;
              }
              &__text {
                &::after {
                  right: 30px;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: $appBreakPoint) {
  .p-search {
    $this: &;

    &-head {
      align-items: flex-start;
      flex-direction: column;
      justify-content: space-between;
      padding-bottom: 14px;
      width: 100%;
      &-link-list {
        margin-top: 0;
        padding-bottom: 0;
        width: 100%;
        &__item {
          max-width: 100%;
        }
      }
    }

    &-list {
      justify-content: space-between;
      width: 100%;
      &__item {
        margin-right: 12px;
        max-width: 100%;
        width: calc(50% - 6px);
        &:nth-child(2n) {
          margin-right: 0;
        }
      }
    }

    .c-btn {
      &--favorite {
        &:hover {
          transform: scale(1);
        }
      }
    }

    &-item {
      .c-badge {
        font-size: $appFontSizeXS;
      }
      &-header {
        padding: 15px 15px 0 15px;
        &::after {
          margin-top: 0;
        }
      }
      &-body {
        padding: 15px 15px;
        &__item {
          .col {
            margin-bottom: 0;
            &.label {
              font-size: $appFontSizeXS;
            }
            &.value {
              font-size: $appFontSizeS;
            }
          }
        }
      }
    }
    &--project {
      #{$this}-item {
        .c-badge {
          &--distribution {
            font-size: $appFontSizeXS;
          }
        }
        &-image {
          min-height: 125px;
        }
        &-header {
          &__title {
            margin-bottom: 15px;
            .c-text {
              font-size: $appFontSizeM;
            }
          }
        }
      }
    }
    &--user,
    &--joined-company-member {
      #{$this}-item {
        .c-badge {
          &--distribution {
            font-size: $appFontSizeXS;
          }
        }
        &-header {
          &__item {
            .col {
              &.value {
                font-size: $appFontSizeS;
              }
            }
            &--last-login {
              .col {
                &.value {
                  font-size: $appFontSizeXS;
                  margin-bottom: 0 !important;
                  // margin-bottom: 2.5px !important;
                }
              }
            }
          }
          &__data {
            padding: 6px 0 0 5px;
            width: auto;
          }
        }
      }
    }
    &--joined-company-deal {
      #{$this}-item {
        &-header {
          &__item {
            .col {
              &.value {
                font-size: $appFontSizeS;
              }
            }
          }
          &__data {
            padding: 6px 0 0 5px;
            width: auto;
          }
        }
      }
    }
    &--company {
      #{$this}-item {
        &-header {
          &__item {
            .col {
              &.value {
                font-size: $appFontSizeS;
              }
            }
            &--name {
              .col {
                &.value {
                  .full-name {
                    display: none;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 572px) {
  .p-search {
    $this: &;
    &-list {
      width: 100%;
      &__item {
        max-width: 100%;
        margin-right: 0;
        width: 100%;
      }
    }
    &--project {
      #{$this}-item {
        &-image {
          min-height: 150px;
        }
      }
    }
  }
}
