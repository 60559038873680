.bg-none {
  background: none;

  &:active {
    border: none;
    box-shadow: none;
    background: none;
  }

  &:hover {
    border: none;
    box-shadow: none;
    background: none;
  }

  &:focus {
    border: none;
    box-shadow: none;
    background: none;
  }
}

.text-black {
  color: #000000;

  &:active {
    color: #000000;
  }

  &:hover {
    color: #000000;
  }
}

.primary-color {
  color: #008bcc;

  &:active {
    color: #008bcc;
  }

  &:hover {
    color: #008bcc;
  }
}

.secondary-color {
  color: #989898;

  &:active {
    color: #989898;
  }

  &:hover {
    color: #989898;
  }
}

.line-height-1 {
  line-height: 1;
}

.fs-10 {
  font-size: 10px;
}

.fs-12 {
  font-size: 12px;
}

.fs-16 {
  font-size: 16px;
}

.fs-20 {
  font-size: 20px;
}

.fs-24 {
  font-size: 24px;
}

.warm-gray {
  background: #f3f2ef;
}

.text-underline {
  text-decoration: underline;
}

.border-hidden {
  border: none;
  box-shadow: none;
  &:active {
    border: none;
    box-shadow: none;
  }

  &:hover {
    border: none;
    box-shadow: none;
  }

  &:focus {
    border: none;
    box-shadow: none;
  }
}

.border-none {
  border: none;
}

.pre-line-text {
  white-space: pre-line;
}

.circle-btn {
  border-radius: 50%;
}

.circle-border-btn {
  border-radius: 35px;
}

.cancel-button {
  margin: 0.2rem 0;
  padding: 5px 12px;
  font-size: 1.2rem;
  border: 1px solid;
  border-radius: 35px;
  text-align: left;
  font-weight: 600;
}

.custom-primary-button {
  margin: 0.2rem 0.6rem;
  padding: 5px 12px;
  font-size: 1.2rem;
  border: 1px solid;
  border-radius: 35px;
  text-align: left;
  background: #008bcc;
  font-Weight: 600;

  &:active, &:focus, &:visited, &:hover {
    background: #008bcc !important;
    border: 2px solid #008bcc !important;
  }
}

.py-1px {
  padding-top: 1px;
  padding-bottom: 1px;
}

.modal-content {
  .close {
    font-size: 26px;
  }

  .header-post-button {
    i {
      font-size: 26px;
    }
  }

  .post-user {
    margin-top: -4px;
    font-size: 18px;
    text-align: left;
  }

  .post-type {
    float: left;
    font-size: 14px;
    padding-left: 6px;
    margin-top: -10px;
  }
}

.ReactModal__Overlay {
  z-index: 99999!important;
}

.grid-container {
  padding: 0!important;

  .height-one {
    padding-top: 65%!important;
  }
}

.border-post {
  border: 1px #e2e1df solid;
}

.rdw-suggestion-dropdown {
  z-index: 999999;
}

.tag-title {
  display: flex;
  align-items: center;
  height: 100%;
  margin-left: -16px;
}

.react-post {
  .nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
    color: #495057;
    background-color: #fff;
    border: none !important;
    border-bottom: 3px solid #057642 !important;
  }
}

@media screen and (min-width: 1919px) {
  .col-lg-2-custom {
    flex: 0 0 8% !important;
    max-width: 8% !important;
  }
}

@media screen and (min-width: 1439px) and (max-width: 1918px) {
  .col-lg-2-custom {
    flex: 0 0 8% !important;
    max-width: 8% !important;
  }
}

@media screen and (min-width: 1023px) and (max-width: 1438px) {
  .col-lg-2-custom {
    flex: 0 0 11.666667% !important;
    max-width: 11.666667% !important;
  }

  .create-post-button-lg {
    flex: 0 0 79.333333%;
    max-width: 79.333333%;
  }
}

@media screen and (min-width: 767px) and (max-width: 1022px) {
  .col-lg-2-custom {
    flex: 0 0 9.666667% !important;
    max-width: 9.666667% !important;
  }
}
