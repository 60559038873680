.p-pricing {
  $this: &;

  &,
  &.card {
    align-items: center;
    background: $appColorWhite;
    border: none;
    border-radius: $appRadiusXL;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    padding: 45px 40px 25px 40px;
    // max-width: 1980px;
    width: 100%;
  }

  &--no-login {
    margin: 0 auto;
    &.card {
      margin: 0 auto;
    }
  }

  .c-title {
    align-items: center;
    border-bottom: 0;
    display: flex;
    justify-content: flex-start;
    margin: 0 0 30px 0;
    padding: 0;
    &__text {
      display: flex;
      color: $appColorBlack;
      font-size: $appFontSize3XL;
      font-weight: $appFontWeightExtraBold;
      margin-right: 20px;
      width: auto;
      &--badge {
        align-items: center;
        background: $appColorFreeRed;
        border: 50px;
        border-radius: 4px;
        // border-radius: 50px;
        box-sizing: border-box;
        color: $appColorWhite;
        display: flex;
        font-size: $appFontSizeBase;
        margin-right: 0;
        justify-content: center;
        padding: 8px 10px;
        width: auto;
      }
    }
  }

  &-warning {
    background: $appColorGrayScale9;
    font-size: $appFontSizeBase;
    margin-bottom: 30px;
    padding: 25px;
    width: 100%;
  }

  &-subscription-plan {
    $plan: &;
    width: 100%;

    &-list {
      align-items: stretch;
      background: transparent;
      border: none;
      box-sizing: border-box;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      // flex-wrap: nowrap;
      list-style: none;
      justify-content: flex-start;
      margin: 0;
      padding: 0;
      width: 100%;
      &__item {
        background: transparent;
        border: none;
        border-radius: 0 !important;
        list-style: none;
        margin: 0;
        padding: 0;
        width: calc(100% / 6);
        // 一気にシングルカラムになるためグチャッとなる。なのでtransitonなしで一瞬で切り替える
        // transition: width .3s;

        &:last-child {
          border-right: none;
          #{$plan}-data {
            &-list {
              &__item {
                border-right: none;
              }
            }
          }
        }

        &:nth-child(5) {
          #{$plan}-data {
            &-list {
              &__item {
                &--account-type {
                  border-right: none;
                }
                &--type {
                  border-right: 1px solid $appColorGrayScale6;
                }
              }
            }
          }
        }

        #{$plan}-data__label {
          display: none;
        }

        &--title {
          display: none;
          // width: 260px;
          #{$plan}-data {
            &__label {
              display: flex;
            }
          }
        }

        &:nth-child(1) {
          &#{$plan}-list__item--title {
            display: flex;
          }
        }
        &:nth-child(3) {
          #{$plan}-data-list__item--account-type {
            #{$plan}-data__value {
              display: none;
            }
          }
        }
      }
    }
    &-data {
      $data: &;

      &__label,
      &__value {
        align-content: center;
        align-items: center;
        box-sizing: border-box;
        display: flex;
        flex-wrap: wrap;
        margin: 0;
        padding: 0 12px;
        max-height: 60px;
        min-height: 60px;
        width: 100%;
      }

      &__label {
        font-size: $appFontSizeBase;
        // font-weight: $appFontWeightExtraBold;
        justify-content: flex-start;
        padding: 0 12px 0 18px;
        .tax {
          font-size: $appFontSizeS;
          font-weight: $appFontWeightNormal;
          padding-top: 1px;
        }
        .supplement {
          align-items: flex-start;
          display: flex;
          font-size: $appFontSizeS;
          font-weight: $appFontWeightNormal;
          justify-content: flex-start;
          width: 100%;
        }
        .line {
          display: inline;
          width: auto;
          &--type {
            &::after {
              content: '/';
              padding: 0 4px;
            }
          }
        }
      }

      &__value {
        justify-content: center;
        i {
          &.is-disabled {
            font-size: $appFontSize2XL;
          }
          &.is-active {
            color: $appColorPrimary1;
            font-size: $appFontSize2XL;
          }
          &.is-infinity {
            color: $appColorPrimary1;
            font-size: $appFontSize2XL;
          }
        }
        .text {
          font-size: $appFontSizeBase;
          text-align: center;
        }
      }

      &-list {
        background: transparent;
        border: none;
        list-style: none;
        margin: 0;
        padding: 0;
        width: 100%;
        &__item {
          background: transparent;
          border: none;
          border-right: 1px solid $appColorGrayScale6;
          border-bottom: 1px solid $appColorGrayScale6;
          box-sizing: border-box;
          list-style: none;
          margin: 0;
          padding: 0;
          width: 100%;

          &--no-bb {
            border-bottom: none;
          }
          &--no-br {
            border-right: none;
          }

          &--toggle {
            display: none;
          }

          &--type {
            background: $appColorGlassBlueAlpha;
            border-right: 0;
            height: 60px;
            max-height: 60px;
            min-height: 60px;
            width: 100%;
            #{$data}__label {
              font-size: $appFontSizeL;
              font-weight: $appFontWeightExtraBold;
              max-height: 60px;
              min-height: 60px;
            }
          }

          &--account-type {
            background: $appColorGrayScale9;
            max-height: 60px;
            min-height: 60px;
            width: 100%;
            #{$data}__value {
              font-size: $appFontSizeL;
              font-weight: $appFontWeightExtraBold;
              max-height: 60px;
              min-height: 60px;
              padding: 0;
              width: 100%;
            }
          }

          &--custom-plan-text {
            align-items: center;
            // background: $appColorWhite;
            background: $appColorGrayScale9;
            display: flex;
            font-size: $appFontSizeBase;
            max-height: calc(60px * 10 + 110px + 10px);
            min-height: calc(60px * 10 + 110px + 10px);
            text-align: center;
          }

          &--name {
            background: $appColorGrayScale9;
            max-height: 60px;
            min-height: 60px;
            width: 100%;
            #{$data}__value {
              font-size: $appFontSize2XL;
              font-weight: $appFontWeightExtraBold;
              max-height: 60px;
              min-height: 60px;
              width: 100%;
            }
          }
          &--btns {
            background: transparent;
            border-bottom: 0 !important;
            border-right: 0 !important;
            max-height: 120px;
            min-height: 120px;
            width: 100%;
            #{$data}__value {
              max-height: 120px;
              min-height: 120px;
              width: 100%;
            }
            .c-btn {
              box-sizing: border-box;
              padding: 10px;
              max-width: 220px;
              width: 100%;
              &--enter {
                cursor: pointer;
              }
              &--more {
                cursor: pointer;
                display: none;
              }
              &__text {
                @include ellipsis;
              }
            }
          }

          &--monthly-price,
          &--yearly-price {
            #{$data}__value {
              .after,
              .before {
                display: flex;
                justify-content: center;
                width: 100%;
              }
              .before,
              .arrow {
                display: none;
              }
              // .before {
              //   color: $appColorGrayScale12;
              //   .number {
              //     font-size: $appFontSizeXL;
              //     text-decoration: line-through;
              //     &::after {
              //       line-height: 22px;
              //     }
              //   }
              //   .unit {
              //     line-height: 22px;
              //     padding-top: 0;
              //   }
              // }
              // .arrow {
              //   font-size: $appFontSizeBase;
              //   padding: 2px 0;
              // }
              .free {
                font-size: $appFontSizeXL;
              }
              .number {
                align-items: flex-end;
                display: flex;
                font-size: $appFontSize3XL;
                font-weight: $appFontWeightExtraBold;
                &::before {
                  content: '$';
                  font-size: $appFontSizeL;
                  font-weight: $appFontWeightExtraBold;
                  line-height: 16px;
                  padding: 0 4px 2px;
                }
              }
              .unit {
                align-items: flex-end;
                display: flex;
                font-size: $appFontSizeS;
                line-height: 16px;
                padding-top: 4px;
                &::before {
                  content: '/';
                  font-size: $appFontSizeS;
                  padding: 0 4px 0 6px;
                }
              }
            }
          }
          &--yearly-price {
            #{$data}__label,
            #{$data}__value {
              max-height: 110px;
              min-height: 110px;
              .save {
                align-items: center;
                display: flex;
                flex-direction: column;
                gap: 0px;
                justify-content: center;
                padding-top: 8px;
                width: 100%;
                .total {
                  align-items: center;
                  display: flex;
                  flex-direction: row;
                  justify-content: center;
                  &::before {
                    content: 'Save us';
                    font-size: $appFontSizeS;
                    font-weight: $appFontWeightNormal;
                    padding-right: 4px;
                  }
                  .number {
                    color: var(--app-color-free-red);
                    font-size: $appFontSizeXL;
                    font-weight: $appFontWeightExtraBold;
                    &::before {
                      content: '$';
                      font-size: $appFontSizeM;
                      font-weight: $appFontWeightExtraBold;
                      line-height: 14px;
                      padding: 0 2px 3px 0;
                    }
                  }
                }
                .breakdown {
                  align-items: center;
                  display: flex;
                  flex-direction: row;
                  justify-content: center;
                  .number {
                    color: var(--app-color-free-red);
                    font-size: $appFontSizeXL;
                    font-weight: $appFontWeightExtraBold;
                    &::before {
                      content: '$';
                      font-size: $appFontSizeM;
                      font-weight: $appFontWeightExtraBold;
                      line-height: 14px;
                      padding: 0 2px 3px 0;
                    }
                  }
                  .unit {
                    align-items: flex-end;
                    display: flex;
                    font-size: $appFontSizeS;
                    line-height: 14px;
                    padding-top: 1px;
                    &::before {
                      content: '/';
                      font-size: $appFontSizeS;
                      padding: 0 2px 0 4px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  &-option {
    $option: &;
    border-top: 1px solid $appColorGrayScale6;
    margin-top: 30px;
    padding-top: 60px;
    width: 100%;

    &-supplement {
      align-items: flex-start;
      display: flex;
      font-weight: $appFontWeightNormal;
      justify-content: flex-start;
      padding: 0 0 10px 0;
      width: 100%;
      &::before {
        content: '\203B';
        padding-right: 5px;
      }
    }

    &-list {
      align-items: stretch;
      background: transparent;
      border: none;
      box-sizing: border-box;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      list-style: none;
      justify-content: flex-start;
      margin: 0;
      padding: 0;
      width: 100%;

      &__item {
        background: transparent;
        border: 1px solid $appColorGrayScale6;
        border-radius: $appRadiusM;
        margin: 0 20px 20px 0;
        overflow: hidden;
        padding: 0;
        transition: width 0.3s;
        width: calc((100% - 20px * 3) / 4);
        &:nth-child(4n) {
          margin-right: 0;
        }
      }
      &__item + &__item {
        border-top: 1px solid $appColorGrayScale6;
      }
    }

    &-data {
      $data: &;

      &__label,
      &__value {
        align-content: center;
        align-items: center;
        box-sizing: border-box;
        display: flex;
        flex-wrap: wrap;
        margin: 0;
        padding: 0;
        width: 100%;
      }

      &-list {
        align-items: flex-start;
        background: transparent;
        border: none;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        list-style: none;
        margin: 0;
        padding: 0;
        width: 100%;
        &__item {
          background: transparent;
          border: none;
          box-sizing: border-box;
          list-style: none;
          margin: 0;
          padding: 0;
          width: 100%;
          &--name {
            background: $appColorGlassBlueAlpha;
            padding: 20px 16px;
            width: 100%;
            .text {
              font-size: $appFontSizeXL;
              font-weight: $appFontWeightExtraBold;
            }
          }
          &--note {
            padding: 30px 16px 10px 16px;
            width: 100%;
          }
          &--price {
            padding: 20px 16px 20px 16px;
            width: 100%;
            &::before {
              border-top: 1px solid $appColorGrayScale6;
              content: '';
              display: flex;
              height: 1px;
              margin-bottom: 20px;
              width: 98%;
            }
            #{$data}__value {
              justify-content: flex-end;
              .number {
                align-items: flex-end;
                display: flex;
                font-size: $appFontSize3XL;
                font-weight: $appFontWeightExtraBold;
                &::after {
                  content: '\5186';
                  font-size: $appFontSizeS;
                  font-weight: $appFontWeightNormal;
                  line-height: 16px;
                  padding-left: 4px;
                }
              }
              .tax {
                font-size: $appFontSizeS;
                font-weight: $appFontWeightNormal;
                padding-top: 7px;
              }
            }
          }
          &--btns {
            padding: 0 0 24px 0;
            #{$data}__value {
              justify-content: center;
            }
            .c-btn {
              max-width: 92%;
              width: 260px;
              &--enter {
                cursor: pointer;
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1700px) {
  .p-pricing {
    $this: &;
    &-option {
      $option: &;
      &-list {
        &__item {
          width: calc((100% - 20px * 2) / 3);
          &:nth-child(3n) {
            margin-right: 0;
          }
          &:nth-child(4n) {
            margin-right: 20px;
          }
        }
      }
    }
  }
}

@media (max-width: 1630px) {
  .p-pricing {
    $this: &;
    &-subscription-plan {
      $plan: &;
      &-data {
        &-list {
          &__item {
            &--max-platinum-scout {
              #{$plan}-data__label,
              #{$plan}-data__value {
                max-height: 80px;
                min-height: 80px;
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1500px) {
  .p-pricing {
    $this: &;
    &-subscription-plan {
      $plan: &;
      &-data {
        &__label,
        &__value {
          max-height: 80px;
          min-height: 80px;
        }
        &-list {
          &__item {
            &--custom-plan-text {
              max-height: calc(60px + 80px * 9 + 110px + 10px);
              min-height: calc(60px + 80px * 9 + 110px + 10px);
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1430px) {
  .p-pricing {
    $this: &;
    &-subscription-plan {
      $plan: &;
      &-data {
        &-list {
          &__item {
            &--monthly-price {
              #{$plan}-data__label,
              #{$plan}-data__value {
                max-height: 80px;
                min-height: 80px;
              }
              #{$plan}-data__value {
                .number {
                  justify-content: center;
                  padding-top: 8px;
                  width: 100%;
                }
              }
            }
            &--yearly-price {
              #{$plan}-data__label,
              #{$plan}-data__value {
                max-height: 132px;
                min-height: 132px;
              }
              #{$plan}-data__value {
                .arrow,
                .before {
                  display: none;
                }
                .after {
                  flex-wrap: wrap;
                  .number {
                    justify-content: center;
                    padding-top: 8px;
                    width: 100%;
                  }
                }
              }
            }
            &--max-platinum-scout {
              #{$plan}-data__label,
              #{$plan}-data__value {
                max-height: 100px;
                min-height: 100px;
              }
            }
            &--custom-plan-text {
              max-height: calc(60px + 80px * 9 + 132px + 10px);
              min-height: calc(60px + 80px * 9 + 132px + 10px);
            }
          }
        }
      }
    }
    &-option {
      $option: &;
      &-list {
        &__item {
          width: calc((100% - 20px * 1) / 2);
          &:nth-child(even) {
            margin-right: 0;
          }
          &:nth-child(odd) {
            margin-right: 20px;
          }
        }
      }
    }
  }
}

@media (max-width: 1220px) {
  .p-pricing {
    $this: &;
    &-subscription-plan {
      $plan: &;
      &-data {
        &-list {
          &__item {
            #{$plan}-data__label,
            #{$plan}-data__value {
              max-height: 80px;
              min-height: 80px;
            }
            &--yearly-price {
              #{$plan}-data__label,
              #{$plan}-data__value {
                max-height: 132px;
                min-height: 132px;
              }
            }
            &--account-type,
            &--name,
            &--type {
              #{$plan}-data__label,
              #{$plan}-data__value {
                max-height: 60px;
                min-height: 60px;
              }
            }
            &--max-platinum-scout {
              #{$plan}-data__label,
              #{$plan}-data__value {
                max-height: 100px;
                min-height: 100px;
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1240px) {
  .p-pricing {
    $this: &;
    &-subscription-plan {
      $plan: &;
      &-data {
        &-list {
          &__item {
            &--account-type {
              #{$plan}-data__value {
                font-size: $appFontSizeM;
              }
            }
            &--yearly-price {
              #{$plan}-data__label,
              #{$plan}-data__value {
                .save {
                  .breakdown {
                    flex-direction: column;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 995px) {
  .p-pricing {
    $this: &;
    &-subscription-plan {
      $plan: &;
      width: 100%;

      &-list {
        &__item {
          &:nth-child(2),
          &:nth-child(3),
          &:nth-child(4),
          &:nth-child(5),
          &:nth-child(6) {
            width: 100%;
            #{$plan}-data-list__item {
              border-right: none;
              &--type {
                border-right: none;
                #{$plan}-data__label {
                  display: flex;
                }
              }
            }
          }
          &:nth-child(1),
          &:nth-child(3) {
            &#{$plan}-list__item--title {
              display: none;
            }
          }
          #{$plan}-data__label {
            display: flex;
          }
          #{$plan}-data__label,
          #{$plan}-data__value {
            max-height: 60px;
            min-height: 60px;
          }

          &:nth-child(3) {
            #{$plan}-data-list__item--account-type {
              #{$plan}-data__value {
                display: flex;
              }
            }
          }

          &:nth-child(4) {
            #{$plan}-data-list__item--account-type {
              #{$plan}-data__value {
                display: none;
              }
            }
          }
        }
      }
      &-data {
        $data: &;
        #{$data}__label {
          max-width: 100px;
          min-width: 100px;
          width: 100px;
        }
        &__value {
          justify-content: flex-end;
          max-width: calc(100% - 100px);
          min-width: calc(100% - 100px);
          width: calc(100% - 100px);
          .text {
            display: inline-block;
            padding-right: 6px;
            text-align: right;
          }
        }
        &-list {
          &__item {
            align-items: flex-end;
            display: flex;
            max-height: none;
            min-height: auto;
            &--type {
              #{$data}__label {
                max-width: 100%;
                min-width: 100%;
                width: 100%;
              }
              #{$data}__value {
                display: none;
              }
            }
            &--account-type {
              #{$data}__value {
                font-size: $appFontSizeL;
              }
            }
            &--account-type,
            &--name {
              #{$data}__value {
                justify-content: center;
                max-width: 100%;
                min-width: 100%;
                width: 100%;
              }
              #{$data}__label {
                display: none;
              }
            }
            &--monthly-price {
              #{$data}__value {
                flex-wrap: nowrap;
                .number {
                  padding-top: 0;
                  width: auto;
                }
              }
            }
            &--monthly-price,
            &--yearly-price {
              #{$data}__label {
                max-width: 110px;
                min-width: 110px;
                width: 110px;
              }
              #{$data}__value {
                max-width: calc(100% - 110px);
                min-width: calc(100% - 110px);
                width: calc(100% - 110px);
                .before,
                .arrow {
                  display: none;
                }
                .after {
                  flex-wrap: nowrap;
                  justify-content: flex-end;
                  .number {
                    padding-top: 0;
                    width: auto;
                  }
                }
              }
            }
            &--yearly-price {
              #{$data}__label {
                max-height: 80px;
                min-height: 80px;
                height: 80px;
              }
              #{$data}__value {
                display: flex;
                flex-direction: row;
                max-height: 80px;
                min-height: 80px;
                height: 80px;
                .save {
                  flex-direction: row;
                  justify-content: flex-end;
                  padding-top: 4px;
                  .breakdown {
                    flex-direction: row;
                    &::before {
                      content: '/';
                      padding: 0 8px;
                    }
                  }
                }
              }
            }
            &--post-public-scope,
            &--max-team-member,
            &--alumni,
            &--edit-permission,
            &--max-platinum-scout,
            &--max-standard-scout,
            &--dashboard {
              #{$data}__label {
                max-width: 70%;
                min-width: 70%;
                width: 70%;
              }
              #{$data}__value {
                max-width: 30%;
                min-width: 30%;
                width: 30%;
              }
            }

            &--company-max-deal-number,
            &--company-deal-publish,
            &--company-view-number,
            &--company-view-user-profile,
            &--company-favorite-number,
            &--company-favorite-user-profile,
            &--project-create,
            &--project-subscribe,
            &--project-view-number,
            &--project-view-user-profile,
            &--project-favorite-number,
            &--project-favorite-user-profile,
            &--project-recruit,
            &--project-subscribe-scope,
            &--project-create-secret-owner,
            &--project-supervisor {
              #{$data}__label {
                max-width: 60%;
                min-width: 60%;
                width: 60%;
              }
              #{$data}__value {
                max-width: 40%;
                min-width: 40%;
                width: 40%;
              }
            }
            &--btns {
              #{$data}__value {
                box-sizing: border-box;
                height: auto;
                max-height: none;
                max-width: 100%;
                min-height: auto;
                min-width: 100%;
                padding: 30px 0;
                width: 100%;
                .c-btn {
                  max-width: none;
                  width: 100%;
                  &--more {
                    display: flex;
                  }
                  &--current,
                  &--select {
                    display: none;
                    margin-top: 15px;
                  }
                  &--custom {
                    display: flex;
                    margin-top: 0;
                  }
                }
              }
            }

            &--post-public-scope,
            &--type,
            &--max-team-member,
            &--alumni,
            &--edit-permission,
            &--max-platinum-scout,
            &--max-standard-scout,
            &--dashboard,
            &--company-max-deal-number,
            &--company-deal-publish,
            &--company-view-number,
            &--company-view-user-profile,
            &--company-favorite-number,
            &--company-favorite-user-profile,
            &--post-public-scope,
            &--project-create,
            &--project-subscribe,
            &--project-view-number,
            &--project-view-user-profile,
            &--project-favorite-number,
            &--project-favorite-user-profile,
            &--project-recruit,
            &--project-subscribe-scope,
            &--project-create-secret-owner,
            &--project-supervisor {
              display: none;
            }

            &--custom-plan-text {
              #{$data}__value {
                justify-content: center;
                max-width: 100%;
                min-width: 100%;
                width: 100%;
              }
            }
          }
          &.is-active {
            #{$data}-list {
              &__item {
                &--post-public-scope,
                &--type,
                &--max-team-member,
                &--alumni,
                &--edit-permission,
                &--max-platinum-scout,
                &--max-standard-scout,
                &--dashboard,
                &--company-max-deal-number,
                &--company-deal-publish,
                &--company-view-number,
                &--company-view-user-profile,
                &--company-favorite-number,
                &--company-favorite-user-profile,
                &--post-public-scope,
                &--project-create,
                &--project-subscribe,
                &--project-view-number,
                &--project-view-user-profile,
                &--project-favorite-number,
                &--project-favorite-user-profile,
                &--project-recruit,
                &--project-subscribe-scope,
                &--project-create-secret-owner,
                &--project-supervisor {
                  display: flex;
                }
                &--btns {
                  .c-btn {
                    &--current,
                    &--select {
                      display: flex;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    &-option {
      $option: &;
      &-data {
        &-list {
          &__item {
            &--btns {
              .c-btn {
                width: 100%;
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 900px) {
  .p-pricing {
    $this: &;
    &-option {
      $option: &;
      &-list {
        &__item {
          margin-right: 0 !important;
          width: 100%;
        }
      }
    }
  }
}

@media (max-width: $appBreakPoint) {
  .p-pricing {
    $this: &;

    &,
    &.card {
      padding: 32px 24px 16px 24px;
    }

    .c-title {
      border-bottom: 0;
      flex-wrap: wrap;
      margin: 0 0 18px 0;
      padding: 0;
      &__text {
        font-size: $appFontSize2XL;
        margin-right: 12px;
        &--badge {
          font-size: $appFontSizeBase;
          margin-right: 0;
          min-width: 210px;
          padding: 8px 10px;
          width: auto;
        }
      }
    }

    &-warning {
      background: $appColorGrayScale9;
      font-size: $appFontSizeS;
      margin-bottom: 18px;
      padding: 15px;
      width: 100%;
    }

    &-subscription-plan {
      $plan: &;
      width: 100%;

      &-list {
        &__item {
          &:nth-child(4),
          &:nth-child(5),
          &:nth-child(6) {
            #{$plan}-data-list__item--account-type {
              display: none;
            }
          }
        }
      }

      &-data {
        $data: &;

        &__label,
        &__value {
          font-size: $appFontSizeS;
          max-height: none !important;
          min-height: auto !important;
          padding: 0 12px;
        }

        &__label {
          .supplement {
            font-size: $appFontSizeXS;
          }
        }

        &__value {
          i {
            &.is-disabled {
              font-size: $appFontSizeXL;
            }
            &.is-active {
              font-size: $appFontSizeXL;
            }
            &.is-infinity {
              font-size: $appFontSizeXL;
            }
          }
          .text {
            font-size: $appFontSizeS;
          }
        }

        &-list {
          &__item {
            align-items: center;
            max-height: none !important;
            min-height: auto !important;
            padding: 12px 0;
            &--type {
              #{$data}__label {
                font-size: $appFontSizeBase;
              }
            }
            &--name {
              padding: 18px 0;
              #{$data}__value {
                font-size: $appFontSizeXL;
              }
            }
            &--yearly-price {
              #{$data}__label {
                max-height: none;
                min-height: auto;
                height: auto;
              }
              #{$data}__value {
                max-height: none;
                min-height: auto;
                height: auto;
              }
            }
            &--monthly-price,
            &--yearly-price {
              #{$data}__value {
                .free {
                  font-size: $appFontSizeL;
                }
                .number {
                  font-size: $appFontSize2XL;
                  &::after {
                    line-height: 18px;
                  }
                }
                .unit {
                  line-height: 18px;
                }
              }
            }
            &--btns {
              padding: 0;
            }
          }
        }
      }
    }

    &-option {
      $option: &;
      margin-top: 30px;
      padding-top: 50px;

      &-supplement {
        font-size: $appFontSizeS;
        padding: 0 0 5px 0;
      }

      &-list {
        &__item {
          margin-bottom: 16px;
        }
      }

      &-data {
        $data: &;
        &-list {
          &__item {
            &--name {
              padding: 16px 12px;
              #{$data}__value {
                .text {
                  font-size: $appFontSizeL;
                }
              }
            }
            &--note {
              padding: 16px 12px;
              #{$data}__value {
                .text {
                  font-size: $appFontSizeS;
                }
              }
            }
            &--price {
              padding: 0 12px 16px 12px;
              &::before {
                margin-bottom: 12px;
              }
              #{$data}__value {
                .text {
                  font-size: $appFontSizeS;
                }
                .number {
                  font-size: $appFontSize2XL;
                  &::after {
                    line-height: 18px;
                  }
                }
                .tax {
                  padding-top: 5px;
                }
              }
            }
            &--btns {
              padding: 0 12px 16px 12px;
              .c-btn {
                max-width: none;
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 540px) {
  .p-pricing {
    $this: &;
    &-subscription-plan {
      &-data {
        $data: &;
        &-list {
          &__item {
            &--yearly-price {
              #{$data}__label {
                max-height: 64px;
                min-height: 64px;
                height: 64px;
              }
              #{$data}__value {
                max-height: 64px;
                min-height: 64px;
                height: 64px;
                .save {
                  flex-direction: column;
                  .total {
                    justify-content: flex-end;
                    width: 100%;
                  }
                  .breakdown {
                    justify-content: flex-end;
                    width: 100%;
                    &::before {
                      display: none;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    &-option {
      .c-title {
        flex-wrap: wrap;
        .c-title__text {
          margin-right: 0;
          width: 100%;
          &--badge {
            margin-top: 10px;
            width: auto;
          }
        }
      }
    }
  }
}

@media (max-width: 420px) {
  .p-pricing {
    $this: &;
    .c-title {
      flex-wrap: wrap;
      .c-title__text {
        margin-right: 0;
        width: 100%;
        &--badge {
          margin-top: 10px;
          width: auto;
        }
      }
    }
  }
}

@media (max-width: 370px) {
  .p-pricing {
    $this: &;
    &-subscription-plan {
      &-data {
        $data: &;
        &-list {
          &__item {
            &--monthly-price,
            &--yearly-price {
              #{$data}__value {
                .number {
                  font-size: $appFontSizeXL;
                }
              }
            }
          }
        }
      }
    }
    &-option {
      &-data {
        $data: &;
        &-list {
          &__item {
            &--price {
              #{$data}__value {
                .number {
                  font-size: $appFontSizeXL;
                }
              }
            }
          }
        }
      }
    }
  }
}
