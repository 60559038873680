.p-joined-project {
  $this: &;
  margin: 0 auto;

  &--search {
    margin-bottom: 0;
  }

  &__spacer {
    height: 30px;
    width: 100%;
  }

  .c-pagination {
    .c-select {
      &__menu {
        &-list {
          // original
          // max-height: 300px !important;
          max-height: 130px !important;
        }
      }
    }
  }

  &-head {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    width: 100%;
    &__container {
      align-items: center;
      background: $appColorGrayScale16;
      border-radius: $appRadiusM;
      display: flex;
      flex-wrap: wrap;
      gap: 0 12px;
      justify-content: flex-end;
      padding: 36px 24px 28px 24px;
      width: 100%;
      &.row {
        margin: 0 0 30px 0;
      }
    }
    &__tab {
      height: 40px;
      width: 140px;
    }
    &__item {
      &.row {
        margin: 0;
      }
      &--tab {
        display: flex;
        gap: 0 12px;
        position: relative;
        max-width: calc(100% - 712px + 12px * 3);
        min-width: calc(100% - 712px + 12px * 3);
        transition: max-width 0.3s, min-width 0.3s, width 0.3s;
        width: calc(100% - 712px + 12px * 3);
      }
      &--keyword {
        position: relative;
        max-width: 300px;
        min-width: 300px;
        transition: max-width 0.3s, min-width 0.3s, width 0.3s;
        width: 300px;
        .c-icon {
          color: $appColorGrayScale6;
          font-size: 36px;
          left: 1px;
          position: absolute;
          top: 8px;
          transform: rotate(-45deg);
          z-index: 1001;
        }
        .c-input {
          border-radius: 50px;
          padding-left: 36px;
          padding-right: 18px;
          transition: width 0.3s;
          width: 300px;
          z-index: 1000;
        }
        .form-group {
          margin: 0;
          width: 100%;
          .invalid-feedback {
            top: -40px;
          }
        }
      }
      &--status {
        position: relative;
        max-width: 140px;
        min-width: 140px;
        width: 140px;
        .form-group {
          margin: 0px;
          width: 100%;
          label {
            font-size: $appFontSizeXS - 1;
            font-weight: $appFontWeightExtraBold;
            left: 5px;
            position: absolute;
            top: -22px;
          }
          .invalid-feedback {
            top: auto;
            bottom: 48px;
            width: 140px;
          }
        }
      }
      &--btns {
        max-width: 200px;
        min-width: 200px;
        width: 200px;
        transition: max-width 0.1s, min-width 0.1s, width 0.1s;
        .c-btn {
          height: 40px;
          margin: 0;
          width: 200px;
          transition: max-width 0.1s, min-width 0.1s, width 0.1s;
        }
      }
    }
  }

  &-list {
    &__item {
      &.is-not-important {
        background: rgba(255, 255, 255, 0.4);
        .p-search-item {
          &-image {
            opacity: 0.4;
          }
          &-header {
            .c-badge {
              &::after {
                background: rgba(255, 255, 255, 0.8);
                border-radius: $appRadiusM;
                content: '';
                height: 100%;
                left: 0;
                opacity: 0.4;
                position: absolute;
                top: 0;
                width: 100%;
              }
            }
            &__title {
              opacity: 0.4;
            }
            &::after {
              opacity: 0.4;
            }
          }
          &-body {
            opacity: 0.4;
          }
        }
      }
    }
  }

  .p-search {
    &--joined-project {
      margin-bottom: 15px;
    }
  }
}

@media (max-width: 1040px) {
  .p-joined-project {
    $this: &;
    &-head {
      &__container {
        gap: 24px 12px;
        justify-content: flex-end;
        padding: 36px 24px 24px 24px;
      }
      &__item {
        &--keyword {
          max-width: 200px;
          min-width: 200px;
          width: 200px;
          .c-input {
            width: 200px;
          }
        }
      }
    }
  }
}

@media (max-width: $appBreakPoint) {
  .p-joined-project {
    $this: &;
    margin: 0 auto 16px auto;

    &--search {
      margin-bottom: 0;
    }

    &-head {
      &__container {
        gap: 18px 0;
        justify-content: space-between;
        padding: 20px;
        &.row {
          margin-bottom: 16px;
        }
      }
      &__item {
        &--keyword {
          max-width: 100%;
          min-width: 100%;
          width: 100%;
          .c-input {
            width: 100%;
          }
          .form-group {
            width: 100%;
          }
        }
        &--status {
          &.row {
            margin-top: 16px;
          }
          max-width: calc(50% - 6px);
          min-width: calc(50% - 6px);
          width: calc(50% - 6px);
        }
        &--btns {
          max-width: 100%;
          min-width: 100%;
          width: 100%;
          .c-btn {
            margin: 0;
            width: 100%;
          }
        }
      }
    }

    .p-search {
      &--joined-project {
        margin-bottom: 3px;
      }
    }

    .c-pagination {
      .c-select {
        &__menu {
          &-list {
            // original
            max-height: 300px !important;
          }
        }
      }
    }
  }
}
