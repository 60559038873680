/**
 * フッター
 */
.l-footer {
  $this: &;
  background: $appColorGrayScale8;
  border-top: 1px $appColorGrayScale12 solid;
  bottom: 0;
  display: flex;
  flex-wrap: nowrap;
  min-width: 360px;
  position: fixed;
  width: 100%;
  transition: background 300ms;
  z-index: 8000;

  &--no-fixed {
    position: relative;
  }

  .list-group {
    border-radius: 0;
    display: flex;
    flex-direction: row;
    flex-shrink: 1;
    height: 45px;
    justify-content: center;
    padding-left: 100px;
    width: 100%;
    &-item {
      background: transparent;
      border: none;
      display: flex;
      padding: 0;
      transition: margin 0.3s;
      a {
        align-items: center;
        color: $appColorBlack;
        cursor: pointer;
        display: flex;
        font-size: $appFontSizeS;
        font-weight: $appFontWeightExtraBold;
        padding: 0;
        &:hover,
        &:active,
        &:visited {
          color: $appColorBlack;
        }
      }
    }
  }

  .list-group-item + .list-group-item {
    margin-left: 30px;
  }

  &__copyright {
    align-items: center;
    color: $appColorBlack;
    display: flex;
    flex-shrink: 2;
    font-size: $appFontSizeXS;
    font-weight: $appFontWeightExtraBold;
    margin-right: 0;
    min-width: 100px;
    padding: 0;
  }
}

@media (max-width: 1000px) {
  .l-footer {
    flex-wrap: wrap;
    .list-group {
      flex-wrap: wrap;
      padding-left: 0;
    }
    &__copyright {
      justify-content: center;
      margin-right: 0;
      padding: 0 0 12.5px 0;
      width: 100%;
    }
  }
}

@media (max-width: 800px) {
  .l-footer {
    .list-group-item + .list-group-item {
      margin-left: 15px;
    }
  }
}

@media (max-width: $appBreakPoint) {
  .l-footer {
    margin-top: 70px;
    position: fixed;
    &--no-fixed {
      position: relative;
      z-index: 1000;
    }
    &--full-height {
      margin-top: 20px;
    }

    &--chat {
      .list-group {
        display: none;
      }
    }
    .list-group {
      flex-wrap: wrap;
      height: auto;
      justify-content: start;
      margin-top: 0;
      &-item {
        border-bottom: 1px solid $appColorGrayScale12;
        justify-content: center;
        padding: 12.5px 0;
        width: 50%;
        a {
          font-size: $appFontSizeS;
        }
        &:nth-child(2n) {
          border-left: 1px solid $appColorGrayScale12;
        }
        &:nth-child(odd):last-child {
          width: 100%;
        }
      }
    }
    .list-group-item + .list-group-item {
      margin-left: 0;
    }
    &__copyright {
      padding: 15px 0 17.5px 0;
    }
  }
}
