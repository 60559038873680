.p-search-project {
  $this: &;
  background: transparent;
  width: 100%;

  &__container {
    padding-bottom: 16px;
  }

  &-list {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    gap: 16px 0;
    justify-content: flex-start;
    &-item {
      position: relative;
      width: 100%;

      // お気に入りボタン
      .c-btn {
        &--favorite {
          border-radius: 50%;
          height: 40px;
          margin: 0;
          position: absolute;
          right: 12.5px;
          top: 12.5px;
          width: 40px;
          z-index: 1100;
          transition: transform 300ms;
          i {
            color: #fff;
            font-size: 24px;
            transition: color 300ms;
          }
          &:hover {
            transform: scale(1.1);
          }
        }
      }
    }
  }

  &-card {
    align-items: flex-start;
    background: $appColorWhite;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.075);
    border-radius: $appRadiusXL;
    display: flex;
    flex: wrap;
    gap: 20px;
    justify-content: flex-start;
    padding: 24px 68px 24px 24px;
    width: 100%;
    a {
      color: $appColorBlack;
      &:hover {
        color: var(--app-color-primary-4) !important;
        text-decoration: underline !important;
      }
      &:active,
      &:visited {
        color: $appColorBlack;
        text-decoration: none;
      }
    }
    &__block {
      &--image {
        max-width: 64px;
        min-width: 64px;
        width: 64px;
      }
      &--main {
        display: flex;
        flex-direction: column;
        gap: 20px;
        max-width: calc(100% - (64px + 242px + (20px * 2)));
        min-width: calc(100% - (64px + 242px + (20px * 2)));
        width: calc(100% - (64px + 242px + (20px * 2)));
      }
      &--sub {
        display: flex;
        flex-direction: column;
        gap: 4px;
        max-width: 242px;
        min-width: 242px;
        width: 242px;
      }
    }
    &__logo {
      background: $appColorGrayScale9;
      background-size: cover;
      background-position: center center;
      background-repeat: no-repeat;
      border-radius: $appRadiusS;
      height: 64px;
      overflow: hidden;
      width: 64px;
      &--none {
        align-items: center;
        background: $appColorGrayScale9;
        display: flex;
        justify-content: center;
        height: 64px;
        i {
          color: $appColorGrayScale8;
          font-size: 42px;
        }
      }
    }
    &-badge {
      &-list {
        align-items: center;
        display: flex;
        justify-content: flex-start;
        gap: 8px;
        width: 100%;
        &__item {
          width: auto;
          .c-badge {
            border-radius: $appRadiusS;
            font-size: $appFontSizeS;
            height: 28px;
            max-width: none;
            min-width: 64px;
            padding: 0 8px;
            width: auto;
            &--distribution {
              min-width: 100px;
            }
            &--casual-interview {
              min-width: 112px;
            }
            &--project-owner-type,
            &--wanted {
              min-width: 64px;
            }
          }
        }
      }
    }

    &-category {
      &-list {
        align-items: center;
        display: flex;
        justify-content: flex-start;
        gap: 8px;
        width: 100%;
        &__item {
          width: auto;
        }
      }
    }

    &-data {
      align-items: flex-start;
      display: flex;
      justify-content: flex-start;
      gap: 8px;
      width: 100%;
      &__label {
        color: $appColorBlack;
        font-size: $appFontSizeL;
      }
      &__value {
        font-size: $appFontSizeM;
        .value {
          display: flex;
          justify-content: flex-start;
          &.col {
            padding: 0;
          }
        }
      }
    }

    &__title {
      @include ellipsisMultiple(2);
      font-size: $appFontSizeXL;
      line-height: 1.4em;
      margin: 0;
      padding: 0;
      width: 100%;
    }
  }
}
