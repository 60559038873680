.p-chat-message {
  $this: &;

  &,
  &.card {
    background: $appColorWhite;
    border-radius: $appRadiusXL;
    box-sizing: border-box;
    color: $appColorBlack;
    padding: 0 0 0 0;
    position: relative;
    width: 100%;
    z-index: 100;
  }

  &-head {
    background: $appColorWhite;
    border-radius: $appRadiusXL $appRadiusXL 0 0;
    margin: 0 0 0 0;
    padding: 30px 40px 0 40px;
    position: sticky;
    top: 60px;
    width: 100%;
    z-index: 1100;
    &__item {
      &--title {
        border-bottom: 1px $appColorGrayScale6 solid;
        margin: 0;
        padding: 0 60px 20px 0;
        width: 100%;
        .c-text {
          @include ellipsis;
          color: $appColorBlack;
          font-size: $appFontSizeL;
          font-weight: $appFontWeightExtraBold;
          max-width: calc(100% - 20px);
          width: auto;
          &:hover {
            color: $appColorBlack;
            text-decoration: underline;
          }
          &:active,
          &:visited {
            color: $appColorBlack;
            text-decoration: none;
          }
        }
        i {
          margin-left: 5px;
        }
      }
    }
  }

  &-sender {
    align-items: center;
    display: flex;
    margin: 4px 0 8px 0;
    width: 100%;
    &__image {
      align-items: center;
      background: $appColorGrayScale8;
      box-sizing: border-box;
      border: 2px solid $appColorGrayScale8;
      border-radius: 50%;
      display: flex;
      flex-grow: initial;
      justify-content: center;
      height: 36px;
      margin: 0;
      overflow: hidden;
      padding: 0;
      width: 36px;
      img {
        width: 100%;
      }
      i {
        color: $appColorWhite;
        font-size: 36px;
      }
    }
    &__data {
      align-items: flex-start;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-left: 6px;
      width: calc(100% - 36px);
    }
    &__name {
      // @include ellipsis;
      display: block;
      font-size: $appFontSizeM;
      line-height: 1.4em;
      width: 100%;
    }
    &__date {
      display: block;
      font-size: $appFontSizeXS;
      line-height: 1em;
      width: 100%;
    }
  }

  &-body {
    margin: 4px 0 4px 0;
    position: relative;
    width: 100%;
    &__text {
      background: $appColorGrayScale8;
      border-radius: $appRadiusM;
      box-sizing: border-box;
      line-height: 1.8em;
      margin: 0 0 0 0;
      // min-width: 60px;
      padding: 8px 12px;
      width: auto;
      a {
        color: $appColorPrimary4;
        text-decoration: none;
        &:hover {
          color: $appColorPrimary4;
          text-decoration: underline;
        }
        &:active,
        &:visited {
          text-decoration: none;
        }
      }
    }
  }

  &-file {
    align-items: center;
    background: $appColorWhite;
    border: 1px solid $appColorGrayScale6;
    border-radius: 12px;
    box-sizing: border-box;
    display: flex;
    justify-content: flex-start;
    max-width: 100%;
    padding: 8px 62px 8px 12px;
    position: relative;
    transition: height 0.3s, width 0.3s;
    width: auto;
    &__document {
      box-sizing: border-box;
      opacity: 1;
      transition: height 0.3s, opacity 0.3s, width 0.3s;
      i {
        color: $appColorDocumentFileIcon;
        font-size: 46px;
        line-height: 1em;
        margin: 0 4px 0 -4px;
        transition: color 300ms;
      }
      &--excel i {
        color: $appColorDocumentExcelIcon;
      }
      &--pdf i {
        color: $appColorDocumentPdfIcon;
      }
      &--powerpoint i {
        color: $appColorDocumentPowerPointIcon;
      }
      &--word i {
        color: $appColorDocumentWordIcon;
      }
    }
    &__name {
      @include ellipsis;
      width: calc(100%);
    }
  }

  &-image {
    background: $appColorWhite;
    border: 1px solid $appColorGrayScale6;
    border-radius: 12px;
    box-sizing: border-box;
    position: relative;
    transition: height 0.3s, width 0.3s;
    &__item {
      background-color: transparent;
      background-position: center center;
      background-size: cover;
      border-radius: 10px;
      box-sizing: border-box;
      max-height: 360px;
      max-width: 360px;
      opacity: 1;
      overflow: hidden;
      transition: height 0.3s, opacity 0.3s, width 0.3s;
    }
    .loader {
      top: -9999px;
      opacity: 0;
      position: absolute;
      transition: opacity 0.3s;
      visibility: hidden;
    }
    &.is-loading {
      height: 300px;
      width: 300px;
      .loader {
        left: calc(50% - 15px);
        max-height: none;
        max-width: none;
        opacity: 1;
        position: absolute;
        top: calc(50% - 15px);
        visibility: visible;
      }
      #{$this}-image__item {
        opacity: 0;
      }
    }
  }

  &-timeline {
    background: transparent;
    border: none;
    border-radius: 0;
    display: flex;
    flex-direction: column;
    list-style: none;
    margin: 16px auto 0 auto;
    padding: 0;
    position: relative;
    width: calc(100% - 80px);
    &::before {
      border-top: 1px $appColorGrayScale6 solid;
      content: '';
      height: 0;
      margin: 0;
      padding: 0;
      position: absolute;
      top: 15px;
      width: 100%;
      z-index: 1000;
    }
    &__item {
      align-items: center;
      background: $appColorWhite;
      border: 1px $appColorGrayScale6 solid;
      border-radius: 50px;
      box-sizing: border-box;
      color: $appColorGrayScale3;
      display: flex;
      font-size: $appFontSizeS;
      font-weight: $appFontWeightExtraBold;
      justify-content: center;
      margin: 0 auto;
      padding: 4px 24px;
      width: auto;
      z-index: 1001;
    }
  }

  &-status-change {
    $statusChange: &;
    background: transparent;
    border: none;
    border-radius: 0;
    display: flex;
    flex-direction: column;
    list-style: none;
    margin: 16px auto 0 auto;
    padding: 0;
    position: relative;
    width: calc(100% - 80px);
    &::before {
      border-top: 1px $appColorGrayScale6 solid;
      content: '';
      height: 0;
      margin: 0;
      padding: 0;
      position: absolute;
      top: 15px;
      width: 100%;
      z-index: 1000;
    }
    &__item {
      align-items: center;
      border-radius: $appRadiusM;
      box-sizing: border-box;
      display: flex;
      font-size: $appFontSizeS;
      font-weight: $appFontWeightExtraBold;
      justify-content: center;
      margin: 0 auto;
      padding: 4px 24px;
      width: auto;
      z-index: 1001;
    }
    &--recruited {
      &::before {
        border-top: 1px $appColorGrayScale6 solid;
      }
      #{$statusChange}__item {
        background: $appColorGlassBlue;
        border: 1px $appColorGlassBlue solid;
        color: $appColorBlack;
      }
    }
  }

  &-list {
    margin-top: 10px;
    // margin-top: -16px;
    padding: 0 0 30px 0;
    width: 100%;

    &-wrapper {
      // 0pxの所は、もともと、115px(フッターエリア + 余白用)
      height: calc(100vh - 0px - 72px - 65px - 35px - 40px);
      opacity: 0;
      scroll-behavior: smooth;
      // transition: height .0s;
      width: 100%;
      &.is-active {
        opacity: 1;
        // transition: height .0s, opacity .3s;
        transition: opacity 0.3s;
      }
      &.has-form {
        height: calc(100vh - 0px - 72px - 65px - 35px - 100px);
      }
      &.has-form.has-line-2 {
        height: calc(100vh - 0px - 72px - 65px - 35px - 121px);
      }
      &.has-form.has-line-3 {
        height: calc(100vh - 0px - 72px - 65px - 35px - 142px);
      }
      &.has-form.has-line-4 {
        height: calc(100vh - 0px - 72px - 65px - 35px - 163px);
      }
      &.has-form.has-line-5 {
        height: calc(100vh - 0px - 72px - 65px - 35px - 184px);
      }
      &.has-form.has-line-6 {
        height: calc(100vh - 0px - 72px - 65px - 35px - 205px);
      }
      &.has-form.has-preview {
        height: calc(100vh - 0px - 72px - 65px - 35px - 200px - 100px);
      }
      &.has-form.has-preview.has-line-2 {
        height: calc(100vh - 0px - 72px - 65px - 35px - 200px - 121px);
      }
      &.has-form.has-preview.has-line-3 {
        height: calc(100vh - 0px - 72px - 65px - 35px - 200px - 142px);
      }
      &.has-form.has-preview.has-line-4 {
        height: calc(100vh - 0px - 72px - 65px - 35px - 200px - 163px);
      }
      &.has-form.has-preview.has-line-5 {
        height: calc(100vh - 0px - 72px - 65px - 35px - 200px - 184px);
      }
      &.has-form.has-preview.has-line-6 {
        height: calc(100vh - 0px - 72px - 65px - 35px - 200px - 205px);
      }
    }

    &__item {
      align-items: flex-start;
      background: transparent;
      border: none;
      border-radius: 0;
      display: flex;
      flex-direction: column;
      list-style: none;
      margin: 0;
      padding: 4px 40px;
      position: relative;
      width: 100%;
      transition: background 0.3s;
      &:hover {
        background: $appColorGrayScale8;
        // background: $appColorGrayScale9;
        #{$this}-control {
          @include fadeIn;
        }
      }
      &--has-sender {
        margin-top: 12px;
        padding: 0px 40px;
      }
      &--is-no-margin {
        padding: 0px 40px;
      }
      &--is-self {
        content: #{$this}-sender;
        .c-dropdown {
          left: auto;
          right: -38px;
        }
        #{$this}-sender {
          flex-direction: row-reverse;
          &__data {
            padding: 0 6px 0 0;
          }
          &__date,
          &__name {
            text-align: right;
          }
        }
        #{$this}-body {
          justify-content: flex-end;
          &__text {
            background: $appColorGlassBlueAlpha;
          }
        }
        #{$this}-control {
          left: 40px;
          right: auto;
        }
      }
    }
  }

  &-preview {
    @include fadeOut;
    overflow-y: hidden;
    overflow-x: auto;
    white-space: nowrap;
    width: 100%;

    &__name,
    &__size {
      @include ellipsis;
      font-size: $appFontSizeS;
      line-height: 1.6em;
      margin: 0;
      max-width: 130px;
      padding: 0;
      text-align: center;
      width: auto;
    }
    &__size {
      line-height: 1em;
      padding-bottom: 4px;
    }

    &-list {
      align-items: flex-start;
      background: transparent;
      border: none;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      list-style: none;
      max-height: 200px;
      min-height: 200px;
      padding: 10px 0 10px 0;
      width: 100%;

      &__item {
        background: transparent;
        border: none;
        margin-left: 30px;
        padding: 0;
        position: relative;
        .c-btn {
          &--delete {
            left: -18px;
            height: 36px;
            margin-top: 1px;
            padding: 0;
            position: absolute;
            top: -9px;
            width: 36px;
            z-index: 1100;
            i {
              font-size: $appFontSizeXL;
              margin-top: 2px;
            }
          }
        }
        #{$this}-image {
          &.is-loading {
            height: 130px;
            width: 130px;
          }
          &__item {
            max-height: 130px;
            max-width: 130px;
            min-height: 130px;
            min-width: 130px;
          }
        }
        #{$this}-file {
          align-items: center;
          justify-content: center;
          max-height: 130px;
          max-width: 130px;
          min-height: 130px;
          min-width: 130px;
          padding: 0;
          i {
            font-size: 90px;
            margin: 0;
          }
        }
      }
    }
  }

  &-form {
    background: $appColorWhite;
    border-radius: 0 0 $appRadiusXL $appRadiusXL;
    box-sizing: border-box;
    bottom: 0;
    padding: 0;
    position: sticky;
    width: 100%;
    z-index: 1100;

    // &.has-preview {
    //   #{$this}-form__block {
    //     // @include fadeIn;
    //     // height: 300px;
    //     overflow: hidden;
    //   }
    // }

    &.is-active {
      #{$this}-form__item-group--body {
        overflow: visible;
      }
      #{$this}-form__block {
        @include fadeIn;
        height: 90px;
        overflow: hidden;
      }
    }

    &.is-active.has-line-2 {
      #{$this}-form__block {
        height: 111px;
      }
      #{$this}-form__item-group--body {
        #{$this}-form__item {
          &--message-body {
            .c-input {
              height: 65px;
            }
          }
        }
      }
    }

    &.is-active.has-line-3 {
      #{$this}-form__block {
        height: 132px;
      }
      #{$this}-form__item-group--body {
        #{$this}-form__item {
          &--message-body {
            .c-input {
              height: 86px;
            }
          }
        }
      }
    }

    &.is-active.has-line-4 {
      #{$this}-form__block {
        height: 153px;
      }
      #{$this}-form__item-group--body {
        #{$this}-form__item {
          &--message-body {
            .c-input {
              height: 107px;
            }
          }
        }
      }
    }

    &.is-active.has-line-5 {
      #{$this}-form__block {
        height: 174px;
      }
      #{$this}-form__item-group--body {
        #{$this}-form__item {
          &--message-body {
            .c-input {
              height: 128px;
            }
          }
        }
      }
    }

    &.is-active.has-line-6 {
      #{$this}-form__block {
        height: 195px;
      }
      #{$this}-form__item-group--body {
        #{$this}-form__item {
          &--message-body {
            .c-input {
              height: 149px;
            }
          }
        }
      }
    }

    &.is-active.has-preview {
      #{$this}-preview {
        @include fadeIn;
      }
      #{$this}-form__block {
        height: 290px;
      }
    }
    &.is-active.has-preview.has-line-2 {
      #{$this}-form__block {
        height: 311px;
      }
    }
    &.is-active.has-preview.has-line-3 {
      #{$this}-form__block {
        height: 332px;
      }
    }
    &.is-active.has-preview.has-line-4 {
      #{$this}-form__block {
        height: 353px;
      }
    }
    &.is-active.has-preview.has-line-5 {
      #{$this}-form__block {
        height: 374px;
      }
    }
    &.is-active.has-preview.has-line-6 {
      #{$this}-form__block {
        height: 395px;
      }
    }

    &::before {
      border-top: 1px $appColorGrayScale6 solid;
      content: '';
      left: 40px;
      position: absolute;
      width: calc(100% - 80px);
    }

    .loader-wrapper {
      .preloader {
        position: absolute;
      }
    }

    //　-----------------------------
    // 無効化中
    //　-----------------------------
    &__toggle {
      align-items: center;
      border-radius: 2px 2px 0 0;
      cursor: pointer;
      // display: flex;
      display: none;
      height: 30px;
      justify-content: center;
      position: absolute;
      left: calc(50% - 15px);
      top: 2px;
      width: 30px;
      i {
        color: $appColorGrayScale3;
        font-size: 14px;
        margin-top: 10px;
        transition: margin 0.3s, transform 0.3s;
        &.is-active {
          color: $appColorPrimary1;
          margin-top: 0;
        }
      }
    }

    &__block {
      align-items: flex-start;
      display: flex;
      flex-direction: column;
      height: 40px;
      max-height: 40px;
      overflow: hidden;
      padding: 0 40px 0 40px;
      // transition: height .3s, opacity .3s;
      transition: opacity 0.3s;
      width: 100%;
    }

    &__item {
      margin: 0;
      width: 100%;
      &-group {
        &--preview {
          display: flex;
          margin: 12px 0 12px 0;
          padding: 0;
          position: relative;
          width: 100%;
          #{$this}-form__item {
            &--message-file {
              @include fadeOut;
              top: -10px;
              position: absolute;
              right: 10px;
              width: auto;
              .form-group {
                &.text-danger {
                  .invalid-feedback {
                    bottom: 30px;
                    top: auto;
                    right: -10px;
                    width: 240px;
                    z-index: 1000;
                    &::before {
                      right: 12px;
                    }
                  }
                }
              }
            }
          }
        }
        &--body {
          align-items: flex-start;
          display: flex;
          justify-content: flex-start;
          margin: 0 0 0 0;
          padding: 0;
          overflow: hidden;
          position: relative;
          width: 100%;
        }
      }

      .form-group {
        margin-bottom: 0;
        width: 100%;
      }

      &--preview {
        margin: 0;
        width: 100%;
      }

      &--message-body {
        margin: 0 0 0 0;
        width: calc(100% - 100px);
        .c-input {
          height: 44px;
          resize: none;
        }
        .form-group {
          &.text-danger {
            .invalid-feedback {
              top: -40px;
              z-index: 1000;
            }
          }
        }
      }
      &--message-file {
        margin: 12px 16px 0 10px;
        width: 20px;
        .form-group {
          &.text-danger {
            .invalid-feedback {
              bottom: 30px;
              top: auto;
              right: -190px;
              width: 220px;
              z-index: 1000;
              &::before {
                right: 190px;
              }
            }
          }
        }
        .c-upload {
          .dropzone {
            background: transparent !important;
            border: none;
            min-height: auto;
            height: auto;
            margin: 0;
            padding: 0;
            width: auto;
            .dz-message {
              padding: 0;
            }
          }
          &.is-disabled {
            .c-btn {
              opacity: 0.4;
            }
          }
          .c-btn {
            background: $appColorWhite !important;
            border: transparent !important;
            border-radius: $appRadiusS;
            height: 20px;
            margin: 0;
            padding: 0;
            width: 20px;
            i {
              color: $appColorGrayScale2;
              font-size: $appFontSize2XL;
              margin-left: 2px;
              transform: rotate(45deg);
            }
            &:hover {
              i {
                color: $appColorGrayScale2;
              }
            }
          }
        }
      }
      &--btns {
        align-items: flex-end;
        display: flex;
        justify-content: flex-end;
        height: 100%;
        margin: -2px 0 0 14px;
        position: relative;
        transition: margin 0.3s;
        width: 40px;
        .c-btn {
          border-radius: $appRadiusS;
          height: 40px;
          margin: 0;
          padding: 0;
          width: 40px;
          &.is-loading {
            i {
              display: none;
            }
          }
          &--white {
            &:disabled {
              background: transparent !important;
            }
          }
          i {
            font-size: $appFontSizeXL;
            margin-left: 2px;
          }
        }
      }
    }
  }

  &-control {
    @include fadeOut;
    left: auto;
    position: absolute;
    right: 40px;
    top: -20px;
    transition: opacity 0.1s;
    z-index: 1001;
    &-list {
      align-items: center;
      background: $appColorWhite;
      border: none;
      border: 1px solid $appColorGrayScale6;
      border-radius: $appRadiusL;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      list-style: none;
      margin: 0;
      padding: 0;
      width: auto;
      &__item {
        border: none;
        border-radius: 0;
        margin: 0;
        padding: 0;
        width: auto;
        &:hover {
          background: $appColorGrayScale8;
          // background: $appColorGrayScale9;
        }
        &:first-child {
          border-bottom-left-radius: $appRadiusL;
          border-top-left-radius: $appRadiusL;
        }
        &:last-child {
          border-bottom-right-radius: $appRadiusL;
          border-top-right-radius: $appRadiusL;
        }
      }
    }
    .c-menu-icon {
      background: transparent;
      i {
        color: $appColorGrayScale2;
        font-size: $appFontSizeL;
        &.mdi {
          &-cloud-download {
            font-size: $appFontSizeL;
          }
          &-pencil {
            font-size: $appFontSizeL;
          }
          &-content-copy {
            font-size: $appFontSizeBase;
          }
          &-trash-can {
            font-size: $appFontSizeL;
          }
        }
      }
    }
  }

  &-participant {
    &-list {
      height: 40px;
      right: 40px;
      overflow: visible;
      position: absolute;
      top: 28px;
      width: 40px;
    }
  }

  .simplebar-vertical {
    background: $appColorGrayScale8;
    border-radius: 20px;
    margin: 10px 16px 10px 0;
    width: 6px;
    .simplebar-scrollbar {
      background: $appColorGrayScale3;
      border-radius: 20px;
      width: 6px;
    }
  }

  .simplebar-horizontal {
    background: $appColorGrayScale8;
    border-radius: 20px;
    height: 6px;
    margin: 0;
    width: 100%;
    .simplebar-scrollbar {
      background: $appColorGrayScale3;
      border-radius: 20px;
      height: 6px !important;
      top: 0;
    }
  }
}

@media (max-width: 1000px) {
  .p-chat-message {
    $this: &;
    &-list {
      // フッターサイズが変わるため調整
      // 0pxの所は、もともと、115px(フッターエリア + 余白用)
      &-wrapper {
        height: calc(100vh - 0px - 72px - 65px - 35px - 33px - 40px);
        &.has-form {
          height: calc(100vh - 0px - 72px - 65px - 35px - 33px - 100px);
        }
        &.has-form.has-line-2 {
          height: calc(100vh - 0px - 72px - 65px - 35px - 33px - 120px);
        }
        &.has-form.has-line-3 {
          height: calc(100vh - 0px - 72px - 65px - 35px - 33px - 140px);
        }
        &.has-form.has-line-4 {
          height: calc(100vh - 0px - 72px - 65px - 35px - 33px - 160px);
        }
        &.has-form.has-line-5 {
          height: calc(100vh - 0px - 72px - 65px - 35px - 33px - 180px);
        }
        &.has-form.has-line-6 {
          height: calc(100vh - 0px - 72px - 65px - 35px - 33px - 200px);
        }
        &.has-form.has-preview {
          height: calc(100vh - 0px - 72px - 65px - 35px - 33px - 200px - 100px);
        }
        &.has-form.has-preview.has-line-2 {
          height: calc(100vh - 0px - 72px - 65px - 35px - 33px - 200px - 120px);
        }
        &.has-form.has-preview.has-line-3 {
          height: calc(100vh - 0px - 72px - 65px - 35px - 33px - 200px - 140px);
        }
        &.has-form.has-preview.has-line-4 {
          height: calc(100vh - 0px - 72px - 65px - 35px - 33px - 200px - 160px);
        }
        &.has-form.has-preview.has-line-5 {
          height: calc(100vh - 0px - 72px - 65px - 35px - 33px - 200px - 180px);
        }
        &.has-form.has-preview.has-line-6 {
          height: calc(100vh - 0px - 72px - 65px - 35px - 33px - 200px - 200px);
        }
      }
    }
  }
}

@media (max-width: $appBreakPoint) {
  .p-chat-message {
    $this: &;

    &-head {
      padding: 24px 24px 0 24px;
      top: 50px;
      &__item {
        &--title {
          padding: 0 46px 14px 0;
          width: 100%;
          .c-text {
            font-size: $appFontSizeBase;
          }
        }
      }
    }

    &-sender {
      margin: 4px 0 8px 0;
      &__name {
        font-size: $appFontSizeS;
      }
      &__date {
        font-size: $appFontSizeXS;
      }
      &__data {
        padding: 0 0 0 8px;
      }
    }

    &-body {
      margin: 4px 0 4px 0;
      &__text {
        background: $appColorGrayScale8;
        border-radius: $appRadiusM;
        box-sizing: border-box;
        line-height: 1.8em;
        margin: 0 0 0 0;
        padding: 8px 12px;
      }
    }

    &-file {
      padding: 10px 35px 8px 12px;
      &__document {
        box-sizing: border-box;
        i {
          font-size: 38px;
          margin: 0 6px 0 -4px;
        }
      }
      &__name {
        @include ellipsis;
        width: calc(100%);
      }
    }

    &-image {
      &__item {
        max-height: 180px;
        max-width: 180px;
      }
      &.is-loading {
        height: 140px;
        width: 140px;
      }
    }

    &-body {
      &__text {
        font-size: $appFontSizeS;
        padding: 8px 10px;
      }
    }

    &-timeline,
    &-status-change {
      margin: 16px auto 0 auto;
      width: calc(100% - 48px);
      &::before {
        top: 15px;
      }
      &__item {
        font-size: $appFontSizeS;
        padding: 4px 20px;
      }
    }

    &-list {
      padding: 0 0 30px 0;
      &-wrapper {
        height: auto !important;
      }
      &__item {
        padding: 0 24px;
        &:hover {
          #{$this}-control {
            @include fadeIn;
          }
        }
        &.is-active {
          background: $appColorGrayScale8;
          // background: $appColorGrayScale9;
          #{$this}-control {
            @include fadeIn;
          }
        }
        &--has-sender {
          margin-top: 12px;
        }
        &--is-self {
          content: #{$this}-sender;
          #{$this}-sender {
            flex-direction: row-reverse;
            &__data {
              padding: 0 8px 0 0;
            }
          }
          #{$this}-control {
            left: 24px;
            right: auto;
          }
        }
      }
    }

    &-form {
      // &.is-active {
      //   #{$this}-form__item {
      //     &-group {
      //       &--body {
      //         #{$this}-form__item {
      //           &--message-body {
      //             min-height: 210px;
      //             .c-input {
      //               height: 100px;
      //             }
      //           }
      //           &--btns {
      //             margin-top: 60px;
      //           }
      //         }
      //       }
      //     }
      //   }
      // }

      &.is-active {
        #{$this}-form__block {
          @include fadeIn;
          height: 86px;
        }
      }

      &.is-active.has-line-2 {
        #{$this}-form__block {
          height: 104.5px;
          #{$this}-form__item-group--body {
            #{$this}-form__item {
              &--message-body {
                .c-input {
                  height: 58.5px;
                }
              }
              &--btns {
                margin-top: 0;
              }
            }
          }
        }
      }

      &.is-active.has-line-3 {
        #{$this}-form__block {
          height: 123px;
          #{$this}-form__item-group--body {
            #{$this}-form__item {
              &--message-body {
                .c-input {
                  height: 77px;
                }
              }
            }
          }
        }
      }

      &.is-active.has-line-4 {
        #{$this}-form__block {
          height: 141.5px;
          #{$this}-form__item-group--body {
            #{$this}-form__item {
              &--message-body {
                .c-input {
                  height: 95.5px;
                }
              }
            }
          }
        }
      }

      &.is-active.has-line-5 {
        #{$this}-form__block {
          height: 160px;
          #{$this}-form__item-group--body {
            #{$this}-form__item {
              &--message-body {
                .c-input {
                  height: 114px;
                }
              }
            }
          }
        }
      }

      &.is-active.has-line-6 {
        #{$this}-form__block {
          height: 178.5px;
          #{$this}-form__item-group--body {
            #{$this}-form__item {
              &--message-body {
                .c-input {
                  height: 132.5px;
                }
              }
            }
          }
        }
      }

      &.is-active.has-preview {
        #{$this}-form__block {
          @include fadeIn;
          height: 238px;
        }
      }
      &.is-active.has-preview.has-line-2 {
        #{$this}-form__block {
          height: 256.5px;
        }
      }
      &.is-active.has-preview.has-line-3 {
        #{$this}-form__block {
          height: 275px;
        }
      }
      &.is-active.has-preview.has-line-4 {
        #{$this}-form__block {
          height: 293.5px;
        }
      }
      &.is-active.has-preview.has-line-5 {
        #{$this}-form__block {
          height: 312px;
        }
      }
      &.is-active.has-preview.has-line-6 {
        #{$this}-form__block {
          height: 330.5px;
        }
      }

      &::before {
        left: 24px;
        width: calc(100% - 48px);
      }

      &__block {
        height: 40px;
        max-height: 40px;
        padding: 0 24px 0 24px;
      }

      &__item {
        margin: 0;
        width: 100%;
        &-group {
          margin: 12px 0 12px 0;
          padding: 0;
          position: relative;
          width: 100%;
          &--preview {
            align-items: flex-end;
          }
          &--body {
            margin: 0 0 0 0;
          }
        }

        &-control {
          align-items: center;
          box-sizing: border-box;
          display: flex;
          justify-content: flex-end;
          margin: 0;
          // padding: 0 5px 0 0;
          position: relative;
          // width: calc(100% - 40px);
          width: 40px;
        }

        .form-group {
          margin-bottom: 0;
          width: 100%;
        }

        &--preview {
          margin: 0;
          width: 100%;
        }

        &--message-body {
          width: calc(100% - 80px);
          .c-input {
            height: 40px;
          }
          .form-group {
            &.text-danger {
              .invalid-feedback {
                top: -40px;
              }
            }
          }
        }

        &--message-file {
          margin: 12px 10px 0 6px;
          width: 20px;
          .form-group {
            &.text-danger {
              .invalid-feedback {
                left: -15px;
                min-width: 180px;
                right: auto;
                width: auto;
                &::before {
                  left: 22px;
                  right: auto;
                }
              }
            }
          }
        }

        &--btns {
          margin: -2px 0 0 8px;
          width: 36px;
          .c-btn {
            height: 36px;
            width: 36px;
            i {
              font-size: $appFontSizeXL;
              margin-left: 2px;
            }
          }
        }
      }
    }

    &-preview {
      &__name,
      &__size {
        font-size: $appFontSizeXS;
        max-width: 100px;
      }

      &-list {
        box-sizing: border-box;
        max-height: 152px;
        min-height: 152px;
        padding: 10px 0 15px 0;
        &__item {
          margin-left: 26px;
          .c-btn {
            &--delete {
              left: -18px;
              height: 30px;
              top: -9px;
              width: 30px;
              i {
                font-size: $appFontSizeL;
              }
            }
          }
          #{$this}-image {
            &.is-loading {
              height: 100px;
              width: 100px;
            }
            &__item {
              max-height: 100px;
              max-width: 100px;
              min-height: 100px;
              min-width: 100px;
            }
          }
          #{$this}-file {
            max-height: 100px;
            max-width: 100px;
            min-height: 100px;
            min-width: 100px;
            i {
              font-size: 70px;
            }
          }
        }
      }
    }

    &-control {
      right: 24px;
      top: -20px;
    }

    &-participant {
      &-list {
        right: 18px;
        top: 16px;
      }
    }
  }
}
