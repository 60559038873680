.c-message {
  $this: &;
  background: $appColorSecondary6;
  border-radius: 4px;
  color: $appColorWhite;
  display: none;
  margin: 0 auto;
  padding: 25px 25px;
  position: relative;
  &.is-active {
    display: flex;
  }
  &__btn {
    color: #fff;
    &:hover {
      color: #fff;
    }
  }
  &--error {
    background: $appColorFreeRed;
    #{$this} {
      &-list {
        &__item {
          color: #fff;
        }
      }
    }
  }
  &__btn {
    font-size: $appFontSize2XL;
    margin: 0;
    padding: 0;
    position: absolute;
    right: 10px;
    top: 10px;
    line-height: 0;
  }
  &-list {
    list-style: none;
    margin: 0;
    padding: 0;
    width: 100%;
    &__item {
      background: transparent;
      border: none;
      font-size: $appFontSizeM;
      font-weight: $appFontWeightExtraBold;
      padding: 0;
      .link {
        display: block;
        a {
          color: $appColorWhite;
          display: inline;
          text-decoration: none;
          &:hover,
          &:visited,
          &:active {
            color: $appColorWhite;
            text-decoration: underline;
          }
        }
        .icon {
          display: inline;
          padding: 0 0 0 8px;
        }
      }
    }
    &__item + &__item {
      margin-top: 5px;
    }
  }
}
@media (max-width: $appBreakPoint) {
  $this: &;
  .c-message {
    &-list {
      &__item {
        font-size: $appFontSizeM;
      }
    }
  }
}
