.p-joined-project-recruit-status-form {
  $this: &;
  .c-message {
    &--error {
      margin-bottom: 20px;
    }
  }
  &__item {
    display: flex;
    flex-direction: column;
    margin: 0 0 15px 0;
    position: relative;
    width: 100%;
    .c-input {
      &--message {
        min-height: 200px;
        max-height: 500px;
      }
    }
    .form-group {
      margin-bottom: 0;
      label {
        font-size: $appFontSizeM;
        font-weight: $appFontWeightExtraBold;
        margin-bottom: 5px;
      }
    }
    &.is-empty {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      justify-content: center;
      margin: 0;
      padding: 30px 0;
      width: 100%;
      p {
        margin: 0;
        padding: 0;
        text-align: center;
      }
      // .c-btn {}
    }
    &--btns {
      margin: 30px 0 20px 0;
      button.c-btn {
        font-size: $appFontSizeL;
      }
      .c-btn {
        margin: 0 auto;
        text-align: center;
        width: 265px;
      }
    }
  }
  &--preview {
    .p-joined-project-recruit-status-form {
      &__item {
        .label,
        .value {
          flex-basis: inherit;
          flex-grow: initial;
          font-size: $appFontSizeM;
          margin-bottom: 5px;
          padding: 0;
        }
        .label {
          font-weight: $appFontWeightExtraBold;
        }
        &--caption {
          .value {
            margin-bottom: 0;
            padding: 10px 0;
          }
          p {
            margin-bottom: 0;
          }
        }
        &--message {
          .value {
            background: $appColorGrayScale8;
            border-radius: $appRadiusM;
            box-sizing: border-box;
            padding: 12px;
            overflow-y: auto;
            max-height: 200px;
          }
        }
        &--btns {
          align-items: center;
          display: flex;
          flex-direction: row;
          justify-content: center;
          .c-btn {
            margin: 0 0;
            width: 190px;
          }
          .c-btn + .c-btn {
            margin-left: 7.5px;
          }
        }
      }
    }
  }
  &--complete {
    flex-direction: column;
    padding: 45px 0 80px 0;
    .p-joined-project-recruit-status-form {
      &__item {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        justify-content: center;
        margin: 0;
        padding: 0;
        width: 100%;
        &--image {
          margin-bottom: 30px;
          i {
            color: $appColorBlack;
            font-size: 60px;
            line-height: 60px;
            margin: 0 auto;
          }
        }
        &--caption {
          font-size: $appFontSize3XL;
          font-weight: $appFontWeightBold;
          line-height: $appFontSize3XL + 8px;
          margin-bottom: 25px;
          text-align: center;
        }
        &--text {
          text-align: center;
          width: 100%;
          p {
            margin-bottom: 0;
          }
        }
        &--btns {
          justify-content: center;
          margin: 35px 0 20px 0;
          .c-btn {
            width: 265px;
            &--close-ps-modal {
              width: 300px;
            }
          }
        }
      }
    }
  }
}

@media (max-width: $appBreakPoint) {
  .p-joined-project-recruit-status-form {
    $this: &;
    &__item {
      margin: 0 0 15px 0;
      .form-group {
        label {
          font-size: $appFontSizeS;
          margin-bottom: 5px;
        }
        .c-input {
          font-size: $appFontSizeS;
          &--message {
            min-height: 150px;
            max-height: 150px;
          }
        }
        .c-select {
          &__menu {
            font-size: $appFontSizeS;
          }
          &__single-value {
            color: $appColorBlack !important;
            font-size: $appFontSizeS !important;
          }
        }
      }
      &--btns {
        margin: 20px 0 10px 0;
        .c-btn {
          margin-left: 0;
          margin-right: 0;
          width: 100%;
        }
        .c-btn + .c-btn {
          margin-top: 15px;
        }
      }
    }
    &--preview {
      .p-joined-project-recruit-status-form {
        &__item {
          margin-bottom: 10px;
          .label,
          .value {
            font-size: $appFontSizeS;
            margin-bottom: 0;
          }
          &--message {
            .value {
              padding: 10px;
              min-height: 150px;
              max-height: 150px;
            }
          }
          &--btns {
            // flex-direction: column-reverse !important;
            flex-direction: column !important;
            .c-btn {
              // margin: 15px 0 0 0;
              margin: 0;
              width: 100%;
            }
            .c-btn + .c-btn {
              // margin: 0 0;
              margin: 15px 0 0 0;
            }
          }
        }
      }
    }
    &--complete,
    &--empty {
      flex-direction: column;
      padding: 20px 0 10px 0;
      .p-joined-project-recruit-status-form {
        &__item {
          display: flex;
          flex-direction: column;
          flex-wrap: wrap;
          justify-content: center;
          margin: 0;
          padding: 0;
          width: 100%;
          &--image {
            margin-bottom: 30px;
            i {
              color: $appColorBlack;
              font-size: 50px;
              line-height: 50px;
              margin: 0 auto;
            }
          }
          &--caption {
            font-size: $appFontSizeL;
            margin-bottom: 10px;
          }
          &--text {
            font-size: $appFontSizeS;
          }
          &--btns {
            margin: 35px 0 0 0;
            .c-btn {
              width: 100%;
            }
          }
        }
      }
    }
  }
}
