.l-page {
  $this: &;
  &--auth {
    align-items: center;
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    // height: 100%;
    min-height: calc(100% + 50px);
    justify-content: center;
    #{$this}-content {
      margin: 0 auto;
      padding: 0;
    }
  }
}

@media (max-height: 650px) {
  .l-page {
    &--auth {
      align-items: flex-start;
    }  
  }
}

@media (max-width: $appBreakPoint) {
  .l-page {
    &--auth {
      display: block;
      height: auto;
      margin: 0 auto;
      min-height: 100%;
    }
  }
}
