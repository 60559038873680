body {
  .Toastify {
    &__toast {
      border-radius: 4px;
      box-shadow: none;
      padding: 12px;
      &-container {
        width: 400px;
      }
      &--success {
        background: $appColorPrimary3;
        color: $appColorWhite;
        font-size: $appFontSizeM;
        font-weight: $appFontWeightExtraBold;
      }
      &--error {
        background: $appColorFreeRed;
        color: $appColorWhite;
        font-size: $appFontSizeM;
        font-weight: $appFontWeightExtraBold;
      }
    }
  }

  @media (max-width: $appBreakPoint) {
    .Toastify {
      &__toast {
        margin-bottom: 10px;
        padding: 8px;
        &-container {
          left: 5% !important;
          right: auto !important;
          top: 30px !important;
          width: 90%;
        }
        &--success {
          font-size: $appFontSizeS;
        }
        &--error {
          font-size: $appFontSizeS;
        }
      }
    }
  }   
}
