.p-joined-company {
  $this: &;
  margin: 0 auto 15px auto;

  .row {
    margin: 0;
  }

  .p-error {
    &__desc {
      margin-top: 25px;
    }
  }

  &-head {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    width: 100%;

    &__container {
      align-items: center;
      background: $appColorGrayScale16;
      border-radius: $appRadiusM;
      display: flex;
      flex-wrap: wrap;
      gap: 0 12px;
      justify-content: flex-start;
      padding: 36px 24px 28px 24px;
      width: 100%;
      &.row {
        margin-bottom: 30px;
      }
    }

    &__item {
      .label,
      .value {
        &-group {
          &--title {
            width: calc(100% - 520px);
          }
          .value {
            &--title {
              justify-content: flex-start;
              min-width: 140px;
            }
          }
        }
        &--control {
          max-width: 80px;
          min-width: 80px;
          width: auto;
        }
      }
      &--view-status {
        gap: 32px;
        width: calc(100% - (300px + 200px + 150px) - (12px * 3));
        .c-checkbox {
          &__item {
            margin-right: 0;
          }
        }
        .c-btn {
          height: 40px;
          margin: 0 0 0 0;
          width: 120px;
        }
      }
      &--keyword {
        position: relative;
        max-width: 300px;
        min-width: 300px;
        transition: max-width 0.3s, min-width 0.3s, width 0.3s;
        width: 300px;
        .c-icon {
          color: $appColorGrayScale6;
          font-size: 36px;
          left: 1px;
          position: absolute;
          top: 8px;
          transform: rotate(-45deg);
          z-index: 1001;
        }
        .c-input {
          border-radius: 50px;
          padding-left: 36px;
          padding-right: 18px;
          transition: width 0.3s;
          width: 100%;
          z-index: 1000;
        }
        .form-group {
          margin: 0;
          width: 100%;
          .invalid-feedback {
            top: -40px;
          }
        }
      }
      &--role {
        position: relative;
        max-width: 150px;
        min-width: 150px;
        width: 150px;
        .form-group {
          margin: 0;
          width: 100%;
          label {
            font-size: $appFontSizeXS - 1;
            font-weight: $appFontWeightExtraBold;
            left: 5px;
            position: absolute;
            top: -22px;
          }
          .invalid-feedback {
            bottom: 48px;
            top: auto;
            width: 170px;
          }
        }
      }
      &--btns {
        max-width: 200px;
        min-width: 200px;
        transition: max-width 0.1s, min-width 0.1s, width 0.1s;
        width: 200px;
        .c-btn {
          height: 40px;
          margin: 0;
          transition: max-width 0.1s, min-width 0.1s, width 0.1s;
          width: 100%;
        }
      }
    }
  }

  &-link-official-tag {
    display: flex;
    font-size: $appFontSizeM;
    margin: 0px 0 30px 0;
    width: 100%;
    &__text {
      display: block;
      text-align: left;
      width: 100%;
      a {
        color: $appColorPrimary4 !important;
        font-weight: $appFontWeightExtraBold;
        text-decoration: none !important;
        &:hover,
        &:active,
        &:visited {
          text-decoration: underline !important;
        }
      }
    }
  }
}

@media (max-width: 1240px) {
  .p-joined-company {
    $this: &;
    &.is-readonly {
      #{$this}-head {
        &__container {
          gap: 0 12px;
          justify-content: flex-start;
          padding: 36px 24px 28px 24px;
        }
        &__item {
          &--view-status {
            width: calc(100% - (300px + 200px + 150px) - (12px * 3));
          }
        }
      }
    }
    &-head {
      &__container {
        gap: 24px 12px;
        justify-content: flex-end;
        padding: 36px 24px 24px 24px;
      }
      &__item {
        &--view-status {
          width: calc(100% - (300px + 150px) - (12px * 2));
        }
      }
    }
  }
}

@media (max-width: 900px) {
  .p-joined-company {
    $this: &;
    &.is-readonly {
      #{$this}-head {
        &__container {
          gap: 0 12px;
          justify-content: flex-start;
          padding: 36px 24px 28px 24px;
        }
        &__item {
          &--view-status {
            width: calc(100% - (300px + 200px + 150px) - (12px * 3));
          }
        }
      }
    }
    &-head {
      &__container {
        gap: 24px 12px;
        justify-content: flex-end;
        padding: 36px 24px 24px 24px;
      }
      &__item {
        &--view-status {
          gap: 24px;
          min-width: 100%;
          width: 100%;
        }
        &--keyword {
          max-width: calc(100% - (150px + 160px) - (12px * 2));
          min-width: calc(100% - (150px + 160px) - (12px * 2));
          width: calc(100% - (150px + 160px) - (12px * 2));
          .form-group,
          .c-input {
            min-width: 100%;
            width: 100%;
          }
        }
        &--btns {
          max-width: 160px;
          min-width: 160px;
          width: 160px;
        }
      }
    }
  }
}

@media (max-width: $appBreakPoint) {
  .p-joined-company {
    $this: &;
    margin: 0 auto 3px auto;
    &.is-readonly {
      #{$this} {
        &-head {
          &__container {
            gap: 18px 0;
            justify-content: space-between;
            padding: 20px;
          }
          &__item {
            &--view-status {
              display: none;
            }
            &--keyword {
              max-width: 100%;
              min-width: 100%;
              width: 100%;
            }
          }
        }
      }
    }
    &--public {
      &.is-readonly {
        #{$this}-head {
          &__item {
            &--member-type {
              max-width: 100%;
              min-width: 100%;
              width: 100%;
            }
          }
        }
      }
    }

    &-link-official-tag {
      margin: 8px 0 24px 0;
      &__text {
        display: block;
        text-align: left;
        width: 100%;
        a {
          background: linear-gradient(
            92.43deg,
            #25aae1 -9.19%,
            rgba(64, 228, 149, 0.8) 87.61%,
            rgba(48, 221, 138, 0.5) 121.75%
          );
          border-radius: $appRadiusM;
          box-sizing: border-box;
          color: $appColorWhite !important;
          display: block;
          padding: 16px 20px;
          position: relative;
          font-weight: $appFontWeightExtraBold;
          text-align: center;
          text-decoration: none !important;
          width: 100%;
          &::after {
            background: linear-gradient(
              92.43deg,
              rgba(48, 221, 138, 0.5) -9.19%,
              rgba(64, 228, 149, 0.8) 37.61%,
              #25aae1 91.75%
            );
            content: '';
            display: block;
            height: 100%;
            left: 0;
            opacity: 0;
            position: absolute;
            top: 0;
            transition: 1s;
            width: 100%;
            z-index: -1;
          }
          &:hover,
          &:active,
          &:visited {
            text-decoration: none !important;
            &::after {
              opacity: 1;
            }
          }
        }
      }
    }

    &-head {
      &__container {
        gap: 18px 0;
        justify-content: space-between;
        padding: 20px;
        &.row {
          margin-bottom: 16px;
        }
      }
      &__item {
        &--company-name {
          .c-text {
            font-size: $appFontSizeL;
            line-height: 1.4em;
          }
        }
        &--view-status {
          gap: 0;
          max-width: 100%;
          min-width: 100%;
          width: 100%;
          .c-checkbox {
            &__item {
              width: 150px;
              label {
                justify-content: flex-start;
                width: 100%;
              }
            }
          }
          .c-btn {
            width: calc(100% - 150px);
          }
        }
        &--keyword {
          max-width: 100%;
          min-width: 100%;
          width: 100%;
          .c-input {
            width: 100%;
          }
        }
        &--role {
          &.row {
            margin-top: 16px;
          }
          max-width: 100%;
          min-width: 100%;
          width: 100%;
        }
        &--btns {
          max-width: 100%;
          min-width: 100%;
          width: 100%;
        }
      }
    }
  }
}
