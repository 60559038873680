.p-signup {
  $this: &;

  &,
  &.card {
    background: $appColorWhite;
    border-radius: $appRadiusXL;
    box-sizing: border-box;
    padding: 40px 40px;
    width: 500px;
  }

  .c-message {
    width: 100%;
    &--error {
      margin-bottom: 25px;
    }
  }

  &-nav {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 30px auto 0 auto;
    width: 100%;
    a {
      color: $appColorPrimary4;
      font-size: $appFontSizeM;
      font-weight: $appFontWeightExtraBold;
      position: relative;
      text-align: center;
      &:hover,
      &:active,
      &:visited {
        color: $appColorPrimary4;
      }
      &:hover {
        text-decoration: underline;
      }
    }
    &--logo {
      align-items: center;
      display: flex;
      flex-direction: row;
      gap: 24px;
      justify-content: center;
      margin: 25px auto 0 auto;
      img {
        &.logo-sharing-economy {
          width: 200px;
        }
      }
    }
    // &--truste {
    //   display: flex;
    //   justify-content: center;
    //   margin: 25px auto 0 auto;
    // }
    // &--sharing-economy {
    //   display: flex;
    //   justify-content: center;
    //   img {
    //     max-width: 280px;
    //     width: 92%;
    //   }
    // }
  }

  &__title {
    align-items: center;
    display: flex;
    justify-content: center;
    margin: 0 0 25px 0;
    position: relative;
    width: 100%;
    .col {
      background: #fff;
      box-sizing: border-box;
      color: $appColorBlack;
      flex-basis: auto;
      flex-grow: inherit;
      font-size: $appFontSize3XL;
      font-weight: $appFontWeightBold;
      margin: 0;
      padding: 10px;
      width: auto;
      z-index: 101;
    }
    &::after {
      background: $appColorGrayScale6;
      // content: ' ';
      content: '\a0';
      height: 1px;
      position: absolute;
      width: 100%;
      z-index: 100;
    }
    &--sub {
      align-items: center;
      display: flex;
      justify-content: center;
      margin: 35px 0 25px 0;
      position: relative;
      width: 100%;
      .col {
        background: #fff;
        box-sizing: border-box;
        color: $appColorGrayScale6;
        flex-basis: auto;
        flex-grow: inherit;
        font-size: $appFontSizeL;
        font-weight: $appFontWeightBold;
        margin: 0;
        padding: 10px;
        width: auto;
        z-index: 101;
      }
      &::after {
        background: $appColorGrayScale6;
        // content: ' ';
        content: '\a0';
        height: 1px;
        position: absolute;
        width: 100%;
        z-index: 100;
      }
    }
  }

  &--confirm {
    .p-signup {
      &__title {
        margin: 0 0 25px 0;
        .col {
          color: $appColorBlack;
          font-size: $appFontSize3XL;
        }
      }
      &__desc {
        color: $appColorBlack;
        font-size: $appFontSizeM;
        margin: 0;
        .col {
          padding: 0;
          span {
            display: block;
          }
          .supply {
            padding-top: 12px;
            a {
              color: $appColorPrimary4;
              position: relative;
              text-align: center;
              &:hover,
              &:active,
              &:visited {
                color: $appColorPrimary4;
              }
              &:hover {
                text-decoration: underline;
              }
            }
          }
        }
      }
      &-form {
        margin-top: 35px;
      }
    }
  }

  &__item {
    margin: 0 0 35px 0;
    &--google,
    &--facebook {
      margin-bottom: 20px;
    }
    &--apple {
      margin-bottom: 0;
    }
  }

  &-form {
    &__item {
      display: flex;
      flex-direction: column;
      margin: 0 0 25px 0;
      position: relative;
      width: 100%;
      .c-icon {
        z-index: 101;
      }
      .c-tooltip {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        &.is-pc {
          .c-tooltip__icon:hover + .c-tooltip__container,
          .c-tooltip__container:hover {
            left: 0;
          }
        }
        &__icon {
          z-index: 1000;
        }
      }
      .invalid-feedback {
        z-index: 1050;
      }
      .form-group {
        margin-bottom: 0;
        label {
          font-size: $appFontSizeM;
          font-weight: $appFontWeightExtraBold;
          margin-bottom: 5px;
          &.is-tooltip {
            margin-left: 30px;
          }
        }
        .c-input {
          z-index: 100;
          &--email,
          &--password,
          &--code {
            padding-left: 41px;
          }
          &--password {
            padding-right: 41px;
          }
        }
      }
      &--email {
        margin-top: 10px;
      }
      &--email,
      &--code,
      &--password {
        .c-icon {
          color: $appColorGrayScale6;
          font-size: $appFontSizeIconInputField1;
          left: 10px;
          position: absolute;
          top: 36px;
          &--eye {
            cursor: pointer;
            left: auto;
            right: 10px;
          }
        }
      }
      &--password {
        margin-bottom: 12px;
      }
      &--password-supply {
        margin-bottom: 20px;
        .line {
          font-size: $appFontSizeS;
          line-height: 1.6em;
          margin: 0;
          padding: 0;
        }
      }
      &--btns {
        margin: 25px 0 0 0;
        button.c-btn {
          font-size: $appFontSizeL;
        }
        .c-btn {
          margin-left: 0;
          margin-right: 0;
        }
        .c-btn + .c-btn {
          margin-top: 20px;
        }
      }
    }
  }

  .c-btn {
    width: 100%;
  }
}

@media (max-height: 1000px) {
  .p-signup {
    &,
    &.card {
      margin-top: 120px;
    }
    &--confirm,
    &--confirm.card {
      margin-top: 0px;
    }
    &-nav {
      &--logo {
        padding-bottom: 90px;
      }
      // &--sharing-economy {
      //   padding-bottom: 90px;
      // }
    }
  }
}

@media (max-height: 900px) {
  .p-signup {
    &,
    &.card {
      margin-top: 30px;
    }
  }
}

@media (max-width: $appBreakPoint) {
  .p-signup {
    &,
    &.card {
      border-radius: $appRadiusM;
      margin: 60px auto 0 auto;
      padding: 32px 24px;
      width: 92%;
    }

    .c-message {
      &--error {
        margin-bottom: 15px;
      }
    }

    &-nav {
      margin-top: 30px;
      padding-bottom: 0;
      a {
        font-size: $appFontSizeS;
      }
      &--logo {
        flex-direction: column;
        gap: 24px;
        img {
          &.logo-sharing-economy {
            max-width: 280px;
            width: 92%;
          }
        }
      }
    }

    &__title {
      margin: -6px 0 20px 0;
      .col {
        font-size: $appFontSizeL;
        padding: 10px;
      }
      &--sub {
        margin: 20px 0 20px 0;
        .col {
          font-size: $appFontSizeM;
          padding: 10px;
        }
      }
    }

    &--confirm {
      padding: 20px 25px !important;
      .p-signup,
      .p-signup.card {
        &__title {
          margin: 5px 0 10px 0;
          .col {
            font-size: $appFontSizeL;
            padding: 10px;
          }
        }
        &-form {
          margin-top: 10px;
        }
      }
    }

    &__item {
      margin: 0 0 15px 0;
      &--apple {
        margin-bottom: 0;
      }
    }

    &-form {
      padding-top: 10px;
      &__item {
        margin: 0 0 15px 0;

        .c-tooltip {
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          &.is-sp {
            .c-tooltip__container {
              left: 0;
              bottom: auto;
              top: 0;
              width: 100%;
            }
          }
        }

        .form-group {
          label {
            font-size: $appFontSizeS;
            margin-bottom: 5px;
            &.is-tooltip {
              margin-left: 25px;
            }
          }
          .c-input {
            font-size: $appFontSizeS;
          }
        }
        &--email {
          margin-top: -10px;
        }
        &--code,
        &--email,
        &--password {
          .c-icon {
            top: 37px;
          }
        }
        &--password {
          margin-bottom: 10px;
        }
        &--password-supply {
          margin-bottom: 15px;
          .line {
            font-size: $appFontSizeXS;
            line-height: 1.6em;
            margin: 0;
            padding: 0;
          }
        }
        &--btns {
          margin: 20px 0 0 0;
          .c-btn {
            margin-left: 0;
            margin-right: 0;
          }
          .c-btn + .c-btn {
            margin-top: 15px;
          }
        }
      }
    }

    .c-btn {
      &--go-signin {
        bottom: 0;
        left: 0;
        opacity: 0;
        overflow: hidden;
        visibility: hidden;
        max-width: 0;
      }
    }
  }
}
