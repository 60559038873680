.c-radio {
  .form-control {
    align-items: center;
    background: transparent;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    padding: 15px 0 !important;
    &.is-invalid {
      background: none;
    }
  }

  &__item {
    align-items: center;
    cursor: pointer;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-right: 18.5px;
    min-height: auto;
    padding-left: 0;

    .custom-control-label {
      align-items: center;
      color: $appColorBlack;
      cursor: pointer;
      display: flex;
      font-size: $appFontSizeM;
      font-weight: $appFontWeightNormal;
      justify-content: center;
      margin-bottom: 0;
      padding-left: 26px;
      user-select: none;
      &::before {
        border: 2px solid $appColorGrayScale6;
        cursor: pointer;
        height: $appRadioSizeM;
        left: 0;
        position: absolute;
        top: auto;
        width: $appRadioSizeM;
      }
      &::after {
        height: $appRadioSizeM;
        left: 0;
        position: absolute;
        top: auto;
        width: $appRadioSizeM;
      }
    }

    .custom-control-input {
      max-height: 0;
      max-width: 0;
      overflow: hidden;
    }

    .custom-control-input:checked ~ .custom-control-label {
      &::before {
        color: $appColorPrimary1;
        border-color: $appColorPrimary1;
        background-color: $appColorPrimary1;
      }
    }
    .custom-control-input:disabled:not(:checked) ~ .custom-control-label {
      color: $appColorBlack;
      &::before {
        background-color: $appColorGrayScale6;
        border-color: $appColorGrayScale6;
        color: $appColorGrayScale6;
      }
    }
    .custom-control-input:disabled:checked ~ .custom-control-label {
      color: $appColorBlack;
      &::before {
        background-color: $appColorGrayScale6;
        border-color: $appColorGrayScale6;
        color: $appColorGrayScale6;
      }
    }
  }

  &.was-validated .custom-control-input:valid ~ .custom-control-label,
  .custom-control-input.is-valid ~ .custom-control-label {
    color: $appColorBlack;
  }

  &.was-validated .custom-control-input:valid ~ .custom-control-label::before,
  .custom-control-input.is-valid ~ .custom-control-label::before {
    border-color: $appColorGrayScale6;
  }

  &.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before,
  .custom-control-input.is-valid:checked ~ .custom-control-label::before {
    color: $appColorPrimary1;
    border-color: $appColorPrimary1;
    background-color: $appColorPrimary1;
  }
}
