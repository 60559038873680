.dropzone {
  border-radius: 4px;
  border-style: dotted;
  border-width: 2px;
  margin-top: 10px;
  .dz-message {
    padding: 15px 20px 25px 20px;
    .dripicons-cloud-upload {
      color: #dee2e6;
      font-size: 50px;
    }
    .description {
      color: #ccc;
      font-size: 12px;
      margin-top: 10px;
      p {
        margin-bottom: 9px;
      }
    }
  }
  &-previews {
    position: relative;
    &-inner {
      display: flex;
    }
    &-image {
      max-width: 500px;
      width: 50%;
      img {
        height: auto;
        width: 100%;
      }
      &-data {
        font-size: 14px;
      }
    }
    .btn-delete {
      box-shadow: none;
      color: #333;
      font-size: 18px;
      padding: 0;
      position: absolute;
      right: 0px;
      top: 5px;
      transition: opacity 300ms, transform 500ms;
      &:hover {
        opacity: .9;
        // transform: rotate(180deg);
      }
    }
  }
}
