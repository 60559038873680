.p-joined-company-member {
  $this: &;
  margin: 0 auto 15px auto;

  .row {
    margin: 0;
  }

  .p-error {
    &__desc {
      margin-top: 25px;
    }
  }

  &.is-readonly {
    #{$this} {
      &-head {
        &__container {
          justify-content: flex-end;
        }
        &__item {
          &--view-status {
            display: none;
          }
          &--btns {
            display: none;
          }
        }
      }
    }
  }

  &--public {
    &.is-readonly {
      #{$this}-head {
        &__item {
          &--keyword {
            max-width: 400px;
            min-width: 400px;
          }
        }
      }
    }
  }

  &-head {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    width: 100%;

    &__container {
      align-items: center;
      background: $appColorGrayScale16;
      border-radius: 0 0 $appRadiusM $appRadiusM;
      display: flex;
      flex-wrap: wrap;
      gap: 0 12px;
      justify-content: flex-start;
      padding: 36px 24px 28px 24px;
      width: 100%;
      &.row {
        margin-bottom: 30px;
      }
    }

    &__item {
      .label,
      .value {
        &-group {
          &--title {
            width: calc(100% - 520px);
          }
          .value {
            &--title {
              justify-content: flex-start;
              min-width: 140px;
            }
          }
        }
        &--control {
          max-width: 80px;
          min-width: 80px;
          width: auto;
        }
      }
      &--company-name {
        &.row {
          margin-bottom: 0;
        }
        width: 100%;
        .c-text {
          border-bottom: 1px solid $appColorGrayScale12;
          display: block;
          font-size: $appFontSizeXL;
          font-weight: $appFontWeightExtraBold;
          line-height: 1.4em;
          padding-bottom: 8px;
          width: 100%;
        }
      }
      &--view-status {
        gap: 32px;
        width: calc(100% - (300px + 200px + 120px + 150px) - (12px * 4));
        .c-checkbox {
          &__item {
            margin-right: 0;
          }
        }
        .c-btn {
          height: 40px;
          margin: 0 0 0 0;
          width: 120px;
        }
      }
      &--keyword {
        position: relative;
        max-width: 300px;
        min-width: 300px;
        transition: max-width 0.3s, min-width 0.3s, width 0.3s;
        width: 300px;
        .c-icon {
          color: $appColorGrayScale6;
          font-size: 36px;
          left: 1px;
          position: absolute;
          top: 8px;
          transform: rotate(-45deg);
          z-index: 1001;
        }
        .c-input {
          border-radius: 50px;
          padding-left: 36px;
          padding-right: 18px;
          transition: width 0.3s;
          width: 100%;
          z-index: 1000;
        }
        .form-group {
          margin: 0;
          width: 100%;
          .invalid-feedback {
            top: -40px;
          }
        }
      }
      &--member-type {
        position: relative;
        max-width: 150px;
        min-width: 150px;
        width: 150px;
        .form-group {
          margin: 0;
          width: 100%;
          label {
            font-size: $appFontSizeXS - 1;
            font-weight: $appFontWeightExtraBold;
            left: 5px;
            position: absolute;
            top: -22px;
          }
          .invalid-feedback {
            bottom: 48px;
            top: auto;
            width: 170px;
          }
        }
      }
      &--status {
        position: relative;
        max-width: 120px;
        min-width: 120px;
        width: 120px;
        .form-group {
          margin: 0;
          width: 100%;
          label {
            font-size: $appFontSizeXS - 1;
            font-weight: $appFontWeightExtraBold;
            left: 5px;
            position: absolute;
            top: -22px;
          }
          .invalid-feedback {
            top: auto;
            bottom: 48px;
            width: 170px;
          }
        }
      }
      &--btns {
        max-width: 200px;
        min-width: 200px;
        width: 200px;
        .c-btn {
          height: 40px;
          margin: 0;
          width: 100%;
        }
      }
    }
  }
}

@media (max-width: 1380px) {
  .p-joined-company-member {
    $this: &;
    &-head {
      &__container {
        gap: 24px 12px;
        justify-content: flex-end;
        padding: 36px 24px 24px 24px;
      }
      &__item {
        &--view-status {
          width: calc(100% - (300px + 120px + 150px) - (12px * 3));
        }
      }
    }
  }
}

@media (max-width: 1030px) {
  .p-joined-company-member {
    $this: &;
    &.is-readonly {
      #{$this} {
        &-head {
          &__container {
            gap: 0 12px;
          }
          &__item {
            &--view-status {
              display: none;
            }
            &--keyword {
              max-width: 300px;
              min-width: 300px;
              width: 300px;
            }
          }
        }
      }
    }
    &--public {
      &.is-readonly {
        #{$this}-head {
          &__item {
            &--keyword {
              max-width: 400px;
              min-width: 400px;
            }
          }
        }
      }
    }
    &-head {
      &__container {
        gap: 24px 12px;
        justify-content: flex-end;
        padding: 36px 24px 24px 24px;
      }
      &__item {
        &--view-status {
          gap: 24px;
          min-width: 100%;
          width: 100%;
        }
        &--keyword {
          max-width: calc(100% - (120px + 150px + 160px) - (12px * 3));
          min-width: calc(100% - (120px + 150px + 160px) - (12px * 3));
          width: calc(100% - (120px + 150px + 160px) - (12px * 3));
          .form-group,
          .c-input {
            min-width: 100%;
            width: 100%;
          }
        }
        &--btns {
          max-width: 160px;
          min-width: 160px;
          width: 160px;
        }
      }
    }
  }
}

@media (max-width: 870px) {
  .p-joined-company-member {
    $this: &;
    &.is-readonly {
      #{$this} {
        &-head {
          &__container {
            gap: 0 12px;
            padding: 36px 24px 24px 24px;
          }
          &__item {
            &--view-status {
              display: none;
            }
            &--keyword {
              max-width: 240px;
              min-width: 240px;
              width: 240px;
            }
          }
        }
      }
    }
    &--public {
      &.is-readonly {
        #{$this}-head {
          &__item {
            &--keyword {
              max-width: 360px;
              min-width: 360px;
            }
          }
        }
      }
    }
    &-head {
      &__container {
        gap: 40px 12px;
        justify-content: flex-end;
        padding: 24px;
      }
      &__item {
        &--view-status {
          max-width: 212px;
          min-width: 212px;
          width: 212px;
        }
        &--keyword {
          max-width: calc(100% - (212px) - (12px * 1));
          min-width: calc(100% - (212px) - (12px * 1));
          width: calc(100% - (212px) - (12px * 1));
          .form-group,
          .c-input {
            min-width: 100%;
            width: 100%;
          }
        }
      }
    }
  }
}

@media (max-width: $appBreakPoint) {
  .p-joined-company-member {
    $this: &;
    margin: 0 auto 3px auto;
    &.is-readonly {
      #{$this} {
        &-head {
          &__container {
            gap: 18px 0;
            justify-content: space-between;
            padding: 20px;
          }
          &__item {
            &--view-status {
              display: none;
            }
            &--keyword {
              max-width: 100%;
              min-width: 100%;
              width: 100%;
            }
          }
        }
      }
    }
    &--public {
      &.is-readonly {
        #{$this}-head {
          &__item {
            &--member-type {
              max-width: 100%;
              min-width: 100%;
              width: 100%;
            }
          }
        }
      }
    }
    &-head {
      &__container {
        gap: 18px 0;
        justify-content: space-between;
        padding: 20px;
        &.row {
          margin-bottom: 16px;
        }
      }
      &__item {
        &--company-name {
          .c-text {
            font-size: $appFontSizeL;
            line-height: 1.4em;
          }
        }
        &--view-status {
          gap: 0;
          max-width: 100%;
          min-width: 100%;
          width: 100%;
          .c-checkbox {
            &__item {
              width: 150px;
              label {
                justify-content: flex-start;
                width: 100%;
              }
            }
          }
          .c-btn {
            width: calc(100% - 150px);
          }
        }
        &--keyword {
          max-width: 100%;
          min-width: 100%;
          width: 100%;
          .c-input {
            width: 100%;
          }
        }
        &--status,
        &--member-type {
          &.row {
            margin-top: 16px;
          }
          max-width: calc(50% - 6px);
          min-width: calc(50% - 6px);
          width: calc(50% - 6px);
        }
        &--btns {
          max-width: 100%;
          min-width: 100%;
          width: 100%;
        }
      }
    }
  }
}
