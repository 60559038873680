//
// シングルテキスト
//
.c-input {
  border: 1px solid $appColorGrayScale6;
  border-radius: 4px;
  box-sizing: border-box;
  color: $appColorBlack;
  font-size: $appFontSizeM;
  height: $appInputHeightM;
  padding: 5px 10px;
  transition: background .3s, border .3s;
  width: 100%;

  &.is-invalid {
    background-image: none;
    padding-right: 10px !important;
  }

  &.is-drag-over {
    background: $appColorGrayScale8;
  }

  &:focus {
    border: 1px solid $appColorPrimary1 !important;
  }

  &::placeholder {
    color: $appColorGrayScale5;
    font-weight: $appFontWeightNormal;
  }
  &:-webkit-autofill {
    box-shadow: 0 0 0px 1000px $appColorAutoFill inset;
  }
}

//
// テキストエリア
//
textarea.c-input {
  padding: 10px 10px;
}

//
// カレンダーUI, 時間選択リールUI
//
.c-input-date,
.c-input-time {
  &__container {
    position: relative;    
  }
  &__overlay {
    height: 100%;
    opacity: 0;
    position: absolute;
    width: 100%;
    z-index: 1001;
  }
  &__selector {
    border: 1px solid $appColorGrayScale6;
    border-radius: 4px;
    bottom: 0;
    box-sizing: border-box;
    color: #333;
    font-size: $appFontSizeM;
    height: $appInputHeightM;
    padding: 5px 10px;
    position: absolute;
    width: 100%;
    &.is-invalid {
      background-image: none;
      padding-right: 10px !important;
    }
    &:focus {
      border: 1px solid $appColorPrimary1 !important;
    }
    &::placeholder {
      color: $appColorGrayScale5;
      font-weight: $appFontWeightNormal;
    }
    &:-webkit-autofill {
      box-shadow: 0 0 0px 1000px $appColorAutoFill inset;
    }
    &::-webkit-inner-spin-button{
      -webkit-appearance: none;
    }
    &::-webkit-clear-button{
      -webkit-appearance: none;
    }
    &::-webkit-calendar-picker-indicator {
      height: 100%;
      opacity: 0;
      position: absolute;
      width: 100%;
    }
  }
}

.c-input-date {
  .c-input {
    // オリジナルのSVGコード: 色を変更したい時などは、オリジナルから背景埋め込み用のコードを再作成
    // https://blog.s0014.com/posts/2017-01-19-il-to-svg/
    // <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="43" height="42" viewBox="0 0 43 42">
    // <path fill="#dee2e6" d="M33.778 33.778h-24.889v-19.556h24.889M28.444 1.778v3.556h-14.222v-3.556h-3.556v3.556h-1.778c-1.973 0-3.556 1.582-3.556 3.556v24.889c0 1.956 1.6 3.556 3.556 3.556h24.889c1.956 0 3.556-1.6 3.556-3.556v-24.889c0-1.973-1.6-3.556-3.556-3.556h-1.778v-3.556"></path>
    // </svg>
    background-image: url('data:image/svg+xml;charset=utf8,%3Csvg%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2243%22%20height%3D%2242%22%20viewBox%3D%220%200%2043%2042%22%3E%3Cpath%20fill%3D%22%23dee2e6%22%20d%3D%22M33.778%2033.778h-24.889v-19.556h24.889M28.444%201.778v3.556h-14.222v-3.556h-3.556v3.556h-1.778c-1.973%200-3.556%201.582-3.556%203.556v24.889c0%201.956%201.6%203.556%203.556%203.556h24.889c1.956%200%203.556-1.6%203.556-3.556v-24.889c0-1.973-1.6-3.556-3.556-3.556h-1.778v-3.556%22%3E%3C%2Fpath%3E%3C%2Fsvg%3E');
    background-position: right 8px center;
    background-repeat: no-repeat;
    background-size: 18px 18px;
    padding-right: 35px;
  }
}

.c-input-time {
  .c-input {
    // オリジナルのSVGコード: 色を変更したい時などは、オリジナルから背景埋め込み用のコードを再作成
    // https://blog.s0014.com/posts/2017-01-19-il-to-svg/
    // icon 1
    // <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="43" height="32" viewBox="0 0 43 32">
    // <path fill="#dee2e6" d="M19.556 30.222c0 0.978 0.8 1.778 1.778 1.778s1.778-0.8 1.778-1.778-0.8-1.778-1.778-1.778-1.778 0.8-1.778 1.778M19.556 5.333v7.111h3.556v-3.413c6.027 0.871 10.667 6.027 10.667 12.302 0 6.88-5.564 12.444-12.444 12.444s-12.444-5.564-12.444-12.444c0-2.987 1.049-5.724 2.809-7.858l9.636 9.636 2.507-2.507-12.089-12.089v0.036c-3.893 2.916-6.418 7.538-6.418 12.782 0 8.836 7.164 16 16 16s16-7.164 16-16-7.164-16-16-16M32 21.333c0-0.978-0.8-1.778-1.778-1.778s-1.778 0.8-1.778 1.778 0.8 1.778 1.778 1.778 1.778-0.8 1.778-1.778M10.667 21.333c0 0.978 0.8 1.778 1.778 1.778s1.778-0.8 1.778-1.778-0.8-1.778-1.778-1.778-1.778 0.8-1.778 1.778z"></path>
    // </svg>
    // icon 2
    // <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="43" height="32" viewBox="0 0 43 32">
    // <path fill="#dee2e6" d="M21.333 35.556c-7.858 0-14.222-6.364-14.222-14.222s6.364-14.222 14.222-14.222 14.222 6.364 14.222 14.222-6.364 14.222-14.222 14.222M21.333 3.556c-9.813 0-17.778 7.964-17.778 17.778s7.964 17.778 17.778 17.778 17.778-7.964 17.778-17.778-7.964-17.778-17.778-17.778M28.871 13.796c-2.080-2.098-4.818-3.129-7.538-3.129v10.667l-7.538 7.538c4.16 4.16 10.916 4.16 15.076 0 4.178-4.16 4.178-10.916 0-15.076z"></path>
    // </svg>
    // background-image: url('data:image/svg+xml;charset=utf8,%3Csvg%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2243%22%20height%3D%2232%22%20viewBox%3D%220%200%2043%2032%22%3E%3Cpath%20fill%3D%22%23dee2e6%22%20d%3D%22M19.556%2030.222c0%200.978%200.8%201.778%201.778%201.778s1.778-0.8%201.778-1.778-0.8-1.778-1.778-1.778-1.778%200.8-1.778%201.778M19.556%205.333v7.111h3.556v-3.413c6.027%200.871%2010.667%206.027%2010.667%2012.302%200%206.88-5.564%2012.444-12.444%2012.444s-12.444-5.564-12.444-12.444c0-2.987%201.049-5.724%202.809-7.858l9.636%209.636%202.507-2.507-12.089-12.089v0.036c-3.893%202.916-6.418%207.538-6.418%2012.782%200%208.836%207.164%2016%2016%2016s16-7.164%2016-16-7.164-16-16-16M32%2021.333c0-0.978-0.8-1.778-1.778-1.778s-1.778%200.8-1.778%201.778%200.8%201.778%201.778%201.778%201.778-0.8%201.778-1.778M10.667%2021.333c0%200.978%200.8%201.778%201.778%201.778s1.778-0.8%201.778-1.778-0.8-1.778-1.778-1.778-1.778%200.8-1.778%201.778z%22%3E%3C%2Fpath%3E%3C%2Fsvg%3E');
    background-image: url('data:image/svg+xml;charset=utf8,%3Csvg%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2243%22%20height%3D%2232%22%20viewBox%3D%220%200%2043%2032%22%3E%3Cpath%20fill%3D%22%23dee2e6%22%20d%3D%22M21.333%2035.556c-7.858%200-14.222-6.364-14.222-14.222s6.364-14.222%2014.222-14.222%2014.222%206.364%2014.222%2014.222-6.364%2014.222-14.222%2014.222M21.333%203.556c-9.813%200-17.778%207.964-17.778%2017.778s7.964%2017.778%2017.778%2017.778%2017.778-7.964%2017.778-17.778-7.964-17.778-17.778-17.778M28.871%2013.796c-2.080-2.098-4.818-3.129-7.538-3.129v10.667l-7.538%207.538c4.16%204.16%2010.916%204.16%2015.076%200%204.178-4.16%204.178-10.916%200-15.076z%22%3E%3C%2Fpath%3E%3C%2Fsvg%3E');
    background-position: right 8px center;
    background-repeat: no-repeat;
    background-size: 20px 20px;
    padding-right: 35px;
  }
}

@media (max-width: $appBreakPoint) {
  .c-input {
    font-size: $appFontSizeS;
  }
  .c-input-date {
    font-size: $appFontSizeS;
  }
}
