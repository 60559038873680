.c-upload {
  $this: &;

  &.is-disabled {
    cursor: default !important;
    .dropzone {
      background: $appColorGrayScale7;
      .dz-message {
        cursor: default !important;
        .no-select {
          background: $appColorGrayScale7;
          .image i {
            color: $appColorGrayScale9;
          }
        }
        .preview {
          background: $appColorGrayScale7;
        }
      }
    }
    #{$this}-supply {
      display: none;
    }
  }

  // ------------------------------------------
  // プロジェクト参考資料用
  // ------------------------------------------
  &--document {
    position: relative;
    width: 100%;
    i {
      color: $appColorGrayScale8;
      font-size: 80px;
      margin-top: 10px;
      transition: color 300ms;
    }

    .c-btn {
      &--delete {
        align-items: center;
        border-radius: $appRadiusM;
        display: flex;
        height: 36px;
        justify-content: center;
        margin: 0;
        position: absolute;
        top: 12px;
        right: 12px;
        width: 36px;
        z-index: 101;
        i {
          font-size: 20px;
          margin: 1px 0 0 1px;
        }
      }
    }

    .dropzone {
      background: $appColorGrayScale9;
      border: none;
      border-radius: $appRadiusM;
      box-sizing: border-box;
      height: 200px;
      position: relative;
      width: 100%;
      z-index: 100;
      .dz-message {
        align-items: center;
        border-radius: initial;
        display: flex;
        justify-content: center;
        height: 100%;
        overflow: hidden;
        padding: 0;
        width: 100%;

        .no-select {
          align-items: center;
          background: $appColorGrayScale9;
          box-sizing: border-box;
          display: flex;
          flex-direction: row;
          height: 100%;
          justify-content: center;
          padding: 25px 30px;
          transition: background 300ms, padding 300ms;
          width: 100%;
          .image {
            margin-top: 20px;
            i {
              color: $appColorGrayScale8;
              font-size: 80px;
              transition: color 300ms;
            }
          }
          .text {
            box-sizing: border-box;
            color: $appColorBlack;
            font-weight: $appFontWeightNormal;
            margin-top: 0;
            padding: 0 0 0 30px;
            text-align: left;
            transition: color 300ms, opacity 300ms, padding 300ms;
            width: 100%;
            .warning {
              color: $appColorFreeRed;
              display: block;
              font-size: $appFontSizeS;
              font-weight: $appFontWeightExtraBold;
              padding: 4px 0;
              p {
                line-height: 1.4em;
                margin: 0;
              }
            }
          }
        }

        .preview {
          align-items: center;
          background: $appColorGrayScale9;
          box-sizing: border-box;
          display: flex;
          flex-direction: row;
          height: 100%;
          justify-content: space-between;
          padding: 25px 30px;
          transition: background 300ms;
          width: 100%;

          .image {
            align-items: center;
            display: flex;
            justify-content: center;
            margin-top: 0;
            max-height: 100%;
            width: 200px;
            overflow: hidden;
            img {
              width: 100%;
            }
          }

          .document {
            margin-top: 20px;
            i {
              // color: $appColorGrayScale8;
              color: $appColorDocumentFileIcon;
              font-size: 80px;
              transition: color 300ms;
            }
            &--excel i {
              color: $appColorDocumentExcelIcon;
            }
            &--pdf i {
              color: $appColorDocumentPdfIcon;
            }
            &--powerpoint i {
              color: $appColorDocumentPowerPointIcon;
            }
            &--word i {
              color: $appColorDocumentWordIcon;
            }
          }

          #{$this}-info {
            box-sizing: border-box;
            justify-content: flex-start;
            list-style: none;
            padding: 0 0 0 30px;
            width: calc(100% - 200px);
            &__item {
              @include ellipsis();
              background: transparent;
              border: none;
              color: $appColorBlack;
              font-size: $appFontSizeS;
              padding: 0;
              text-align: left;
            }
          }

          &--document {
            #{$this}-info {
              width: calc(100% - 80px);
            }
          }
        }
      }
    }
    #{$this}-supply {
      background: $appColorGrayScale8;
      border-radius: 4px;
      box-sizing: border-box;
      color: $appColorBlack;
      margin: 10px 0 10px 0;
      padding: 25px 30px 20px 30px;
      .warning {
        color: $appColorFreeRed;
        display: block;
        font-size: $appFontSizeS;
        p {
          margin: 0;
        }
      }
    }
  }

  // ------------------------------------------
  // Deal Showcase画像・ファイル
  // ------------------------------------------
  &--deal-showcase {
    display: flex;
    flex-direction: column;
    gap: 12px;
    position: relative;
    width: 100%;
    i {
      color: $appColorGrayScale8;
      font-size: 80px;
      margin-top: 10px;
      transition: color 300ms;
    }

    .c-btn {
      &--delete {
        align-items: center;
        border-radius: $appRadiusM;
        display: flex;
        height: 36px;
        justify-content: center;
        margin: 0;
        position: absolute;
        top: 12px;
        right: 12px;
        width: 36px;
        z-index: 101;
        i {
          font-size: 20px;
          margin: 1px 0 0 1px;
        }
      }
    }

    #{$this}-preview {
      &-list {
        align-items: center;
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 12px 8px;
        justify-content: flex-start;
        width: 100%;
        &__item {
          align-items: center;
          background-color: $appColorGrayScale8;
          cursor: pointer;
          display: flex;
          position: relative;
          width: calc((100% / 3) - (24px / 3));
          &:hover {
            opacity: 0.8;
          }
          .c-btn {
            left: 8px;
            right: auto;
            top: 8px;
          }
        }
      }

      &-document,
      &-image {
        display: flex;
        flex-direction: column;
        height: auto;
        gap: 8px;
        width: 100%;
        &-data {
          display: block;
          width: 100%;
          &__label,
          &__size {
            @include ellipsis();
            color: $appColorBlack;
            font-size: $appFontSizeS;
            display: block;
            line-height: 1.2em;
            text-align: center;
            width: 100%;
          }
        }
      }

      &-image {
        &__file {
          align-items: center;
          background-color: $appColorGrayScale9;
          background-position: center center;
          background-repeat: no-repeat;
          background-size: cover;
          display: flex;
          flex-direction: column;
          justify-content: center;
          height: 150px;
          width: 100%;
        }
      }

      &-document {
        &__file {
          align-items: center;
          background-color: $appColorGrayScale9;
          background-position: center center;
          background-repeat: no-repeat;
          background-size: cover;
          cursor: default;
          display: flex;
          flex-direction: column;
          justify-content: center;
          height: 150px;
          width: 100%;
          i {
            color: $appColorGrayScale8;
            font-size: 64px;
            margin-top: 10px;
            transition: color 300ms;
          }
        }
      }
    }

    .dropzone {
      border: 1px dashed $appColorGrayScale6;
      border-radius: $appRadiusM;
      box-sizing: border-box;
      height: auto;
      margin-top: 0;
      min-height: 132px;
      position: relative;
      width: 100%;
      z-index: 100;
      .dz-message {
        align-items: center;
        border-radius: initial;
        display: flex;
        justify-content: center;
        height: 100%;
        overflow: hidden;
        padding: 0;
        width: 100%;
        .no-select {
          align-items: center;
          background: $appColorGrayScale9;
          box-sizing: border-box;
          display: flex;
          flex-direction: row;
          height: 100%;
          min-height: 132px;
          justify-content: center;
          padding: 24px 24px;
          transition: background 300ms, padding 300ms;
          width: 100%;
          .image {
            margin-top: 20px;
            i {
              color: $appColorGrayScale8;
              font-size: 68px;
              transition: color 300ms;
            }
          }
          .text {
            box-sizing: border-box;
            color: $appColorBlack;
            font-size: $appFontSizeM;
            font-weight: $appFontWeightNormal;
            margin-top: 0;
            padding: 0 0 0 24px;
            text-align: left;
            transition: color 300ms, opacity 300ms, padding 300ms;
            max-width: 600px;
            width: 100%;
            .warning {
              color: $appColorFreeRed;
              display: block;
              font-size: $appFontSizeS;
              font-weight: $appFontWeightExtraBold;
              padding: 4px 0;
              p {
                line-height: 1.4em;
                margin: 0;
              }
            }
          }
        }
      }
      .invalid-feedback {
        top: -42px;
      }
    }
    #{$this}-supply {
      background: $appColorGrayScale8;
      border-radius: 4px;
      box-sizing: border-box;
      color: $appColorBlack;
      margin: 10px 0 10px 0;
      padding: 25px 30px 20px 30px;
      .warning {
        color: $appColorFreeRed;
        display: block;
        font-size: $appFontSizeS;
        p {
          margin: 0;
        }
      }
    }
  }

  // ------------------------------------------
  // ユーザー・会社のプロフィール画像用
  // ------------------------------------------
  &--user-profile,
  &--company-profile,
  &--deal-logo {
    position: relative;
    width: 200px;
    .dropzone {
      border: 8px solid $appColorGrayScale8;
      border-radius: 50%;
      box-sizing: border-box;
      height: 200px;
      position: relative;
      width: 200px;
      z-index: 100;
      &.is-drag {
        .dz-message {
          .no-image {
            background: $appColorGrayScale8;
            i {
              color: $appColorGrayScale9;
            }
            .text {
              // color: $appColorGrayScale9;
              opacity: 0;
            }
          }
        }
      }
      .dz-message {
        align-items: center;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        height: 100%;
        overflow: hidden;
        padding: 0;
        width: 100%;
        .preview {
          width: 100%;
        }
        .no-image {
          align-items: center;
          background: $appColorGrayScale9;
          display: flex;
          flex-direction: column;
          height: 100%;
          justify-content: center;
          transition: background 300ms;
          width: 100%;
          i {
            color: $appColorGrayScale8;
            // font-size: 90px;
            font-size: 130px;
            line-height: 60px;
            margin-top: 10px;
            transition: color 300ms;
          }
          .text {
            color: $appColorGrayScale5;
            font-size: $appFontSizeM;
            font-weight: $appFontWeightExtraBold;
            margin-top: 10px;
            position: absolute;
            transition: color 300ms, opacity 300ms;
            width: 145px;
          }
        }
      }
    }

    #{$this}-supply {
      background: $appColorGrayScale8;
      border-radius: 4px;
      color: $appColorBlack;
      font-size: $appFontSizeXS;
      line-height: $appFontSizeXS + 4;
      margin-top: 20px;
      padding: 12.5px;
      .warning {
        color: $appColorFreeRed;
        display: block;
        font-weight: $appFontWeightExtraBold;
        padding-top: 5px;
        p {
          margin: 0;
        }
      }
    }

    #{$this}-info {
      list-style: none;
      margin-top: 15px;
      &__item {
        @include ellipsis();
        border: none;
        color: $appColorBlack;
        font-size: $appFontSizeS;
        padding: 0;
        text-align: center;
      }
    }

    .c-btn {
      &--delete {
        align-items: center;
        border-radius: $appRadiusM;
        top: -5px;
        display: flex;
        font-size: 20px;
        height: 36px;
        justify-content: center;
        margin: 0;
        position: absolute;
        left: -5px;
        width: 36px;
        z-index: 101;
        i {
          margin: 1px 0 0 1px;
        }
      }
    }
  }

  &--company-profile {
    .dropzone {
      .dz-message {
        .no-image {
          i {
            font-size: 104px;
          }
        }
      }
    }
  }
  // background-color: $appColorGrayScale9;
  // border-color: $appColorGrayScale9;
  // border-radius: $appRadiusL;
  &--deal-logo {
    .dropzone {
      border: none;
      border-radius: $appRadiusL;
      .dz-message {
        background: transparent;
        border-radius: $appRadiusL;
        .no-image {
          i {
            font-size: 104px;
          }
        }
      }
    }
  }
}

@media (max-width: 1180px) {
  .c-upload {
    $this: &;
    &--document {
      .dropzone {
        .dz-message {
          .no-select {
            .image {
              margin-top: 20px;
            }
            .text {
              padding: 0 0 0 20px;
            }
          }
          .preview {
            #{$this}-info {
              padding: 0 0 0 20px;
            }
          }
        }
      }
    }
    &--deal-showcase {
      .dropzone {
        .dz-message {
          .no-select {
            .image {
              margin-top: 20px;
            }
            .text {
              padding: 0 0 0 20px;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 860px) {
  .c-upload {
    $this: &;
    &--deal-showcase {
      #{$this}-preview {
        &-list {
          &__item {
            width: calc((100% / 2) - (12px / 2));
          }
        }
      }
    }
  }
}

@media (max-width: $appBreakPoint) {
  .c-upload {
    $this: &;
    &--document {
      .dropzone {
        height: auto;
        .dz-message {
          .no-select {
            flex-direction: column;
            padding: 20px;
            .image {
              margin: 0;
              padding: 25px 0 20px 0;
              i {
                font-size: 64px;
              }
            }
            .text {
              font-size: $appFontSizeS;
              line-height: 1.2em;
              padding: 0;
              .warning {
                font-size: $appFontSizeS;
                margin-top: 5px;
              }
            }
          }
          .preview {
            flex-direction: column;
            .image {
              width: 100%;
            }
            #{$this}-info {
              padding: 15px 0 0 0;
              width: 100%;
            }
          }
        }
      }

      #{$this}-supply {
        background: $appColorGrayScale8;
        border-radius: 4px;
        box-sizing: border-box;
        color: $appColorBlack;
        font-size: $appFontSizeS;
        line-height: 1.2em;
        margin: 10px 0 10px 0;
        padding: 20px;
        .warning {
          color: $appColorFreeRed;
          display: block;
          font-size: $appFontSizeS;
          margin-top: 5px;
        }
      }
    }

    &--deal-showcase {
      .dropzone {
        height: auto;
        .dz-message {
          .no-select {
            flex-direction: column;
            padding: 20px;
            .image {
              margin: 0;
              padding: 25px 0 20px 0;
              i {
                font-size: 64px;
              }
            }
            .text {
              font-size: $appFontSizeS;
              line-height: 1.2em;
              padding: 0;
              .warning {
                font-size: $appFontSizeS;
                margin-top: 5px;
              }
            }
          }
        }
      }

      #{$this}-preview {
        &-list {
          gap: 16px 0;
          padding: 4px;
          &__item {
            height: 180px;
            width: 100%;
          }
        }
      }
    }
  }
}
