.p-project,
.p-project.card {
  $this: &;
  background: $appColorWhite;
  border-radius: $appRadiusXL;
  box-sizing: border-box;
  color: $appColorBlack;
  padding: 77.5px 40px 45px 40px;
  position: relative;
  width: 100%;
  z-index: 100;

  &.is-sticker {
    margin-top: 20px;
    padding-top: 85px;
  }

  .c-badge {
    border-radius: 0;
    font-size: $appFontSizeS;
    height: 30px;
    &--sticker-wanted {
      font-size: $appFontSizeM;
      height: auto;
      position: absolute;
      left: 40px;
      top: -6px;
    }
    &-list {
      &--category {
        .c-btn {
          &--badge {
            border-radius: $appRadiusM;
            padding: 0 15px;
            width: auto;
          }
        }
        .c-badge {
          &-list {
            &__item {
              margin: 0 8px 8px 0;
            }
          }
        }
      }
      &--tag {
        .c-badge {
          border-radius: $appRadiusM;
          padding: 0 10px;
          min-width: 80px;
          width: auto;
          &-list {
            &__item {
              margin: 0 8px 8px 0;
            }
          }
        }
      }
    }
  }

  &__image {
    border-radius: $appRadiusM;
    overflow: hidden;
    width: 100%;
    &-container {
      display: flex;
      flex-direction: column;
      gap: 16px 0;
      width: 100%;
    }
    &--owner-image {
      align-items: center;
      background: $appColorWhite;
      border: 8px solid $appColorGrayScale8;
      border-radius: 50%;
      box-sizing: border-box;
      display: flex;
      height: 150px;
      justify-content: center;
      margin: 0 auto;
      overflow: hidden;
      width: 150px;
      img {
        width: 100%;
      }
    }
    &--no-image {
      background: $appColorGrayScale9;
      i {
        color: $appColorGrayScale8;
        font-size: 97.5px;
      }
    }
  }

  &__data-group {
    align-items: center;
    border-bottom: 1px solid $appColorGrayScale6;
    display: flex;
    flex-grow: 1;
    justify-content: flex-start;
    margin: 0;
    padding: 12.5px 10px;
    width: 100%;
    &:first-child {
      border-top: 1px solid $appColorGrayScale6;
    }
  }

  &__data {
    display: inline-block;
    margin: 0;
    padding: 0;
    .label,
    .value {
      box-sizing: border-box;
      display: inline;
      font-size: $appFontSizeM;
      margin-right: 20px;
      padding: 0;
      width: auto;
    }
    .label {
      color: $appColorGrayScale11;
      font-size: $appFontSizeXS;
      font-weight: $appFontWeightExtraBold;
      margin-right: 10px;
    }
    &--prices {
      align-items: flex-start;
      display: flex;
      flex-wrap: nowrap;
      justify-content: flex-start;
      .label {
        flex-grow: 0;
        margin-right: 10px;
        max-width: 50px;
        min-width: 50px;
        width: 50px;
      }
      .value-list {
        background: none;
        flex-grow: 1;
        list-style: none;
        margin: 0;
        padding: 0;
      }
      .value {
        background: none;
        border: none;
        &.is-disabled {
          color: $appColorGrayScale12;
          text-decoration: line-through;
        }
      }
      .warning {
        color: $appColorFreeRed;
        font-size: $appFontSizeS;
        font-weight: $appFontWeightBold;
        padding-top: 5px;
      }
    }
  }

  &__item {
    &--btns {
      .c-btn {
        &--favorite {
          border-radius: 50%;
          height: 40px;
          margin: 0;
          position: absolute;
          right: 18.5px;
          top: 18.5px;
          transition: right 0.3s, top 0.3s, transform 300ms;
          width: 40px;
          z-index: 1100;
          &:hover {
            transform: scale(1.1);
          }
          i {
            color: #fff;
            font-size: 24px;
            transition: color 300ms;
          }
        }
        &--enter {
          height: 40px;
          margin: 0;
          position: absolute;
          right: 68.5px;
          top: 18.5px;
          width: 120px;
          z-index: 1100;
          &.is-scouted {
            width: 180px;
          }
        }
      }
    }
    &--public-btns {
      .c-btn {
        &--enter {
          height: 40px;
          margin: 0;
          position: absolute;
          right: 18.5px;
          top: 18.5px;
          width: 120px;
          z-index: 1100;
          &.is-scouted {
            width: 180px;
          }
        }
      }
    }
  }

  &-header {
    border: none;
    border-radius: 0;
    padding: 0;
    position: relative;
    .expired-on {
      align-items: center;
      background: $appColorFreeRed;
      box-sizing: border-box;
      border-radius: $appRadiusM;
      color: $appColorWhite;
      display: flex;
      height: 30px;
      margin: 0;
      justify-content: center;
      padding: 5px 15px;
      position: relative;
      .label,
      .value {
        flex-basis: inherit;
        flex-grow: initial;
        font-size: $appFontSizeM;
        font-weight: $appFontWeightExtraBold;
        padding: 0;
        width: auto;
      }
      .label {
        align-items: center;
        display: flex;
        &::after {
          background: $appColorWhite;
          content: '';
          display: block;
          height: 13px;
          margin: 0 10px;
          opacity: 0.5;
          width: 1px;
        }
      }
    }
    &__item {
      display: flex;
      margin: 0;
      width: 100%;
      // &--title {
      //   margin-bottom: 25px;
      // }
      .c-badge-list {
        // margin: 20px 0 25px 0;
        margin: 10px 0 25px 0;
      }
      &--image {
        justify-content: flex-end;
        position: relative;
        .form-group {
          width: 100%;
          .invalid-feedback {
            top: -18px;
            .label {
              align-items: center;
              display: flex;
              &::after {
                background: $appColorWhite;
                content: '';
                display: block;
                height: 13px;
                margin: 0 10px;
                opacity: 0.5;
                width: 1px;
              }
            }
          }
        }
        .expired-on {
          background: transparent;
          color: $appColorBlack;
          height: auto;
          margin-top: -10px;
          padding: 5px 0 2px 0;
          .label {
            &::after {
              background: $appColorBlack;
            }
          }
        }
      }
    }
    &__title {
      align-items: center;
      display: flex;
      flex-wrap: wrap;
      margin: 0 0 0 0;
      width: 100%;
      .c-text {
        font-size: $appFontSizePageTitlePc;
        // font-size: $appFontSize3XL;
        font-weight: $appFontWeightExtraBold;
        // letter-spacing: -0.05em;
        line-height: 1.4em;
        margin: 0;
        position: relative;
        width: 100%;
      }
      .number {
        color: $appColorGrayScale11;
        font-size: $appFontSizeS;
        margin: 0 0 2px 0;
        width: 100%;
      }
    }
  }

  &-sticker-official {
    display: flex;
    flex-direction: column;
    height: 32px;
    width: 100%;
  }

  &-body {
    border: none;
    border-radius: 0;
    padding: 0;
    &__block {
      align-items: flex-start;
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-between;
      margin: 15px 0 0 0;
      padding: 10px 0 35px 0;
      width: 100%;
    }
    &__item {
      display: flex;
      margin: 0;
      width: 100%;
      &--owner {
        flex-direction: column;
        flex-grow: 0;
        gap: 16px 0;
        margin-top: 5px;
        min-width: 180px;
        width: 180px;
        &.is-sticker {
          margin-top: 0;
        }
        .c-image {
          &:hover {
            opacity: 0.9;
          }
        }
        .c-text {
          color: $appColorPrimary4;
          font-size: $appFontSizeM;
          font-weight: $appFontWeightNormal;
          text-align: center;
          width: 100%;
        }
      }

      &--base-data {
        flex-direction: column;
        flex-grow: 1;
        padding-left: 20px;
        width: auto;
      }

      &--category,
      &--goal,
      &--overview,
      &--skill-category {
        margin-top: 35px;
        .c-title {
          border: none;
          margin: 0 0 15px 0;
          padding: 0 0 0 5px;
          width: 100%;
          &__text {
            font-size: $appFontSizeXL;
            font-weight: $appFontWeightExtraBold;
            // letter-spacing: -0.05em;
          }
        }
        .c-text {
          background: $appColorGrayScale9;
          border-radius: $appRadiusXL;
          font-size: $appFontSizeM;
          font-weight: $appFontWeightNormal;
          padding: 25px 20px;
          width: 100%;
        }
        a {
          color: $appColorPrimary4;
          text-decoration: none;
          &:hover {
            color: $appColorPrimary4;
            text-decoration: underline;
          }
          &:active,
          &:visited {
            text-decoration: none;
          }
        }
      }

      &--category,
      &--skill-category {
        margin: 15px 0 0 0;
        padding-bottom: 10px;
        .c-title {
          border-bottom: 1px solid $appColorGrayScale6;
          padding-bottom: 15px;
        }
        .empty {
          color: $appColorGrayScale6;
          font-size: $appFontSizeM;
          font-weight: $appFontWeightExtraBold;
        }
      }

      &--skill-category {
        margin: 35px 0 0 0;
      }

      &--goal {
        margin: 35px 0 0 0;
      }
    }
  }

  &-footer {
    border: none;
    padding: 0;
    &__item {
      align-items: center;
      display: flex;
      justify-content: center;
      padding: 0;
      width: 100%;
      &--btns {
        margin: 45px 0 5px 0;
        .c-btn {
          margin: 0;
          width: 265px;
        }
      }
    }
  }

  // -------------------------------------------
  // 新規登録誘導モーダル
  // -------------------------------------------
  &-public {
    &-signup {
      align-items: center;
      display: flex;
      flex-direction: column;
      gap: 24px 0;
      justify-content: center;
      min-height: 240px;
      padding: 32px 0;
      width: 100%;

      &__title {
        font-size: $appFontSize3XL;
        // font-weight: $appFontWeightExtraBold;
        line-height: 1.6em;
        text-align: center;
      }

      &__btns {
        display: block;
        width: 100%;

        .c-btn {
          margin: 0 auto;
          width: 265px;
        }
      }
    }
  }

  // -------------------------------------------
  // プロジェクト応募フォーム
  // -------------------------------------------
  &-subscribe {
    &-form {
      .c-message {
        &--error {
          margin-bottom: 20px;
        }
      }

      &__item {
        display: flex;
        flex-direction: column;
        margin: 0 0 15px 0;
        position: relative;
        width: 100%;
        .c-input {
          &--message {
            min-height: 200px;
            max-height: 500px;
          }
        }
        .form-group {
          margin-bottom: 0;
          label {
            font-size: $appFontSizeM;
            font-weight: $appFontWeightExtraBold;
            margin-bottom: 5px;
          }
        }
        &--btns {
          margin: 30px 0 20px 0;
          button.c-btn {
            font-size: $appFontSizeL;
          }
          .c-btn {
            margin: 0 auto;
            width: 265px;
          }
        }
      }
      &--preview {
        .p-project-subscribe-form {
          &__item {
            .label,
            .value {
              flex-basis: inherit;
              flex-grow: initial;
              font-size: $appFontSizeM;
              margin-bottom: 5px;
              padding: 0;
            }
            .label {
              font-weight: $appFontWeightExtraBold;
            }
            &--message {
              .value {
                background: $appColorGrayScale8;
                border-radius: $appRadiusM;
                box-sizing: border-box;
                padding: 12px;
                overflow-y: auto;
                max-height: 200px;
              }
            }
            &--btns {
              align-items: center;
              display: flex;
              flex-direction: row;
              justify-content: center;
              .c-btn {
                margin: 0 0;
                width: 190px;
              }
              .c-btn + .c-btn {
                margin-left: 7.5px;
              }
            }
          }
        }
      }
      &--complete {
        flex-direction: column;
        padding: 45px 0 80px 0;
        .p-project-subscribe-form {
          &__item {
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            justify-content: center;
            margin: 0;
            padding: 0;
            width: 100%;
            &--image {
              margin-bottom: 30px;
              i {
                color: $appColorBlack;
                font-size: 60px;
                line-height: 60px;
                margin: 0 auto;
              }
            }
            &--caption {
              font-size: $appFontSize3XL;
              font-weight: $appFontWeightBold;
              margin-bottom: 25px;
              text-align: center;
            }
            &--text {
              text-align: center;
              width: 100%;
              p {
                margin-bottom: 0;
              }
            }
            &--btns {
              justify-content: center;
              margin: 35px 0 20px 0;
              .c-btn {
                width: 265px;
              }
            }
          }
        }
      }
    }
  }
}
@media (max-width: $appBreakPoint) {
  .p-project,
  .p-project.card {
    $this: &;
    border-radius: $appRadiusM;
    padding: 64px 24px 32px 24px;

    &.is-sticker {
      padding-top: 70px;
    }

    .c-badge {
      font-size: $appFontSizeXS;
      &--sticker-wanted {
        font-size: $appFontSizeS;
        left: 24px;
      }
    }

    &__image {
      &-container {
        gap: 8px 0;
        width: 80px;
      }
      &--owner-image {
        background: $appColorWhite;
        border: 4px solid $appColorGrayScale8;
        // border: 8px solid $appColorGrayScale8;
        border-radius: 50%;
        height: 80px;
        width: 80px;
        // height: 200px;
        // width: 200px;
      }
      &--no-image {
        background: $appColorGrayScale9;
        i {
          color: $appColorGrayScale8;
          // font-size: 130px;
          font-size: 97.5px;
        }
      }
    }

    &__data-group {
      flex-direction: column;
      justify-content: flex-start;
      padding: 12.5px 0;
      width: 100%;
      &:first-child {
        border-top: 1px solid $appColorGrayScale6;
      }
    }

    &__data {
      width: 100%;
      .label,
      .value {
        box-sizing: border-box;
        display: inline;
        font-size: $appFontSizeS;
        margin-right: 0;
        padding: 0;
        width: auto;
      }
      .label {
        font-size: $appFontSizeXS;
        margin-right: 10px;
      }
      &--date-range {
        display: flex;
        flex-direction: column;
      }
      &--prices {
        .warning {
          font-size: $appFontSizeXS;
          padding-top: 0;
        }
      }
    }

    &__item {
      &--btns {
        .c-btn {
          &--favorite {
            right: 14.5px;
            top: 14.5px;
            &:hover {
              transform: scale(1);
            }
          }
          &--enter {
            display: none;
          }
        }
      }
      &--public-btns {
        .c-btn {
          &--enter {
            display: none;
          }
        }
      }
    }

    &-header {
      .expired-on {
        .label,
        .value {
          font-size: $appFontSizeS;
        }
        .label {
          align-items: center;
          display: flex;
          &::after {
            height: 12px;
            margin-top: -1px;
          }
        }
      }

      &__item {
        .c-badge-list {
          margin-bottom: 15px;
        }
        &--image {
          .form-group {
            .invalid-feedback {
              top: -15px;
            }
          }
          .expired-on {
            margin-top: -5px;
          }
        }
      }
      &__title {
        .c-text {
          font-size: $appFontSize2XL;
          line-height: 1.25em;
        }
      }
    }

    &-sticker-official {
      height: 24px;
    }

    &-body {
      &__block {
        flex-direction: column;
        flex-wrap: wrap;
        // margin: 20px 0 0 0;
        margin: 10px 0 0 0;
        padding: 0;
      }
      &__item {
        display: flex;
        margin: 0;
        width: 100%;
        &--owner {
          align-items: center;
          display: flex;
          flex-direction: row;
          flex-grow: 1;
          flex-wrap: nowrap;
          gap: 0 12px;
          margin-top: 0;
          width: 100%;
          &.is-sticker {
            .c-text {
              margin-top: 32px;
            }
          }
          .c-text {
            color: $appColorPrimary4;
            flex-grow: 1;
            font-size: $appFontSizeS;
            padding: 0;
            // padding: 15px 0 0 0;
            text-align: left;
            width: auto;
            word-break: break-all;
          }
        }

        &--base-data {
          flex-direction: column;
          flex-grow: 1;
          margin: 20px 0 0px 0;
          padding-left: 0;
          width: 100%;
        }

        &--category,
        &--goal,
        &--skill-category {
          margin: 0 0 30px 0;
          .c-title {
            margin: 0 0 10px 0;
            &__text {
              font-size: $appFontSizeL;
            }
          }
          .c-text {
            font-size: $appFontSizeS;
            padding: 15px;
            width: 100%;
          }
          .empty {
            font-size: $appFontSizeS;
          }
        }

        &--category,
        &--skill-category {
          margin: 30px 0 22px 0;
          padding-bottom: 0;
          .c-title {
            border-bottom: 1px solid $appColorGrayScale6;
            padding-bottom: 15px;
          }
        }
      }
    }

    &-footer {
      margin-top: -10px;
      padding: 0;
      &__item {
        &--btns {
          margin: 0;
          .c-btn {
            margin: 0;
            width: 100%;
          }
        }
      }
    }

    // -------------------------------------------
    // 新規登録誘導モーダル
    // -------------------------------------------
    &-public {
      &-signup {
        gap: 24px 0;
        min-height: 240px;
        padding: 24px 0;

        &__title {
          font-size: $appFontSize2XL;
          line-height: 1.6em;
          text-align: center;
        }

        &__btns {
          .c-btn {
            margin: 0 auto;
          }
        }
      }
    }

    // -------------------------------------------
    // 経歴リストの新規登録・編集フォーム
    // -------------------------------------------
    &-subscribe {
      &-form {
        &__item {
          margin: 0 0 15px 0;
          .form-group {
            label {
              font-size: $appFontSizeS;
              margin-bottom: 5px;
            }
            .c-input {
              font-size: $appFontSizeS;
              &--message {
                min-height: 150px;
                max-height: 150px;
              }
            }
            .c-select {
              &__menu {
                font-size: $appFontSizeS;
              }
              &__single-value {
                color: $appColorBlack !important;
                font-size: $appFontSizeS !important;
              }
            }
          }
          &--btns {
            margin: 20px 0 10px 0;
            .c-btn {
              margin-left: 0;
              margin-right: 0;
              width: 100%;
            }
            .c-btn + .c-btn {
              margin-top: 15px;
            }
          }
        }
        &--preview {
          .p-project-subscribe-form {
            &__item {
              margin-bottom: 10px;
              .label,
              .value {
                font-size: $appFontSizeS;
                margin-bottom: 0;
              }
              &--message {
                .value {
                  padding: 10px;
                  min-height: 150px;
                  max-height: 150px;
                }
              }
              &--btns {
                // flex-direction: column-reverse !important;
                flex-direction: column !important;
                .c-btn {
                  // margin: 15px 0 0 0;
                  margin: 0;
                  width: 100%;
                }
                .c-btn + .c-btn {
                  // margin: 0 0;
                  margin: 15px 0 0 0;
                }
              }
            }
          }
        }
        &--complete {
          flex-direction: column;
          padding: 20px 0 10px 0;
          .p-project-subscribe-form {
            &__item {
              display: flex;
              flex-direction: column;
              flex-wrap: wrap;
              justify-content: center;
              margin: 0;
              padding: 0;
              width: 100%;
              &--image {
                margin-bottom: 30px;
                i {
                  color: $appColorBlack;
                  font-size: 50px;
                  line-height: 50px;
                  margin: 0 auto;
                }
              }
              &--caption {
                font-size: $appFontSizeL;
                margin-bottom: 10px;
              }
              &--text {
                font-size: $appFontSizeS;
              }
              &--btns {
                margin: 35px 0 0 0;
                .c-btn {
                  width: 100%;
                }
              }
            }
          }
        }
      }
    }
  }
}
