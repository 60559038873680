.form-group {
  position: relative;
  label {
    &.is-required {
      align-items: center;
      display: flex;
      justify-content: flex-start;
      &::after {
        background: $appColorFreeRed;
        box-sizing: border-box;
        border-radius: $appRadiusM;
        color: #fff;
        // content: '必須';
        content: '\5FC5\9808';
        font-size: 11px;
        font-weight: $appFontWeightExtraBold;
        line-height: 1em;
        margin: -5px 0 0 10px;
        padding: 7px 10px 5px 10px;
      }
    }
  }

  &.is-required {
    legend {
      align-items: center;
      display: flex;
      justify-content: flex-start;
      &::after {
        background: $appColorFreeRed;
        box-sizing: border-box;
        border-radius: $appRadiusM;
        color: #fff;
        // content: '必須';
        content: '\5FC5\9808';
        font-size: 11px;
        font-weight: $appFontWeightExtraBold;
        line-height: 1em;
        margin: -5px 0 0 10px;
        padding: 7px 10px 5px 10px;
      }
    }
  }

  &.text-complete,
  &.text-danger,
  &.was-validated {
    label {
      color: $appColorBlack;
    }
    input {
      border-color: $appColorFreeRed;
    }
    // セレクターエリアの単位や区切り文字要素
    .unit {
      color: $appColorBlack;
    }
    .c-select {
      color: $appColorBlack;
      &__control {
        border-color: $appColorFreeRed !important;
      }
    }
    .invalid-feedback {
      background: $appColorFreeRed;
      border-radius: $appRadiusM;
      color: #fff;
      display: flex;
      font-size: $appFontSizeS;
      font-weight: $appFontWeightExtraBold;
      justify-content: center;
      line-height: 1.3em;
      top: -15px;
      padding: 6.5px 15px;
      position: absolute;
      right: 0;
      width: auto;
      &::before {
        border: 5px solid transparent;
        border-top: 6px solid $appColorFreeRed;
        bottom: 0px;
        content: '';
        margin-bottom: -10px;
        position: absolute;
        right: 30px;
        z-index: auto;
      }
    }
  }
  &.was-validated {
    .invalid-feedback {
      left: 100px;
      right: auto;
      top: -30px;
      &::before {
        left: 25px;
        right: auto;
      }
    }
  }

  &.text-complete {
    input {
      border-color: $appColorPrimary1;
    }
    .invalid-feedback {
      background: $appColorPrimary1;
      &::before {
        border-top-color: $appColorPrimary1;
      }
    }
  }
}

@media (max-width: $appBreakPoint) {
  .form-group {
    &.text-complete,
    &.text-danger {
      .invalid-feedback {
        font-size: $appFontSizeXS;
        font-weight: $appFontWeightBold;
        padding: 6.5px 10px;
      }
    }
  }
}