.p-work-status {
  .c-message + &-form {
    margin-top: 20px;    
  }
  &-form {
    &__item {
      display: flex;
      flex-direction: column;
      margin: 0 0 15px 0;
      position: relative;
      width: 100%;
      .form-group {
        margin-bottom: 0;
        label {
          font-size: $appFontSizeM;
          font-weight: $appFontWeightExtraBold;
          margin-bottom: 5px;
        }
      }
      &--btns {
        margin: 30px 0 20px 0;
        button.c-btn {
          font-size: $appFontSizeL;
        }
        .c-btn {
          margin: 0 auto;
          width: 265px;
        }
      }
    }
  }
}
@media (max-width: $appBreakPoint) {
  .p-work-status {
    .c-message + &-form {
      margin-top: 15px;
    }
    &-form {
      &__item {
        margin: 0 0 15px 0;
        .form-group {
          label {
            font-size: $appFontSizeS;
            margin-bottom: 5px;
          }
          .c-input {
            font-size: $appFontSizeS;
            &--message {
              min-height: 150px;
              max-height: 150px;
            }
          }
          .c-select {
            &__menu {
              font-size: $appFontSizeS;
            }            
            &__single-value {
              color: $appColorBlack !important;
              font-size: $appFontSizeS !important;
            }
          }
        }
        &--btns {
          margin: 20px 0 10px 0;
          .c-btn {
            margin-left: 0;
            margin-right: 0;
            width: 100%;
          }
          .c-btn + .c-btn {
            margin-top: 15px;
          }
        }
      }
    }
  }
}