.c-select {
  $this: &;
  font-size: $appFontSizeM;
  width: 100%;
  &__control {
    border-color: $appColorGrayScale6 !important;
    min-height: $appSelectorHeightM !important;
    &--is-focused {
      border-color: $appColorPrimary1 !important;
      box-shadow: none !important;
    }
    &--is-disabled {
      background-color: $appColorGrayScale7 !important;
    }
  }
  &__menu {
    z-index: 2001 !important;
  }
  &__single-value {
    color: $appColorBlack !important;
  }
  &__placeholder {
    color: $appColorGrayScale5 !important;
    font-weight: $appFontWeightNormal;
  }

  &__clear-indicator {
    svg {
      height: 14px;
      width: 14px;
      fill: $appColorGrayScale5 !important;
    }
  }

  &__indicator-separator {
    background-color: $appColorGrayScale6 !important;
  }
  &__dropdown-indicator {
    svg {
      height: 14px;
      width: 14px;
      fill: $appColorGrayScale5;
    }
  }

  &__group {
    &-heading {
      //border-bottom: 4px $appColorGrayScale6 solid !important;
      color: $appColorBlack !important;
      font-size: $appFontSizeM !important;
      // font-weight: $appFontWeightExtraBold !important;
      background: $appColorGrayScale7;
      padding: 12px 12px 12px 12px !important;
      margin-bottom: 8px !important;
      &::before {
        content: '\25A0';
        // content: '\25BC';
        font-size: $appFontSizeXS !important;
        padding-right: 4px;
      }
    }
  }

  &__value-container {
    &--is-multi {
      padding: 4px !important;
    }
  }
  &__multi-value {
    background-color: $appColorPrimary1 !important;
    color: $appColorWhite !important;
    cursor: pointer;
    &__label {
      color: $appColorWhite !important;
      font-size: 12px;
      font-weight: bold;
    }
    &--is-disabled {
      background-color: transparent !important;
      #{$this}__multi-value__label {
        color: $appColorBlack !important;
        font-weight: normal !important;
      }
      #{$this}__multi-value__remove {
        display: none !important;
      }
    }
  }
}

@media (max-width: $appBreakPoint) {
  .c-select {
    font-size: $appFontSizeS;
    &__group {
      &-heading {
        font-size: $appFontSizeS !important;
        &::before {
          font-size: $appFontSizeXS - 2 !important;
        }
      }
    }
    // &__option {
    //   padding: 4px 12px !important;
    // }
  }
}
