.p-company-authorization {
  $this: &;
  .c-message + &-form {
    margin-top: 20px;    
  }
  &-form {
    &__item {
      display: flex;
      flex-direction: column;
      margin: 0 0 15px 0;
      position: relative;
      width: 100%;

      &--btns {
        .form-group {
          .invalid-feedback {
            top: -15px;
          }
        }
      }

      .form-group {
        margin-bottom: 0;
        label {
          font-size: $appFontSizeM;
          font-weight: $appFontWeightExtraBold;
          margin-bottom: 5px;
        }
        .c-checkbox {
          &__item {
            label {
              font-weight: $appFontWeightNormal;
            }
          }
        }
        legend {
          font-size: $appFontSizeM;
          font-weight: $appFontWeightExtraBold;
          margin-bottom: 0;
        }
        &.c-radio {
          label {
            font-weight: $appFontWeightNormal;
          }
        }
      }
      &--btns {
        align-items: center;
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin: 30px 0 20px 0;
        .c-btn {
          margin: 0 0;
          width: 210px;
        }
        .c-btn + .c-btn {
          margin-left: 7.5px;
        }
      }
    }
  }
}

@media (max-width: $appBreakPoint) {
  .p-company-authorization {
    $this: &;
    .c-message + &-form {
      margin-top: 15px;
    }
    &-form {
      &__item {
        margin: 0 0 15px 0;
        .form-group {
          label {
            font-size: $appFontSizeS;
            margin-bottom: 5px;
          }
          .c-input {
            font-size: $appFontSizeS;
          }
        }
        &--btns {
          flex-direction: column !important;
          margin: 20px 0 10px 0;
          .c-btn {
            margin: 0;
            width: 100%;
          }
          .c-btn + .c-btn {
            margin: 15px 0 0 0;
          }
        }
      }
    }
  }
}