.c-badge {
  align-items: center;
  background: $appColorBlack;
  border-radius: $appRadiusS;
  color: #fff;
  display: flex;
  font-size: $appFontSizeXS;
  font-weight: bold;
  justify-content: center;
  padding: 4px;
  text-align: center;
  width: 65px;
  &--category {
    background: $appColorPrimary4;
  }
  &--category,
  &--company,
  &--offerable,
  &--scoutable,
  &--tag {
    background: $appColorPrimary4;
  }

  &--offerable-other {
    background: $appColorSecondary1;
  }

  &--wanted {
    background: $appColorFreeRed;
  }

  &--casual-interview {
    background: $appColorFreeRed;
  }

  &--prime {
    background: $appColorGrayScale4;
    visibility: visible;
  }
  &--client {
    background: $appColorSecondary1;
    visibility: visible;
  }
  &--agent {
    background: $appColorFreePurple;
    visibility: visible;
  }

  &--owned-by {
    &-company {
      background: $appColorPrimary4;
      visibility: visible;
    }
  }

  &--user-status {
    &-active {
      background: $appColorPrimary1;
      visibility: visible;
    }
    &-suspend {
      background: $appColorGrayScale5;
      visibility: visible;
    }
    &-invited {
      background: $appColorSecondary1;
      visibility: visible;
    }
  }

  &--status {
    &-active {
      background: $appColorSecondary1;
      visibility: visible;
    }
    &-ongoing {
      background: $appColorFreeYellow;
      visibility: visible;
    }
    &-planned {
      background: $appColorBlack;
      visibility: visible;
    }
  }

  &--company {
    &-authorized {
      background: $appColorPrimary1;
      min-width: 80px;
    }
    &-unauthorized {
      background: $appColorFreeRed;
      min-width: 80px;
    }
  }

  &--company-member-type {
    box-sizing: border-box;
    padding: 8px;
    min-width: 80px;
    width: auto;
    &-regular {
      background: $appColorPrimary1;
    }
    &-alumni {
      background: $appColorSecondary1;
    }
    &-collaborator {
      background: $appColorFreePurple;
    }
  }

  &--company-member-status {
    box-sizing: border-box;
    padding: 8px;
    min-width: 80px;
    width: auto;
    &-invited {
      background: $appColorFreeRed;
    }
  }

  &--project {
    &-status {
      &-active {
        background: $appColorPrimary1;
      }
      &-draft {
        background: $appColorSecondary1;
      }
      &-reserve {
        background: $appColorFreeYellow;
      }
      &-inactive {
        background: $appColorFreeRed;
      }
      &-completed {
        background: $appColorBlack;
      }
    }
    &-relate-type {
      &-contractor {
        background: $appColorSecondary1;
      }
      &-orderer {
        background: $appColorPrimary1;
      }
    }
    &-recruit-reference-type {
      &-subscribe {
        background: $appColorPrimary1;
      }
      &-scout {
        background: $appColorSecondary1;
      }
    }
    &-recruit-status {
      &-recruited {
        background: $appColorPrimary1;
      }
      &-declined {
        background: $appColorFreeRed;
      }
      &-rejected {
        background: $appColorFreeRed;
      }
      &-waiting {
        background: $appColorSecondary1;
      }
      &-in-progress {
        background: $appColorSecondary1;
      }
      &-subscribe {
        background: $appColorPrimary1;
      }
      &-scout {
        background: $appColorSecondary1;
      }
    }
    &-publish-scope {
      background: $appColorFreeRed;
      &.is-disabled {
        background: $appColorGrayScale6;
      }
    }
  }

  &--connection {
    &-type {
      &-invite {
        background: $appColorSecondary1;
      }
      &-project {
        background: $appColorPrimary1;
      }
      &-company {
        background: $appColorPrimary1;
      }
      &-request {
        background: $appColorFreeRed;
      }
    }
    // &-status {}
  }

  &--notification {
    &-type {
      &-service {
        background: $appColorPrimary1;
      }
      &-system {
        background: $appColorFreeRed;
      }
    }
  }

  &--sticker-wanted {
    background: #e95464;
    color: white;
    display: inline-block;
    font-size: 14px;
    font-weight: bold;
    height: auto;
    left: 0;
    margin: 0;
    padding: 16.5px 0 15.5px 0;
    position: relative;
    text-align: center;
    top: -18px;
    width: 80px;
    &::before {
      position: absolute;
      content: '';
      top: 0;
      right: -6px;
      border: none;
      border-bottom: solid 6px #d03141;
      border-right: solid 6px transparent;
    }
    &::after {
      content: '';
      position: absolute;
      left: 0;
      top: 100%;
      height: 0;
      width: 0;
      border-left: 40px solid $appColorFreeRed;
      border-right: 40px solid $appColorFreeRed;
      border-bottom: 10px solid transparent;
    }
  }

  &-list {
    align-items: center;
    background: none;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    list-style: none;
    margin: 0;
    padding: 0;
    &__item {
      background: none;
      border: none;
      line-height: 0;
      padding: 0;
    }
  }
}
