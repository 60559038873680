.c-tab {
  &__pane {
    &.active {
      .col {
        margin: 0;
        padding: 0;
      }
    }
  }
  &-list {
    align-items: flex-end;
    background: transparent;
    border: none;
    // border-bottom: 2px $appColorGrayScale6 solid;
    border-bottom: 1px $appColorGrayScale12 solid;
    border-radius: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    margin-bottom: 15px;
    justify-content: flex-start;
    list-style: none;
    &__item {
      align-items: center;
      // background: $appColorGrayScale9;
      background: $appColorGrayScale16;
      border: none;
      border-radius: $appRadiusM $appRadiusM 0 0;
      box-sizing: border-box;
      cursor: pointer;
      display: flex;
      font-size: $appFontSizeS;
      height: 40px;
      max-height: 40px;
      overflow: hidden;
      position: relative;
      text-align: center;
      transition: background 0.3s, color 0.3s;
      width: 140px;
      z-index: 999;
      & + & {
        margin-left: 6px;
      }
      .col {
        line-height: 1em;
        margin-top: 2px;
        padding: 0;
      }
      &:hover {
        background: $appColorPrimary2;
        color: $appColorWhite;
        font-weight: $appFontWeightExtraBold;
      }
      &:first-child {
        border-radius: $appRadiusM $appRadiusM 0 0;
      }
      &.is-active {
        background: $appColorPrimary1;
        color: $appColorWhite;
        font-weight: $appFontWeightExtraBold;
      }
      &.is-badge {
        overflow: visible;
      }
      &.is-badge::before {
        background: #e25464;
        border-radius: 50%;
        content: '';
        height: 12px;
        position: absolute;
        width: 12px;
        right: -3px;
        top: -3px;
      }
    }
  }
}

@media (max-width: $appBreakPoint) {
  .c-tab {
    &-list {
      border-bottom: 1px $appColorGrayScale6 solid;
      justify-content: space-between;
      margin-bottom: 8px;
      &__item {
        padding: 0 5px;
        width: 100%;
        & + & {
          margin-left: 6px;
        }
      }
    }
  }
}
