.c-profile-dropdown {
  $this: &;
  align-items: center;
  display: flex;
  justify-content: flex-end;

  &__separator {
    background: $appColorGrayScale2Alpha;
    height: 60px;
    margin: 0;
    opacity: 0.85;
    transition: opacity 300ms;
    width: 1px;
  }
  &__label {
    @include ellipsis();
    align-content: center;
    background: transparent;
    color: $appColorBlack;
    cursor: default;
    display: flex;
    flex-direction: column;
    font-size: $appFontSizeXS;
    font-weight: $appFontWeightExtraBold;
    height: 60px;
    justify-content: center;
    // padding: 13.5px 12.5px 11.5px 12.5px;
    padding: 12.5px;
    position: relative;
    transition: background 0.3s, padding 0.3s, width 0.3s;
    width: 110px;
    z-index: 8000;

    .code,
    .name,
    .organization {
      @include ellipsis();
      line-height: $appFontSizeS + 1;
      margin: 0;
      width: 85px;
    }
    .arrow {
      font-size: 12px;
      line-height: 0;
      position: absolute;
      right: 6.5px;
      top: calc(50% + 1px);
    }
  }

  &__image {
    align-items: center;
    cursor: pointer;
    display: flex;
    height: 60px;
    justify-content: center;
    position: relative;
    width: 64px;
    &:hover {
      background: $appColorGrayScale2Alpha;
    }
    .col {
      align-items: center;
      background: $appColorWhite;
      border-radius: 50%;
      display: flex;
      flex-basis: auto;
      flex-grow: 0;
      height: 34px;
      justify-content: center;
      margin: 0;
      overflow: hidden;
      padding: 0;
      width: 34px;
    }
    i {
      color: $appColorGrayScale6;
      font-size: 24px;
    }
    img {
      width: 100%;
    }
  }

  &-nav {
    box-sizing: border-box;
    height: 0;
    right: 10px;
    opacity: 0;
    overflow: hidden;
    padding: 0;
    position: absolute;
    transition: opacity 300ms;
    top: 70px;
    visibility: hidden;
    width: 200px;
    z-index: 1000;
    &-list {
      background: $appColorWhite;
      border: 1px solid rgba(0, 0, 0, 0.15);
      // border: none;
      border-radius: $appRadiusM;
      box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.05);
      list-style: none;
      overflow: hidden;
      padding: 0;
      &__item {
        border: none;
        border-bottom: 1px dotted $appColorGrayScale6;
        box-sizing: border-box;
        padding: 0;
        &--account {
          border-bottom: none;
          display: none;
          #{$this}__label {
            background: $appColorBlack;
            padding: 15px 15px 11.5px 15px;
            height: auto;
            width: 100%;
            .code,
            .name,
            .organization {
              font-size: $appFontSizeS;
              height: auto;
              line-height: $appFontSizeM + 3;
              margin: 0;
              width: 100%;
            }
            .name {
              color: $appColorWhite;
            }
          }
        }

        &--toggle {
          background: $appColorHoverGray;
          display: none;
          padding: 15px 15px;
          .loader {
            display: none;
            position: absolute;
            left: calc(50% - 5px);
            top: calc(50% - 7px);
          }
          &.is-loading {
            height: 52px;
            justify-content: center;
            .loader {
              display: block;
            }
            .c-switch {
              display: none;
            }
          }
        }

        &--notification {
          display: none;
        }

        &:last-child {
          border-bottom: none;
        }
        a {
          align-items: center;
          color: $appColorBlack;
          display: flex;
          font-size: $appFontSizeM;
          font-weight: $appFontWeightExtraBold;
          justify-content: flex-start;
          line-height: $appFontSizeM;
          padding: 12.5px 15px;
          text-decoration: none;
          &.is-pc {
            display: flex;
          }
          &:hover {
            background: $appColorHoverGray;
          }
          &:hover,
          &:active,
          &:visited {
            text-decoration: none;
          }
          i {
            color: $appColorBlack;
            font-size: $appFontSizeXL;
            line-height: $appFontSizeXL - 1px;
            margin: 0 8px 0 0;
          }
        }
      }
    }
    &--organization {
      #{$this}-nav-list {
        overflow-x: hidden;
        overflow-y: auto;
        max-height: 360px;
        &__item {
          color: $appColorBlack;
          cursor: pointer;
          &:hover {
            background: $appColorHoverGray;
          }
          .col {
            @include ellipsis;
            font-size: $appFontSizeM;
            font-weight: $appFontWeightExtraBold;
            padding: 12.5px 15px;
          }
        }
      }
    }
    &.is-active {
      height: auto;
      visibility: visible;
      opacity: 1;
      overflow: inherit;
    }
  }

  &--no-login {
    #{$this}-nav {
      right: 10px;
      top: 70px;
      width: 200px;
    }
    #{$this}__image {
      .col {
        background: $appColorWhite;
      }
    }
  }
}

@media (max-width: 1180px) {
  .c-profile-dropdown {
    $this: &;

    &__separator {
      opacity: 0;
      width: 0;
    }
    &__label {
      overflow: hidden;
      padding: 0;
      width: 0;
      .arrow {
        opacity: 0;
      }
    }
    &-nav {
      &-list {
        &__item {
          &--account {
            display: flex;
            #{$this}__label {
              .arrow {
                opacity: 1;
              }
            }
          }
          &--toggle {
            display: flex;
          }
          &--notification {
            display: block;
          }
        }
      }
    }
  }
}

@media (max-width: $appBreakPoint) {
  .c-profile-dropdown {
    $this: &;
    &--no-login {
      #{$this}-nav {
        right: 0;
        top: 50px;
        width: 50%;
      }
    }

    &__separator {
      height: 50px;
    }
    &__label {
      height: 50px;
    }
    &__image {
      height: 50px;
      width: 56px;
      .col {
        height: 32px;
        width: 32px;
      }
    }
    &-nav {
      right: 0;
      top: 50px;
      width: 50%;
      z-index: 8010;
      &-list {
        // box-shadow: none;
        border-radius: 0 0 $appRadiusM $appRadiusM;
        &__item {
          &--account {
            #{$this}__label {
              display: flex;
              font-size: $appFontSizeXS;
              width: 100%;
            }
          }
          &--toggle {
            .c-switch {
              .custom-control-label {
                font-size: $appFontSizeS;
                &::before,
                &::after {
                  margin-top: 0;
                }
              }
            }
          }
          a {
            font-size: $appFontSizeS;
            &.is-pc {
              display: none;
            }
            i {
              font-size: $appFontSizeL;
              line-height: $appFontSizeL - 1px;
            }
          }
        }
      }
      &--organization {
        #{$this}-nav-list {
          max-height: 480px;
          &__item {
            .col {
              font-size: $appFontSizeS;
            }
          }
        }
      }
    }
  }
}
