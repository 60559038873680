.c-breadcurmb {
  $this: &;
  margin: -15px 0 5px 0;
  padding: 0;
  position: relative;
  transition: margin 0.3s;
  width: 100%;

  // シンプルテキストリンクバージョン
  &--simple {
    margin: -15px 0 15px 0;
    #{$this}-list {
      flex-direction: row;
      justify-content: flex-start;
      width: 100%;
      &__item {
        display: block;
        margin: 0 0 6px 0;
        width: auto;
        &--setting {
          display: none;
        }
        a,
        .col {
          background: transparent;
          height: auto;
          max-width: 100%;
          padding: 0;
          transition: background 0s;
          .c-text {
            @include ellipsis;
            color: $appColorGrayScale10;
            display: block;
            font-size: $appFontSizeS;
            font-weight: $appFontWeightNormal;
            line-height: 1.6em;
            max-width: 330px;
            padding-right: 25px;
            position: relative;
            text-align: left;
            text-decoration: underline;
            &::after {
              // content: '›';
              content: '\203A';
              display: block;
              right: 12.5px;
              position: absolute;
              text-decoration: none;
              top: 0;
            }
          }
        }
        &:hover,
        &:active,
        &:visited {
          a {
            background: transparent;
          }
        }

        // シンプルテキスト表示の場合は、PC表示でも、スマホ表示用のテキストリンクを表示
        &--home {
          &.is-pc {
            display: none;
          }
          &.is-sp {
            display: block;
          }
          a {
            border-radius: 0;
            color: $appColorBlack;
            height: auto;
            width: auto;
          }
        }

        &.is-active {
          .col {
            background: transparent;
            color: $appColorBlack;
            .c-text {
              color: $appColorBlack;
              font-weight: $appFontWeightExtraBold;
              padding-right: 0;
              text-decoration: none;
              &::after {
                display: none;
              }
            }
          }
          &.is-detail-name {
            .col {
              .c-text {
                font-weight: normal;
                padding-right: 25px;
                &::after {
                  display: block;
                }
              }
            }
          }
        }
      }
    }
    #{$this}-list__item + #{$this}-list__item {
      margin-left: 0;
    }
  }

  &__block {
    // position: absolute;
    position: relative;
    width: 100%;
    // z-index: 7000;
  }
  &-list {
    align-items: center;
    background: none;
    border: none;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    list-style: none;
    justify-content: flex-end;
    margin: 0;
    padding: 0;
    width: 100%;
    &__item {
      // background: $appColorGrayScale7;
      background: transparent;
      border: none;
      margin: 0 0 10px 0;
      padding: 0;
      width: auto;
      a,
      .col {
        align-items: center;
        background: $appColorGrayScale6;
        border-radius: 50px;
        box-sizing: border-box;
        cursor: pointer;
        display: flex;
        height: 36px;
        margin: 0;
        max-width: 200px;
        justify-content: center;
        padding: 0 20px;
        transition: background 0.3s;
        i {
          font-size: 18px;
          line-height: 18px;
        }
        .c-text {
          @include ellipsis;
          color: $appColorWhite;
          display: block;
          font-size: $appFontSizeS;
          font-weight: $appFontWeightExtraBold;
          line-height: $appFontSizeS;
          text-align: center;
          width: 100%;
        }
      }
      &:hover {
        a {
          background: $appColorGrayScale2;
        }
      }
      &:active,
      &:visited {
        a {
          background: $appColorGrayScale6;
        }
      }
      &--home {
        &.is-pc {
          display: block;
        }
        &.is-sp {
          display: none;
        }
        a {
          border-radius: 50%;
          color: $appColorWhite;
          height: 36px;
          padding: 0;
          width: 36px;
        }
      }

      &.is-active {
        .col {
          background: $appColorGrayScale2;
          color: $appColorWhite;
          cursor: default;
        }
      }
    }
    &__item + &__item {
      margin-left: 10px;
    }
  }
}

@media (max-width: $appBreakPoint) {
  .c-breadcurmb {
    $this: &;
    // margin: 0;
    margin: -30px 0 20px 0;
    transition: margin 0s;

    // シンプルテキストリンクバージョン
    &--simple {
      #{$this}-list {
        &__item {
          &--setting {
            display: block;
          }
        }
      }
    }

    &-list {
      flex-direction: row;
      justify-content: flex-start;
      width: 100%;
      &__item {
        display: block;
        margin: 0 0 6px 0;
        width: auto;
        &--setting {
          display: block;
        }
        a,
        .col {
          background: transparent;
          height: auto;
          max-width: 100%;
          padding: 0;
          transition: background 0s;
          .c-text {
            color: $appColorGrayScale10;
            font-size: $appFontSizeS;
            font-weight: $appFontWeightNormal;
            line-height: 1.6em;
            max-width: 330px;
            padding-right: 25px;
            position: relative;
            text-align: left;
            text-decoration: underline;
            &::after {
              content: '›';
              display: block;
              right: 12.5px;
              position: absolute;
              text-decoration: none;
              top: 0;
            }
          }
        }
        &:hover,
        &:active,
        &:visited {
          a {
            background: transparent;
          }
        }

        &--home {
          &.is-pc {
            display: none;
          }
          &.is-sp {
            display: block;
          }
          a {
            border-radius: 0;
            color: $appColorBlack;
            height: auto;
            width: auto;
          }
        }

        &.is-active {
          .col {
            background: transparent;
            color: $appColorBlack;
            .c-text {
              color: $appColorBlack;
              font-weight: $appFontWeightExtraBold;
              padding-right: 0;
              &::after {
                display: none;
              }
            }
          }
        }
      }
      &__item + &__item {
        margin-left: 0;
      }
    }
  }
}
