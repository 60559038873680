.l-page {
  $this: &;

  &-error-container {
    position: relative;
  }

  &--base {
    align-items: flex-start;
    box-sizing: border-box;
    display: flex;
    min-height: 100%;
    justify-content: flex-start;
    padding: 0 0 0 0;
    // padding: 110px 0 135px 0;
    transition: padding 300ms;
    #{$this}-content {
      // margin: 0 auto;
      margin: 0;
      max-width: calc(980px + 240px);
      padding: 100px 0 135px 240px;
      transition: margin 300ms, padding 300ms;
      width: 92%;

      &--no-sidebar {
        margin: 0 auto;
        padding: 100px 0 135px 0;
        position: relative;
      }

      &--full-width {
        max-width: 100%;
        padding: 100px 40px 135px 240px;
        width: 100%;
        .p-error {
          width: 100%;
        }
        &#{$this}-content--no-sidebar {
          padding: 100px 40px 135px 40px;
          .p-error {
            width: 100%;
          }
        }
        &#{$this}-content--chat-group-message {
          padding: 100px 40px 20px 40px;
          .p-error {
            width: 100%;
          }
        }
      }

      &--chat-group-message {
        padding: 100px 40px 20px 40px;
      }

      &--full-width + &--full-height {
        padding: 100px 40px 45px 240px;
      }

      // --------------------------------
      // .c-progress-bar {
      //   background: $appColorWhite;
      //   border-radius: 8px 8px 0 0;
      //   padding-top: 25px;
      // }
      // --------------------------------

      .c-progress-bar {
        margin: 35px auto 25px auto;
      }

      .p-error {
        position: absolute;
        width: calc(100% - 280px);
      }

      .tab-content {
        .p-error {
          position: relative;
          width: 100%;
        }
      }

      &--no-sidebar {
        .p-error {
          width: 100%;
        }
      }

      &--full-width {
        .p-error {
          width: 100%;
        }
        &#{$this}-content--no-sidebar {
          .p-error {
            width: 100%;
          }
        }
      }
    }
  }
}
@media (max-width: 1180px) {
  .l-page {
    $this: &;
    &--base {
      #{$this}-content {
        // padding-left: 60px;
        max-width: calc(980px - 40px);
        padding-left: 100px;
        width: calc(100% - 40px);

        &--no-sidebar {
          padding-left: 0;
        }

        &--chat-group-message {
          padding-bottom: 20px;
        }

        &--full-width {
          max-width: calc(980px + 240px);
          padding-left: 100px;
          width: 100%;
          .p-error {
            width: 100%;
          }
          &#{$this}-content--no-sidebar {
            padding-left: 40px;
          }
          &#{$this}-content--chat-group-message {
            padding-bottom: 20px;
          }
        }

        .p-error {
          width: calc(100% - 140px);
        }

        &--no-sidebar {
          .p-error {
            width: 100%;
          }
        }

        &--full-width {
          .p-error {
            width: 100%;
          }
          &#{$this}-content--no-sidebar {
            .p-error {
              width: 100%;
            }
          }
        }

        .tab-content {
          .p-error {
            position: relative;
            width: 100%;
          }
        }
      }
    }
  }
}

@media (max-width: $appBreakPoint) {
  .l-page {
    $this: &;
    &--base {
      padding: 100px 0 0 0;
      #{$this}-content {
        margin: 0 auto;
        max-width: 92%;
        padding: 0;
        width: 92%;

        &--no-sidebar {
          padding: 0;
        }

        &--chat-group-message {
          padding-bottom: 20px;
        }

        &--full-width {
          max-width: 92%;
          padding: 0;
          width: 92%;
          &#{$this}-content--no-sidebar {
            padding: 0;
          }
          &#{$this}-content--chat-group-message {
            padding-bottom: 20px;
          }
        }

        .c-progress-bar {
          margin: 0 auto 15px auto;
        }

        .p-error {
          position: inherit;
          width: 100%;
        }

        .tab-content {
          .p-error {
            position: relative;
            width: 100%;
          }
        }
      }
    }
  }
}
