.p-joined-company-deal {
  $this: &;
  margin: 0 auto 15px auto;

  .row {
    margin: 0;
  }

  .p-error {
    &__desc {
      margin-top: 25px;
    }
  }

  &.is-readonly {
    #{$this} {
      &-head {
        &__container {
          justify-content: flex-end;
        }
        &__item {
          &--keyword {
            max-width: 400px;
            min-width: 400px;
            width: 400px;
          }
          &--btns {
            display: none;
          }
        }
      }
    }
  }

  &-head {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    width: 100%;

    &__container {
      align-items: center;
      background: $appColorGrayScale16;
      border-radius: 0 0 $appRadiusM $appRadiusM;
      display: flex;
      flex-wrap: wrap;
      gap: 0 12px;
      justify-content: flex-end;
      padding: 36px 24px 28px 24px;
      width: 100%;
      &.row {
        margin-bottom: 30px;
      }
    }

    &__item {
      &--company-name {
        &.row {
          margin-bottom: 0;
        }
        width: 100%;
        .c-text {
          border-bottom: 1px solid $appColorGrayScale12;
          display: block;
          font-size: $appFontSizeXL;
          font-weight: $appFontWeightExtraBold;
          line-height: 1.4em;
          padding-bottom: 8px;
          width: 100%;
        }
      }
      &--keyword {
        position: relative;
        max-width: 300px;
        min-width: 300px;
        transition: max-width 0.3s, min-width 0.3s, width 0.3s;
        width: 300px;
        .c-icon {
          color: $appColorGrayScale6;
          font-size: 36px;
          left: 1px;
          position: absolute;
          top: 8px;
          transform: rotate(-45deg);
          z-index: 1001;
        }
        .c-input {
          border-radius: 50px;
          padding-left: 36px;
          padding-right: 18px;
          transition: width 0.3s;
          width: 100%;
          z-index: 1000;
        }
        .form-group {
          margin: 0;
          width: 100%;
          .invalid-feedback {
            top: -40px;
          }
        }
      }
      &--status {
        position: relative;
        max-width: 120px;
        min-width: 120px;
        width: 120px;
        .form-group {
          margin: 0;
          width: 100%;
          label {
            font-size: $appFontSizeXS - 1;
            font-weight: $appFontWeightExtraBold;
            left: 5px;
            position: absolute;
            top: -22px;
          }
          .invalid-feedback {
            top: auto;
            bottom: 48px;
            width: 170px;
          }
        }
      }
      &--btns {
        max-width: 200px;
        min-width: 200px;
        width: 200px;
        .c-btn {
          height: 40px;
          margin: 0;
          width: 100%;
        }
      }
    }
  }
}

@media (max-width: 1030px) {
  .p-joined-company-deal {
    $this: &;
    &-head {
      &__item {
        &--btns {
          max-width: 160px;
          min-width: 160px;
          width: 160px;
        }
      }
    }
  }
}

@media (max-width: 800px) {
  .p-joined-company-deal {
    $this: &;
    &.is-readonly {
      #{$this} {
        &-head {
          &__item {
            &--keyword {
              max-width: 400px;
              min-width: 400px;
              width: 400px;
            }
          }
        }
      }
    }
    &-head {
      &__item {
        &--keyword {
          max-width: calc(100% - (120px + 160px) - (12px * 2));
          min-width: calc(100% - (120px + 160px) - (12px * 2));
          width: calc(100% - (120px + 160px) - (12px * 2));
          .form-group,
          .c-input {
            min-width: 100%;
            width: 100%;
          }
        }
      }
    }
  }
}

@media (max-width: $appBreakPoint) {
  .p-joined-company-deal {
    $this: &;
    margin: 0 auto 3px auto;
    &.is-readonly {
      #{$this} {
        &-head {
          &__item {
            &--keyword {
              max-width: 100%;
              min-width: 100%;
              width: 100%;
            }
          }
        }
      }
    }
    &-head {
      &__container {
        gap: 18px 0;
        justify-content: space-between;
        padding: 20px;
        &.row {
          margin-bottom: 16px;
        }
      }
      &__item {
        &--keyword {
          max-width: 100%;
          min-width: 100%;
          width: 100%;
          .c-input {
            width: 100%;
          }
        }
        &--status,
        &--member-type {
          &.row {
            margin-top: 16px;
          }
          max-width: 100%;
          min-width: 100%;
          width: 100%;
        }
        &--btns {
          max-width: 100%;
          min-width: 100%;
          width: 100%;
        }
      }
    }
  }
}
