// ---------------------------------
// theme: マイページ
// * 現状は、ベースのLightテーマと同じ
// ---------------------------------
.l-header {
  $this: &;
  &--account-setting {
    background: $appColorGrayScale8;
    border-bottom: 1px $appColorGrayScale12 solid;
    #{$this}__logo {
      img {
        &.black {
          display: block;
        }
        &.white {
          display: none;
        }
      }
    }
    #{$this}__item {
      &--invitation,
      &--notification {
        .col {
          i {
            color: $appColorBlack;
          }
          &:hover {
            background: $appColorGrayScale2Alpha;
            i {
              color: $appColorGrayScale2;
            }
          }
        }
      }
    }
    #{$this}-toggle {
      &-list {
        &__item {
          .c-text {
            color: $appColorWhite;
            &.is-active {
              color: $appColorWhite;
            }
          }
          .c-switch {
            .custom-control-input:focus:not(:checked)
              ~ .custom-control-label::before {
              border-color: $appColorGrayScale5;
            }
            .custom-control-input:checked ~ .custom-control-label {
              &::before {
                background: $appColorSecondary1;
                border-color: $appColorSecondary1;
              }
            }
          }
        }
      }
    }
    #{$this}-search {
      .c-input {
        border-color: $appColorGrayScale2Alpha !important;
      }
      .c-btn {
        &--enter {
          background: $appColorSecondary1;
          border-color: $appColorSecondary1;
          &:hover {
            background: $appColorSecondary6 !important;
            border-color: $appColorSecondary6 !important;
          }
        }
      }
    }
    #{$this}-menu {
      &-list {
        &__item {
          .col {
            &:hover {
              background: $appColorGrayScale2;
              i {
                color: $appColorWhite;
              }
            }
            i {
              color: $appColorBlack;
            }
          }
        }
      }
    }
  }
}

.l-sidebar {
  $this: &;
  &--account-setting {
    background: #f2f2f2;
    border-right: 1px $appColorGrayScale12 solid;

    #{$this} {
      &-list {
        &__item-group {
          &::before {
            display: none !important;
          }
        }
        &__item {
          &:hover {
            background: $appColorLightModeSideBarScale2;
          }
          .col {
            i,
            .c-text {
              color: $appColorBlack;
            }
          }
          &.is-active {
            background: $appColorLightModeSideBarScale2;
            &:hover {
              background: $appColorLightModeSideBarScale2;
            }
          }
          &--child {
            &:hover {
              background: $appColorLightModeSideBarScale3;
            }
            &.is-active {
              background: $appColorLightModeSideBarScale3;
            }
          }
        }
      }
    }
  }
}

.l-footer {
  $this: &;
  &--account-setting {
    background: $appColorGrayScale8;
    border-top: 1px $appColorGrayScale12 solid;
    .list-group {
      &-item {
        a {
          color: $appColorBlack !important;
          &:hover,
          &:active,
          &:visited {
            color: $appColorBlack !important;
          }
        }
      }
    }
    #{$this} {
      &__copyright {
        color: $appColorBlack !important;
      }
    }
  }
}

.c-profile-dropdown {
  $this: &;
  &--account-setting {
    #{$this} {
      &__image {
        .col {
          background: $appColorWhite;
          i {
            color: $appColorGrayScale6;
          }
        }
        &:hover {
          background: $appColorGrayScale2Alpha;
          .col {
            background: $appColorWhite;
            i {
              color: $appColorGrayScale6;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1180px) {
  .c-profile-dropdown {
    $this: &;
    &--account-setting {
      #{$this} {
        &__label {
          color: $appColorWhite;
        }
      }
    }
  }
}

@media (max-width: $appBreakPoint) {
  .l-sidebar {
    $this: &;
    &--account-setting {
      background: rgba(
        red($appColorLightModeSideBarScale1),
        green($appColorLightModeSideBarScale1),
        blue($appColorLightModeSideBarScale1),
        0.85
      );
      // background: rgba(
      //   red($appColorGrayScale15),
      //   green($appColorGrayScale15),
      //   blue($appColorGrayScale15),
      //   0.85
      // );
      border-right: none;
      #{$this} {
        &-list {
          &__item-group {
            &::before {
              border-color: $appColorLightModeSideBarScale4;
              display: block !important;
            }
          }
          &__item {
            &--child {
              &::before {
                border-color: $appColorLightModeSideBarScale2;
                display: block !important;
              }
            }
          }
        }
      }
    }
  }
  .l-footer {
    $this: &;
    &--account-setting {
      .list-group {
        &-item {
          border-color: $appColorGrayScale12 !important;
        }
      }
    }
  }
}
