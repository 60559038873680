.p-joined-project-recruit {
  $this: &;
  margin: 0 auto 15px auto;

  &--search {
    margin-bottom: 0;
  }

  .row {
    margin: 0;
  }

  .p-error {
    padding-top: 56px;
    &__desc {
      margin-top: 25px;
    }
  }

  &-head {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    width: 100%;

    &__container {
      align-items: center;
      background: $appColorGrayScale16;
      border-radius: 0 0 $appRadiusM $appRadiusM;
      display: flex;
      flex-wrap: wrap;
      gap: 0 12px;
      justify-content: flex-end;
      padding: 36px 24px 28px 24px;
      width: 100%;
      &.row {
        margin-bottom: 30px;
      }
    }

    &__item {
      &.row {
        margin-bottom: 0;
      }
      &--project-title {
        width: 100%;
        &.row {
          margin-bottom: 0;
        }
        .c-text {
          border-bottom: 1px solid $appColorGrayScale12;
          display: block;
          font-size: $appFontSizeXL;
          font-weight: $appFontWeightExtraBold;
          line-height: 1.4em;
          padding-bottom: 8px;
          width: 100%;
        }
        a {
          color: var(--app-color-black) !important;
          &:hover {
            color: var(--app-color-primary-4) !important;
            text-decoration: underline !important;
          }
          &:active,
          &:visited {
            text-decoration: none !important;
          }
        }
      }
      &--reference-type,
      &--status {
        position: relative;
        .form-group {
          margin: 0;
          width: 100%;
          label {
            font-size: $appFontSizeXS - 1;
            font-weight: $appFontWeightExtraBold;
            left: 5px;
            position: absolute;
            top: -22px;
          }
          .invalid-feedback {
            bottom: 48px;
            top: auto;
            width: 140px;
          }
        }
      }
      &--reference-type {
        max-width: 140px;
        min-width: 140px;
        width: 140px;
      }
      &--status {
        max-width: 140px;
        min-width: 140px;
        width: 140px;
      }
    }
  }
}

@media (max-width: 1000px) {
  .p-joined-project-recruit {
    $this: &;
    &-head {
      &__item {
        &--keyword {
          max-width: 200px;
          min-width: 200px;
          width: 200px;
          .c-input {
            width: 200px;
          }
        }
      }
    }
  }
}

@media (max-width: 840px) {
  .p-joined-project-recruit {
    $this: &;

    &-head {
      &__container {
        gap: 24px 12px;
        justify-content: flex-end;
        padding: 36px 24px 24px 24px;
      }
    }
  }
}

@media (max-width: $appBreakPoint) {
  .p-joined-project-recruit {
    $this: &;
    margin: 0 auto 16px auto;

    &--search {
      margin-bottom: 0;
    }

    .p-error {
      &__desc {
        margin-top: 15px;
      }
    }

    &-head {
      flex-direction: column;
      flex-wrap: wrap;
      width: 100%;
      &__item {
        &--project-title {
          .c-text {
            font-size: $appFontSizeL;
            line-height: 1.4em;
          }
        }
        &--reference-type,
        &--status {
          max-width: calc(50% - 6px);
          min-width: calc(50% - 6px);
          width: calc(50% - 6px);
        }
      }
    }
  }
}
