.p-chat-group {
  $this: &;
  margin: 0 auto 15px auto;

  &--search {
     margin-bottom: 0;
  }

  .row {
    margin: 0;
  }

  &-head {
    align-items: center;
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-end;
    width: 100%;
    &__item {
      &.row {
        margin-bottom: 15px;
      }
      &--keyword {
        position: relative;
        max-width: 520px;
        min-width: 520px;
        transition: max-width .3s, min-width .3s, width .3s;
        width: 520px;
        .c-icon {
          color: $appColorGrayScale6;
          font-size: 36px;
          left: 1px;
          position: absolute;
          top: 8px;
          transform: rotate(-45deg);
          z-index: 1001;
        }
        .c-input {
          border-radius: 50px;
          padding-left: 36px;
          padding-right: 18px;
          transition: width .3s;
          width: 520px;
          z-index: 1000;
        }
        .form-group {
          margin: 0;
          .invalid-feedback {
            top: -40px;
          }
        }
      }
    }
  }

  &__unread-count {
    @include ellipsis;
    align-items: center;
    background: $appColorFreeRed;
    border-radius: 50px;
    box-sizing: border-box;
    color: $appColorWhite;
    display: flex;
    font-size: $appFontSizeS;
    font-weight: $appFontWeightExtraBold;
    height: 20px;
    justify-content: center;
    max-width: 32px;
    min-width: 20px;
    padding: 0 5px;
    position: absolute;
    right: -5px;
    top: -5px;
    width: auto;
    z-index: 1001;
  }

  &-detail {
    &__image {
      &--project {
        align-items: center;
        background-color: $appColorGrayScale8;
        background-position: center center;
        background-size: cover;
        border-radius: $appRadiusM;
        display: flex;
        flex-grow: initial;
        justify-content: center;
        height: 120px;
        margin: 0;
        overflow: hidden;
        padding: 0;
        width: 100%;
        img {
          width: 100%;
          z-index: 1000;
        }            
        &--no-image {
          background: none $appColorGrayScale8;
          box-sizing: border-box;
          border: 4px solid $appColorGrayScale8;
        }
      }
      &--organization {
        align-items: center;
        background: $appColorWhite;
        box-sizing: border-box;
        border: 4px solid $appColorGrayScale8;
        border-radius: 50%;
        display: flex;
        flex-grow: initial;
        justify-content: center;
        height: 64px;
        margin: 0;
        overflow: hidden;
        padding: 0;
        width: 64px;
        img {
          width: 100%;
        }
        i {
          color: $appColorGrayScale8;
          font-size: 48px;
        }
      }
    }
    &-head {
      flex-direction: column;
      padding: 20px 20px 0 20px !important;
      &__item {
        display: flex;
        flex-direction: column;
        margin: 0 0 15px 0;
        position: relative;
        width: 100%;
        &-group {
          align-items: center;
          display: flex;
          justify-content: flex-start;
          margin: 0;
          &--organization {
            align-items: flex-start;
            justify-content: space-between;
          }
        }

        &--title {
          align-items: flex-end;
          color: #333;
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          margin-bottom: 0;
          padding: 0 0 12px 0;
          a {
            @include ellipsis;
            color: $appColorBlack;
            display: block;
            font-size: $appFontSizeXL;
            font-weight: $appFontWeightExtraBold;
            text-decoration: none;
            max-width: calc(100% - 26px);
            &:hover {
              text-decoration: underline !important;
            }
            &:visited,
            &:active {
              color: $appColorBlack !important;
              text-decoration: none !important;
            }
          }
          i {
            display: block;
            font-size: $appFontSizeM;
            margin: 0 5px;
            width: 16px;
          }
        }

        &--organization {
          justify-content: center;
          width: 100px;
          a {
            display: flex;
            justify-content: center;
          }
          .c-text {
            @include ellipsis;
            color: $appColorBlack !important;
            display: block;
            font-size: $appFontSizeS;
            font-weight: $appFontWeightNormal;
            padding-top: 6px;
            text-align: center;
            width: 100%;
            &:hover {
              color: $appColorBlack !important;
              text-decoration: underline !important;
            }
            &:visited,
            &:active {
              color: $appColorBlack !important;
              text-decoration: none !important;
            }
          }
        }

        &--sent-at {
          display: flex;
          flex-direction: column;
          font-size: $appFontSizeM;
          font-weight: $appFontWeightNormal;
          justify-content: flex-start;
          padding-top: 12px;
          width: calc(100% - 110px);
        }
      }
    }
    &-body {
      display: flex;
      flex-direction: column;
      padding: 28px 20px 20px 20px !important;
      &__item {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin: 0;
        position: relative;
        width: 100%;

        & + & {
          margin-top: 15px;
        }

        &--category {
          align-items: flex-start;
        }

        .col {
          padding: 0;
        }
        .label {
          align-items: center;
          color: $appColorGrayScale11;
          display: flex;
          font-size: $appFontSizeS;
          font-weight: $appFontWeightExtraBold;
          justify-content: flex-start;
          max-width: 150px;
          min-width: 150px;
          width: 150px;
          i {
            font-size: 22px;
            margin-right: 4px;
          }
        }
        .value {
          font-size: $appFontSizeM;
          font-weight: $appFontWeightNormal;
          width: calc(100% - 160px);
          .c-badge {
            border-radius: $appRadiusM;
            font-size: $appFontSizeS;
            height: 30px;
            min-width: 70px;
            padding: 0 10px;
            width: auto;
            &-list {
              &__item {
                margin: 0 8px 8px 0;
              }
            }
          }
        }
      }
    }
  }

  &-list {
    background: none;
    border-radius: $appRadiusM;
    list-style: none;
    margin: 0;
    padding: 0;

    &__item {
      align-items: center;
      background: $appColorWhite;
      border: none;
      border-bottom: 1px $appColorGrayScale6 solid;
      border-radius: 0;
      cursor: pointer;
      display: flex;
      flex-wrap: wrap;
      list-style: none;
      margin: 0;
      // padding: 17.5px 0 10px 0;
      padding: 12px 0;
      width: 100%;

      // max-height: 70px;
      // overflow: hidden;

      &:last-child {
        border-bottom: none;
      }

      &:hover {
        background: $appColorGrayScale9;
      }

      &--empty {
        &:hover {
          background: $appColorWhite;
        }
        .col.value {
          max-width: 100%;
          min-width: 100%;
          padding: 15px 15px !important;
          text-align: center;
          width: 100%;
        }
      }

      &-image {
        align-items: center;
        background-color: $appColorGrayScale8;
        background-position: center center;
        background-size: cover;
        border-radius: $appRadiusM;
        display: flex;
        flex-grow: initial;
        justify-content: center;
        height: 44px;
        margin: 0;
        overflow: hidden;
        padding: 0;
        width: 78px;
        transition: width .3s;
        img {
          width: 100%;
          z-index: 1000;
        }
        i {
          color: $appColorWhite;
          font-size: 40px;
        }
        &--no-image {
          background: none $appColorGrayScale8;
          box-sizing: border-box;
          border: 4px solid $appColorGrayScale8;
        }
      }

      .col.label,
      .col.value {
        padding: 0 12.5px 0 12.5px;
      }

      .label,
      .value {
        align-items: center;
        display: flex;
        flex-grow: 0;
        transition: margin .3s, max-width .3s, min-width .3s;
        &.is-sortable {
          cursor: pointer;
        }
        &-group {
          align-items: stretch;
          display: flex;
          transition: width .3s;
          &--title {
            width: calc(100% - 490px);
          }
          .label,
          .value {
            &--title {
              justify-content: flex-start;
              min-width: 130px;
              width: calc(100% - 148px);
            }
          }
          &--title {
            align-items: center;
            flex-grow: 1;
          }
        }

        &--title {
          @include ellipsis;
          display: block;
          flex-grow: 1;
          width: auto;
          a {
            color: $appColorBlack;
            text-decoration: none;
            &:visited,
            &:active {
              color: $appColorBlack;
            }
            &:hover {
              color: $appColorBlack;
              text-decoration: underline;
            }
          }
          i {
            margin-left: 5px;
          }
        }

        &--control {
          max-width: 80px;
          min-width: 80px;
          width: auto;
        }
        &--latest-sent-at {
          max-width: 210px; 
          min-width: 210px;
          width: auto;
        }
        &--relate-type {
          max-width: 100px; 
          min-width: 100px;
          width: auto;
        }
        &--reference-type {
          max-width: 100px; 
          min-width: 100px;
          width: auto;
        }
        &--image {
          max-width: 78px;
          min-width: 78px;
          width: auto;
        }
        &--switch {
          justify-content: center;
          max-width: 70px;
          min-width: 70px;
          width: auto;
        }
      }

      .c-badge {
        border-radius: $appRadiusM;
        font-size: $appFontSizeS;
        height: 30px;
        min-width: 70px;
        padding: 0 10px;
        width: auto;
      }

      .value {
        &--image {
          padding: 0 !important;
          position: relative;
        }        
        &--switch {
          i {
            font-size: $appFontSizeM;
            transform: rotate(90deg);
            transition: transform .3s;
            &.is-active {
              color: $appColorPrimary1;
              transform: rotate(180deg);
            }
          }
        }
        &--control {
          align-items: center;
          cursor: default;
          flex-direction: row;
          flex-wrap: wrap;
          display: flex;
          justify-content: flex-start;
          text-align: center;
          .c-menu-icon + .c-menu-icon {
            margin-left: 6px;
          }

          .c-menu-icon--detail {
            // background: red;
            background: transparent;
            i {
              // color: $appColorSecondary1;
              font-size: 30px;
              margin-top: 1px;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 920px) {
  .p-chat-group {
    $this: &;
    &-list {
      &__item {

        &-image { width: 60px; }
        .label,
        .value {
          &-group {
            &--title {
              width: calc(100% - 415px);
            }
            .label,
            .value {
              &--title {
                width: calc(100% - 120px);
              }
            }
          }
          &--control {
            max-width: 50px;
            min-width: 50px;
          }
          &--latest-sent-at {
            justify-content: flex-end;
            max-width: 205px;
            min-width: 205px;
          }
          &--relate-type {
            max-width: 80px; 
            min-width: 80px;
          }
          &--reference-type {
            max-width: 80px; 
            min-width: 80px;
          }
          &--image {
            max-width: 60px;
            min-width: 60px;
          }
          &--switch {
            max-width: 60px; 
            min-width: 60px;
          }
        }
      }
    }
  }
}

@media (max-width: 810px) {
  .p-chat-group {
    $this: &;
    &-list {
      &__item {
        &-image { width: 60px; }
        .label,
        .value {
          &-group {
            &--title {
              padding-bottom: 10px;
              width: 100%;
            }
          }
        }
      }
    }
  }
}

@media (max-width: $appBreakPoint) {
  .p-chat-group {
    $this: &;
    margin: 0 auto 16px auto;

    &--search {
      margin-bottom: 0;
    }

    &-head {
      flex-direction: column;
      flex-wrap: wrap;
      width: 100%;
      &__item {
        &--keyword {
          max-width: 100%;
          min-width: 100%;
          width: 100%;
          .c-input {
            width: 100%;
          }
          .form-group {
            width: 100%;
          }
        }
      }
    }

    &-detail {
      &-head {
        padding: 15px 15px 0 15px !important;
        &__item {
          &--title {
            padding: 0 0 8px 0;
            a {
              font-size: $appFontSizeL;
              max-width: calc(100% - 26px);
            }
          }
          &--organization {
            margin-bottom: 10px;
            .c-text { padding-top: 3px; }
          }
          &--sent-at {
            font-size: $appFontSizeS;
            font-weight: $appFontWeightNormal;
            padding-top: 18px;
          }
        }
      }
      &-body {
        padding: 15px !important;
        &__item {
          & + & {
            margin-top: 4px;
          }

          .label {
            font-size: $appFontSizeXS;
            font-weight: $appFontWeightExtraBold;
            justify-content: flex-start;
            max-width: 120px;
            min-width: 120px;
            width: 120px;
            i {
              font-size: 17px;
              margin-right: 4px;
            }
          }
          .value {
            font-size: $appFontSizeS;
            font-weight: $appFontWeightNormal;
            width: calc(100% - 160px);
            .c-badge {
              font-size: $appFontSizeXS;
              height: 26px;
              min-width: 50px;
              padding: 0 6px;
              width: auto;
              &-list {
                &__item {
                  margin: 0 6px 6px 0;
                }
              }
            }
          }
        }
      }
    }

    &-list {
      &__item {
        padding: 10px !important;
        &--head {
          .label {
            &--control {
              display: none !important;
            }
          }
        }
        .col {
          font-size: $appFontSizeS;
          padding: 2.5px !important;
        }
        .col.value--image + .col.value--title {
          padding-left: 5px !important;
        }

        .label,
        .value {
          &-group {
            &--title {
              padding-bottom: 5px;
            }
            &--status,
            &--control {
              width: 50%;
            }
            .label,
            .value {
              &--title {
                width: calc(100% - 102px);
              }
            }
          }
          &--control {
            justify-content: flex-end;
            max-width: 100%;
            min-width: 100%;
          }
          &--title {
            min-width: auto;
          }
          &--relate-type {
            max-width: 75px; 
            min-width: 75px;
          }
          &--reference-type {
            max-width: 75px; 
            min-width: 75px;
          }
          &--image {
            margin: 0 6px;
          }
          &--switch {
            max-width: 30px;
            min-width: 30px;
          }
          &--latest-sent-at {
            justify-content: flex-start;
            max-width: 100%;
            min-width: 100%;
          }
        }

        .value {
          &--switch {
            i { font-size: $appFontSizeS; }
          }
        }
      }
    }
  }
}