.c-select-checkbox {
  $this: &;
  background: transparent;
  border-bottom: 1px $appColorWhite solid;
  border-radius: 0;
  box-sizing: border-box;
  color: $appColorWhite;
  cursor: pointer;
  font-size: $appFontSizeS;
  font-weight: bold;
  height: 35px;
  position: relative;
  width: 100%;

  &.is-single {
    #{$this}-option {
      &-item {
        margin-right: 0;
        width: 100%;
      }
    }
    .form-group {
      width: 100%;
    }
  }

  &__reset {
    box-shadow: none;
    color: $appColorWhite;
    font-size: $appFontSizeS;
    margin: 3px 0 0 0;
    transition: transform 300ms;
  }

  &__dropdown {
    box-shadow: none;
    color: $appColorWhite;
    font-size: 15px;
    margin: 3px -2px 0 0;
    transition: transform 0.15s;
    &.is-active {
      margin-top: 2px;
      transform: rotate(180deg);
    }
  }

  &-value {
    align-items: center;
    display: flex;
    height: 100%;
    margin-top: -1px;
    justify-content: space-between;
    &-item {
      @include ellipsis;
      font-size: $appFontSizeS;
      font-weight: normal;
      flex-grow: 2;
      margin-top: 3px;
      .placeholder {
        color: $appColorWhite;
        opacity: 0.4;
      }
    }
    &-control {
      align-items: center;
      display: flex;
      justify-content: flex-end;
    }
    &-total {
      align-items: center;
      background: $appColorWhite;
      border-radius: 25px;
      color: $appColorBlack;
      display: flex;
      font-size: 10px;
      font-weight: bold;
      height: 13px;
      margin: 3px 5px 0 0;
      justify-content: center;
      // letter-spacing: -0.05em;
      word-break: normal;
      padding: 2px 8px;
      width: auto;
    }
  }
  &-option {
    *::selection {
      background: transparent !important;
      color: $appColorBlack;
    }
    &-item {
      box-sizing: border-box;
      padding: 0 5px 0 0;
      width: 50%;
      &.is-newline {
        margin-right: 0;
        width: 100%;
      }
      &-label {
        box-sizing: border-box;
        color: $appColorBlack;
        cursor: pointer;
        display: block;
        font-size: $appFontSizeS;
        font-weight: $appFontWeightNormal;
        // letter-spacing: -0.05em;
        line-height: 1.35em;
        padding: 4px 0 4px 20px;
      }
    }

    &-item-group {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 15px;
      width: 100%;
      &-label {
        border-bottom: 1px $appColorGrayScale6 solid;
        box-sizing: border-box;
        color: $appColorBlack;
        cursor: pointer;
        font-size: $appFontSizeS;
        font-weight: $appFontWeightNormal;
        display: block;
        // letter-spacing: -0.05em;
        line-height: 1.35em;
        padding: 5px 0 14px 20px;
        margin-bottom: 8px;
        width: 100%;
      }
    }
    &-item-group-wrapper {
      box-sizing: border-box;
      display: flex;
      flex-wrap: wrap;
      max-height: none;
      overflow: auto;
      padding: 7.5px 10px;
      position: relative;
      width: 100%;
    }
  }
  .form-group {
    background: $appColorWhite;
    border-radius: 3px;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), 0 4px 11px rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    cursor: default;
    display: flex;
    max-height: 0;
    max-width: 0;
    overflow: hidden;
    opacity: 0;
    position: absolute;
    top: calc(100% + 9px);
    transition: opacity 0.1s;
    width: 180%;
    z-index: 1500;
    &.is-active {
      align-items: flex-start;
      justify-content: flex-start;
      flex-wrap: wrap;
      max-height: none;
      max-width: none;
      opacity: 1;
      overflow: auto;
    }
    .btn-close {
      color: $appColorBlack;
      font-size: 16px;
      top: 2px;
      position: absolute;
      right: 5px;
    }
  }
  .form-check-input {
    margin: 2px 5px 0 -18px;
  }

  .simplebar-vertical {
    background: $appColorGrayScale8;
    border-radius: 20px;
    margin: 10px 16px 10px 0;
    width: 6px;
    .simplebar-scrollbar {
      background: $appColorGrayScale3;
      border-radius: 20px;
      width: 6px;
    }
  }

  .simplebar-horizontal {
    background: $appColorGrayScale8;
    border-radius: 20px;
    height: 6px;
    margin: 0;
    width: 100%;
    .simplebar-scrollbar {
      background: $appColorGrayScale3;
      border-radius: 20px;
      height: 6px !important;
      top: 0;
    }
  }

  &--static {
    border-bottom: none;
    border-radius: 0;
    color: $appColorBlack;
    height: auto;

    #{$this}-option {
      &.form-group {
        background: transparent;
        box-shadow: none;
        max-height: 360px;
        max-width: none;
        position: relative;
        overflow: inherit;
        opacity: 1;
        width: 100%;
      }
      &-item-group-wrapper {
        padding: 0 20px 0 0;
      }
      &-item {
        &-group {
          &-label {
            border-bottom: 2px $appColorGrayScale6 solid;
            font-size: $appFontSizeBase;
            margin-bottom: 10px;
            padding: 5px 0 10px 26px;
            .form-check-input {
              margin: 2px 5px 0 -24px;
            }
          }
        }
        &-label {
          font-size: $appFontSizeBase;
          padding: 4px 0 4px 26px;
          .form-check-input {
            margin: 2px 5px 0 -24px;
          }
        }
      }
    }
  }
}

@media (max-width: $appBreakPoint) {
  .c-select-checkbox {
    $this: &;
    &-value {
      &-control {
        align-items: center;
        display: flex;
        height: 100%;
        justify-content: flex-end;
      }
    }
    &__reset {
      align-items: center;
      display: flex;
      justify-content: center;
      height: 100%;
      margin: 0 0 0 0;
      width: 30px;
      i {
        margin-top: 1px;
      }
    }
    &__dropdown {
      align-items: center;
      display: flex;
      height: 100%;
      justify-content: center;
      margin: 0 -2px 0 0;
      width: 30px;
      &.is-active {
        margin-top: 0;
      }
    }
    &-option {
      &-item {
        &-label {
          padding-left: 24px;
        }
      }
      &-item-group {
        margin-bottom: 10px;
        &-label {
          padding-left: 24px;
        }
      }
    }
    .form-check-input {
      margin-left: -22px;
    }

    &--static {
      #{$this}-option {
        &-item {
          &-group {
            &-label {
              font-size: $appFontSizeM;
            }
          }
          &-label {
            font-size: $appFontSizeM;
          }
        }
      }
    }
  }
}
