.p-joined-company-member {
  $this: &;
  &-form {
    .c-message {
      margin-bottom: 20px;
    }
    &__item {
      display: flex;
      flex-direction: column;
      margin: 0 0 15px 0;
      position: relative;
      width: 100%;
      .form-group {
        margin-bottom: 0;
        label {
          font-size: $appFontSizeM;
          font-weight: $appFontWeightExtraBold;
          margin-bottom: 5px;
        }
      }
      &--btns {
        margin: 30px 0 20px 0;
        button.c-btn {
          font-size: $appFontSizeL;
        }
        .c-btn {
          margin: 0 auto;
          width: 265px;
        }
      }
    }
    &--preview {
      #{$this}-form {
        &__item {
          .label,
          .value {
            flex-basis: inherit;
            flex-grow: initial;
            font-size: $appFontSizeM;
            margin-bottom: 5px;
            padding: 0;
          }
          .label {
            font-weight: $appFontWeightExtraBold;
          }
          &--btns {
            align-items: center;
            display: flex;
            flex-direction: row;
            justify-content: center;
            .c-btn {
              margin: 0 0;
              width: 190px;
              &--member-type {
                width: 230px;
              }
            }
            .c-btn + .c-btn {
              margin-left: 7.5px;
            }
          }
        }
      }
    }
    &--complete {
      flex-direction: column;
      padding: 45px 0 80px 0;
      #{$this}-form {
        &__item {
          display: flex;
          flex-direction: column;
          flex-wrap: wrap;
          justify-content: center;
          margin: 0;
          padding: 0;
          width: 100%;
          &--image {
            margin-bottom: 30px;
            i {
              color: $appColorBlack;
              font-size: 60px;
              line-height: 60px;
              margin: 0 auto;
            }
          }
          &--caption {
            font-size: $appFontSize3XL;
            font-weight: $appFontWeightBold;
            margin-bottom: 25px;
            text-align: center;
          }
          &--text {
            text-align: center;
            width: 100%;
            p {
              margin-bottom: 0;
            }
          }
          &--btns {
            justify-content: center;
            margin: 35px 0 20px 0;
            .c-btn {
              width: 265px;
            }
          }
        }
      }
    }
  }
}

@media (max-width: $appBreakPoint) {
  .p-joined-company-member {
    $this: &;
    &-form {
      &__item {
        margin: 0 0 15px 0;
        .form-group {
          label {
            font-size: $appFontSizeS;
            margin-bottom: 5px;
          }
          .c-input {
            font-size: $appFontSizeS;
          }
          .c-select {
            &__menu {
              font-size: $appFontSizeS;
            }
            &__single-value {
              color: $appColorBlack !important;
              font-size: $appFontSizeS !important;
            }
          }
        }
        &--btns {
          margin: 20px 0 10px 0;
          .c-btn {
            margin-left: 0;
            margin-right: 0;
            width: 100%;
          }
          .c-btn + .c-btn {
            margin-top: 15px;
          }
        }
      }
      &--preview {
        #{$this}-form {
          &__item {
            margin-bottom: 10px;
            .label,
            .value {
              font-size: $appFontSizeS;
              margin-bottom: 0;
            }
            &--btns {
              // flex-direction: column-reverse !important;
              flex-direction: column !important;
              .c-btn {
                // margin: 15px 0 0 0;
                margin: 0;
                width: 100%;
              }
              .c-btn + .c-btn {
                // margin: 0 0;
                margin: 15px 0 0 0;
              }
            }
          }
        }
      }
      &--complete {
        flex-direction: column;
        padding: 20px 0 10px 0;
        #{$this}-form {
          &__item {
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            justify-content: center;
            margin: 0;
            padding: 0;
            width: 100%;
            &--image {
              margin-bottom: 30px;
              i {
                color: $appColorBlack;
                font-size: 50px;
                line-height: 50px;
                margin: 0 auto;
              }
            }
            &--caption {
              font-size: $appFontSizeL;
              margin-bottom: 10px;
            }
            &--text {
              font-size: $appFontSizeS;
            }
            &--btns {
              margin: 35px 0 0 0;
              .c-btn {
                width: 100%;
              }
            }
          }
        }
      }
    }
  }
}
