.p-company,
.p-company.card {
  $this: &;
  background: $appColorWhite;
  border-radius: $appRadiusXL;
  box-sizing: border-box;
  color: $appColorBlack;
  padding: 45px 40px;
  position: relative;
  width: 100%;
  z-index: 100;

  & + .p-company-deal-list {
    margin-top: 16px;
  }

  &.is-active-deal {
    margin-bottom: 16px;
  }

  .c-badge {
    border-radius: 0;
    font-size: $appFontSizeS;
    height: 30px;
    &--sticker-wanted {
      font-size: $appFontSizeM;
      height: auto;
      position: absolute;
      left: 40px;
      top: -6px;
    }
    &-list {
      &--tag {
        align-items: center;
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        gap: 8px;
      }
    }
  }

  &__image {
    align-items: center;
    background: $appColorWhite;
    border: 8px solid $appColorGrayScale8;
    border-radius: 50%;
    box-sizing: border-box;
    display: flex;
    height: 200px;
    justify-content: center;
    margin: 0 auto;
    overflow: hidden;
    width: 200px;
    img {
      width: 100%;
    }
    &--no-image {
      background: $appColorGrayScale9;
      i {
        color: $appColorGrayScale8;
        font-size: 104px;
      }
    }
  }

  &__data-group {
    align-items: center;
    border-bottom: 1px solid $appColorGrayScale6;
    display: flex;
    flex-grow: 1;
    justify-content: flex-start;
    margin: 0;
    padding: 12.5px 10px;
    width: 100%;
    &--tag {
      border-bottom: none;
      padding-bottom: 0;
    }
  }

  &__data {
    display: inline-block;
    margin: 0;
    padding: 0;
    .label,
    .value {
      box-sizing: border-box;
      display: inline;
      font-size: $appFontSizeM;
      margin-right: 20px;
      padding: 0;
      width: auto;
    }
    .label {
      color: $appColorGrayScale11;
      font-size: $appFontSizeXS;
      font-weight: $appFontWeightExtraBold;
      margin-right: 10px;
    }
    &--name {
      box-sizing: border-box;
      a {
        color: $appColorBlack;
        font-size: $appFontSizePageTitlePc;
        font-weight: $appFontWeightBold;
        line-height: 1.25em;
        text-decoration: none;
        &:active,
        &:visited {
          color: $appColorBlack;
          text-decoration: none;
        }
        &:hover {
          color: $appColorBlack;
          text-decoration: underline;
        }
      }
      i {
        display: inline;
        font-size: $appFontSizeM;
        margin-left: 5px;
      }
    }
    &--address {
      .value {
        p {
          display: inline;
          padding-right: 15px;
        }
      }
    }
    &--site-url {
      a {
        color: $appColorPrimary4;
        font-size: $appFontSizeM;
        position: relative;
        text-align: center;
        text-decoration: none;
        &:hover,
        &:active,
        &:visited {
          color: $appColorPrimary4;
        }
        &:hover {
          text-decoration: underline;
        }
      }
      i {
        display: inline;
        font-size: $appFontSizeM;
        margin-left: 5px;
      }
    }
  }

  &__item {
    &--btns {
      .c-btn {
        &--favorite {
          border-radius: 50%;
          height: 40px;
          margin: 0;
          position: absolute;
          right: 18.5px;
          top: 18.5px;
          transition: right 0.3s, top 0.3s, transform 300ms;
          width: 40px;
          z-index: 1100;
          &:hover {
            transform: scale(1.1);
          }
          i {
            color: #fff;
            font-size: 24px;
            transition: color 300ms;
          }
        }
        &--enter {
          height: 40px;
          margin: 0;
          position: absolute;
          right: 68.5px;
          top: 18.5px;
          width: 120px;
          z-index: 1100;
          // .c-btn__text {
          //   font-size: $appFontSizeM;
          // }
        }
      }
    }
  }

  &-member {
    &__image {
      align-items: center;
      background: $appColorWhite;
      border: 8px solid $appColorGrayScale8;
      border-radius: 50%;
      box-sizing: border-box;
      cursor: pointer;
      display: flex;
      height: 130px;
      justify-content: center;
      margin: 0 auto;
      overflow: hidden;
      padding: 0;
      width: 130px;
      &:hover {
        opacity: 0.9;
      }
      img {
        width: 100%;
      }
      &--no-image {
        background: $appColorGrayScale9;
        i {
          color: $appColorGrayScale8;
          font-size: 97.5px;
        }
      }
    }
    &__value {
      align-items: center;
      cursor: pointer;
      display: flex;
      font-size: $appFontSizeM;
      justify-content: center;
      padding: 10px 0;
      text-align: center;
      width: 100%;
      &--name {
        color: $appColorPrimary4;
        text-decoration: none;
        &:hover,
        &:active,
        &:visited {
          color: $appColorPrimary4;
        }
        &:hover {
          text-decoration: underline;
        }
      }
    }
    &-list {
      align-items: stretch;
      background: transparent;
      border: none;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      list-style: none;
      justify-content: flex-start;
      margin: 0;
      padding: 0;
      width: 100%;
      &__item {
        background: transparent;
        border: none;
        margin: 0 20px 15px 0;
        padding: 0;
      }
    }
  }

  &-sticker-official {
    display: flex;
    flex-direction: column;
    height: 32px;
    width: 100%;
  }

  &-body {
    border: none;
    border-radius: 0;
    padding: 0;
    &__block {
      align-items: flex-start;
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-between;
      margin: 0;
      padding: 20px 0 0 0;
      width: 100%;
    }
    &__item {
      display: flex;
      margin: 0;
      width: 100%;
      &--image {
        display: flex;
        flex-direction: column;
        flex-grow: 0;
        gap: 16px;
        margin-top: 0;
        min-width: 200px;
        width: 200px;
        &.is-sticker {
          padding-top: 12px;
        }
      }

      &--base-data {
        flex-direction: column;
        flex-grow: 1;
        padding-left: 30px;
        width: auto;
      }

      &--activity,
      &--tag,
      &--overview,
      &--member,
      &--mission,
      &--project,
      &--purpose,
      &--vision {
        margin-top: 35px;
        .c-title {
          border: none;
          margin: 0 0 15px 0;
          padding: 0 0 0 5px;
          width: 100%;
          &__text {
            font-size: $appFontSizeXL;
            font-weight: $appFontWeightExtraBold;
            // letter-spacing: -0.05em;
          }
        }
        .c-text {
          background: $appColorGrayScale9;
          border-radius: $appRadiusXL;
          font-size: $appFontSizeM;
          font-weight: $appFontWeightNormal;
          padding: 25px 20px;
          width: 100%;
        }
        a {
          color: $appColorPrimary4;
          text-decoration: none;
          &:hover {
            color: $appColorPrimary4;
            text-decoration: underline;
          }
          &:active,
          &:visited {
            text-decoration: none;
          }
        }
      }

      &--activity {
        position: relative;
        .c-loader-container {
          padding: 80px;
          width: 100%;
        }
      }

      &--tag,
      &--project,
      &--member {
        padding-bottom: 10px;
        .c-title {
          border-bottom: 1px solid $appColorGrayScale6;
          padding-bottom: 15px;
        }
        .empty {
          color: $appColorGrayScale6;
          font-size: $appFontSizeM;
          font-weight: $appFontWeightExtraBold;
        }
        .loading {
          display: flex;
          justify-content: center;
          margin: 10px 0 30px 0;
          width: 100%;
        }
      }

      &--tag {
        margin: 15px 0 0 0;
      }

      &--member {
        margin-top: 55px;
        .c-title {
          margin-bottom: 20px;
        }
        .empty {
          margin: -5px 0 15px 0;
        }
      }

      &--project {
        margin-top: 15px;
        padding-bottom: 0;
        .c-title {
          margin-bottom: 20px;
        }
        .empty {
          margin-top: -5px;
        }
        .p-search {
          margin-bottom: -2%;
          width: 100%;
          &-list {
            margin-bottom: 0;
            width: 100%;
            &__item {
              box-shadow: 0 0 4px rgba(0, 0, 0, 0.1);
              margin: 0 2% 2% 0;
              max-width: none;
              width: 32%;
              &:nth-child(3n) {
                margin-right: 0;
              }
              .c-text {
                background: transparent;
                border-radius: none;
                padding: 0;
              }
            }
          }
        }
      }

      &--more {
        align-items: center;
        display: flex;
        justify-content: center;
        margin: 45px 0 5px 0;
        padding: 0;
        width: 100%;
        &-member {
          margin: 10px 0 20px 0;
        }
        .c-btn {
          margin: 0;
          width: 265px;
        }
      }
    }
  }
}

@media (max-width: 960px) {
  .p-company,
  .p-company.card {
    $this: &;
    &-body {
      &__item {
        &--project {
          .p-search {
            &-list {
              margin-bottom: 0;
              width: 100%;
              &__item {
                box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
                margin: 0;
                max-width: none;
                width: calc(50% - 6px);
                &:nth-child(odd) {
                  margin: 0 12px 2% 0;
                }
                &:nth-child(even) {
                  margin: 0 0 2% 0;
                }
                .c-text {
                  background: transparent;
                  border-radius: none;
                  padding: 0;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: $appBreakPoint) {
  .p-company,
  .p-company.card {
    $this: &;
    border-radius: $appRadiusM;
    padding: 48px 24px 32px 24px;

    &.is-public {
      .p-company-body {
        &__item {
          &--image {
            margin-top: 0;
            &.is-sticker {
              padding-top: 0;
            }
          }
        }
      }
    }

    .c-badge {
      font-size: $appFontSizeXS;
    }

    &__image {
      height: 200px;
      width: 200px;
      img {
        width: 100%;
      }
      &--no-image {
        i {
          font-size: 104px;
        }
      }
    }

    &__data-group {
      flex-direction: column;
      justify-content: flex-start;
      padding: 12.5px 0;
      width: 100%;
      &--tag {
        padding-bottom: 0;
      }
    }

    &__data {
      width: 100%;
      .label,
      .value {
        box-sizing: border-box;
        display: inline;
        font-size: $appFontSizeS;
        margin-right: 0;
        padding: 0;
        width: auto;
      }
      .label {
        font-size: $appFontSizeXS;
        margin-right: 10px;
      }

      &--name {
        a {
          font-size: $appFontSize2XL;
          line-height: 1.25em;
        }
      }

      &--address {
        .value {
          p {
            padding-right: 10px;
          }
        }
      }
      &--site-url {
        a {
          font-size: $appFontSizeS;
        }
        i {
          font-size: $appFontSizeM;
        }
      }
    }

    &__item {
      &--btns {
        .c-btn {
          &--favorite {
            right: 14.5px;
            top: 14.5px;
            &:hover {
              transform: scale(1);
            }
          }
          &--enter {
            display: none;
          }
        }
      }
    }

    &-member {
      &__value {
        font-size: $appFontSizeS;
      }
      &-list {
        &__item {
          margin: 0 12px 12px 0;
        }
      }
    }

    &-sticker-official {
      height: 32px;
    }

    &-body {
      &__block {
        flex-direction: column;
        flex-wrap: wrap;
        padding: 0 0 0 0;
      }

      &__item {
        margin: 0 0 22px 0;
        &--image {
          display: flex;
          flex-grow: 1;
          justify-content: center;
          margin: 10px 0 0 0;
          min-width: auto;
          width: 100%;
        }

        &--base-data {
          flex-direction: column;
          flex-grow: 1;
          margin: 20px 0 30px 0;
          padding: 0;
          width: 100%;
        }
        &--tag,
        &--overview,
        &--member,
        &--mission,
        &--project,
        &--purpose,
        &--vision {
          .c-title {
            margin: 0 0 10px 0;
            padding-left: 0;
            &__text {
              font-size: $appFontSizeL;
            }
          }
          .c-text {
            font-size: $appFontSizeS;
            padding: 15px;
            width: 100%;
          }
          .empty {
            font-size: $appFontSizeS;
          }
        }
        &--tag {
          margin: 10px 0 22px 0;
          padding-bottom: 0;
        }

        &--member {
          margin: 10px 0 22px 0;
          padding-bottom: 0;
          .c-title {
            margin-bottom: 20px;
          }
          .empty {
            margin: -10px 0 20px 0;
          }
        }
        &--project {
          margin: 10px 0 22px 0;
          padding-bottom: 0;
          .c-title {
            margin-bottom: 20px;
          }
          .empty {
            margin-top: -10px;
          }
        }
        &--more {
          margin: 15px 0;
          .c-btn {
            width: 100%;
          }
        }
      }
    }
  }
}

@media (max-width: 572px) {
  .p-company,
  .p-company.card {
    &-member {
      &__image {
        height: 140px;
        width: 140px;
        &--no-image {
          i {
            font-size: 92.5px;
          }
        }
      }
      &-list {
        flex-wrap: wrap;
        justify-content: space-between;
        width: 100%;
        &__item {
          background: transparent;
          border: none;
          margin: 0 0 2% 0;
          min-width: 140px;
          padding: 0;
          width: 50%;
        }
      }
    }

    &-body {
      &__item {
        &--project {
          .p-search {
            margin-bottom: -12px;
            &-list {
              margin-bottom: 0;
              width: 100%;
              &__item {
                box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
                margin: 0 0 12px 0;
                max-width: none;
                width: 100%;
                &:nth-child(odd) {
                  margin: 0 0 12px 0;
                }
                &:nth-child(even) {
                  margin: 0 0 12px 0;
                }
                .c-text {
                  background: transparent;
                  border-radius: none;
                  padding: 0;
                }
              }
            }
          }
        }
      }
    }
  }
}
