.c-title {
  border-bottom: 1px solid $appColorGrayScale11;
  margin: 0 0 30px 0;
  padding: 0 0 25px 15px;
  width: 100%;
  &__text {
    color: $appColorBlack;
    font-size: $appFontSizePageTitlePc;
    font-weight: $appFontWeightExtraBold;
    padding: 0;
  }
}

@media (max-width: $appBreakPoint) {
  .c-title {
    margin: 0 0 20px 0;
    padding: 0 0 15px 10px;
    &__text {
      color: $appColorBlack;
      font-size: $appFontSize2XL;
    }
  }
}
