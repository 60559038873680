.p-favorite {
  $this: &;
  margin: 0 auto 15px auto;

  .row {
    margin: 0;
  }

  &--user {
    #{$this}-list {
      &__item {
        .label,
        .value {
          &-group {
            &--title {
              width: calc(100% - 495px);
            }
            .value {
              &--title {
                justify-content: flex-start;
                min-width: 240px;
              }
            }
          }
        }
      }
    }
  }

  &--company {
    #{$this}-list {
      &__item {
        &-image {
          i {
            font-size: 36px;
          }
        }
        .label,
        .value {
          &-group {
            &--title {
              width: calc(100% - 270px);
            }
            .value {
              &--title {
                justify-content: flex-start;
                min-width: 240px;
              }
            }
          }
        }
      }
    }
  }

  &-list {
    background: none;
    border-radius: $appRadiusM;
    list-style: none;
    margin: 0;
    padding: 0;
    &__item {
      align-items: center;
      background: $appColorWhite;
      border: none;
      // border-bottom: 1px $appColorGrayScale6 solid;
      border-bottom: 1px $appColorGrayScale12 solid;
      border-radius: 0;
      display: flex;
      flex-wrap: wrap;
      list-style: none;
      margin: 0;
      padding: 17.5px 0 10px 0;
      // padding: 12.5px 0 5px 0;
      width: 100%;

      &:last-child {
        border-bottom: none;
      }

      &:hover {
        background: $appColorGrayScale9;
      }

      &--head {
        // background: $appColorGrayScale9;
        background: $appColorGrayScale16;
        padding: 20px 0 12.5px 0;
        .label,
        .value {
          font-weight: $appFontWeightExtraBold;
        }
        &:hover {
          background: $appColorGrayScale9;
        }
      }

      &--empty {
        &:hover {
          background: $appColorWhite;
        }
        .col.value {
          max-width: 100%;
          min-width: 100%;
          padding: 15px 15px !important;
          text-align: center;
          width: 100%;
        }
      }

      &-image {
        align-items: center;
        background: $appColorWhite;
        box-sizing: border-box;
        border: 4px solid $appColorGrayScale8;
        border-radius: 50%;
        display: flex;
        flex-grow: initial;
        justify-content: center;
        height: 64px;
        margin: 0;
        overflow: hidden;
        padding: 0;
        width: 64px;
        img {
          width: 100%;
        }
        i {
          color: $appColorGrayScale8;
          font-size: 48px;
        }
      }

      .col.label,
      .col.value {
        padding: 0 12.5px 5px 12.5px;
      }

      .label,
      .value {
        align-items: center;
        display: flex;
        flex-direction: column;
        flex-grow: 0;
        justify-content: flex-start;
        word-break: break-all;
        &-group {
          align-items: stretch;
          display: flex;
          .value {
            align-items: flex-start;
            display: flex;
            justify-content: center;
            &--title {
              align-items: center;
            }
          }
          &--title {
            flex-grow: 1;
          }
        }
        &--title {
          align-items: center;
          flex-direction: row;
          flex-grow: 1;
          min-width: 250px;
          width: auto;
          a {
            // @include ellipsis;
            color: $appColorBlack;
            text-decoration: none;
            // width: auto;
            &:visited,
            &:active {
              color: $appColorBlack;
            }
            &:hover {
              color: $appColorBlack;
              text-decoration: underline;
            }
          }
          i {
            margin-left: 5px;
          }
        }
        &--control {
          max-width: 60px;
          min-width: 60px;
          width: auto;
        }
        &--project-expired-on {
          max-width: 135px;
          min-width: 135px;
          width: auto;
        }
        &--project-prices {
          max-width: 160px;
          min-width: 160px;
          width: auto;
        }

        &--project-recruit-status {
          max-width: 140px;
          min-width: 140px;
          width: auto;
        }

        &--project-status {
          max-width: 120px;
          min-width: 120px;
          width: auto;
        }
        &--user-working-status {
          max-width: 225px;
          min-width: 225px;
          width: auto;
        }
        &--created-at {
          max-width: 210px;
          min-width: 210px;
          width: auto;
        }
      }

      .value {
        &--image {
          padding-right: 0 !important;
        }
        &--project-status {
          align-items: center;
          display: flex;
        }
        &--control {
          align-items: center;
          cursor: pointer;
          display: flex;
          text-align: center;
          i {
            font-size: 26px;
          }
        }
        &--project-expired-on {
          .date,
          .diff {
            display: block;
            width: 100%;
          }
        }
        &--project-prices {
          .max,
          .min {
            display: block;
            width: 100%;
          }
        }
        .c-badge {
          border-radius: $appRadiusM;
          font-size: $appFontSizeS;
          height: 30px;
          min-width: 70px;
          padding: 0 10px;
          width: auto;
          &--project-recruit-status-subscribe {
          }
        }
      }

      .label {
        align-items: flex-start;
      }
    }
  }
}

@media (max-width: 900px) {
  .p-favorite {
    $this: &;
    &--project,
    &--user {
      #{$this}-list {
        &__item {
          .label,
          .value {
            &-group {
              justify-content: space-between;
              width: 100%;
              &--title {
                width: 100%;
              }
            }
            &--control {
              flex-grow: 1;
              max-width: none;
              justify-content: flex-end;
              width: auto;
            }
          }
        }
      }
    }
  }
}

@media (max-width: $appBreakPoint) {
  .p-favorite {
    $this: &;
    margin: 0 auto 16px auto;
    &-list {
      &__item {
        padding: 12.5px 10px 10px 10px !important;
        &--head {
          .label {
            &--control {
              display: none !important;
            }
          }
        }
        .col {
          font-size: $appFontSizeS;
          padding: 2.5px !important;
        }
        .col.value--image + .col.value--title {
          padding-left: 10px !important;
        }
        .value-group {
          .value {
            &--control {
              align-items: flex-end;
              // justify-content: flex-end;
              margin-top: 10px !important;
              max-width: 100%;
              min-width: 100%;
              width: 100%;
            }
          }
        }
      }
    }

    &--project {
      #{$this}-list {
        &__item {
          .label,
          .value {
            &-group {
              justify-content: space-between;
              width: 100%;
              &--title {
                width: 100%;
              }
            }
            &--project-recruit-status,
            &--project-expired-on,
            &--project-prices,
            &--project-status {
              max-width: 50%;
              min-width: 50%;
              width: 50%;
            }
          }
        }
      }
    }

    &--user {
      #{$this}-list {
        &__item {
          .label,
          .value {
            &-group {
              justify-content: space-between;
              width: 100%;
              &--title {
                width: 100%;
              }
            }
            &--user-working-status,
            &--created-at {
              max-width: 50%;
              min-width: 50%;
              width: 50%;
            }
          }
        }
      }
    }

    &--company {
      #{$this}-list {
        &__item {
          .label,
          .value {
            &-group {
              justify-content: space-between;
              width: 100%;
              &--title {
                width: 100%;
              }
            }
            &--created-at {
              width: 100%;
            }
          }
        }
      }
    }
  }
}
