.p-consent {
  $this: &;

  &,
  &.card {
    background: $appColorWhite;
    border-radius: 8px;
    box-sizing: border-box;
    padding: 35px 40px;
    width: 100%;
  }

  &__text {
    color: $appColorBlack;
    font-size: $appFontSizeM;
    line-height: 1.75em;
    padding: 0;
    .readme {
      display: block;
      padding-bottom: 12px;
    }
  }

  &__date {
    color: $appColorBlack;
    font-size: $appFontSizeM;
    line-height: 1.75em;
    padding: 15px 0 0 0;
    text-align: right;
    width: 100%;
  }

  &__supplementary-provisions {
    color: $appColorBlack;
    display: flex;
    flex-wrap: wrap;
    font-size: $appFontSizeM;
    justify-content: flex-end;
    padding-top: 16px;
    width: 100%;
    .caption {
      font-weight: $appFontWeightExtraBold;
      margin: 0;
      padding: 0;
      text-align: right;
      width: 100%;
    }
    .date {
      margin: 0;
      padding: 0;
      text-align: right;
      width: 100%;
    }
  }

  &-head {
    box-sizing: border-box;
    border-bottom: 2px solid $appColorGrayScale7;
    border-radius: 0;
    padding: 0 0 20px 0;
    margin: 0 0 20px 0;
  }

  &--privacy {
    #{$this}-head {
      display: flex;
      justify-content: flex-end;
    }
  }

  &-list {
    line-height: 1.75em;
    padding: 0;
    min-height: auto;
    dl {
      margin-bottom: 0;
    }
    &__title {
      color: $appColorBlack;
      font-size: $appFontSizeL;
      font-weight: $appFontWeightExtraBold;
      margin-bottom: 10px;
      span {
        padding-right: 7.5px;
      }
    }
    &__caption {
      color: $appColorBlack;
      font-weight: $appFontWeightExtraBold;
      margin: 10px 0;
      span {
        padding-right: 7.5px;
      }
    }
    &__text {
      color: $appColorBlack;
      font-size: $appFontSizeM;
      margin-bottom: 25px;
      &:last-child {
        margin-bottom: 0;
      }
      a {
        color: $appColorPrimary4;
        text-decoration: underline;
        &:hover,
        &:active,
        &:visited {
          color: $appColorPrimary4;
        }
        &:hover {
          text-decoration: underline;
        }
      }
    }
    &--child {
      padding: 10px 0 0 35px;
      ol,
      ul {
        padding: 0;
      }
    }
    &--child-sub {
      padding: 0 0 0 20px;
      ol {
        // list-style-type: lower-roman;
        padding: 5px 0;
      }
    }
    &--nobr {
      padding-top: 0;
    }
  }

  &-table {
    line-height: 1.75em;
    padding: 0;
    min-height: auto;
    &__item {
      align-items: flex-start;
      border-bottom: 1px solid $appColorGrayScale7;
      box-sizing: border-box;
      display: flex;
      margin: 0 0 0 0;
      padding: 0 0 20px 0;
      &:last-child {
        border-bottom: none;
        padding-bottom: 0;
      }
    }
    &__item + &__item {
      margin-top: 20px;
    }
    &__label {
      flex-basis: 180px;
      flex-grow: initial;
      font-size: $appFontSizeM;
      font-weight: $appFontWeightBold;
      padding: 0 0 0 0;
      width: 180px;
    }
    &__value {
      flex-basis: fit-content;
      flex-grow: 99;
      font-size: $appFontSizeM;
      padding: 0 0 0 0;
      a {
        color: $appColorPrimary4;
        font-weight: $appFontWeightExtraBold;
        &:hover,
        &:active,
        &:visited {
          color: $appColorPrimary4;
        }
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}

@media (max-width: $appBreakPoint) {
  .p-consent {
    $this: &;

    &,
    &.card {
      background: $appColorWhite;
      border-radius: $appRadiusM;
      padding: 24px 24px;
    }

    &__text {
      font-size: $appFontSizeS;
      line-height: 1.5em;
    }

    &__date {
      font-size: $appFontSizeS;
      line-height: 1.5em;
      padding: 15px 0 0 0;
    }

    &__supplementary-provisions {
      font-size: $appFontSizeS;
      padding-top: 20px;
    }

    &-head {
      padding: 0 0 18px 0;
      margin: 0 0 18px 0;
    }

    &-list {
      line-height: 1.5em;
      &__title {
        font-size: $appFontSizeM;
        font-weight: $appFontWeightExtraBold;
        margin-bottom: 7.5px;
        span {
          padding-right: 5px;
        }
      }
      &__caption {
        margin: 7.5px 0;
        span {
          padding-right: 5px;
        }
      }
      &__text {
        color: $appColorBlack;
        font-size: $appFontSizeS;
        line-height: 1.5em;
        margin-bottom: 15px;
      }
      &--child {
        padding: 10px 0 0 25px;
      }
      &--child-sub {
        padding: 0 0 0 15px;
      }
      &--nobr {
        padding-top: 0;
      }
    }

    &-table {
      line-height: 1.5em;
      &__item {
        flex-direction: column;
        padding: 0 0 10px 0;
      }
      &__item + &__item {
        margin-top: 10px;
      }
      &__label {
        flex-basis: auto;
        font-size: $appFontSizeS;
        font-weight: $appFontWeightExtraBold;
        width: auto;
        &::before {
          content: '\25A0';
          padding-right: 2.5px;
        }
      }
      &__value {
        font-size: $appFontSizeS;
      }
    }
  }
}
