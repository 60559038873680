.p-contact {
  $this: &;

  &,
  &.card {
    background: $appColorWhite;
    border-radius: 8px;
    box-sizing: border-box;
    padding: 45px 40px;
    position: relative;
    width: 100%;
    z-index: 100;    
  }

  &__block {
    padding: 0;
    min-height: auto;
  }
  &-form {
    &__item {
      display: flex;
      flex-direction: column;
      margin: 0 0 25px 0;
      position: relative;
      width: 100%;
      .form-group {
        margin-bottom: 0;
        label {
          font-size: $appFontSizeM;
          font-weight: $appFontWeightExtraBold;
          margin-bottom: 5px;
        }
        legend {
          font-size: $appFontSizeM;
          font-weight: $appFontWeightExtraBold;
          margin-bottom: 0;
        }
        &.c-radio {
          label {
            font-weight: $appFontWeightNormal;
          }
        }
      }
      .c-input {
        &--message {
          height: 250px;
          min-height: 250px;
        }
      }
      &--btns {
        margin: 45px 0 5px 0;
        button.c-btn {
          font-size: $appFontSizeL;
        }
        .c-btn {
          margin: 0 auto;
          width: 265px;
        }
      }
    }
  }

  &--preview {
    &,
    &.card {
      flex-wrap: wrap;
      padding-top: 55px;
    }

    #{$this}-form {
      flex-wrap: wrap;
      &__item {
        border-bottom: 1px solid $appColorGrayScale6;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        margin-bottom: 20px;
        padding-bottom: 15px;
        .label {
          flex-basis: auto;
          flex-grow: 0;
          font-size: $appFontSizeM;
          font-weight: $appFontWeightExtraBold;
          padding: 0 0 0 0;
          min-width: 220px;
          width: 220px;
        }
        .value {
          flex-basis: auto;
          flex-grow: 1;
          font-size: $appFontSizeM;
          padding: 0 0 0 0;
          width: calc(100% - 220px);
        }
        &--btns  {
          border-bottom: 0;
          justify-content: center;
          margin: 45px 0 5px 0;
          padding-bottom: 0;
          .c-btn {
            margin: 0 7.5px;
          }
        }
      }
    }
  }

  &--complete {
    #{$this}-form {
      flex-wrap: wrap;
      &__item {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        margin: 0;
        padding: 0;
        width: 100%;
        &--image {
          color: $appColorGrayScale3;
          padding: 20px 0 30px 0;
          i {
            font-size: 110px;
            line-height: 110px;
            margin: 0 auto;
          }
        }
        &--text {
          font-size: $appFontSize2XL;
          justify-content: center;
        }
        &--supplement {
          flex-direction: column;
          font-size: $appFontSizeBase;
          margin-top: 36px;
          justify-content: center;
          p {
            margin-bottom: 4px;
            text-align: center;
            width: 100%;
            &.warning {
              color: $appColorFreeRed;
              font-weight: $appFontWeightExtraBold;
            }
          }
        }
        &--btns {
          justify-content: center;
          margin: 45px 0;
          .c-btn {
            width: 265px;
          }
        }
      }
    }
  }
}

.c-message + .p-contact,
.c-message + .p-contact.card {
  margin-top: 30px;
}

@media (max-width: $appBreakPoint) {
  .p-contact {
    $this: &;

    &,
    &.card {
      border-radius: $appRadiusM;
      padding: 32px 24px;
    }

    &-form {
      &__item {
        margin: 0 0 15px 0;
        .form-group {
          label {
            font-size: $appFontSizeS;
            margin-bottom: 5px;
          }
          .c-input {
            font-size: $appFontSizeS;
          }
        }
        .c-radio {
          legend {
            font-size: $appFontSizeS;
          }
          .form-control {
            padding: 10px 0 0 0!important;
          }
          &__item {
            margin: 0 0 5px 0;
            width: 100%;
          }
        }
        &--radio {
          margin: 5px 0;
        }
        &--btns {
          margin: 32px 0 0 0;
          .c-btn {
            margin-left: 0;
            margin-right: 0;
            width: 100%;
          }
        }
      }
    }

    &--preview {
      #{$this}-form {
        flex-wrap: wrap;
        &__item {
          flex-direction: column;
          margin-bottom: 15px;
          padding-bottom: 10px;
          .label {
            font-size: $appFontSizeS;
            min-width: 100%;
            width: 100%;
          }
          .value {
            box-sizing: border-box;
            font-size: $appFontSizeS;
            padding-left: 0;
            width: 100%;
          }
          &--btns  {
            display: flex;
            flex-direction: column;
            margin: 32px 0 0 0;
            .c-btn {
              margin: 0;
              width: 100%;
            }
            .c-btn + .c-btn {
              // margin: 0 0 15px 0;
              margin: 15px 0 0 0;
            }
          }
        }
      }
    }

    &--complete {
      #{$this}-form {
        &__item {
          &--image {
            i {
              font-size: 80px;
              line-height: 80px;
              margin: 0 auto;
            }
          }
          &--text {
            font-size: $appFontSizeL;
            justify-content: center;
          }
          &--supplement {
            font-size: $appFontSizeS;
            margin-top: 18px;
          }
          &--btns {
            // flex-direction: column-reverse;
            flex-direction: column;
            justify-content: center;
            margin: 45px 0 20px 0;
            .c-btn {
              margin: 0;
              width: 100%;
            }
            .c-btn + .c-btn {
              // margin: 0 0 15px 0;
              margin: 15px 0 0 0;
            }
          }
        }
      }
    }
  }
}
