.p-account-profile {
  $this: &;
  &--edit,
  &--edit.card,
  &--preview,
  &--preview.card,
  &--complete,
  &--complete.card {
    background: $appColorWhite;
    border-radius: 8px;
    box-sizing: border-box;
    margin-top: 20px;
    padding: 45px 40px;
    width: 100%;
    #{$this}__block {
      padding: 0;
      min-height: auto;
    }
    #{$this}-form {
      align-items: flex-start;
      display: flex;
      justify-content: space-between;
      margin: 0;
      &__block {
        &--image {
          flex-grow: 0;
          width: 200px;
        }
        &--base-data {
          box-sizing: border-box;
          flex-grow: 1;
          padding: 0 0 0 50px;
          width: calc(100% - 200px);
          #{$this}-form__item {
            &:first-child {
              padding-top: 0;
            }
          }
        }
      }
      &__supply-text {
        &--code {
          font-size: $appFontSizeL;
          margin: 0 0 0 0;
        }
        &--code-detail {
          font-size: $appFontSizeM - 2;
          font-weight: $appFontWeightNormal;
          margin: 5px 0 0 0;
        }
        &--display-name-used {
          color: $appColorFreeRed;
          font-size: $appFontSizeS;
          font-weight: $appFontWeightExtraBold;
          margin: -12px 0 0px 0px;
        }
        &--tel {
          color: $appColorFreeRed;
          font-size: $appFontSizeS;
          font-weight: $appFontWeightExtraBold;
          margin: 5px 0 0 0;
        }
        &--invite-code {
          background: $appColorFreeGlassRed;
          border-radius: $appRadiusM;
          box-sizing: border-box;
          color: $appColorFreeRed;
          font-size: $appFontSizeM;
          font-weight: $appFontWeightExtraBold;
          margin: 25px 0 25px 0;
          padding: 18px 24px 14px 24px;
        }
      }
      &__item {
        display: flex;
        flex-direction: column;
        margin: 0 0 25px 0;
        position: relative;
        width: 100%;
        &--name {
          flex-direction: row;
          flex-wrap: nowrap;
          gap: 0 18px;
          transition: gap 0.3s;
          .form-group {
            width: calc(50% - 9px);
            .invalid-feedback {
              bottom: 48px;
              top: auto;
            }
          }
          &.is-active {
            background: $appColorGrayScale8;
            border-radius: $appRadiusM;
            padding: 12px 12px;
            transition: background 0.3s;
            position: relative;
            &::after {
              background: $appColorPrimary1;
              border-radius: 0 $appRadiusM 0 0;
              color: $appColorWhite;
              content: '\9078\629E\4E2D';
              font-size: $appFontSizeS - 1;
              font-weight: $appFontWeightExtraBold;
              padding: 2px 12px;
              position: absolute;
              right: 0;
              top: 0;
              z-index: 1100;
            }
            .form-group {
              .invalid-feedback {
                z-index: 1200;
              }
            }
          }
        }

        &--display-name {
          &.is-active {
            background: $appColorGrayScale8;
            border-radius: $appRadiusM;
            padding: 12px 12px;
            transition: background 0.3s;
            position: relative;
            &::after {
              background: $appColorPrimary1;
              border-radius: 0 $appRadiusM 0 0;
              color: $appColorWhite;
              content: '\9078\629E\4E2D';
              font-size: $appFontSizeS - 1;
              font-weight: $appFontWeightExtraBold;
              padding: 2px 12px;
              position: absolute;
              right: 0;
              top: 0;
              z-index: 1100;
            }
            .form-group {
              .invalid-feedback {
                z-index: 1200;
              }
            }
          }
        }

        &--code {
          margin: 0 0 0 0;
          .form-group {
            &--row {
              display: flex;
              align-items: center;
              gap: 4px;
              .form-group {
                flex-grow: 2;
              }
            }
          }
          .c-btn {
            &--badge-gray {
              font-size: $appFontSizeS;
              height: 25px;
              .c-btn__text {
                font-size: $appFontSizeS;
              }
            }
          }
          .invalid-feedback {
            bottom: 48px;
            top: auto;
            z-index: 1000;
          }
          .supply-text {
            br {
              display: none;
            }
            font-size: $appFontSizeS;
            color: $appColorGrayScale1;
            margin: 3px 0 0 0;
          }
        }

        &--half {
          .form-group {
            width: 50%;
          }
        }
        &--radio {
          margin: 0 0 0 0;
          padding: 10px 0;
        }
        &--image {
          .invalid-feedback {
            bottom: 100%;
            top: auto;
            width: 220px;
            z-index: 1000;
          }
        }
        &--invite-code {
          margin-bottom: 0;
          &.has-invite-code {
            margin-top: 45px;
          }
          .c-title {
            border: none;
            display: flex;
            flex-wrap: wrap;
            margin: 0 5px 5px 0;
            padding: 0;
            width: 100%;
            label {
              margin-left: 30px;
            }
          }
          .c-tooltip {
            position: absolute;
            width: 500px;
          }
        }
        &--birthday {
          .form-group {
            align-items: center;
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-start;
            .year,
            .month,
            .day {
              align-items: center;
              display: flex;
              justify-content: flex-start;
            }
            .year {
              width: 105px;
              .c-select {
                width: 95px;
              }
            }
            label {
              width: 100%;
            }
            .col {
              &.unit {
                flex-basis: auto;
                flex-grow: 0;
                font-size: $appFontSizeS;
                padding: 0 5px;
                text-align: center;
                width: 30px;
              }
            }
            .invalid-feedback {
              left: 115px;
              right: auto;
              top: -15px;
            }
          }
          .c-select {
            &-wrapper {
              align-items: center;
              display: flex;
              justify-content: flex-start;
              width: auto;
            }
            &--year {
              width: 95px;
            }
            &--month {
              width: 80px;
            }
            &--day {
              width: 80px;
            }
          }
        }
        &--work-experience {
          margin: 0 0 0 0;
          &-career {
            margin-top: 35px;
          }
          &-skill {
            margin-top: 35px;
          }
          &-language {
            margin-top: 35px;
          }
        }
        .form-group {
          margin-bottom: 0;
          label {
            font-size: $appFontSizeM;
            font-weight: $appFontWeightExtraBold;
            margin-bottom: 5px;
          }
          .c-switch {
            label {
              font-weight: $appFontWeightNormal;
            }
          }
          legend {
            font-size: $appFontSizeM;
            font-weight: $appFontWeightExtraBold;
            margin-bottom: 0;
          }
          &.c-radio {
            label {
              font-weight: $appFontWeightNormal;
            }
          }
        }
        &--btns {
          margin: 45px 0 5px 0;
          button.c-btn {
            font-size: $appFontSizeL;
          }
          .c-btn {
            margin: 0 auto;
            width: 265px;
          }
        }
      }
    }
  }

  &--preview,
  &--preview.card {
    padding-top: 55px;
    #{$this}-form {
      flex-wrap: wrap;
      &__item {
        align-items: center;
        border-bottom: 1px solid $appColorGrayScale6;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        margin-bottom: 20px;
        padding-bottom: 15px;
        .label {
          flex-basis: auto;
          flex-grow: 0;
          font-size: $appFontSizeM;
          font-weight: $appFontWeightExtraBold;
          padding: 0 0 0 0;
          min-width: 120px;
          width: 120px;
          &--name {
            &.is-active {
              min-width: 120px;
            }
          }
          &--display-name {
            &.is-active {
              min-width: 170px;
            }
          }
          &.is-active {
            align-items: center;
            display: flex;
            &::before {
              background: $appColorPrimary1;
              border-radius: $appRadiusM;
              color: $appColorWhite;
              content: '\9078\629E\4E2D';
              font-size: $appFontSizeS - 1;
              font-weight: $appFontWeightExtraBold;
              margin-right: 8px;
              padding: 2px 8px;
            }
          }
        }
        .value {
          flex-basis: auto;
          flex-grow: 1;
          font-size: $appFontSizeM;
          padding: 0 0 0 0;
          width: auto;
        }
        &--image {
          border: 8px solid $appColorGrayScale8;
          border-radius: 50%;
          box-sizing: border-box;
          height: 200px;
          margin: 0;
          overflow: hidden;
          padding: 0;
          position: relative;
          width: 200px;
          z-index: 100;
          .p-profile {
            align-items: center;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            height: 100%;
            padding: 0;
            width: 100%;
            .preview {
              width: 100%;
            }
          }
          .no-image {
            align-items: center;
            background: $appColorGrayScale9;
            display: flex;
            flex-direction: column;
            height: 100%;
            justify-content: center;
            transition: background 300ms;
            width: 100%;
            i {
              color: $appColorGrayScale8;
              // font-size: 90px;
              font-size: 130px;
              line-height: 60px;
              margin-top: 10px;
              transition: color 300ms;
            }
            .text {
              color: $appColorGrayScale5;
              font-size: $appFontSizeM;
              font-weight: $appFontWeightExtraBold;
              margin-top: 10px;
              position: absolute;
              transition: color 300ms, opacity 300ms;
              width: 145px;
            }
          }
        }
        &--btns,
        &--consent,
        &--invite-code,
        &--work-experience {
          border-bottom: 0;
        }
        &--work-experience {
          flex-wrap: wrap;
          &-career,
          &-skill,
          &-language {
            margin-bottom: 0;
            padding-bottom: 0;
          }
          .p-work-experience {
            &-skill {
              &-list {
                &__item {
                  .p-work-experience-skill-data__title {
                    &.card-header {
                      padding-top: 4px;
                      padding-bottom: 12px;
                    }
                  }
                  .p-work-experience-skill__icon {
                    top: 4px;
                    &.is-large {
                      top: -2px;
                    }
                  }
                  &--no-detail {
                    padding-bottom: 12px;
                    .p-work-experience-skill-data__title {
                      &.card-header {
                        padding-top: 4px;
                        padding-bottom: 0;
                      }
                    }
                    &:last-child {
                      padding-bottom: 0;
                      .p-work-experience-skill-data__title {
                        padding-bottom: 0;
                        &.is-large {
                          padding-bottom: 6px;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        &--invite-code {
          background: $appColorGrayScale8;
          border-radius: $appRadiusM;
          box-sizing: border-box;
          padding: 18px 24px 14px 24px;
          margin-top: 20px;
        }

        &--btns {
          justify-content: center;
          margin-bottom: 5px;
          padding-bottom: 0;
          .c-btn {
            margin: 0 7.5px;
          }
        }

        &--consent {
          margin-bottom: 0;
          padding-bottom: 0;
          .nav {
            align-items: center;
            box-sizing: border-box;
            display: flex;
            justify-content: flex-start;
            padding-left: 33px;
            width: 100%;
            .separator {
              display: block;
              flex-basis: auto;
              flex-grow: 0;
              text-align: center;
              width: 30px;
            }
            a {
              color: $appColorPrimary4;
              font-size: $appFontSizeM;
              // font-weight: $appFontWeightExtraBold;
              position: relative;
              text-align: center;
              &:hover,
              &:active,
              &:visited {
                color: $appColorPrimary4;
              }
              &:hover {
                text-decoration: underline;
              }
            }
          }
          .form-group {
            background: $appColorGrayScale9;
            border-radius: $appRadiusM;
            box-sizing: border-box;
            padding: 25px;
            width: 100%;
            .invalid-feedback {
              left: 25px;
              right: auto;
              top: -10px;
              &::before {
                left: auto;
                right: auto;
              }
            }
          }
          .c-checkbox {
            &__item {
              .custom-control-label {
                font-weight: $appFontWeightNormal;
                padding-left: 32px;
                &::before,
                &::after {
                  margin-top: 1px;
                }
              }
            }
          }
        }
      }
    }
  }

  &--complete,
  &--complete.card {
    #{$this}-form {
      flex-direction: column;
      &__item {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        margin: 0;
        padding: 0;
        width: 100%;
        &--image {
          color: $appColorGrayScale3;
          padding: 20px 0 30px 0;
          i {
            font-size: 110px;
            line-height: 110px;
            margin: 0 auto;
          }
        }
        &--text {
          font-size: $appFontSize2XL;
          justify-content: center;
        }
        &--btns {
          justify-content: center;
          margin: 45px 0;
          .c-btn {
            width: 265px;
          }
        }
      }
    }
  }
}

@media (max-width: 960px) {
  .p-account-profile {
    $this: &;
    &--edit,
    &--edit.card {
      #{$this}-form {
        &__item {
          &--name {
            gap: 0 12px;
            .form-group {
              width: calc(50% - 6px);
            }
          }
        }
      }
    }
  }
}

@media (max-width: $appBreakPoint) {
  .p-account-profile {
    $this: &;
    &--edit,
    &--edit.card,
    &--preview,
    &--preview.card,
    &--complete,
    &--complete.card {
      border-radius: $appRadiusM;
      padding: 32px 24px;
      #{$this}-form {
        flex-direction: column;
        &__item {
          margin: 0 0 15px 0;
          &--code {
            margin: 0 0 0 0;
            .form-group{
              &--row {
                margin-left: 0;
                .form-group {
                  width: min-content;
                }
              }
            }
            .supply-text {
              br {
                display: block;
              }
              font-size: $appFontSizeS;
              margin: 3px 0 0 0;
            }
          }
          &--image {
            .invalid-feedback {
              width: auto;
            }
          }
        }
        &__supply-text {
          &--display-name-used {
            font-size: $appFontSizeS - 1;
            margin: 0 0 4px 0px;
          }
          &--tel {
            font-size: $appFontSizeS - 1;
            margin: 5px 0 0 0;
          }
          &--invite-code {
            font-size: $appFontSizeS;
            margin: 20px 0 20px 0;
            padding: 14px 16px 12px 16px;
          }
        }
        &__block {
          &--image {
            margin-bottom: 10px;
            width: 100%;
            #{$this}-form {
              &__item {
                .form-group {
                  display: flex;
                  justify-content: center;
                }
                .c-upload-supply {
                  display: none;
                }
              }
            }
          }
          &--base-data {
            padding-left: 0;
            width: 100%;
            #{$this}-form {
              &__item {
                .form-group {
                  label {
                    font-size: $appFontSizeS;
                    margin-bottom: 5px;
                  }
                  .c-input {
                    font-size: $appFontSizeS;
                  }
                }
                .c-radio {
                  legend {
                    font-size: $appFontSizeS;
                  }
                  .form-control {
                    padding: 10px 0 0 0 !important;
                  }
                  &__item {
                    margin: 0 0 5px 0;
                    width: 100%;
                  }
                }
                &--radio {
                  margin: 0;
                }
                &--display-name {
                  &.is-active {
                    &::after {
                      font-size: $appFontSizeXS;
                    }
                  }
                }
                &--name {
                  flex-direction: column;
                  flex-wrap: wrap;
                  gap: 15px 0;
                  margin-bottom: 15px;
                  &.is-active {
                    &::after {
                      font-size: $appFontSizeXS;
                    }
                  }
                  .form-group {
                    width: 100%;
                    .invalid-feedback {
                      bottom: auto;
                      top: -15px;
                    }
                  }
                }
                &--birthday {
                  .form-group {
                    label {
                      width: 100%;
                    }
                    .c-select {
                      &-wrapper {
                        margin-bottom: 10px;
                        width: 100%;
                        &:last-child {
                          margin-bottom: 0;
                        }
                      }
                      &--year,
                      &--month,
                      &--day {
                        width: 100%;
                      }
                    }
                    .col {
                      &.unit {
                        flex-basis: auto;
                        flex-grow: 0;
                        font-size: $appFontSizeS;
                        padding: 0 5px;
                        text-align: center;
                        width: 30px;
                      }
                    }
                    .invalid-feedback {
                      left: auto;
                      right: 0;
                      top: -15px;
                    }
                  }
                }
              }
            }
          }
        }
        &__item {
          &--invite-code {
            margin-bottom: 0;
            .c-title {
              margin-bottom: 0;
              label {
                font-size: $appFontSizeS;
                margin-left: 25px;
              }
            }
            .c-tooltip {
              width: 100%;
              &__container {
                box-sizing: border-box;
                bottom: 0;
                padding-right: 30px;
                width: 100%;
              }
            }
          }
          &--work-experience {
            margin: 0 0 0 0;
            &-career {
              margin: 25px 0 0 0;
            }
            &-skill {
              margin: 25px 0 0 0;
            }
            &-language {
              margin: 25px 0 0 0;
            }
          }
          &--btns {
            margin: 32px 0 0 0;
            .c-btn {
              margin-left: 0;
              margin-right: 0;
              width: 100%;
            }
          }
        }
      }
    }
    &--preview,
    &--preview.card {
      #{$this}-form {
        &__block {
          &--image {
            display: flex;
            justify-content: center;
            margin-bottom: 35px;
            width: 100%;
          }
          &--base-data {
            #{$this}-form {
              &__item {
                margin-bottom: 15px;
                padding-bottom: 10px;
                .label {
                  font-size: $appFontSizeS;
                  &--name {
                    &.is-active {
                      min-width: 120px;
                    }
                  }
                  &--display-name {
                    &.is-active {
                      min-width: 170px;
                    }
                  }
                  &.is-active {
                    &::before {
                      font-size: $appFontSizeXS;
                    }
                  }
                }
                .value {
                  font-size: $appFontSizeS;
                }
              }
            }
          }
        }
        &__item {
          &--invite-code {
            margin: 15px 0;
            padding: 14px 16px 12px 16px;
            .label {
              font-size: $appFontSizeS;
            }
            .value {
              font-size: $appFontSizeS;
            }
          }
          &--btns {
            display: flex;
            // flex-direction: column-reverse;
            flex-direction: column;
            margin: 32px 0 0 0;
            .c-btn {
              margin: 0;
              width: 100%;
            }
            .c-btn + .c-btn {
              // margin: 0 0 15px 0;
              margin: 15px 0 0 0;
            }
          }
          &--consent {
            margin-bottom: 0;
            padding-bottom: 0;
            .nav {
              padding-left: 33px;
              width: 100%;
              a {
                font-size: $appFontSizeS;
              }
            }
            .form-group {
              padding: 5px 20px;
              .invalid-feedback {
                top: -20px;
              }
            }
            .c-checkbox {
              &__item {
                .custom-control-label {
                  font-size: $appFontSizeS;
                }
              }
            }
          }
          &--work-experience {
            .p-work-experience {
              &-skill {
                &-list {
                  &__item {
                    .p-work-experience-skill-data__title {
                      &.card-header {
                        padding-top: 0;
                        padding-bottom: 4px;
                      }
                      &.is-large {
                        &.card-header {
                          padding-top: 0;
                          padding-bottom: 4px;
                        }
                      }
                      &:last-child {
                        padding-bottom: 0;
                      }
                    }
                  }
                }
              }
              &-language {
                &-data {
                  &__title {
                    &.card-header {
                      .group {
                        width: 100%;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    &--complete,
    &--complete.card {
      #{$this}-form {
        &__item {
          &--image {
            i {
              font-size: 80px;
              line-height: 80px;
              margin: 0 auto;
            }
          }
          &--text {
            font-size: $appFontSizeL;
            justify-content: center;
          }
          &--btns {
            justify-content: center;
            margin: 45px 0 20px 0;
            .c-btn {
              width: 100%;
            }
          }
        }
      }
    }
  }
}
